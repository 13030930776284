import {
  REQUEST_CURRENCY_BALANCE_SHEETS,
  RECEIVE_CURRENCY_BALANCE_SHEETS,
} from '../actions/currencyBalanceSheets';

const defaultOrder = {
  field: 'account_name',
  direction: 'asc',
};

const initialState = {
  processingGroups: {},
  defaultOrder,
};

export default function processingGroupSortingReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CURRENCY_BALANCE_SHEETS:
      return {
        ...state,
        processingGroups: {
          ...state.processingGroups,
          [action.payload.processingGroupId]: {
            ...action.payload.order,
          },
        },
      };
    case RECEIVE_CURRENCY_BALANCE_SHEETS: {
      return {
        ...state,
        processingGroups: {
          ...state.processingGroups,
          [action.payload.processingGroupId]: {
            ...action.payload.order,
          },
        },
      };
    }
    default:
      return state;
  }
}
