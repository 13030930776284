const ADD_CAPSTOCK_EXCEPTION = 'ADD_CAPSTOCK_EXCEPTION';
const REMOVE_CAPSTOCK_EXCEPTION = 'REMOVE_CAPSTOCK_EXCEPTION';
const CHANGE_CAPSTOCK_EXCEPTION = 'CHANGE_CAPSTOCK_EXCEPTION';

const addCapstockException = uuid => ({
  type: ADD_CAPSTOCK_EXCEPTION,
  payload: {
    uuid,
  },
});

const removeCapstockException = (currencyLineItemMapUuid, uuid, id) => ({
  type: REMOVE_CAPSTOCK_EXCEPTION,
  payload: {
    currencyLineItemMapUuid,
    uuid,
    id,
  },
});

const changeCapstockException = (currencyLineItemMapUuid, uuid, updatedException) => ({
  type: CHANGE_CAPSTOCK_EXCEPTION,
  payload: {
    currencyLineItemMapUuid,
    uuid,
    updatedException,
  },
});

export {
  ADD_CAPSTOCK_EXCEPTION,
  REMOVE_CAPSTOCK_EXCEPTION,
  CHANGE_CAPSTOCK_EXCEPTION,
  addCapstockException,
  removeCapstockException,
  changeCapstockException,
};
