import React from 'react';
import PropTypes from 'prop-types';
import { Table, Alert } from 'react-bootstrap';
import I18n from 'i18n-js';
import Row from 'react-bootstrap/es/Row';
import Col from 'react-bootstrap/es/Col';
import { isEmpty } from 'lodash';
import { ProcessingGroupTableContainer } from '../containers/processingGroupTableContainer';
import {
  SortedProcessingGroupNameFieldContainer,
  SortedProcessingGroupIdFieldContainer,
  SortedProcessingGroupStatusFieldContainer,
} from '../containers/sortedProcessingGroupNameFieldContainer';

class CurrencyBalanceSheetsTable extends React.PureComponent {
  render() {
    const {
      loading, error, processingGroupIds, sendOrderMessage,
    } = this.props;
    const toggleSheets = !isEmpty((new URLSearchParams(window.location.search)).get('toggle'));

    if (loading) {
      return I18n.t('loading');
    }


    return (
      <Row>
        { error
          && (
            <Alert bsStyle="danger">
              An error occurred while loading the currency balance sheets:
              {' '}
              {error}
            </Alert>
          )
        }
        { sendOrderMessage
          && (
            <Alert bsStyle="info" className="pre-line">
              {sendOrderMessage}
            </Alert>
          )
        }
        <Col sm={12}>
          <div className="table-responsive with-dropdown">
            <Table id="currency-balance-sheets-table">
              <thead>
                <tr>
                  <SortedProcessingGroupIdFieldContainer data-test-id="sortByProcessingGroupName" />
                  <SortedProcessingGroupNameFieldContainer data-test-id="sortByProcessingGroupId" />
                  <SortedProcessingGroupStatusFieldContainer data-test-id="sortByProcessingGroupStatus" />
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  processingGroupIds.map(processingGroupId => (
                    <ProcessingGroupTableContainer
                      key={processingGroupId}
                      id={processingGroupId}
                      toggleSheets={toggleSheets}
                    />
                  ))
                }
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    );
  }
}

CurrencyBalanceSheetsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  processingGroupIds: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  sendOrderMessage: PropTypes.string.isRequired,
};

export { CurrencyBalanceSheetsTable };
