import { useReducer } from 'react';
import _ from 'lodash';

const buildIdentifiersMap = (identifiers, value) => _.chain(identifiers)
  .keyBy(_.identity)
  .mapValues(() => value)
  .value();

const generateSettlementIdentifier = (id, criteria) => `${id}-settlement-${criteria.localCurrency.isoCode}-${criteria.settlementDate}`;
const generateHedgeIdentifier = (id, index) => `${id}-hedge-${index}`;
const areAllExpanded = objectElements => (
  Object.keys(objectElements).every(identifier => objectElements[identifier])
);

const TOGGLE_ALL_SHEET_DETAILS = 'TOGGLE_ALL_SHEET_DETAILS';
const TOGGLE_ONE_SHEET_DETAIL = 'TOGGLE_ONE_SHEET_DETAIL';

function initializeSectionsState(currencyBalanceSheet) {
  const { settlementGroups, hedges, id } = currencyBalanceSheet;
  const hedgesIdentifiers = hedges.map((hedge, index) => generateHedgeIdentifier(id, index));
  const settlementIdentifiers = settlementGroups
    .flatMap(group => group.settlements)
    .map(({ criteria }) => (
      generateSettlementIdentifier(id, criteria)
    ));
  const allIdentifiers = hedgesIdentifiers.concat(settlementIdentifiers);
  return buildIdentifiersMap(allIdentifiers, false);
}

function sectionsReducer(state, action) {
  switch (action.type) {
    case TOGGLE_ALL_SHEET_DETAILS: {
      const areAllDetailsExpanded = areAllExpanded(state);
      return _.mapValues(state, () => !areAllDetailsExpanded);
    }
    case TOGGLE_ONE_SHEET_DETAIL: {
      const { identifier } = action.payload;
      return {
        ...state,
        [identifier]: !state[identifier],
      };
    }
    default:
      throw new Error();
  }
}

const useSectionsToggling = (currencyBalanceSheet) => {
  const [sectionsState, dispatchSectionAction] = useReducer(sectionsReducer, currencyBalanceSheet,
    initializeSectionsState);

  const toggleAllSections = () => {
    dispatchSectionAction({ type: TOGGLE_ALL_SHEET_DETAILS });
  };

  const toggleSection = (identifier) => {
    dispatchSectionAction({
      type: TOGGLE_ONE_SHEET_DETAIL,
      payload: {
        identifier,
      },
    });
  };

  return {
    sectionsState,
    toggleAllSections,
    toggleSection,
  };
};

export {
  useSectionsToggling,
  generateHedgeIdentifier,
  generateSettlementIdentifier,
  areAllExpanded,
};
