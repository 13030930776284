import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';

export const ValidatedSheetBadge = ({ validatedByEmail }) => (
  <span className="badge badge-primary">
    {I18n.t('activerecord.attributes.currency_balance_sheet.validation_statuses.validated', { email: validatedByEmail })}
  </span>
);

ValidatedSheetBadge.propTypes = {
  validatedByEmail: PropTypes.string.isRequired,
};
