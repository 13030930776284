import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
} from 'react-bootstrap';
import I18n from 'i18n-js';

import SelectField from '../SelectField';

// eslint-disable-next-line import/no-unresolved
import constants from '../../../rails_contants';

const Component = (props) => {
  const {
    onSubmit,
    errors,
    formData,
    onChange,
  } = props;

  const handleChange = (e) => {
    onChange(e);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const tradePurposeOptions = constants
    .tradePurposeOptions
    .map(x => ({ optionName: I18n.t(`activerecord.attributes.fx_contract.trade_purposes.${x}`), optionValue: x }));

  const tradeSubPurposeOptions = constants
    .tradeSubPurposeOptions
    .map(x => ({ optionName: x, optionValue: x }));

  return (
    <Form>
      <SelectField
        name="tradePurpose"
        onChange={handleChange}
        value={formData.tradePurpose}
        errors={errors.tradePurpose}
        options={tradePurposeOptions}
        label={I18n.t('activerecord.attributes.execution_trade_type.trade_purpose')}
      />
      <SelectField
        name="tradeSubPurpose"
        onChange={handleChange}
        value={formData.tradeSubPurpose}
        errors={errors.tradeSubPurpose}
        options={tradeSubPurposeOptions}
        label={I18n.t('activerecord.attributes.execution_trade_type.trade_sub_purpose')}
      />
      <FormGroup>
        <Button onClick={handleSubmit}>Save</Button>
      </FormGroup>
    </Form>
  );
};

Component.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    tradePurpose: PropTypes.string,
    tradeSubPurpose: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    tradePurpose: PropTypes.arrayOf(PropTypes.string),
    tradeSubPurpose: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Component;
