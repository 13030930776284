import Client from '../../../client';

const REQUEST_ACCOUNT_FLAG_NOTES = 'REQUEST_ACCOUNT_FLAG_NOTES';
const RECEIVE_ACCOUNT_FLAG_NOTES = 'RECEIVE_ACCOUNT_FLAG_NOTES';

const requestAccountFlagNotes = data => ({
  type: REQUEST_ACCOUNT_FLAG_NOTES,
  payload: data,
});

const receiveAccountFlagNotes = data => ({
  type: RECEIVE_ACCOUNT_FLAG_NOTES,
  payload: data,
});

const fetchAccountFlagNotes = data => async (dispatch) => {
  dispatch(requestAccountFlagNotes(data));

  const response = await Client.listAccountFlags(data);
  const json = await response.json();

  dispatch(receiveAccountFlagNotes(json));
};

export {
  REQUEST_ACCOUNT_FLAG_NOTES,
  RECEIVE_ACCOUNT_FLAG_NOTES,
  fetchAccountFlagNotes,
};
