import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'i18n-js';
import { capstockException } from './commonPropTypes';
import { DestroyEntityButton } from '../../../shared/destroyEntityButton';
import { ActionsSelect } from './actionsSelect';
import {
  EXCEPTION_BUSINESS_DAYS, EXCEPTION_FAR_OPEN_HEDGE, TYPE_OPTIONS, getSelectedType,
} from '../others/capstockExceptionTypes';

class CapstockExceptionFormGroup extends React.Component {
  retrieveNestedAttributeFieldName = (type) => {
    if (type === EXCEPTION_BUSINESS_DAYS) {
      return 'capstock_settlement_dates_set_business_days_from_trade_dates_attributes';
    } if (type === EXCEPTION_FAR_OPEN_HEDGE) {
      return 'capstock_settlement_dates_use_far_open_hedges_attributes';
    }
    return '';
  };

  generateFieldName = (type, mapIndex, exceptionIndex, fieldName) => {
    const nestedAttributesName = this.retrieveNestedAttributeFieldName(type);
    return `account[currency_line_item_maps_attributes][${mapIndex}][${nestedAttributesName}][${exceptionIndex}][${fieldName}]`;
  };

  generateId = (type, mapIndex, exceptionIndex, fieldName) => {
    const nestedAttributesName = this.retrieveNestedAttributeFieldName(type);
    return `account_currency_line_item_maps_attributes_${mapIndex}_${nestedAttributesName}_${exceptionIndex}_${fieldName}`;
  };

  handleChange = (changedValues) => {
    const { currencyLineItemMapUuid, exception, onChange } = this.props;
    onChange(currencyLineItemMapUuid, exception.uuid, changedValues);
  };

  handleActionChanged = (event) => {
    const { currentTarget: { value } } = event;
    this.handleChange({ action: value });
  };

  handleTypeChanged = (event) => {
    const { currentTarget: { value } } = event;
    switch (value) {
      case TYPE_OPTIONS.FAR_OPEN_HEDGE_DATE_PROVIDED:
        this.handleChange({ type: EXCEPTION_FAR_OPEN_HEDGE, dateProvided: true });
        break;
      case TYPE_OPTIONS.FAR_OPEN_HEDGE_NO_DATE_PROVIDED:
        this.handleChange({ type: EXCEPTION_FAR_OPEN_HEDGE, dateProvided: false });
        break;
      case TYPE_OPTIONS.BUSINESS_DAYS_DATE_PROVIDED:
        this.handleChange({ type: EXCEPTION_BUSINESS_DAYS, dateProvided: true });
        break;
      case TYPE_OPTIONS.BUSINESS_DAYS_NO_DATE_PROVIDED:
        this.handleChange({ type: EXCEPTION_BUSINESS_DAYS, dateProvided: false });
        break;
      default:
        this.handleChange({ type: null, dateProvided: null });
        break;
    }
  };

  handleBusinessDaysChanged = (event) => {
    const { currentTarget: { value } } = event;
    this.handleChange({ businessDays: value });
  };

  handleRemove = () => {
    const { onRemove, exception, currencyLineItemMapUuid } = this.props;
    onRemove(currencyLineItemMapUuid, exception.uuid, exception.id);
  };

  render() {
    const { exception, mapIndex, index } = this.props;
    return (
      <div className="nested-fields clearfix sub-nested-field">
        {
          exception.id && (
            <input
              type="hidden"
              className="form-control m-b"
              required
              id={this.generateId(exception.type, mapIndex, index, 'id')}
              name={this.generateFieldName(exception.type, mapIndex, index, 'id')}
              value={exception.id}
              readOnly
            />
          )
        }
        <React.Fragment>
          <input
            type="hidden"
            className="form-control"
            id={this.generateId(exception.type, mapIndex, index, 'date_provided')}
            name={this.generateFieldName(exception.type, mapIndex, index, 'date_provided')}
            value={exception.dateProvided ? 'true' : 'false'}
            readOnly
          />
          <div className="form-group col-sm-4">
            <label htmlFor={this.generateId(exception.type, mapIndex, index, 'action')} className="sr-only">
              {I18n.t('users_portal.accounts.form.capstock_exceptions.apply_exception')}
            </label>
            <div className="input-group">
              <span className="input-group-addon">{I18n.t('users_portal.accounts.form.capstock_exceptions.apply_exception')}</span>
              <ActionsSelect
                id={this.generateId(exception.type, mapIndex, index, 'action')}
                name={this.generateFieldName(exception.type, mapIndex, index, 'action')}
                action={exception.action}
                onChange={this.handleActionChanged}
              />
              <span className="input-group-addon">{I18n.t('users_portal.accounts.form.capstock_exceptions.action')}</span>
            </div>
          </div>
          <div className="form-group col-sm-4">
            <label htmlFor={this.generateId(exception.type, mapIndex, index, 'type')} className="sr-only">{I18n.t('activerecord.attributes.capstock_settlement_date_exceptions.type')}</label>
            <div className="input-group">
              <span className="input-group-addon">{I18n.t('users_portal.accounts.form.capstock_exceptions.cash_settlement_date')}</span>
              <select
                className="form-control m-b"
                required
                id={this.generateId(exception.type, mapIndex, index, 'type')}
                name={this.generateFieldName(exception.type, mapIndex, index, 'type')}
                value={getSelectedType(exception.type, exception.dateProvided)}
                onChange={this.handleTypeChanged}
                disabled={!!exception.id}
              >
                <option value="" />
                <option value={TYPE_OPTIONS.FAR_OPEN_HEDGE_DATE_PROVIDED}>
                  {I18n.t('users_portal.accounts.form.capstock_exceptions.types.far_hedge_date_date_provided')}
                </option>
                <option value={TYPE_OPTIONS.FAR_OPEN_HEDGE_NO_DATE_PROVIDED}>
                  {I18n.t('users_portal.accounts.form.capstock_exceptions.types.far_hedge_date_no_date_provided')}
                </option>
                <option value={TYPE_OPTIONS.BUSINESS_DAYS_DATE_PROVIDED}>
                  {I18n.t('users_portal.accounts.form.capstock_exceptions.types.business_days_date_provided')}
                </option>
                <option value={TYPE_OPTIONS.BUSINESS_DAYS_NO_DATE_PROVIDED}>
                  {I18n.t('users_portal.accounts.form.capstock_exceptions.types.business_days_no_date_provided')}
                </option>
              </select>
            </div>
          </div>
          <div className="form-group col-sm-3">
            { exception.type === EXCEPTION_BUSINESS_DAYS && (
              <React.Fragment>
                <label htmlFor={this.generateId(exception.type, mapIndex, index, 'business_days')} className="sr-only">
                  {I18n.t('activerecord.attributes.capstock_settlement_date_exceptions.business_days')}
                </label>
                <input
                  type="number"
                  className="form-control m-b"
                  required
                  id={this.generateId(exception.type, mapIndex, index, 'business_days')}
                  name={this.generateFieldName(exception.type, mapIndex, index, 'business_days')}
                  min={0}
                  onChange={this.handleBusinessDaysChanged}
                  value={exception.businessDays || ''}
                  placeholder={I18n.t('activerecord.attributes.capstock_settlement_date_exceptions.business_days')}
                />
              </React.Fragment>
            )}
          </div>
          <div className="form-group col-sm-1 text-right">
            <DestroyEntityButton onClick={this.handleRemove} />
          </div>
        </React.Fragment>
      </div>
    );
  }
}

CapstockExceptionFormGroup.propTypes = {
  exception: capstockException.isRequired,
  onRemove: PropTypes.func.isRequired,
  mapIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  currencyLineItemMapUuid: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { CapstockExceptionFormGroup };
