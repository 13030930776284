import React, { useState, useMemo } from 'react';
import I18n from 'i18n-js';
import { FxContractsTable } from '../newTradesTab/fxContractsTable';
import { Loading } from '../../../../shared/loading';
import { usePolledFxContractsFetch } from '../../hooks/usePolledFxContractsFetch';
import { Filters } from '../newTradesTab/filters';
import { displayNumber } from '../../shared/displayNumber';

export const ExecutionTab = () => {
  const [searchState, setSearchState] = useState({});
  const filters = useMemo(() => {
    const { selectedClientId, selectedAccountId, selectedProcessingGroupId } = searchState;
    return {
      client_id: selectedClientId,
      account_id: selectedAccountId,
      processing_group_id: selectedProcessingGroupId,
      status: ['executed', 'pending'],
    };
  }, [searchState]);
  const [fxContractsState] = usePolledFxContractsFetch(filters);
  const { loading, fxContracts } = fxContractsState;
  const additionalColumns = useMemo(() => ([
    {
      id: 'allInRate',
      display: () => I18n.t('oona.dashboard.index.fx_contracts.all_in_rate'),
      accessor: fxContract => fxContract.allInRate && displayNumber(fxContract.allInRate),
    },
    {
      id: 'tradeStatus',
      display: () => I18n.t('oona.dashboard.index.fx_contracts.trade_status'),
      accessor: r => I18n.t(`activerecord.attributes.fx_contract.trade_statuses.${r.tradeStatus}`),
    },
  ]), []);

  return (
    <div>
      <div className="text-center">
        <Loading loading={loading} />
      </div>
      <Filters
        onFilter={setSearchState}
        loading={loading}
      />
      <FxContractsTable
        id="executedFxContracts"
        fxContracts={fxContracts}
        loading={loading}
        additionalColumns={additionalColumns}
      />
    </div>
  );
};
