import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { WrappedReactSelect } from '../wrappedReactSelect';

export const ProcessingGroupFilter = ({
  processingGroups,
  onChange,
  selectedProcessingGroupId,
  loading,
  ...otherProps
}) => {
  const getProcessingGroupOptions = useCallback(() => {
    const processingGroupOptions = processingGroups.map(pg => ({
      value: pg.id,
      label: `(${pg.id}) ${pg.name}`,
    }));
    processingGroupOptions.unshift({
      value: undefined,
      label: I18n.t('sheets_filter_component.processing_groups.all'),
    });
    return processingGroupOptions;
  }, [processingGroups]);

  const getSelectedProcessingGroup = useCallback(
    () => getProcessingGroupOptions().find(pg => pg.value === selectedProcessingGroupId) || null,
    [getProcessingGroupOptions, selectedProcessingGroupId],
  );

  return (
    <WrappedReactSelect
      className="filter-processing-groups"
      placeholder={I18n.t('sheets_filter_component.processing_groups.all')}
      options={getProcessingGroupOptions()}
      value={getSelectedProcessingGroup()}
      onChange={onChange}
      isLoading={loading}
      loadingMessage={I18n.t('loading')}
      {...otherProps}
    />
  );
};

ProcessingGroupFilter.propTypes = {
  processingGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedProcessingGroupId: PropTypes.number,
};

ProcessingGroupFilter.getDefaultProps = {
  selectedProcessingGroupId: null,
};
