import React from 'react';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { buildReduxStore } from '../../../shared/buildReduxStore';
import currencyLineItemMaps from '../reducers/currencyLineItemMaps';
import destroyed from '../reducers/destroyed';
import capstockExceptions from '../reducers/capstockExceptions';
import { CurrencyLineItemMapsSectionContainer } from './currencyLineItemMapsSectionContainer';
import { appendUuid } from '../../../utils/uuid';

const rootReducer = combineReducers({
  currencyLineItemMaps,
  destroyed,
  capstockExceptions,
});

// Rehydrate Store
let storeData = null;
if (Object.prototype.hasOwnProperty.call(window, 'STATE_FROM_SERVER')
  && Object.prototype.hasOwnProperty.call(window.STATE_FROM_SERVER, 'currencyLineItemMaps')) {
  const maps = appendUuid(window.STATE_FROM_SERVER.currencyLineItemMaps);
  const exceptions = {};
  maps
    .filter(map => Object.prototype.hasOwnProperty.call(map, 'capstockSettlementDateExceptions'))
    /* eslint no-param-reassign: ["error", { "props": false }] */
    .forEach((map) => {
      exceptions[map.uuid] = appendUuid(map.capstockSettlementDateExceptions);
      delete map.capstockSettlementDateExceptions;
    });
  storeData = {
    currencyLineItemMaps: maps,
    capstockExceptions: exceptions,
  };
}

const store = buildReduxStore({ rootReducer, storeData });

const AccountLineItems = () => (
  <Provider store={store}>
    <CurrencyLineItemMapsSectionContainer />
  </Provider>
);

export { AccountLineItems };
