import connect from 'react-redux/es/connect/connect';
import { SheetValidationStatus } from '../components/sheetValidationStatus';

const mapStateToProps = (state, props) => {
  const sheetId = props.currencyBalanceSheetId;
  const currencyBalanceSheet = state.currencyBalanceSheets.currencyBalanceSheets[sheetId];
  return {
    validationStatus: currencyBalanceSheet.validationStatus,
    validatedByEmail: currencyBalanceSheet.validatedByEmail,
    isValidating: !!state.validating.currencyBalanceSheets[sheetId],
  };
};

export const SheetValidationStatusContainer = connect(mapStateToProps)(SheetValidationStatus);
