import React from 'react';
import { Routes, Route } from 'react-router-dom';
import GroupIndex from './groups/Index';
import GroupNew from './groups/New';
import GroupEdit from './groups/Edit';
import GroupAccounts from './groups/Accounts';
import SubgroupIndex from './subgroups/Index';
import SubgroupEdit from './subgroups/Edit';
import SubgroupNew from './subgroups/New';
import TradeTypeIndex from './tradeTypes/Index';
import TradeTypeNew from './tradeTypes/New';
import LocalCurrencyIndex from './localCurrencies/Index';
import LocalCurrencyNew from './localCurrencies/New';

export default () => (
  <Routes>
    <Route path="/execution_settings" element={<GroupIndex />} />
    <Route path="/execution_settings/groups/new" element={<GroupNew />} />
    <Route path="/execution_settings/groups/:groupId/edit" element={<GroupEdit />} />
    <Route path="/execution_settings/groups/:groupId/subgroups" element={<SubgroupIndex />} />
    <Route path="/execution_settings/groups/:groupId/subgroups/new" element={<SubgroupNew />} />
    <Route path="/execution_settings/groups/:groupId/subgroups/:subgroupId/edit" element={<SubgroupEdit />} />
    <Route path="/execution_settings/groups/:groupId/subgroups/:subgroupId/trade_types" element={<TradeTypeIndex />} />
    <Route path="/execution_settings/groups/:groupId/subgroups/:subgroupId/trade_types/new" element={<TradeTypeNew />} />
    <Route path="/execution_settings/groups/:groupId/subgroups/:subgroupId/local_currencies" element={<LocalCurrencyIndex />} />
    <Route path="/execution_settings/groups/:groupId/subgroups/:subgroupId/local_currencies/new" element={<LocalCurrencyNew />} />
    <Route path="/execution_settings/groups/:groupId/accounts" element={<GroupAccounts />} />
  </Routes>
);
