import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Routes from '../../../routes.js.erb';
import { currencyBalanceSheet as currencyBalanceSheetPropTypes } from './commonPropTypes';
import { AccountNote } from '../../../notes/components/resourceNote';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { Loading } from '../../../shared/loading';

const getActionName = (isValidating, isRebalancing) => {
  if (isValidating) return 'Validating';
  if (isRebalancing) return 'Rebalancing';
  return I18n.t('actions');
};

export const CurrencyBalanceSheetActions = ({
  currencyBalanceSheet, isValidating, isRebalancing, onValidate, onValidateAndSend,
}) => {
  const {
    id: currencyBalanceSheetId,
    account: { id: accountId },
    ledgerDate, validationStatus,
  } = currencyBalanceSheet;
  const [didDropdownOpenAtLeastOnce, setDidDropdownOpenAtLeastOnce] = useState(false);
  const {
    canShow, canValidate, canRead: canShowNote,
    loading,
  } = useAuthorizedResource([
    { modelClass: 'CurrencyBalanceSheet', modelId: currencyBalanceSheetId, modelAction: 'show' },
    { modelClass: 'CurrencyBalanceSheet', modelId: currencyBalanceSheetId, modelAction: 'validate' },
    { modelClass: 'Note', modelAction: 'read' },
  ], didDropdownOpenAtLeastOnce);

  const handleValidation = useCallback((e) => {
    e.preventDefault();
    onValidate([currencyBalanceSheetId]);
  }, [onValidate, currencyBalanceSheetId]);

  const handleValidationAndSend = useCallback((e) => {
    e.preventDefault();
    onValidateAndSend([currencyBalanceSheetId]);
  }, [onValidateAndSend, currencyBalanceSheetId]);

  const disabled = isValidating || isRebalancing;

  return (
    <div data-test-id={`sheetActions${currencyBalanceSheetId}`}>
      <DropdownButton
        bsStyle="primary"
        title={getActionName(isValidating, isRebalancing)}
        id={`sheetActions${currencyBalanceSheetId}`}
        onToggle={open => (open ? setDidDropdownOpenAtLeastOnce(true) : null)}
        disabled={disabled}
      >
        {
          (loading) && (
            <MenuItem eventKey="loading">
              <Loading loading />
            </MenuItem>
          )
        }

        {
          (validationStatus !== 'validated' && canValidate) && (
            <>
              <MenuItem eventKey="validate" disabled={disabled} onClick={handleValidation}>
                {I18n.t('currency_balance_sheets.index.validate')}
              </MenuItem>
              <MenuItem eventKey="validate_and_send" disabled={disabled} onClick={handleValidationAndSend}>
                {I18n.t('currency_balance_sheets.index.validate_and_send')}
              </MenuItem>
            </>
          )
        }
        {
          canShow && (
            <>
              <MenuItem eventKey="show" href={Routes.account_currency_balance_sheet_path(accountId, ledgerDate)} disabled={disabled}>
                {I18n.t('currency_balance_sheets.index.view')}
              </MenuItem>

              <MenuItem eventKey="viewTrades" href={Routes.fx_contracts_path({ trade_status: [0, 5], account_ids: [accountId] })} target="_blank" rel="noopener noreferrer">
                {I18n.t('currency_balance_sheets.index.view_trades')}
              </MenuItem>
            </>
          )
        }
        {
          canShowNote && (
            <li role="presentation">
              <AccountNote accountId={accountId} />
            </li>
          )
        }
      </DropdownButton>
    </div>
  );
};

CurrencyBalanceSheetActions.propTypes = {
  currencyBalanceSheet: currencyBalanceSheetPropTypes.isRequired,
  onValidate: PropTypes.func.isRequired,
  onValidateAndSend: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  isRebalancing: PropTypes.bool.isRequired,
};
