import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button, Col } from 'react-bootstrap';
import ReportConfigSelectableField from './reportConfigSelectableField';
import ReportConfigurationTable from './reportConfigurationTable';
import ReportConfigFixedField from './reportConfigFixedField';

class ReportFieldsConfiguration extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    /* eslint-disable react/forbid-prop-types */
    fields: PropTypes.object.isRequired,
    /* eslint-enable react/forbid-prop-types */
    selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    notSelectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    onAddField: PropTypes.func.isRequired,
    onRemoveField: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func.isRequired,
    updateBeforeSortStart: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onAddFixedField: PropTypes.func.isRequired,
    onRemoveFixedField: PropTypes.func.isRequired,
    onAddFormulaField: PropTypes.func.isRequired,
    onRemoveFormulaField: PropTypes.func.isRequired,
    orderingItemId: PropTypes.number.isRequired,
  };

  addSelectableField = (index, event) => {
    const { onAddField } = this.props;
    onAddField(index, event);
  };

  onSortEnd = (data) => {
    const { onSortEnd } = this.props;
    onSortEnd(data);
  };

  updateBeforeSortStart = (data) => {
    const { updateBeforeSortStart } = this.props;
    updateBeforeSortStart(data);
  };

  handleAddFixedField = (event) => {
    event.preventDefault();
    const { onAddFixedField } = this.props;
    onAddFixedField();
  };

  handleAddFormulaField = (event) => {
    event.preventDefault();
    const { onAddFormulaField } = this.props;
    onAddFormulaField();
  };

  removeSelectableField = (index, event) => {
    const { onRemoveField } = this.props;
    onRemoveField(index, event);
  };

  render = () => {
    const {
      selectedFields, notSelectedFields, fields, onChange, onRemoveFixedField,
      children, orderingItemId,
    } = this.props;
    return (
      <React.Fragment>
        <Col sm={6} className="selected-fields">
          <div className="view-container-subtitle">
            <h3>
              {I18n.t('users_portal.reports_configs.form.selected_fields_table_title')}
              <span className="help-title-text">{I18n.t('users_portal.reports_configs.edit.help_text_to_order')}</span>
            </h3>
          </div>
          <ReportConfigurationTable id="used-fields">
            <SortableList
              items={selectedFields}
              fields={fields}
              onRemoveSelectableField={this.removeSelectableField}
              onRemoveFixedField={onRemoveFixedField}
              onSortEnd={this.onSortEnd}
              updateBeforeSortStart={this.updateBeforeSortStart}
              onChange={onChange}
              distance={1}
              orderingItemId={orderingItemId}
            />
          </ReportConfigurationTable>
          <Button bsStyle="primary" onClick={this.handleAddFixedField}>
            {I18n.t('users_portal.reports_configs.form.add_fixed_field')}
          </Button>
          <Button bsStyle="primary" onClick={this.handleAddFormulaField}>
            {I18n.t('users_portal.reports_configs.form.add_formula_field')}
          </Button>
        </Col>
        <Col sm={6} className="discarded-fields">
          <div className="view-container-subtitle">
            <h3>{I18n.t('users_portal.reports_configs.form.discarded_fields_table_title')}</h3>
          </div>
          <ReportConfigurationTable>
            <tbody>
              {
              notSelectedFields.map((uuid, index) => (
                <ReportConfigSelectableField
                  key={uuid}
                  fieldIndex={index}
                  onAdd={this.addSelectableField}
                  {...fields[uuid]}
                  disabled
                />
              ))
            }
            </tbody>
          </ReportConfigurationTable>
        </Col>
        { children }
      </React.Fragment>
    );
  }
}

const SortableItem = SortableElement(({
  selectedField, fieldIndex, onRemoveSelectableField, onRemoveFixedField, disabled,
  onChange, isMoving,
}) => {
  if (selectedField.type === 'Reports::ConfigSelectableField') {
    return (
      <ReportConfigSelectableField
        {...selectedField}
        disabled={disabled}
        onChange={onChange}
        fieldIndex={fieldIndex}
        onRemove={onRemoveSelectableField}
        isMoving={isMoving}
      />
    );
  }
  return (
    <ReportConfigFixedField
      onChange={onChange}
      onRemoveFixedField={onRemoveFixedField}
      isMoving={isMoving}
      {...selectedField}
    />
  );
});

const SortableList = SortableContainer(({
  items, onRemoveSelectableField, onRemoveFixedField, onChange, fields, distance, orderingItemId,
}) => (
  <tbody>
    {items.map((uuid, index) => (
      <SortableItem
        key={`${uuid}`}
        index={index}
        onRemoveSelectableField={onRemoveSelectableField}
        onRemoveFixedField={onRemoveFixedField}
        onChange={onChange}
        fieldIndex={index}
        selectedField={fields[uuid]}
        distance={distance}
        isMoving={index === orderingItemId}
      />
    ))}
  </tbody>
));

export { ReportFieldsConfiguration };
