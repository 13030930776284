import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Item from './Item';
import Paginator from '../Paginator';

const OrderHeader = (props) => {
  const {
    field,
    order,
    title,
    onChange,
  } = props;

  const active = order.field === field;

  const handleChange = async () => {
    if (order.direction === 'asc') {
      onChange({ field, direction: 'desc' });
    } else {
      onChange({ field, direction: 'asc' });
    }
  };

  return (
    <a onClick={handleChange} className="order-link">
      {title}
      {
        active && (
          <i className="material-icons">
            {order.direction === 'asc' ? 'arrow_upward' : 'arrow_downward'}
          </i>
        )
      }
    </a>
  );
};

OrderHeader.propTypes = {
  field: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Component = (props) => {
  const {
    items,
    onRemove,
    currentPage,
    totalPages,
    onPageChange,
    onOrderChange,
    order,
  } = props;

  return (
    <>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th>id</th>
            <th className="order-header">
              <OrderHeader
                field="name"
                order={order}
                title={I18n.t('activerecord.attributes.execution_group.name')}
                onChange={onOrderChange}
              />
            </th>
            <th className="order-header">
              <OrderHeader
                field="client"
                order={order}
                title={I18n.t('activerecord.attributes.execution_group.client')}
                onChange={onOrderChange}
              />
            </th>
            <th>{I18n.t('activerecord.attributes.execution_group.description')}</th>
            <th>{I18n.t('activerecord.attributes.execution_group.netting_restrictions')}</th>
            <th>Status</th>
            <th className="order-header">
              <OrderHeader
                field="created_at"
                order={order}
                title={I18n.t('activerecord.attributes.execution_group.created_at')}
                onChange={onOrderChange}
              />
            </th>
            <th>{I18n.t('activerecord.attributes.execution_group.updated_at')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {
            items.map(group => <Item key={group.id} {...group} onRemove={onRemove} />)
          }
        </tbody>
      </Table>
      <div style={{ textAlign: 'center' }}>
        <Paginator
          totalPages={totalPages}
          currentPage={currentPage}
          onChange={onPageChange}
        />
      </div>
    </>
  );
};

Component.defaultProps = {
  items: [],
};

Component.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      nettingRestrictions: PropTypes.string.isRequired,
      client: PropTypes.shape({
        id: PropTypes.number.isRequired,
        companyName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  order: PropTypes.shape({
    direction: PropTypes.string,
    field: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default Component;
