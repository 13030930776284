import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import moment from 'moment';
import Routes from '../../../routes.js.erb';
import { currencyPropType, fxContractPropType } from '../commonPropTypes';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { OpeningFxContractPopover } from './openingFxContract';
import {
  labelClassForOperation,
  leftAmount,
  leftOperationSide,
  rightAmount,
  rightOperationSide,
} from '../shared/tradeHelpers';
import { Amount } from '../../../shared/amount';

export const ExposureTradeTableRow = ({ fxContract, hedgeRatioEvaluation, baseCurrency }) => {
  const {
    id, tradeDate, settlementDate, counterparty, tradePurpose,
    openingFxContract, tradeStatus, estimatedOrFinalAllInRate,
    fixedSide,
  } = fxContract;
  const hedgeEvaluatesOnBaseSide = hedgeRatioEvaluation === 'account_base_currency';
  const canShow = useAuthorizedResource({ modelClass: 'FxContract', modelId: fxContract.id, modelAction: 'show' });
  const tradeRightAmount = Amount(rightAmount(fxContract, baseCurrency));
  const tradeLeftAmount = Amount(leftAmount(fxContract, baseCurrency));

  const operation = (tradeAmount, side) => (
    <React.Fragment>
      <td>
        <span className={labelClassForOperation(side)}>
          {I18n.t(`currency_balance_sheets.show.currency_table.${side}`)}
        </span>
      </td>
      <td className={!['executed', 'failed'].includes(tradeStatus) && fixedSide !== side ? 'text-right estimated-side' : 'text-right'}>
        {tradeAmount}
      </td>
    </React.Fragment>
  );

  const rightOperation = operation(tradeRightAmount, rightOperationSide(fxContract, baseCurrency));
  const leftOperation = operation(tradeLeftAmount, leftOperationSide(fxContract, baseCurrency));

  const roundedRate = Amount(estimatedOrFinalAllInRate, { digits: 6 });
  const formattedTradeDate = tradeDate ? moment(tradeDate).format('DD-MMM-YY') : '-';
  const formattedSettlementDate = moment(settlementDate).format('DD-MMM-YY');

  const classForRate = ['executed', 'failed'].includes(tradeStatus) ? 'currency_rate text-center' : 'text-center estimated-side';

  return (
    <tr className="fx_contract_details_row" id={`fx-contract-${fxContract.id}`} key={fxContract.id}>
      {
        hedgeEvaluatesOnBaseSide ? (
          <React.Fragment>
            {rightOperation}
            <td className={classForRate}>
              {roundedRate}
            </td>
            {leftOperation}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {leftOperation}
            <td className={classForRate}>
              {roundedRate}
            </td>
            {rightOperation}
          </React.Fragment>
        )
      }
      <td className="text-center">
        {`${formattedTradeDate} / ${formattedSettlementDate}`}
      </td>
      <td>{counterparty.shortName}</td>
      <td className="trade-purpose-cell">
        <span>{I18n.t(`activerecord.attributes.fx_contract.trade_purposes.${tradePurpose}`)}</span>
        {
          openingFxContract && (
            <OpeningFxContractPopover
              openingFxContract={openingFxContract}
              baseCurrency={baseCurrency}
            />
          )
        }
      </td>
      <td className={`fx-contract-trade-status-${tradeStatus.replace(/-/g, '_')} text-capitalize`}>
        {I18n.t(`activerecord.attributes.fx_contract.trade_statuses.${tradeStatus}`)}
      </td>
      <td className="text-right">
        {
          canShow && (
            <a href={Routes.admin_fx_contract_path({ id })}>
              <i className="material-icons">launch</i>
            </a>
          )
        }
      </td>
    </tr>
  );
};

ExposureTradeTableRow.propTypes = {
  baseCurrency: currencyPropType.isRequired,
  fxContract: fxContractPropType.isRequired,
  hedgeRatioEvaluation: PropTypes.string.isRequired,
};
