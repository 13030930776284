import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { ExposureSummary } from './exposureSummary';
import { ExposureLineItemsTable } from './exposureLineItemsTable';
import {
  accountPropTypes, currencyPropType, hedgeLineItemPropType, fxContractPropType,
} from '../commonPropTypes';
import { ExposureTradesTable } from './exposureTradesTable';

export const ExposureSection = (props) => {
  const {
    account, group, showLineItems, showTrades, isExpanded,
    identifier, onExpandOne,
  } = props;
  const { criteria } = group;

  const handleExpandClick = (e) => {
    e.preventDefault();
    onExpandOne(identifier);
  };

  return (
    <React.Fragment>
      <ExposureSummary
        {...props}
        onExpandClick={handleExpandClick}
        isExpanded={isExpanded}
      />
      <Collapse in={isExpanded}>
        <div>
          <div className="details-row bottom-separator">
            <div className="details-column vertical-separator table-response-sm">
              <ExposureLineItemsTable
                lineItems={group.lineItems}
                hedgeRatioEvaluation={account.hedgeRatioEvaluation}
                baseCurrency={account.baseCurrency}
                localCurrency={criteria.localCurrency}
                showContent={showLineItems}
              />
            </div>
            <div className="details-column table-responsive-sm">
              <ExposureTradesTable
                fxContracts={group.fxContracts}
                hedgeRatioEvaluation={account.hedgeRatioEvaluation}
                baseCurrency={account.baseCurrency}
                localCurrency={criteria.localCurrency}
                showContent={showTrades}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

ExposureSection.propTypes = {
  account: accountPropTypes.isRequired,
  group: PropTypes.shape({
    lineItems: PropTypes.arrayOf(hedgeLineItemPropType).isRequired,
    fxContracts: PropTypes.arrayOf(fxContractPropType).isRequired,
    criteria: PropTypes.shape({
      localCurrency: currencyPropType.isRequired,
    }),
  }).isRequired,
  showLineItems: PropTypes.bool.isRequired,
  showTrades: PropTypes.bool.isRequired,
  identifier: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  onExpandOne: PropTypes.func.isRequired,
};

ExposureSection.defaultProps = {
  isExpanded: false,
};
