import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import moment from 'moment';
import classNames from 'classnames';
import { currencyPropType, hedgeLineItemPropType } from '../commonPropTypes';
import { Amount } from '../../../shared/amount';

export const SettlementLineItemsTable = (props) => {
  const { lineItems, localCurrency, showContent } = props;
  return (
    <table className={classNames('table', 'currency-exposures-table', { hidden: !showContent })}>
      <thead>
        <tr>
          <th className="text-right">{localCurrency.isoCode}</th>
          <th className="text-center">{I18n.t('currency_balance_sheet.currency_exposures.date')}</th>
          <th>{I18n.t('currency_balance_sheet.currency_exposures.type')}</th>
        </tr>
      </thead>
      <tbody>
        {
        lineItems.map((lineItem) => {
          const {
            localCurrencyAmount, valuationDate, type,
          } = lineItem;
          return (
            <tr key={lineItem.id} className="currency_line_item_row" id={`line-item-${lineItem.id}`}>
              <td className="text-right">{ Amount(localCurrencyAmount) }</td>
              <td className="text-center">{ moment(valuationDate).format('DD-MMM-YY') }</td>
              <td>{ I18n.t(`activerecord.attributes.currency_line_items/base.types.${type}`) }</td>
            </tr>
          );
        })
      }
      </tbody>
    </table>
  );
};

SettlementLineItemsTable.propTypes = {
  lineItems: PropTypes.arrayOf(hedgeLineItemPropType).isRequired,
  localCurrency: currencyPropType.isRequired,
  showContent: PropTypes.bool.isRequired,
};
