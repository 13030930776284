import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, FormControl } from 'react-bootstrap';
import I18n from 'i18n-js';
import { NetGroupCounterparty } from '../shared/netGroupCounterparty';
import { Date } from '../../../../shared';
import { displayNumber } from '../../shared/displayNumber';
import { ErrorPanel } from '../../../../shared/errorPanel';

export const NetByPivotSection = ({
  fxContractGroups, error,
  pivotCurrencyCode, onPivotCurrencyChange,
}) => (
  <div>
    {error && <ErrorPanel>{error}</ErrorPanel>}
    {
      fxContractGroups.length !== 0 && (
        <Fragment>
          <Col xs={12} className="text-right">
            <label htmlFor="pivotCurrencyCode">
              {I18n.t('oona.pivot_currency')}
              <FormControl
                componentClass="select"
                required
                value={pivotCurrencyCode}
                onChange={event => onPivotCurrencyChange(event.target.value)}
                name="pivotCurrencyCode"
              >
                {
                  ['GBP', 'EUR', 'USD', 'JPY'].map(currency => (
                    <option key={currency} value={currency}>{currency}</option>
                  ))
                }
              </FormControl>
            </label>
          </Col>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  {I18n.t('oona.net_fx_contracts.net_by_pivot.groups_table.columns.non_pivot_currency')}
                </th>
                <th scope="col">
                  {I18n.t('oona.net_fx_contracts.net_by_pivot.groups_table.columns.non_pivot_side')}
                </th>
                <th scope="col">
                  {I18n.t('oona.net_fx_contracts.net_by_pivot.groups_table.columns.settlement_date')}
                </th>
                <th scope="col">
                  {I18n.t('oona.net_fx_contracts.net_by_pivot.groups_table.columns.net_amount_in_non_pivot_currency')}
                </th>
                <th scope="col">
                  {I18n.t('oona.net_fx_contracts.net_by_pivot.groups_table.columns.counterparty')}
                </th>
              </tr>
            </thead>
            <tbody>
              {
              fxContractGroups.map(fxContractGroup => (
                <tr key={[fxContractGroup.settlementDate, fxContractGroup.nonPivotCurrency.isoCode].join('-')}>
                  <th scope="row">{fxContractGroup.nonPivotCurrency.isoCode}</th>
                  <th scope="row">{fxContractGroup.fixedSide}</th>
                  <th scope="row">
                    <Date date={fxContractGroup.settlementDate} />
                  </th>
                  <th scope="row">
                    {displayNumber(fxContractGroup.netAmountInNonPivotCurrency)}
                  </th>
                  <th scope="row">
                    <NetGroupCounterparty counterparties={fxContractGroup.counterparties} />
                  </th>
                </tr>
              ))
            }
            </tbody>
          </table>
        </Fragment>
      )
    }
  </div>
);

NetByPivotSection.propTypes = {
  error: PropTypes.string,
  fxContractGroups: PropTypes.arrayOf(PropTypes.shape({
    settlementDate: PropTypes.string.isRequired,
    nonPivotCurrency: PropTypes.shape({
      isoCode: PropTypes.string,
      id: PropTypes.number,
    }).isRequired,
    fixedSide: PropTypes.string.isRequired,
    netAmountInNonPivotCurrency: PropTypes.string.isRequired,
  })).isRequired,
  pivotCurrencyCode: PropTypes.string.isRequired,
  onPivotCurrencyChange: PropTypes.func.isRequired,
};

NetByPivotSection.defaultProps = {
  error: null,
};
