import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, FormGroup, ControlLabel, Table,
} from 'react-bootstrap';
import _ from 'lodash';
import I18n from 'i18n-js';
import moment from 'moment';
import { accountPropTypes, accountScheduledRollSettingForDatePropTypes } from '../../../currencyBalanceSheets/show/commonPropTypes';
import { availableRollPropTypes } from './commonPropTypes';
import { DefaultSettlementDatePicker } from './defaultSettlementDatePicker';
import { NewSettlementDatePicker } from './newSettlementDatePicker';
import { AvailableRollRow } from './availableRollRow';
import { AccountSettingFormGroup } from './accountSettingFormGroup';

const propTypes = {
  availableRolls: PropTypes.arrayOf(availableRollPropTypes).isRequired,
  accounts: PropTypes.arrayOf(accountPropTypes),
  rollSettings: PropTypes.shape({
    rollStrategy: PropTypes.oneOf([
      'to_current_ratio',
      'to_target',
    ]).isRequired,
    unevenRollToTargetStrategy: PropTypes.oneOf([
      'roll_with_separate_rebalance_trade',
      'roll_including_adjustment_trade_into_roll_open_trade',
    ]).isRequired,
    rollCurrencyConfiguration: PropTypes.oneOf([
      'close_exposure_currency_open_account_currency',
      'close_account_currency_open_exposure_currency',
      'close_account_currency_open_account_currency',
      'close_exposure_currency_open_exposure_currency',
    ]).isRequired,
    rollCloseEachContractOrTotal: PropTypes.oneOf([
      'total',
      'each',
    ]).isRequired,
    rollCloseCounterpartyAllocationStrategy: PropTypes.oneOf([
      'based_on_counterparty_positions',
      'based_on_spot_allocations',
      'based_on_forward_allocations',
      'to_block_counterparty',
    ]).isRequired,
    rollOpenCounterpartyAllocationStrategy: PropTypes.oneOf([
      'based_on_counterparty_positions',
      'roll_with_separate_reallocation_trade',
      'roll_including_reallocation_trade_into_roll_open_trade',
      'to_block_counterparty',
    ]).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
  nextAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
};

function buildSettlementDatesToOpen(nextSettlementDate, availableRolls) {
  const { length } = availableRolls;

  return _.times(length).reduce((acc, index) => {
    acc[index] = nextSettlementDate;
    return acc;
  }, {});
}

function initializeSettlementDatesToOpenFromScheduledRoll(currentAccountScheduledRollSetting,
  availableRolls) {
  if (currentAccountScheduledRollSetting && availableRolls.length > 0) {
    const nextSettlementDateString = currentAccountScheduledRollSetting.nextSettlementDate;
    const nextSettlementDate = moment(nextSettlementDateString);
    return buildSettlementDatesToOpen(nextSettlementDate, availableRolls);
  }

  return {};
}

class RollForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    const {
      currentAccountScheduledRollSetting, availableRolls,
      nextAccountScheduledRollSetting,
    } = this.props;

    const { scheduledRollDate, nextSettlementDate } = nextAccountScheduledRollSetting || {};

    const settlementDatesToOpen = initializeSettlementDatesToOpenFromScheduledRoll(
      currentAccountScheduledRollSetting, availableRolls,
    );

    this.state = {
      selectedRolls: {},
      settlementDatesToOpen,
      ...props.rollSettings,
      nextRollDate: scheduledRollDate,
      nextSettlementDate,
      processing: false,
    };
  }

  handleSelectAll = (event) => {
    const { target: { checked } } = event;
    this.setState((state, props) => {
      if (checked) {
        const selectedRolls = props.availableRolls.reduce((accumulator, currentRoll, index) => {
          accumulator[index] = true;
          return accumulator;
        }, {});
        return { selectedRolls };
      }
      return {
        selectedRolls: {},
        settlementDatesToOpen: {},
      };
    });
  };

  handleCurrencyPairSelection = (index, checked) => {
    this.setState((state) => {
      if (checked) {
        return {
          selectedRolls: {
            ...state.selectedRolls,
            ...{ [index]: checked },
          },
        };
      }
      return {
        selectedRolls: _.omit(state.selectedRolls, index),
      };
    });
  };

  handleSettlementDateOpenChange = (index, date) => {
    this.setState(state => ({
      settlementDatesToOpen: {
        ...state.settlementDatesToOpen,
        ...{ [index]: date },
      },
    }));
  };

  /* Validate all counterparty banks are being rolled for a given */
  /* currency / settlement date pair.                             */
  validateRollRequestIsForAllCounterparties = (availableRolls, rollCombinations) => {
    let valid = true;

    const rollCombinationsGroups = _.map(rollCombinations, value => ({
      group_by_id: `${value.currency_ids[0]}|${value.currency_ids[1]}|${value.settlement_date_to_close}`,
      counterparty_id: value.counterparty_id,
    }));
    const rollCombinationsGroupIds = _.map(rollCombinationsGroups, 'group_by_id');
    const availableRollsGroups = _.map(availableRolls, value => ({
      group_by_id: `${value.currency1.id}|${value.currency2.id}|${value.settlementDateToClose}`,
      currency1_isocode: value.currency1.isoCode,
      currency2_isocode: value.currency2.isoCode,
      counterparty_id: value.counterparty.id,
      counterparty_shortname: value.counterparty.shortName,
      settlement_date_to_close: value.settlementDateToClose,
    }));
    const requiredCounterpartiesToBeRolled = _
      .chain(availableRollsGroups)
      .filter(obj => _.includes(rollCombinationsGroupIds, obj.group_by_id))
      .reject(a => _.find(rollCombinationsGroups, b => b.group_by_id === a.group_by_id
        && b.counterparty_id === a.counterparty_id))
      .value();

    if (requiredCounterpartiesToBeRolled.length > 0) {
      const requiredCounterpartiesToBeRolledMessage = _
        .chain(requiredCounterpartiesToBeRolled)
        .keys()
        .map(key => (
          `Currency1: ${requiredCounterpartiesToBeRolled[key].currency1_isocode}\n`
          + `Currency2: ${requiredCounterpartiesToBeRolled[key].currency2_isocode}\n`
          + `Settlement Date: ${requiredCounterpartiesToBeRolled[key].settlement_date_to_close}\n`
          + `Counterparty: ${requiredCounterpartiesToBeRolled[key].counterparty_shortname}`
        ))
        .value();

      valid = false;
      alert(`Must roll all banks that have the same currency pair and settlement date:\n${
        requiredCounterpartiesToBeRolledMessage}`);
    }

    return valid;
  };

  validateForm = (availableRolls, rollCombinations) => {
    let valid = true;

    valid = this.validateRollRequestIsForAllCounterparties(availableRolls, rollCombinations);

    return valid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ processing: true });
    const {
      selectedRolls,
      settlementDatesToOpen,
      rollStrategy,
      unevenRollToTargetStrategy,
      rollCurrencyConfiguration,
      nextRollDate,
      nextSettlementDate,
      rollCloseEachContractOrTotal,
      rollCloseCounterpartyAllocationStrategy,
      rollOpenCounterpartyAllocationStrategy,
    } = this.state;
    const { onSubmit } = this.props;
    const { availableRolls } = this.props;
    const rollCombinations = Object.keys(selectedRolls).map((index) => {
      const rollData = availableRolls[index];
      return {
        currency_ids: [rollData.currency1.id, rollData.currency2.id],
        settlement_date_to_open: settlementDatesToOpen[index].format('YYYY-MM-DD'),
        settlement_date_to_close: rollData.settlementDateToClose,
        counterparty_id: rollData.counterparty.id,
      };
    });
    const data = {
      roll_strategy: rollStrategy,
      uneven_roll_to_target_strategy: unevenRollToTargetStrategy,
      roll_currency_configuration: rollCurrencyConfiguration,
      roll_close_each_contract_or_total: rollCloseEachContractOrTotal,
      roll_close_counterparty_allocation_strategy: rollCloseCounterpartyAllocationStrategy,
      roll_open_counterparty_allocation_strategy: rollOpenCounterpartyAllocationStrategy,
      roll_combinations: rollCombinations,
      next_roll_date: nextRollDate,
      next_settlement_date: nextSettlementDate,
    };

    const valid = this.validateForm(availableRolls, rollCombinations);

    if (valid) onSubmit(data, this.handleError);
    else this.setState({ processing: false });
  };

  handleError = () => {
    // Set processing to false to re-enable the submit button so the user can submit the form again
    // after fixing whatever input was causing the error (if that were to be the case)
    this.setState({ processing: false });
  }

  handleNextRollDateChange = (date) => {
    this.setState({ nextRollDate: date.format('YYYY-MM-DD') });
  };

  handleNextSettlementDateChange = (date) => {
    this.setState({ nextSettlementDate: date.format('YYYY-MM-DD') });
  };

  handleRollOnRollCurrencyConfigurationChange = (event) => {
    this.handleRadioValueChanged('rollCurrencyConfiguration', event);
  };

  handleRollStrategyChange = (event) => {
    this.handleRadioValueChanged('rollStrategy', event);
  };

  handleUnevenRollToTargetStrategyChange = (event) => {
    this.handleRadioValueChanged('unevenRollToTargetStrategy', event);
  };

  handleRollCloseEachContractOrTotalChange = (event) => {
    this.handleRadioValueChanged('rollCloseEachContractOrTotal', event);
  };

  handleRollCloseCounterpartyAllocationStrategyChange = (event) => {
    this.handleRadioValueChanged('rollCloseCounterpartyAllocationStrategy', event);
  };

  handleRollOpenCounterpartyAllocationStrategyChange = (event) => {
    this.handleRadioValueChanged('rollOpenCounterpartyAllocationStrategy', event);
  };

  handleRadioValueChanged = (attribute, event) => {
    const { target: { value } } = event;
    const { rollSettings } = this.props;
    const isNotDefaultValue = rollSettings[attribute] !== value;
    if (isNotDefaultValue) {
      /* eslint-disable no-restricted-globals */
      if (!confirm(I18n.t('shared.currency_balance_sheets.open_trades_roll_form.confirm_settings_change'))) {
        return;
      }
      /* eslint-enable no-restricted-globals */
    }
    this.setState({ [attribute]: value });
  };

  handleDefaultSettlementDateSelected = (date) => {
    const { availableRolls } = this.props;
    const settlementDatesToOpen = buildSettlementDatesToOpen(date, availableRolls);
    this.setState({ settlementDatesToOpen });
  };

  anyRollInHoliday = () => {
    const { availableRolls } = this.props;
    const { selectedRolls, settlementDatesToOpen } = this.state;

    return _.keys(selectedRolls).some((index) => {
      const { holidays } = availableRolls[index];
      const date = settlementDatesToOpen[index];

      return holidays && holidays.find(holiday => moment(holiday).isSame(date));
    });
  };

  isRollButtonDisabled = () => {
    const { processing, selectedRolls, settlementDatesToOpen } = this.state;
    if (processing) return true;

    const dates = _.pickBy(settlementDatesToOpen);

    return this.anyRollInHoliday() || _.keys(selectedRolls).length === 0
      || _.keys(selectedRolls).some(key => !dates[key]);
  };

  isRollToCurrentRatioSelected = () => {
    const { rollStrategy } = this.state;
    return rollStrategy === 'to_current_ratio';
  };

  isRollCloseEachContractSelected = () => {
    const { rollCloseEachContractOrTotal } = this.state;
    return rollCloseEachContractOrTotal === 'each';
  };

  isRollCloseBasedOnCounterpartyPositionsSelected = () => {
    const { rollCloseCounterpartyAllocationStrategy } = this.state;
    return rollCloseCounterpartyAllocationStrategy === 'based_on_counterparty_positions';
  };

  renderAvailableRolls = () => {
    const { availableRolls } = this.props;
    const { selectedRolls, settlementDatesToOpen } = this.state;
    return availableRolls.map((availableRoll, index) => {
      const {
        currency1: { isoCode: isoCode1 },
        currency2: { isoCode: isoCode2 },
        settlementDateToClose,
        counterparty,
      } = availableRoll;
      const key = `${isoCode1}${isoCode2}${settlementDateToClose}${counterparty.shortName}`;

      return (
        <AvailableRollRow
          key={key}
          roll={availableRoll}
          index={index}
          isSelected={!!selectedRolls[index]}
          value={settlementDatesToOpen[index]}
          onPairSelected={this.handleCurrencyPairSelection}
          onSettlementDateChange={this.handleSettlementDateOpenChange}
          counterparty={counterparty}
        />
      );
    });
  };

  render = () => {
    const { availableRolls, nextAccountScheduledRollSetting, accounts } = this.props;

    const allAccountWithCalendar = accounts.every(account => account.scheduledRollDateCalendar);

    const {
      rollCurrencyConfiguration,
      rollStrategy,
      unevenRollToTargetStrategy,
      rollCloseEachContractOrTotal,
      rollCloseCounterpartyAllocationStrategy,
      rollOpenCounterpartyAllocationStrategy,
      selectedRolls,
      nextRollDate,
      nextSettlementDate,
    } = this.state;

    const accountSettings = [
      {
        controlId: 'roll_on_currency_side',
        get key() { return this.controlId; },
        name: 'roll_currency_configuration',
        namePluralized: 'roll_currency_configurations',
        onChange: this.handleRollOnRollCurrencyConfigurationChange,
        state: rollCurrencyConfiguration,
        options: [
          { value: 'close_exposure_currency_open_account_currency' },
          { value: 'close_account_currency_open_exposure_currency' },
          { value: 'close_account_currency_open_account_currency' },
          { value: 'close_exposure_currency_open_exposure_currency' },
        ],
      },
      {
        controlId: 'roll_close_each_contract_or_total',
        get key() { return this.controlId; },
        get name() { return this.controlId; },
        namePluralized: 'roll_close_each_contract_or_totals',
        onChange: this.handleRollCloseEachContractOrTotalChange,
        state: rollCloseEachContractOrTotal,
        options: [
          { value: 'total' },
          { value: 'each', disabled: !this.isRollCloseBasedOnCounterpartyPositionsSelected() },
        ],
      },
      {
        controlId: 'roll_close_counterparty_allocation_strategy',
        get key() { return this.controlId; },
        get name() { return this.controlId; },
        namePluralized: 'roll_close_counterparty_allocation_strategies',
        onChange: this.handleRollCloseCounterpartyAllocationStrategyChange,
        state: rollCloseCounterpartyAllocationStrategy,
        options: [
          { value: 'based_on_counterparty_positions' },
          { value: 'based_on_spot_allocations', disabled: this.isRollCloseEachContractSelected() },
          { value: 'based_on_forward_allocations', disabled: this.isRollCloseEachContractSelected() },
          { value: 'to_block_counterparty', disabled: this.isRollCloseEachContractSelected() },
        ],
      },
      {
        controlId: 'roll_open_counterparty_allocation_strategy',
        get key() { return this.controlId; },
        get name() { return this.controlId; },
        namePluralized: 'roll_open_counterparty_allocation_strategies',
        onChange: this.handleRollOpenCounterpartyAllocationStrategyChange,
        state: rollOpenCounterpartyAllocationStrategy,
        options: [
          { value: 'based_on_counterparty_positions' },
          { value: 'roll_with_separate_reallocation_trade' },
          { value: 'roll_including_reallocation_trade_into_roll_open_trade' },
          { value: 'to_block_counterparty' },
        ],
      },
      {
        controlId: 'roll_strategy',
        get key() { return this.controlId; },
        get name() { return this.controlId; },
        namePluralized: 'roll_strategies',
        onChange: this.handleRollStrategyChange,
        state: rollStrategy,
        options: [
          { value: 'to_current_ratio' },
          { value: 'to_target' },
        ],
      },
      {
        controlId: 'uneven_roll_to_target_strategy',
        get key() { return this.controlId; },
        get name() { return this.controlId; },
        namePluralized: 'uneven_roll_to_target_strategies',
        onChange: this.handleUnevenRollToTargetStrategyChange,
        state: unevenRollToTargetStrategy,
        options: [
          { value: 'roll_with_separate_rebalance_trade', disabled: this.isRollToCurrentRatioSelected() },
          { value: 'roll_including_adjustment_trade_into_roll_open_trade', disabled: this.isRollToCurrentRatioSelected() },
        ],
      },
    ];

    return (
      <Form onSubmit={this.handleSubmit}>
        {accountSettings.map(setting => <AccountSettingFormGroup {...setting} />)}

        <Table id="rollFormTable" striped condensed hover>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={availableRolls.every((roll, index) => !!selectedRolls[index])}
                  onChange={this.handleSelectAll}
                />
              </th>
              <th>{I18n.t('shared.currency_balance_sheets.open_trades_roll_form.currency_pairs_table.currency_1')}</th>
              <th>{I18n.t('shared.currency_balance_sheets.open_trades_roll_form.currency_pairs_table.currency_2')}</th>
              <th>{I18n.t('shared.currency_balance_sheets.open_trades_roll_form.currency_pairs_table.settlement_date_to_close')}</th>
              <th>{I18n.t('shared.currency_balance_sheets.open_trades_roll_form.currency_pairs_table.counterparty')}</th>
              <th>{I18n.t('shared.currency_balance_sheets.open_trades_roll_form.currency_pairs_table.new_settlement_date_to_open')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>
                <DefaultSettlementDatePicker
                  onSelectDate={this.handleDefaultSettlementDateSelected}
                  availableRolls={availableRolls}
                  selectedRolls={selectedRolls}
                />
              </td>
            </tr>
            { this.renderAvailableRolls() }
          </tbody>
        </Table>

        { allAccountWithCalendar
          ? ((
            _.isNull(nextAccountScheduledRollSetting)
            || _.isNull(nextAccountScheduledRollSetting.scheduledRollDate)
          ) && (
            <div>
              <FormGroup>
                <ControlLabel>{I18n.t('activerecord.attributes.account.next_roll_date')}</ControlLabel>
                <NewSettlementDatePicker
                  onSelectedDate={this.handleNextRollDateChange}
                  required
                  disabled={false}
                  originalSettlementDate={moment().format('YYYY-MM-DD')}
                  value={nextRollDate}
                />
              </FormGroup>

              <FormGroup>
                <ControlLabel>{I18n.t('activerecord.attributes.account.next_settlement_date')}</ControlLabel>
                <NewSettlementDatePicker
                  onSelectedDate={this.handleNextSettlementDateChange}
                  required
                  disabled={false}
                  originalSettlementDate={moment().format('YYYY-MM-DD')}
                  value={nextSettlementDate}
                />
              </FormGroup>
            </div>
          )
          ) : (
            <FormGroup>
              <ControlLabel>{I18n.t('activerecord.attributes.account.next_roll_date')}</ControlLabel>
              <NewSettlementDatePicker
                onSelectedDate={this.handleNextRollDateChange}
                required
                disabled={false}
                originalSettlementDate={moment().format('YYYY-MM-DD')}
                value={nextRollDate}
              />
            </FormGroup>
          )
        }

        <div className="text-right">
          <Button type="submit" bsStyle="primary" onClick={this.handleSubmit} disabled={this.isRollButtonDisabled()}>
            {I18n.t('shared.currency_balance_sheets.open_trades_roll_form.roll')}
          </Button>
        </div>
      </Form>
    );
  }
}

RollForm.propTypes = propTypes;
RollForm.defaultProps = {
  accounts: [],
  currentAccountScheduledRollSetting: null,
  nextAccountScheduledRollSetting: null,
};

export { RollForm };
