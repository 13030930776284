/* eslint-disable max-len */
import connect from 'react-redux/es/connect/connect';
import { fetchCurrencyBalanceSheets, fetchProcessingGroups } from '../actions/currencyBalanceSheets';
import { SortedValue } from '../components/sortedValue';

const generateSortedProcessingGroupValueContainer = ({ currentField, options }) => {
  const mapStateToProps = (state) => {
    const { order } = state.processingGroups;
    const { field, direction } = order;
    return {
      field: currentField,
      currentDirection: (field === currentField) ? direction : null,
      options,
    };
  };

  const mapDispatchToProps = { onClick: fetchProcessingGroups };

  return connect(mapStateToProps, mapDispatchToProps)(SortedValue);
};

const generateSortedCurrencyBalanceSheetValueContainer = ({ currentField, options }) => {
  const mapStateToProps = (state, props) => {
    const { processingGroupId } = props;
    const { processingGroups, defaultOrder } = state.processingGroupSorting;
    const order = processingGroups[processingGroupId] || defaultOrder;
    const { filters } = state.processingGroups;
    const { field, direction } = order;
    return {
      field: currentField,
      currentDirection: (field === currentField) ? direction : null,
      ledgerDate: filters.ledgerDate,
      options,
    };
  };

  const mapDispatchToProps = { onClick: fetchCurrencyBalanceSheets };

  return connect(mapStateToProps, mapDispatchToProps)(SortedValue);
};

export { generateSortedProcessingGroupValueContainer, generateSortedCurrencyBalanceSheetValueContainer };
/* eslint-enable max-len */
