/* eslint-disable max-len */
import Client from '../../../client';

const REQUEST_WEIGHTS = 'REQUEST_WEIGHTS';
const RECEIVE_WEIGHTS = 'RECEIVE_WEIGHTS';
const ERROR_WEIGHTS = 'ERROR_WEIGHTS';

const requestWeight = dates => ({
  type: REQUEST_WEIGHTS,
  payload: { dates },
});

const receiveWeight = payload => ({
  type: RECEIVE_WEIGHTS,
  payload,
});

const errorWeight = error => ({
  type: ERROR_WEIGHTS,
  payload: { error },
});

const changeWeightsDate = (accountId, dates) => (
  async (dispatch) => {
    dispatch(requestWeight(dates));

    const response = await Client.getAccountWeightsWeight(accountId, dates);
    const json = await response.json();

    if (response.ok) {
      dispatch(receiveWeight(json));
    } else {
      dispatch(errorWeight(json.message));
    }
  }
);

export {
  REQUEST_WEIGHTS,
  RECEIVE_WEIGHTS,
  ERROR_WEIGHTS,
  changeWeightsDate,
};
