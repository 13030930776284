import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Client from '../../../client';

const POLL_EVERY_X_SECONDS = process.env.OONA_TRADES_POLLING_IN_SECONDS;

const doRequest = (filters, currentFxContracts, setState) => {
  setState({ fxContracts: currentFxContracts, loading: true });
  Client
    .getOonaFxContracts(filters)
    .then(response => response.json())
    .then((data) => {
      setState({ fxContracts: _.keyBy(data.fxContracts, 'id'), loading: false });
    });
};

export const usePolledFxContractsFetch = (filters) => {
  const [state, setState] = useState({ fxContracts: {}, loading: true });
  const stateRef = useRef(state);
  stateRef.current = state;
  const filtersRef = useRef(filters);
  filtersRef.current = filters;

  const serializedFxContracts = JSON.stringify(state.fxContracts);
  useEffect(() => {
    doRequest(filters, state.fxContracts, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, serializedFxContracts, setState]);

  useEffect(() => {
    const currentInterval = setInterval(() => {
      if (stateRef.current.loading) return;
      doRequest(filtersRef.current, stateRef.current.fxContracts, setState);
    }, POLL_EVERY_X_SECONDS * 1000);

    return () => {
      clearInterval(currentInterval);
    };
  }, []);

  return [state];
};
