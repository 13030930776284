import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './checkbox.scss';

export const Checkbox = ({
  label,
  checked,
  name,
  onCheck,
  onUncheck,
  value,
  spanOptions,
  disabled,
}) => {
  const handleChange = useCallback(
    (event) => {
      const { target } = event;
      if (target.checked) {
        onCheck(value);
      } else {
        onUncheck(value);
      }
    },
    [value, onCheck, onUncheck],
  );

  const inputOptions = {
    name,
    disabled,
    onChange: handleChange,
  };

  if (!_.isUndefined(checked)) {
    inputOptions.checked = checked;
  }

  return (
    <span className="select-checkbox-container" {...spanOptions}>
      <label className="control control--checkbox" htmlFor={name}>
        {label && <span className="checkbox-label">{label}</span>}
        <input type="checkbox" className="reports-config-checkbox" id={name} {...inputOptions} />
        <span className="control__indicator" />
      </label>
    </span>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  onUncheck: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  spanOptions: PropTypes.object,
};

Checkbox.defaultProps = {
  label: null,
  checked: undefined,
  disabled: false,
  spanOptions: {},
};
