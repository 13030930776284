import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  DropdownButton,
  MenuItem,
  Label,
} from 'react-bootstrap';
import { DateTime } from '../../../shared/date';

const Item = (props) => {
  const {
    id,
    name,
    client,
    description,
    nettingRestrictions,
    status,
    createdAt,
    updatedAt,
    onRemove,
  } = props;

  const navigate = useNavigate();

  const handleRemove = () => {
    onRemove(id);
  };

  const handleSubgroups = () => {
    navigate(`/execution_settings/groups/${id}/subgroups`);
  };

  const handleEdit = () => {
    navigate(`/execution_settings/groups/${id}/edit`);
  };

  const handleManageAccounts = () => {
    navigate(`/execution_settings/groups/${id}/accounts`);
  };

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{client.displayName}</td>
      <td>{description}</td>
      <td>{nettingRestrictions}</td>
      <td>
        <Label style={{ textTransform: 'uppercase' }}>{status}</Label>
      </td>
      <td>
        <DateTime dateTime={createdAt} />
      </td>
      <td>
        <DateTime dateTime={updatedAt} />
      </td>
      <td className="pull-right">
        <DropdownButton
          bsStyle="primary"
          title="Actions"
        >
          <MenuItem eventKey="1" onClick={handleEdit}>
            Edit
          </MenuItem>
          <MenuItem eventKey="2" onClick={handleRemove}>
            Delete
          </MenuItem>
          <MenuItem divider />
          <MenuItem eventKey="3" onClick={handleManageAccounts}>
            Manage Accounts
          </MenuItem>
          <MenuItem eventKey="4" onClick={handleSubgroups}>
            Manage Subgroups
          </MenuItem>
        </DropdownButton>
      </td>
    </tr>
  );
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  nettingRestrictions: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Item;
