import connect from 'react-redux/es/connect/connect';
import { ProcessingGroupActions } from '../components/processingGroupActions';
import { fetchCurrencyBalanceSheetBulkValidation, fetchCurrencyBalanceSheetBulkValidationAndSend, fetchBulkAccountRebalance } from '../actions/currencyBalanceSheets';
import {
  fetchValidateMostRecentBalanceSheetsInProcessingGroup,
  fetchValidateAndSendMostRecentBalanceSheetsInProcessingGroup,
} from '../actions/processingGroups';

const mapStateToProps = (state, props) => {
  const processingGroup = state.processingGroups.processingGroups[props.processingGroupId];
  const ids = processingGroup.currencyBalanceSheetIds;
  return {
    isFetchingCurrencyBalanceSheets: processingGroup.isFetchingCurrencyBalanceSheets,
    currencyBalanceSheetIds: ids,
    selectedBalanceSheetIds: ids.filter(id => state.selectedSheets.selected[id]),
    isValidating: !!state.validating.processingGroups[props.processingGroupId],
  };
};

const mapDispatchToProps = {
  onRebalance: fetchBulkAccountRebalance,
  onValidate: fetchCurrencyBalanceSheetBulkValidation,
  onValidateAndSend: fetchCurrencyBalanceSheetBulkValidationAndSend,
  onValidateMostRecentBalanceSheets: fetchValidateMostRecentBalanceSheetsInProcessingGroup,
  onValidateAndSendMostRecentBalanceSheets:
    fetchValidateAndSendMostRecentBalanceSheetsInProcessingGroup,
};

export const ProcessingGroupActionsContainer = connect(mapStateToProps,
  mapDispatchToProps)(ProcessingGroupActions);
