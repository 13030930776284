import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Routes from '../../../routes.js.erb';
import { RollProcessingGroupContainer } from '../../../roll/processingGroup/containers/rollProcessingGroupContainer';
import { ProcessingGroupNote } from '../../../notes/components/resourceNote';
import { Loading } from '../../../shared/loading';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { AdminOnly } from '../../../shared/adminOnly';
import { BulkProfitAndLossButton } from './bulkProfitAndLossButton';
import { RollAccountContainer } from '../../../roll/account/rollAccountContainer';

// Wrap component in AdminOnly HOC since Clients cannot interact with PGs
export const ProcessingGroupActions = AdminOnly((props) => {
  const [didDropdownOpenAtLeastOnce, setDidDropdownOpenAtLeastOnce] = useState(false);
  const {
    canValidate, canBulkRebalance, canRoll, canCreate: canCreateNote, loading: loadingPermissions,
  } = useAuthorizedResource([
    { modelClass: 'CurrencyBalanceSheet', modelAction: 'validate' },
    { modelClass: 'Account', modelAction: 'bulk_rebalance' },
    { modelClass: 'ProcessingGroup', modelAction: 'roll' },
    { modelClass: 'Note', modelAction: 'create' },
  ], didDropdownOpenAtLeastOnce);

  const loading = loadingPermissions;

  const handleValidation = (e) => {
    e.preventDefault();
    const { onValidate, selectedBalanceSheetIds } = props;
    if (selectedBalanceSheetIds.length === 0) return;
    onValidate(selectedBalanceSheetIds);
  };

  const handleValidationAndSend = (e) => {
    e.preventDefault();
    const { onValidateAndSend, selectedBalanceSheetIds } = props;
    if (selectedBalanceSheetIds.length === 0) return;
    onValidateAndSend(selectedBalanceSheetIds);
  };

  const handleRebalanceSelected = (e) => {
    e.preventDefault();
    const { onRebalance, selectedBalanceSheetIds } = props;
    onRebalance(selectedBalanceSheetIds);
  };

  const handleValidateMostRecentBalanceSheets = (e) => {
    e.preventDefault();
    const { onValidateMostRecentBalanceSheets, processingGroupId, currentFilters } = props;
    onValidateMostRecentBalanceSheets(processingGroupId, currentFilters);
  };

  const handleValidateAndSendMostRecentBalanceSheets = (e) => {
    e.preventDefault();
    const { onValidateAndSendMostRecentBalanceSheets, processingGroupId, currentFilters } = props;
    onValidateAndSendMostRecentBalanceSheets(processingGroupId, currentFilters);
  };


  const handleDropdownToggled = (open) => {
    if (!open) return;

    setDidDropdownOpenAtLeastOnce(true);
  };

  const {
    selectedBalanceSheetIds, isValidating, isFetchingCurrencyBalanceSheets, processingGroupId,
  } = props;
  const actionOverProcessingGroupHappening = isValidating || isFetchingCurrencyBalanceSheets;

  return (
    <div data-test-id={`processingGroupActions${processingGroupId}`}>
      <DropdownButton
        bsStyle="primary"
        title={isValidating ? 'Validating' : I18n.t('actions')}
        id={`processingGroupActions${processingGroupId}`}
        onToggle={handleDropdownToggled}
        disabled={actionOverProcessingGroupHappening}
      >
        {
          loading && (
            <MenuItem eventKey="loading">
              <Loading loading />
            </MenuItem>
          )
        }

        <MenuItem eventKey="viewTrades" href={Routes.fx_contracts_path({ trade_status: [0, 5], processing_group_ids: [processingGroupId] })} target="_blank" rel="noopener noreferrer">
          {I18n.t('currency_balance_sheets.index.view_trades')}
        </MenuItem>

        {
          canValidate && (
            <>
              <MenuItem eventKey="validateSelected" onClick={handleValidation} disabled={selectedBalanceSheetIds.length === 0 || actionOverProcessingGroupHappening}>
                {I18n.t('currency_balance_sheets.index.bulk_validate')}
              </MenuItem>
              <MenuItem eventKey="validateSelectedAndSend" onClick={handleValidationAndSend} disabled={selectedBalanceSheetIds.length === 0 || actionOverProcessingGroupHappening}>
                {I18n.t('currency_balance_sheets.index.bulk_validate_send')}
              </MenuItem>
              <MenuItem eventKey="validateAll" onClick={handleValidateMostRecentBalanceSheets} disabled={actionOverProcessingGroupHappening}>
                {I18n.t('currency_balance_sheets.index.validate_all')}
              </MenuItem>
              <MenuItem eventKey="validateAndSendall" onClick={handleValidateAndSendMostRecentBalanceSheets} disabled={actionOverProcessingGroupHappening}>
                {I18n.t('currency_balance_sheets.index.validate_send_all')}
              </MenuItem>
            </>
          )
        }
        {
          canBulkRebalance && (
            <MenuItem eventKey="rebalanceSelected" onClick={handleRebalanceSelected} disabled={selectedBalanceSheetIds.length === 0 || actionOverProcessingGroupHappening}>
              {I18n.t('currency_balance_sheets.index.rebalance_selected')}
            </MenuItem>
          )
        }
        {
          canRoll && (
            <>
              <li role="presentation" className="">
                <RollProcessingGroupContainer processingGroupId={processingGroupId} />
              </li>

              <li role="presentation" className={selectedBalanceSheetIds.length ? '' : 'button-disabled'}>
                <RollAccountContainer currencyBalanceSheetIds={selectedBalanceSheetIds}>
                  Roll Selected
                </RollAccountContainer>
              </li>
            </>
          )
        }
        <BulkProfitAndLossButton
          processingGroupId={processingGroupId}
          dropdownOpen={didDropdownOpenAtLeastOnce}
        />
        {
          canCreateNote && (
            <li role="presentation">
              <ProcessingGroupNote processingGroupId={processingGroupId} />
            </li>
          )
        }
      </DropdownButton>
    </div>
  );
});

ProcessingGroupActions.propTypes = {
  onRebalance: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onValidateMostRecentBalanceSheets: PropTypes.func.isRequired,
  onValidateAndSendMostRecentBalanceSheets: PropTypes.func.isRequired,
  processingGroupId: PropTypes.number.isRequired,
  selectedBalanceSheetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isValidating: PropTypes.bool.isRequired,
  isFetchingCurrencyBalanceSheets: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  currentFilters: PropTypes.object.isRequired,
  /* eslint-enabled react/forbid-prop-types */
};
