import { Alert } from 'react-bootstrap';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';
import { Date } from '../../shared';

export const HedgeSettlementDatePickerResult = ({ dates, source, onDismiss }) => (
  dates.length === 0 ? (
    <Alert bsStyle="warning" onDismiss={onDismiss}>
      $
      {I18n.t('currency_balance_sheets.index.hedge_settlement_date_picker.modal.no_results')}
    </Alert>
  ) : (
    <Alert bsStyle="success" onDismiss={onDismiss}>
      { I18n.t('dates') }
      { ' ' }
      <ul>
        {
          dates.map(date => (
            <li key={date}>
              <Date date={date} format="LL" />
            </li>
          ))
        }
      </ul>
      {
        source && (
          `${I18n.t('currency_balance_sheets.index.hedge_settlement_date_picker.modal.source')}: ${I18n.t(`currency_balance_sheets.index.hedge_settlement_date_picker.modal.sources.${source}`)}`
        )
      }
    </Alert>
  )
);

HedgeSettlementDatePickerResult.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
};

HedgeSettlementDatePickerResult.defaultProps = {
  dates: [],
  source: null,
};
