import React from 'react';
import PropTypes from 'prop-types';
import { currencyPropType, hedgeLineItemPropType } from '../commonPropTypes';
import { LineItemSameCurrencyTable } from './exposureLineItems/lineItemSameCurrencyTable';
import { LineItemDifferentCurrencyTable } from './exposureLineItems/lineItemDifferentCurrencyTable';

export const ExposureLineItemsTable = (props) => {
  const {
    lineItems, localCurrency, baseCurrency, showContent,
  } = props;
  const areDifferentCurrencies = localCurrency.isoCode !== baseCurrency.isoCode;

  if (areDifferentCurrencies) {
    return (
      <LineItemDifferentCurrencyTable {...props} />
    );
  }
  return (
    <LineItemSameCurrencyTable
      lineItems={lineItems}
      localCurrency={localCurrency}
      showContent={showContent}
    />
  );
};

ExposureLineItemsTable.propTypes = {
  lineItems: PropTypes.arrayOf(hedgeLineItemPropType).isRequired,
  localCurrency: currencyPropType.isRequired,
  baseCurrency: currencyPropType.isRequired,
  hedgeRatioEvaluation: PropTypes.string.isRequired,
  showContent: PropTypes.bool.isRequired,
};
