import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Client from '../../../../client';
import { ReportConfigurationForm } from '../../shared/components/reportConfigurationForm';
import Routes from '../../../../routes.js.erb';

class EditReportConfigurationContainer extends React.Component {
  static propTypes = {
    configId: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.object.isRequired,
    timezones: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    const { configId } = this.props;
    Client
      .getReportConfiguration(configId)
      .then(response => response.json())
      .then((data) => {
        const { selectedFields, notSelectedFields, scheduleConfiguration } = data.config;
        this.setState({
          selectedFields,
          notSelectedFields,
          scheduleConfiguration,
          loading: false,
        });
      });
  };

  handleSubmit = (payload) => {
    const { configId } = this.props;
    Client.updateReportConfiguration(configId, payload)
      .then(response => response.json())
      .then(({ success, message }) => {
        if (success) {
          alert(I18n.t('users_portal.reports_configs.update.success'));
          /* eslint-disable no-undef */
          window.location.href = Routes.reports_configs_path();
          /* eslint-enable no-undef */
        } else {
          alert(message);
        }
      });
  };

  render() {
    const {
      loading, selectedFields, notSelectedFields, scheduleConfiguration,
    } = this.state;
    if (loading) {
      return I18n.t('loading');
    }
    const { configId, options, ...otherProps } = this.props;
    return (
      <ReportConfigurationForm
        selectedFields={selectedFields}
        notSelectedFields={notSelectedFields}
        scheduleConfiguration={scheduleConfiguration}
        selectedOptions={options}
        {...otherProps}
        edit
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export { EditReportConfigurationContainer };
