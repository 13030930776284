import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';

import { account as accountPropTypes } from '../commonPropTypes';

import { AccountRowContainer } from '../../containers/accounts/rowContainer';

import AccountHeaders from './headers';

class AccountsTable extends React.PureComponent {
  render() {
    const {
      expanded, accounts, processingGroupId,
    } = this.props;

    if (!expanded) return null;

    if (accounts.length === 0) {
      return (
        <tr>
          <td colSpan={99}>{ I18n.t('users_portal.processing_dashboard.empty_accounts') }</td>
        </tr>
      );
    }

    return (
      <>
        <AccountHeaders processingGroupId={processingGroupId} />
        { accounts.map(account => (
          <AccountRowContainer
            key={`${processingGroupId}-${account.id}`}
            processingGroupId={processingGroupId}
            id={account.id}
          />
        ))}
      </>
    );
  }
}

AccountsTable.propTypes = {
  accounts: PropTypes.arrayOf(accountPropTypes),
  expanded: PropTypes.bool,
  processingGroupId: PropTypes.number,
};

AccountsTable.defaultProps = {
  accounts: [],
  expanded: false,
  processingGroupId: null,
};

export { AccountsTable };
