import PropTypes from 'prop-types';
import React from 'react';
import Client from '../../../client';
import { CLIENT } from '../../entityTypes';
import { ClientFilter } from '../../../shared/filters/clientFilter';
import { NewResourceModal } from './newResourceModal';
import { useEntityModalState } from '../hooks/useEntityModalState';

export const NewNoteClientModal = ({ onSubmit, onClose, show }) => {
  const {
    entities, selectedEntityId, loading, handleChange, handleEnter,
  } = useEntityModalState(Client.getClients, 'clients');

  return (
    <NewResourceModal
      entity={{ type: CLIENT, id: selectedEntityId }}
      onSubmit={onSubmit}
      onClose={onClose}
      onEnter={handleEnter}
      show={show}
    >
      <ClientFilter
        clients={entities}
        loading={loading}
        onChange={handleChange}
        selectedClientId={selectedEntityId}
      />
    </NewResourceModal>
  );
};

NewNoteClientModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
