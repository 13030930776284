import { generateSortedCurrencyBalanceSheetFieldContainer } from '../shared/sortedFieldContainerFactory';
import { generateSortedCurrencyBalanceSheetValueContainer } from '../shared/sortedValueContainerFactory';

const SortedAccountIdFieldContainer = generateSortedCurrencyBalanceSheetFieldContainer({
  currentField: 'account_id',
  displayKey: 'activerecord.attributes.account.id',
  options: { width: '5%' },
});

const SortedAccountIdValueContainer = generateSortedCurrencyBalanceSheetValueContainer({
  currentField: 'account_id',
  options: { width: '5%' },
});

const SortedAccountNameFieldContainer = generateSortedCurrencyBalanceSheetFieldContainer({
  currentField: 'account_name',
  displayKey: 'activerecord.attributes.account.account_name',
  options: { width: '20%' },
});

const SortedAccountNameValueContainer = generateSortedCurrencyBalanceSheetValueContainer({
  currentField: 'account_name',
  options: { width: '25%' },
});

export {
  SortedAccountNameFieldContainer,
  SortedAccountNameValueContainer,
  SortedAccountIdFieldContainer,
  SortedAccountIdValueContainer,
};
