import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import moment from 'moment';
import classNames from 'classnames';
import Client from '../../../client';
import { Editor } from './editor';
import { Viewer } from './viewer';
import { NotePropType } from '../../commonPropTypes';
import { RelatedNotesButton } from '../../components/noteButtons';
import './noteModal.scss';

export class EditNoteModalContent extends React.Component {
  static propTypes = {
    note: NotePropType.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      submitting: false,
      editMode: false,
      text: props.note.text,
    };

    this.submitNote = this.submitNote.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
  }

  deleteNote() {
    this.setState({ submitting: true });
    const { note: { id }, onDelete } = this.props;
    Client
      .deleteNote(id)
      .then(() => {
        this.setState({ submitting: false });
        onDelete(id);
      });
  }

  submitNote() {
    const { text } = this.state;
    const { note: { id }, onSubmit } = this.props;
    this.setState({ submitting: true });
    Client
      .updateNote(id, { text })
      .then(response => response.json())
      .then(({ note }) => {
        this.setState({ submitting: false });
        onSubmit(note);
      });
  }

  render() {
    const {
      note: {
        entityType, updatedAt, text: originalText, user: { email: userEmail },
      },
      onSubmit, onDelete, ...otherProps
    } = this.props;
    const { submitting, text, editMode } = this.state;
    return (
      <Fragment>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('notes.edit_note_modal.title', { entity_type: entityType })}</Modal.Title>
        </Modal.Header>
        <Modal.Body key="edit-note-body" bsClass="note-modal-body">
          <ul className="nav nav-tabs">
            <li
              className={classNames({ active: !editMode })}
              onClick={() => this.setState({ editMode: false })}
              role="presentation"
            >
              <a href="#">{I18n.t('preview')}</a>
            </li>
            <li
              className={classNames({ active: editMode })}
              onClick={() => this.setState({ editMode: true })}
              role="presentation"
            >
              <a href="#">{I18n.t('edit')}</a>
            </li>
          </ul>
          <div className="note-modal-body-content">
            {
              editMode ? (
                <Editor
                  initialValue={text}
                  onChange={newText => this.setState({ text: newText })}
                />
              ) : (
                <div className="preview-note-tab-content">
                  <Viewer value={text} />
                </div>
              )
            }
          </div>
          <div className="text-right">
            {I18n.t('notes.edit_note_modal.last_edited_at', { time: moment(updatedAt).format('MMMM DD, YYYY HH:mm') })}
            {' '}
            {I18n.t('notes.edit_note_modal.edited_by', { user: userEmail })}
          </div>
        </Modal.Body>
        <Modal.Footer className="clearfix">
          { otherProps && (
            <div className="pull-left">
              <RelatedNotesButton {...otherProps} asButton />
            </div>
          )}
          <div className="pull-right">
            <Button
              bsStyle="danger"
              onClick={this.deleteNote}
              disabled={submitting}
            >
              {I18n.t('notes.edit_note_modal.delete')}
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.submitNote}
              disabled={submitting || text.length === 0 || text === originalText}
            >
              {I18n.t('notes.edit_note_modal.submit')}
            </Button>
          </div>
        </Modal.Footer>
      </Fragment>
    );
  }
}


export const EditNoteModal = (props) => {
  const { onClose, show } = props;
  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      { show && <EditNoteModalContent {...props} /> }
    </Modal>
  );
};

EditNoteModal.propTypes = {
  note: NotePropType,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

EditNoteModal.defaultProps = {
  note: null,
};
