import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Routes from '../../../routes.js.erb';
import { CustomAsyncSelect } from '../../../shared/customAsyncSelect';

export const RawAccountDataMapSetsSelect = (props) => {
  const {
    selectedClientId, selectedAccountId, selectedProcessingGroupId,
    selectedRawAccountDataMapSetId,
    onChange,
  } = props;

  const optionsUrl = Routes.api_v1_raw_account_data_map_sets_path(
    {
      format: 'json',
      client: selectedClientId,
      account: selectedAccountId,
      processing_group: selectedProcessingGroupId,
    },
  );

  return (
    <CustomAsyncSelect
      name="raw_account_data_map_set"
      id="select_account_data_map_set"
      placeholder={I18n.t('users_portal.raw_data_files.index.filter.all_account_data_map_set')}
      allowBlank
      blankPlaceholder={I18n.t('users_portal.raw_data_files.index.filter.all_account_data_map_set')}
      optionsUrl={optionsUrl}
      buildOptions={mapSets => mapSets.map(set => ({ value: set.id, label: set.name }))}
      defaultOptionValue={selectedRawAccountDataMapSetId}
      onChange={onChange}
    />
  );
};

RawAccountDataMapSetsSelect.defaultProps = {
  selectedAccountId: null,
  selectedClientId: null,
  selectedProcessingGroupId: null,
  selectedRawAccountDataMapSetId: null,
};

RawAccountDataMapSetsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedAccountId: PropTypes.number,
  selectedClientId: PropTypes.number,
  selectedProcessingGroupId: PropTypes.number,
  selectedRawAccountDataMapSetId: PropTypes.number,
};
