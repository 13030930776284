import {
  REMOVE_LINE_ITEM_MAP,
} from '../actions/currencyLineItemMaps';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_LINE_ITEM_MAP: {
      const { id } = action.payload;
      if (!id) {
        return state;
      }
      return [
        ...state,
        id,
      ];
    }
    default:
      return state;
  }
}
