import connect from 'react-redux/es/connect/connect';

import { ProcessingGroupRow } from '../../components/processingGroup/row';

const mapStateToProps = (state, props) => {
  const processingGroup = state.processingGroups.data.processingGroups[props.id];

  return {
    ...processingGroup,
    currentFilters: state.processingGroups.filters,
  };
};

export const ProcessingGroupRowContainer = connect(mapStateToProps)(ProcessingGroupRow);
