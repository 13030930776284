/* eslint-disable no-undef */
import _ from 'lodash';
import snakeize from 'snakeize';
import toFormData from './utils/toFormData';
import Routes from './routes.js.erb';

/* eslint-disable max-len */
export default class Client {
  static getJson(url) {
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  static postJson(url, data) {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    });
  }

  static putJson(url, data) {
    let options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    if (data) {
      options = {
        ...options,
        body: JSON.stringify(data),
      };
    }
    return fetch(url, options);
  }

  static deleteJson(url) {
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  static headJson(url) {
    return fetch(url, {
      method: 'HEAD',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  static getCurrencies() {
    return Client.getJson(Routes.api_v1_currencies_path());
  }

  static getAccountAvailableRolls(currencyBalanceSheetIds) {
    return Client.getJson(Routes.available_rolls_api_v1_accounts_path({ currency_balance_sheet_ids: currencyBalanceSheetIds }));
  }

  static getProcessingGroupAvailableRolls(processingGroupId) {
    return Client.getJson(
      Routes.api_v1_processing_group_available_rolls_path({
        processing_group_id: processingGroupId,
      }),
    );
  }

  static getProcessingGroups(filters = {}, order = {}) {
    const params = {
      client_id: filters.clientId,
      account_id: filters.accountId,
      processing_group_id: filters.processingGroupId,
      selected_ledger_date: filters.ledgerDate,
      flag_id: filters.flagId,
      without_notes: filters.withoutNotes,
      order: _.isEmpty(order) ? order : `${order.field}:${order.direction}`,
    };

    return Client.getJson(Routes.api_v1_processing_groups_path(params));
  }

  static getExecutionGroups({ filters, order, page }) {
    const params = {
      filters,
      order: _.isEmpty(order) ? order : `${order.field}:${order.direction}`,
      page,
    };

    return Client.getJson(Routes.api_v1_execution_groups_path(snakeize(params)));
  }

  static getExecutionSubgroups(groupId, params) {
    return Client.getJson(Routes.api_v1_execution_group_execution_subgroups_path(groupId, snakeize(params)));
  }

  static getExecutionTradeTypes(subgroupId, params) {
    return Client.getJson(Routes.api_v1_execution_subgroup_execution_trade_types_path(subgroupId, snakeize(params)));
  }

  static getExecutionLocalCurrencies(subgroupId, params) {
    return Client.getJson(Routes.api_v1_execution_subgroup_execution_local_currencies_path(subgroupId, snakeize(params)));
  }

  static getExecutionSubgroup(id) {
    return Client.getJson(Routes.api_v1_execution_subgroup_path(id));
  }

  static getExecutionGroup(id) {
    return Client.getJson(Routes.api_v1_execution_group_path(id));
  }

  static createExecutionGroup(data) {
    return Client.postJson(Routes.api_v1_execution_groups_path(), snakeize(data));
  }

  static createExecutionSubgroup(data) {
    return Client.postJson(Routes.api_v1_execution_subgroups_path(), snakeize(data));
  }

  static createExecutionTradeType(data) {
    return Client.postJson(Routes.api_v1_execution_trade_types_path(), snakeize(data));
  }

  static createExecutionLocalCurrency(data) {
    return Client.postJson(Routes.api_v1_execution_local_currencies_path(), snakeize(data));
  }

  static updateExecutionGroup(id, data) {
    return Client.putJson(Routes.api_v1_execution_group_path(id), snakeize(data));
  }

  static updateExecutionSubgroup(id, data) {
    return Client.putJson(Routes.api_v1_execution_subgroup_path(id), snakeize(data));
  }

  static removeExecutionTradeType(id) {
    return Client.deleteJson(Routes.api_v1_execution_trade_type_path(id));
  }

  static removeExecutionLocalCurrency(id) {
    return Client.deleteJson(Routes.api_v1_execution_local_currency_path(id));
  }

  static removeExecutionSubgroup(id) {
    return Client.deleteJson(Routes.api_v1_execution_subgroup_path(id));
  }

  static removeExecutionGroup(id) {
    return Client.deleteJson(Routes.api_v1_execution_group_path(id));
  }

  static getCurrencyBalanceSheets(
    {
      ledgerDate, processingGroupId, flagId, accountId, clientId,
    },
    order = {},
    page = 1,
    perPage = 5,
  ) {
    const params = {
      ledger_date: ledgerDate,
      processing_group_id: processingGroupId,
      flag_id: flagId,
      account_id: accountId,
      client_id: clientId,
      order: _.isEmpty(order) ? order : `${order.field}:${order.direction}`,
      page,
      per_page: perPage,
    };
    return Client.getJson(Routes.api_v1_currency_balance_sheets_path(params));
  }

  static getCurrencyBalanceSheet(id) {
    return Client.getJson(Routes.api_v1_currency_balance_sheet({ id, format: 'json' }));
  }

  static createAccountRoll(accountId, data) {
    return Client.postJson(Routes.api_v1_account_rolls_path(accountId), data);
  }

  static createAccountBulkRoll(accountIds, data) {
    return Client.postJson(Routes.bulk_roll_api_v1_accounts_path(), { account_ids: accountIds, ...data });
  }

  static createProcessingGroupRoll(processingGroupId, data) {
    return Client.postJson(Routes.api_v1_processing_group_rolls_path(processingGroupId), data);
  }

  static updateReportConfiguration(configurationId, data) {
    return fetch(Routes.api_v1_reports_config_path(configurationId), {
      method: 'PUT',
      body: toFormData(data),
    });
  }

  static createReportConfiguration(data) {
    return fetch(Routes.api_v1_reports_configs_path(), {
      method: 'POST',
      body: toFormData(data),
    });
  }

  static getReportDataset(datasetId) {
    return Client.getJson(Routes.api_v1_reports_dataset_path(datasetId));
  }

  static getReportConfiguration(configId) {
    return Client.getJson(Routes.api_v1_reports_config_path(configId));
  }

  static validateBalanceSheets(...ids) {
    return Client.putJson(Routes.bulk_validate_api_v1_currency_balance_sheets_path({ ids }));
  }

  static rebalanceAccounts(ids) {
    return Client.putJson(
      Routes.bulk_rebalance_api_v1_accounts_path({ currency_balance_sheet_ids: ids }),
    );
  }

  static getFlags() {
    return Client.getJson(Routes.api_v1_flags_path());
  }

  static getClients({ withoutNotes } = { withoutNotes: null }) {
    return Client.getJson(Routes.api_v1_clients_path({ without_notes: withoutNotes }));
  }

  static getAccounts(
    {
      clientId, processingGroupId, accountType, withoutNotes,
    } = {
      clientId: null,
      processingGroupId: null,
      accountType: null,
      withoutNotes: null,
    },
  ) {
    return Client.getJson(
      Routes.api_v1_accounts_path({
        client_id: clientId,
        processing_group_id: processingGroupId,
        account_type: accountType,
        without_notes: withoutNotes,
      }),
    );
  }

  static bulkValidateCurrencyBalanceSheets(ids) {
    return Client.putJson(Routes.bulk_validate_api_v1_currency_balance_sheets_path({ ids }));
  }

  static putValidateMostRecentBalanceSheetsInProcessingGroup(id, filters) {
    return Client.putJson(
      Routes.validate_most_recent_balance_sheets_api_v1_processing_group_path(id, filters),
    );
  }

  static sendOrder(currencyBalanceSheetIds) {
    return Client.postJson(
      Routes.send_order_api_v1_fx_contracts_path({ currency_balance_sheet_ids: currencyBalanceSheetIds }),
    );
  }

  static getRawDataFiles(updatedAfter) {
    return Client.getJson(
      Routes.api_v1_raw_data_files_path({ format: 'json', updated_after: updatedAfter }),
    );
  }

  static getEntityNote(entityType, entityId) {
    switch (entityType) {
    case 'ProcessingGroup':
      return Client.getJson(
        Routes.api_v1_entity_note_path({
          format: 'json',
          entities: 'processing_groups',
          entity_id: entityId,
        }),
      );
    case 'Client':
      return Client.getJson(
        Routes.api_v1_entity_note_path({
          format: 'json',
          entities: 'clients',
          entity_id: entityId,
        }),
      );
    case 'Account':
      return Client.getJson(
        Routes.api_v1_entity_note_path({
          format: 'json',
          entities: 'accounts',
          entity_id: entityId,
        }),
      );
    default:
      return null;
    }
  }

  static peekClientNote(clientId) {
    return Client.headJson(
      Routes.api_v1_entity_note_path({ format: 'json', entities: 'clients', entity_id: clientId }),
    );
  }

  static peekProcessingGroupNote(processingGroupId) {
    return Client.headJson(
      Routes.api_v1_entity_note_path({
        format: 'json',
        entities: 'processing_groups',
        entity_id: processingGroupId,
      }),
    );
  }

  static peekAccountNote(accountId) {
    return Client.headJson(
      Routes.api_v1_entity_note_path({
        format: 'json',
        entities: 'accounts',
        entity_id: accountId,
      }),
    );
  }

  static peekEntityNote(entityType, entityId) {
    switch (entityType) {
    case 'ProcessingGroup':
      return Client.peekProcessingGroupNote(entityId);
    case 'Client':
      return Client.peekClientNote(entityId);
    case 'Account':
      return Client.peekAccountNote(entityId);
    default:
      return null;
    }
  }

  static getNotes(entityType, entityId) {
    return Client.getJson(
      Routes.api_v1_notes_path({ format: 'json', entity_type: entityType, entity_id: entityId }),
    );
  }

  static peekNotes(entityType, entityId) {
    return Client.headJson(
      Routes.api_v1_notes_path({ format: 'json', entity_type: entityType, entity_id: entityId }),
    );
  }

  static createNote(data) {
    return Client.postJson(Routes.api_v1_notes_path(), data);
  }

  static updateNote(id, noteData) {
    return Client.putJson(Routes.api_v1_note_path({ id }), { note: noteData });
  }

  static deleteNote(noteId) {
    return Client.deleteJson(Routes.api_v1_note_path({ id: noteId }));
  }

  static getUnusualFiles(evaluatedAt) {
    return Client.getJson(
      Routes.rosy_api_v1_unusual_files_path({
        format: 'json',
        filters: { evaluated_at: evaluatedAt },
      }),
    );
  }

  static getUnusualRecords(evaluatedAt, model) {
    return Client.getJson(
      Routes.rosy_api_v1_unusual_records_path({
        format: 'json',
        filters: { evaluated_at: evaluatedAt, model },
      }),
    );
  }

  static getOonaFxContracts(filters) {
    return Client.getJson(Routes.oona_api_fx_contracts_path({ format: 'json', ...filters }));
  }

  static netPairsFxContracts(ids) {
    return Client.postJson(Routes.oona_api_net_pairs_path({ format: 'json' }), {
      fx_contracts: ids,
    });
  }

  static netByPivotFxContracts(ids, pivotCurrencyCode) {
    const data = {
      fx_contract_ids: ids,
      pivot_currency_code: pivotCurrencyCode,
    };
    return Client.postJson(Routes.oona_api_net_by_pivot_path({ format: 'json' }), data);
  }

  static simulateTradeExecution(ids) {
    return Client.putJson(
      Routes.simulate_trade_execution_oona_api_fx_contracts_path({ format: 'json' }),
      { fx_contracts: ids },
    );
  }

  static userAuthorized(rules) {
    const permissions = _.map(rules, rule => _.mapKeys(rule, (value, key) => _.snakeCase(key)));
    return Client.getJson(
      Routes.api_v1_authorizations_path({
        format: 'json',
        permissions,
      }),
    );
  }

  static bulkProfitAndLossConversion(processingGroupId) {
    return Client.postJson(
      Routes.profit_and_loss_local_conversions_api_v1_processing_group_path({
        format: 'json',
        id: processingGroupId,
      }),
      {},
    );
  }

  static createProcess(data) {
    return Client.postJson(Routes.api_v1_processes_path(), data);
  }

  static getProcesses(
    {
      clientId, processingGroupId, accountType, processStatus, page,
    } = {
      clientId: null,
      processingGroupId: null,
      accountType: null,
      processStatus: null,
      page: 1,
    },
  ) {
    return Client.getJson(
      Routes.api_v1_processes_path({
        client_id: clientId,
        processing_group_id: processingGroupId,
        account_type: accountType,
        process_status: processStatus,
        page,
      }),
    );
  }

  static getProcess(processId) {
    return Client.getJson(Routes.api_v1_process_path(processId));
  }

  static createAccountFlag(accountIds, flagId, action, note) {
    const data = {
      account_flag: {
        account_ids: accountIds,
        flag_id: flagId,
        action,
        note,
      },
    };
    return Client.postJson(Routes.api_v1_account_flags_path({ format: 'json' }), data);
  }

  static listAccountFlags({ flagId, clientId }) {
    const data = {
      flag_id: flagId,
      client_id: clientId,
    };

    return Client.getJson(Routes.list_notes_api_v1_account_flags_path({ format: 'json', ...data }));
  }

  // Processing Dashboard
  static getProcessingDashboardProcessingGroupsMetadata(filters = {}, order = {}, page = 1) {
    const params = {
      client_id: filters.clientId,
      account_id: filters.accountId,
      processing_group_id: filters.processingGroupId,
      selected_ledger_date: filters.ledgerDate,
      order: _.isEmpty(order) ? order : `${order.field}:${order.direction}`,
      per_page: filters.perPage,
      page,
    };

    return Client.getJson(Routes.processing_dashboard_metadata_api_v1_processing_groups_path(params));
  }

  static getProcessingDashboardProcessingGroupsData(ids = [], filters) {
    const params = {
      account_id: filters.accountId,
      selected_ledger_date: filters.ledgerDate,
      ids,
    };
    if (Array.isArray(ids) && ids?.length > 0) {
      return Client.getJson(Routes.processing_dashboard_data_api_v1_processing_groups_path(params));
    }
    return null;
  }

  // Account Weights

  // Format of date expected to be: YYYY-MM-DD
  static getAccountWeightsWeight(accountId, dates) {
    return Client.getJson(Routes.weights_api_v1_account_path(accountId, { valuation_dates: dates }));
  }
}
/* eslint-enable max-len */
/* eslint-enable no-undef */
