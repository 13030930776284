import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { SummaryColumn } from './summaryColumn';
import { Percentage } from '../../../shared';
import { Amount } from '../../../shared/amount';
import { WeightSummary } from './weightSummary';

export const ExposureSummary = (props) => {
  const {
    account: {
      hedgeRatioEvaluation,
      baseCurrency,
      currencyExposureSettings,
    },
    group,
    onExpandClick,
    isExpanded,
    identifier,
  } = props;
  const {
    currencyExposureHedgeInLocalCurrency,
    currencyExposureHedgeInBaseCurrency,
    totalInBaseCurrency,
    totalInLocalCurrency,
    criteria: { localCurrency },
    currencyHedgeRatio,
    nextSettlementDate,
    weightSummary,
  } = group;
  const hedgeEvaluatesOnBaseSide = hedgeRatioEvaluation === 'account_base_currency';
  const areDifferentCurrencies = localCurrency.isoCode !== baseCurrency.isoCode;
  const lineItemsLocalSummary = (
    <SummaryColumn title={localCurrency.isoCode} subtitle={I18n.t('currency_balance_sheet.summary.local_currency')}>
      { Amount(currencyExposureHedgeInLocalCurrency) }
    </SummaryColumn>
  );
  const lineItemsBaseSummary = (
    <SummaryColumn title={baseCurrency.isoCode} subtitle={I18n.t('currency_balance_sheet.summary.base_currency')}>
      { Amount(currencyExposureHedgeInBaseCurrency) }
    </SummaryColumn>
  );
  const fxContractsLocalSummary = (
    <SummaryColumn title={localCurrency.isoCode} subtitle={I18n.t('currency_balance_sheet.summary.local_currency')}>
      { Amount(totalInLocalCurrency) }
    </SummaryColumn>
  );
  const fxContractsBaseSummary = (
    <SummaryColumn title={baseCurrency.isoCode} subtitle={I18n.t('currency_balance_sheet.summary.base_currency')}>
      { Amount(totalInBaseCurrency) }
    </SummaryColumn>
  );
  const matchingSetting = currencyExposureSettings
    .find(setting => setting.localCurrency.isoCode === localCurrency.isoCode);
  const weightSummaryComponent = !_.isNull(weightSummary)
    && <WeightSummary weight={weightSummary} />;
  return (
    <div className="summary-row bottom-separator">
      {
        hedgeEvaluatesOnBaseSide ? (
          <React.Fragment>
            { lineItemsLocalSummary }
            { areDifferentCurrencies && lineItemsBaseSummary }
          </React.Fragment>
        ) : (
          <React.Fragment>
            { areDifferentCurrencies && lineItemsBaseSummary }
            { lineItemsLocalSummary }
          </React.Fragment>
        )
      }
      {
        areDifferentCurrencies ? (
          <React.Fragment>
            { weightSummaryComponent }
            <SummaryColumn
              title={I18n.t('shared.currency_balance_sheets.show.hedge_ratio')}
              subtitle={(
                <React.Fragment>
                  {I18n.t('currency_balance_sheet.summary.normal')}
                  { ' ' }
                  <Percentage percentage={matchingSetting?.lowerBound} />
                  { ' - ' }
                  <Percentage percentage={matchingSetting?.upperBound} />
                </React.Fragment>
              )}
              valueClassName={currencyHedgeRatio.isOutOfBounds ? 'current_hedge_ratio_out_of_tolerance' : 'current_hedge_ratio'}
            >
              <Percentage percentage={currencyHedgeRatio.percentage} />
            </SummaryColumn>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="summary-column" />
            { weightSummaryComponent }
            <div className="summary-column" />
          </React.Fragment>
        )
      }
      <div className="summary-column">
        <div className="value settlement-date">
          { nextSettlementDate ? moment(nextSettlementDate).format('DD-MMMM-YYYY') : '-' }
        </div>
        <div className="value-subtitle">
          {I18n.t('currency_balance_sheet.summary.next_open_settlement_date')}
        </div>
      </div>
      {
        !areDifferentCurrencies && (
          <React.Fragment>
            <div className="summary-column" />
            <div className="summary-column" />
          </React.Fragment>
        )
      }
      {
        areDifferentCurrencies && (hedgeEvaluatesOnBaseSide ? (
          <React.Fragment>
            { fxContractsBaseSummary }
            { fxContractsLocalSummary }
          </React.Fragment>
        ) : (
          <React.Fragment>
            { fxContractsLocalSummary }
            { fxContractsBaseSummary }
          </React.Fragment>
        )
        )
      }
      <a className="collapse-button" role="button" onClick={onExpandClick} tabIndex={0} id={`expand-${identifier}`}>
        <i className="material-icons">{(isExpanded) ? 'unfold_less' : 'unfold_more'}</i>
      </a>
    </div>
  );
};

ExposureSummary.propTypes = {
  account: PropTypes.shape({
    baseCurrency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isoCode: PropTypes.string.isRequired,
    }).isRequired,
    hedgeRatioEvaluation: PropTypes.string.isRequired,
    currencyExposureSettings: PropTypes.arrayOf(
      PropTypes.shape({
        localCurrency: PropTypes.shape({
          id: PropTypes.number.isRequired,
          isoCode: PropTypes.string.isRequired,
          rank: PropTypes.number.isRequired,
        }),
        lowerBound: PropTypes.string.isRequired,
        upperBound: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  group: PropTypes.shape({
    criteria: PropTypes.shape({
      localCurrency: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isoCode: PropTypes.string.isRequired,
        rank: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    currencyHedgeRatio: PropTypes.shape({
      localCurrency: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isoCode: PropTypes.string.isRequired,
        rank: PropTypes.number.isRequired,
      }).isRequired,
      isOutOfBounds: PropTypes.bool.isRequired,
      percentage: PropTypes.string.isRequired,
    }).isRequired,
    currencyExposureHedgeInLocalCurrency: PropTypes.string.isRequired,
    currencyExposureHedgeInBaseCurrency: PropTypes.string.isRequired,
    totalInBaseCurrency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    totalInLocalCurrency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    nextSettlementDate: PropTypes.string,
    weightSummary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  isExpanded: PropTypes.bool,
  onExpandClick: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
};

ExposureSummary.defaultProps = {
  isExpanded: false,
};
