import React, { Fragment } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Client from '../../../client';
import { ClientFilter } from '../../../shared/filters/clientFilter';
import { ProcessingGroupFilter } from '../../../shared/filters/processingGroupFilter';
import { AccountTypeFilter } from '../../../shared/filters/accountTypeFilter';
import { ProcessStatusFilter } from './processStatusFilter';
import '../../../shared/filters/clientProcessingGroupAccountFilter.scss';

export class ProcessesFilter extends React.Component {
  static propTypes = {
    selectedClientId: PropTypes.number,
    selectedProcessingGroupId: PropTypes.number,
    selectedAccountType: PropTypes.string,
    selectedProcessStatus: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    /* eslint-disable react/forbid-prop-types */
    layout: PropTypes.object,
    clientInputProps: PropTypes.object,
    processingGroupInputProps: PropTypes.object,
    accountTypeInputProps: PropTypes.object,
    processStatusInputProps: PropTypes.object,
    /* eslint-enable react/forbid-prop-types */
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      clients: [],
      processingGroups: [],
      loadingClients: true,
      loadingProcessingGroups: true,
    };
  }

  static getDefaultProps = {
    selectedClientId: null,
    selectedProcessingGroupId: null,
    selectedAccountType: null,
    selectedProcessStatus: null,
    layout: {},
    clientInputProps: {},
    processingGroupInputProps: {},
    accountTypeInputProps: {},
    processStatusInputProps: {},
  };

  componentDidMount = () => {
    const { selectedClientId } = this.props;
    this.fetchClients();
    this.fetchProcessingGroups(selectedClientId);
  };

  componentDidUpdate = (prevProps) => {
    const { selectedClientId } = this.props;
    if (_.isEqual(selectedClientId, prevProps.selectedClientId)) return;
    this.fetchProcessingGroups(selectedClientId);
  };

  handleClientChange = (option) => {
    const { onChange } = this.props;
    const selectedClientId = option ? option.value : null;
    onChange({
      selectedClientId,
      selectedProcessingGroupId: null,
    });
  };

  handleProcessingGroupChange = (option) => {
    const { onChange } = this.props;
    const newSelectedProcessingGroupId = option ? option.value : null;
    if (newSelectedProcessingGroupId != null) {
      onChange({
        selectedProcessingGroupId: newSelectedProcessingGroupId,
      });
    } else {
      onChange({
        selectedProcessingGroupId: null,
      });
    }
  };

  handleAccountTypeChange = (option) => {
    const { onChange } = this.props;
    const newAccountType = option ? option.value : null;
    if (newAccountType != null) {
      onChange({
        selectedAccountType: newAccountType,
      });
    } else {
      onChange({
        selectedAccountType: null,
      });
    }
  };

  handleProcessStatusChange = (option) => {
    const { onChange } = this.props;
    const newProcessStatus = option ? option.value : null;
    if (newProcessStatus != null) {
      onChange({
        selectedProcessStatus: newProcessStatus,
      });
    } else {
      onChange({
        selectedProcessStatus: null,
      });
    }
  };

  fetchClients() {
    Client.getClients()
      .then(response => response.json())
      .then((data) => {
        this.setState({
          clients: data.clients,
          loadingClients: false,
        });
      });
  }

  fetchProcessingGroups(clientId) {
    Client.getProcessingGroups({ clientId })
      .then(response => response.json())
      .then((data) => {
        this.setState({
          processingGroups: data.processingGroups,
          loadingProcessingGroups: false,
        });
      });
  }

  render() {
    const {
      loadingClients,
      loadingProcessingGroups,
      clients,
      processingGroups,
      selectedAccountType,
    } = this.state;
    const {
      selectedClientId,
      selectedProcessingGroupId,
      selectedProcessStatus,
      layout,
      clientInputProps,
      processingGroupInputProps,
      accountTypeInputProps,
      processStatusInputProps,
    } = this.props;
    return (
      <Fragment>
        <Col {...layout}>
          <div className="form-group">
            <ClientFilter
              clients={clients}
              loading={loadingClients}
              onChange={this.handleClientChange}
              selectedClientId={selectedClientId}
              {...clientInputProps}
            />
          </div>
        </Col>
        <Col {...layout}>
          <div className="form-group">
            <ProcessingGroupFilter
              processingGroups={processingGroups}
              selectedProcessingGroupId={selectedProcessingGroupId}
              onChange={this.handleProcessingGroupChange}
              loading={loadingProcessingGroups}
              {...processingGroupInputProps}
            />
          </div>
        </Col>
        <Col {...layout}>
          <div className="form-group">
            <AccountTypeFilter
              selectedAccountType={selectedAccountType}
              onChange={this.handleAccountTypeChange}
              {...accountTypeInputProps}
            />
          </div>
        </Col>
        { selectedProcessStatus !== undefined && (
          <Col {...layout}>
            <div className="form-group">
              <ProcessStatusFilter
                selectedStatus={selectedProcessStatus}
                onChange={this.handleProcessStatusChange}
                {...processStatusInputProps}
              />
            </div>
          </Col>
        )}
      </Fragment>
    );
  }
}
