import React from 'react';
import moment from 'moment';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { availableRollPropTypes } from './commonPropTypes';
import { NewSettlementDatePicker } from './newSettlementDatePicker';

function DefaultSettlementDatePicker({ onSelectDate, availableRolls, selectedRolls }) {
  const furthestDate = moment.max(
    availableRolls
      .map(availableRoll => availableRoll.settlementDateToClose)
      .map(date => moment(date, 'YYYY-MM-DD')),
  ).format('YYYY-MM-DD');

  return (
    <NewSettlementDatePicker
      onSelectedDate={onSelectDate}
      required={false}
      disabled={!availableRolls.some((roll, index) => !!selectedRolls[index])}
      originalSettlementDate={furthestDate}
      placeholder={I18n.t('apply_to_all')}
    />
  );
}

DefaultSettlementDatePicker.propTypes = {
  onSelectDate: PropTypes.func.isRequired,
  availableRolls: PropTypes.arrayOf(availableRollPropTypes).isRequired,
  /* eslint-disable react/forbid-prop-types */
  selectedRolls: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

export { DefaultSettlementDatePicker };
