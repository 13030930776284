import React, { memo } from 'react';

import SharedHeaders from '../shared/headers';

import {
  SortedProcessingGroupNameFieldContainer,
  SortedProcessingGroupIdFieldContainer,
} from '../../containers/processingGroups/sortedFieldsContainers';

const processingDashboardHeaders = () => (
  <thead>
    <tr>
      <SortedProcessingGroupIdFieldContainer data-test-id="sortByProcessingGroupName" />
      <SortedProcessingGroupNameFieldContainer data-test-id="sortByProcessingGroupId" />
      <SharedHeaders />
    </tr>
  </thead>
);

processingDashboardHeaders.propTypes = {};

export default memo(processingDashboardHeaders);
