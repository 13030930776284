import React from 'react';
import PropTypes from 'prop-types';

import { account as accountPropTypes } from '../commonPropTypes';
import Routes from '../../../routes.js.erb';
import Columns from '../shared/columns';

const AccountRow = (props) => {
  const {
    account,
    ledgerDate,
  } = props;

  const {
    id,
    name,
  } = account;

  const sharedLinks = [
    Routes.raw_data_files_path({ account: id }),
    Routes.account_currency_balance_sheet_path(id, ledgerDate),
    Routes.fx_contracts_path({ account_ids: [id] }),
  ];

  return (
    <tr data-test-class={`account${id}`} className="account-row">
      <td data-test-class="sheetAccountName">{id}</td>
      <td data-test-class="sheetAccountName">{name}</td>
      <Columns {...account} sharedLinks={sharedLinks} />
    </tr>
  );
};

AccountRow.propTypes = {
  account: accountPropTypes.isRequired,
  ledgerDate: PropTypes.string.isRequired,
};

export { AccountRow };
