import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

export const NetGroupCounterparty = ({ counterparties }) => {
  const noCounterparties = counterparties.length === 0;
  return (
    <select className="form-control" required disabled={noCounterparties}>
      <option value={null}>
        {
          noCounterparties ? I18n.t('oona.net_fx_contracts.net_pairs.no_common_counterparties')
            : I18n.t('oona.net_fx_contracts.net_pairs.select_counterparty')
        }
      </option>
      {
        counterparties.map(counterparty => (
          <option key={counterparty.id} value={counterparty.id}>{counterparty.name}</option>
        ))
      }
    </select>
  );
};

NetGroupCounterparty.propTypes = {
  counterparties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};
