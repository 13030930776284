import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from '../../../shared/date';

const LocalCurrencyItem = (props) => {
  const {
    id,
    currencyIsoCode,
    createdAt,
    updatedAt,
    onRemove,
  } = props;

  const handleRemove = async () => { onRemove(id); };

  return (
    <tr>
      <td>{id}</td>
      <td>{currencyIsoCode}</td>
      <td>
        <DateTime dateTime={createdAt} />
      </td>
      <td>
        <DateTime dateTime={updatedAt} />
      </td>
      <td className="pull-right">
        <a className="btn btn-success" onClick={handleRemove}>Delete</a>
      </td>
    </tr>
  );
};

LocalCurrencyItem.propTypes = {
  id: PropTypes.number.isRequired,
  currencyIsoCode: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export { LocalCurrencyItem };
