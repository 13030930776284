import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

if (window.dashboardURL) {
  const options = {
    url: window.dashboardURL,
    container: '#dashboardContainer',
    scrolling: 'no',
    height: '2510px',
    printEnabled: true,
  };
  const dash = embedDashboard(options);
  dash.on('error', console.log);
  dash.on('load', console.log);
}
