import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormGroup,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';

const Component = (props) => {
  const {
    errors,
    onChange,
    name,
    value,
    options,
    label,
  } = props;

  return (
    <FormGroup validationState={errors.length > 0 ? 'error' : null}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl componentClass="select" name={name} onChange={onChange} value={value}>
        <option value="" />
        {
          options.map(({ optionName, optionValue }) => (
            <option key={optionName} value={optionValue}>{optionName}</option>
          ))
        }
      </FormControl>
      <HelpBlock>{errors && errors.join(', ')}</HelpBlock>
    </FormGroup>
  );
};

Component.defaultProps = {
  value: '',
  errors: [],
  options: [],
};

Component.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      optionName: PropTypes.string.isRequired,
      optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

export default Component;
