import React, {
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import _ from 'lodash';
import { Editor } from '../../listing/components/editor';
import '../../listing/components/noteModal.scss';
import { useNoteSubmit } from '../hooks/useNoteSubmit';
import { RelatedNotesButton } from '../../components/noteButtons';

export const NewResourceModal = (props) => {
  const {
    onClose, show, entity, onSubmit,
    onEnter, children, ...otherProps
  } = props;
  const { id: entityId, type: entityType } = entity;
  const {
    setText, submitNote, submitting, text,
  } = useNoteSubmit({ onSubmit, entityType, entityId });

  return (
    <Modal show={show} onHide={onClose} backdrop="static" onEnter={onEnter}>
      { show && (
        <Fragment>
          <Modal.Header closeButton>
            <Modal.Title>
              {entityType
                ? I18n.t('notes.new_note_modal.title', { entity_type: entityType })
                : I18n.t('notes.new_note_modal.title_no_entity') }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass="note-modal-body">
            { children }
            <div className="note-modal-body-content">
              <Editor onChange={newText => setText(newText)} />
            </div>
          </Modal.Body>
          <Modal.Footer className="clearfix">
            { !_.isEmpty(otherProps) && (
              <div className="pull-left">
                <RelatedNotesButton {...otherProps} asButton />
              </div>
            )}
            <div className="pull-right">
              <Button
                bsStyle="primary"
                onClick={submitNote}
                disabled={submitting || text.length === 0 || !entityId}
              >
                {I18n.t('notes.new_note_modal.submit')}
              </Button>
            </div>
          </Modal.Footer>
        </Fragment>
      ) }
    </Modal>
  );
};

NewResourceModal.propTypes = {
  children: PropTypes.node,
  entity: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

NewResourceModal.defaultProps = {
  children: null,
};
