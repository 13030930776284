import { connect } from 'react-redux';

import { CurrencyBalanceSheetsTable } from '../components/currencyBalanceSheetsTable';

const mapStateToProps = state => ({
  loading: state.processingGroups.isFetching,
  error: state.processingGroups.error,
  processingGroupIds: state.processingGroups.processingGroupIds,
  sendOrderMessage: state.currencyBalanceSheets.sendOrderMessage,
});

export const CurrencyBalanceSheetsContainer = connect(mapStateToProps)(CurrencyBalanceSheetsTable);
