/* eslint-disable max-len */
import React from 'react';

import { FormGroup, ControlLabel } from 'react-bootstrap';
import Col from 'react-bootstrap/es/Col';
import Row from 'react-bootstrap/es/Row';
import moment from 'moment';
import PropTypes from 'prop-types';
import { createCableCarRoute } from 'redux-cablecar';

import { ClientProcessingGroupAccountFilter } from '../../shared/filters/clientProcessingGroupAccountFilter';

import '../../shared/buttonFilterGroup.scss';

import { NewDatePicker } from '../../shared/newLedgerDatePicker/NewLedgerDatePicker';
import { FilterControl } from '../../shared/filters/FilterControl';
import { SelectFilter } from '../../shared/filters/SelectFilter';

class Filters extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.currentDate = moment();

    const cablecar = createCableCarRoute();
    const { store } = props;

    this.state = {
      selectedAccountId: null,
      selectedClientId: null,
      selectedProcessingGroupId: null,
      selectedLedgerDate: this.currentDate,
      appliedLedgerDate: this.currentDate,
      selectedPerPage: 25,
      cablecar,
      store,
      connection: null,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange = (selectedState) => {
    this.setState(selectedState);
  };

  componentDidMount = () => {
    const clientId = parseInt(new URLSearchParams(window.location.search).get('client_id'), 10) || null;
    const processingGroupId = parseInt(new URLSearchParams(window.location.search).get('processing_group_id'), 10) || null;

    this.setState(
      {
        selectedClientId: clientId,
        selectedProcessingGroupId: processingGroupId,
      },
      () => this.applyFilters(),
    );
  };

  applyFilters = (forceReload = true) => {
    const { onFilter, order } = this.props;
    const {
      selectedClientId,
      selectedAccountId,
      selectedProcessingGroupId,
      selectedLedgerDate,
      selectedPerPage,
      appliedLedgerDate,
      cablecar,
      connection,
      store,
    } = this.state;

    const filters = {
      clientId: selectedClientId,
      accountId: selectedAccountId,
      processingGroupId: selectedProcessingGroupId,
      ledgerDate: this.getSelectedLedgerDate(),
      perPage: selectedPerPage,
    };

    if (selectedLedgerDate !== appliedLedgerDate) { connection.destroy(); }

    const newConnection = cablecar.connect(store, 'ProcessingDashboardChannel', this.getOptions(selectedLedgerDate));

    this.setState({
      appliedLedgerDate: selectedLedgerDate,
      connection: newConnection,
    });
    onFilter(filters, order, 1, forceReload);
  };

  handleLedgerDateChange = (ledgerDate) => {
    this.setState({
      selectedLedgerDate: ledgerDate,
    });
  };

  handlePerPageChange = (perPage) => {
    this.setState({
      selectedPerPage: perPage,
    }, () => this.applyFilters(false));
  }

  getSelectedLedgerDate = () => {
    const { selectedLedgerDate } = this.state;
    return selectedLedgerDate.format('YYYY-MM-DD');
  };

  getOptions = date => ({
    params: {
      user_id: window.currentUser.id,
      ledger_date: date.format('YYYY-MM-DD'),
    },
  });

  handleFilterClick = (e) => {
    e.preventDefault();
    this.applyFilters();
  };

  handleClearFilter = () => {
    this.setState({
      selectedClientId: null,
      selectedProcessingGroupId: null,
      selectedAccountId: null,
      selectedLedgerDate: this.currentDate,
    });
  };

  render() {
    const {
      selectedClientId,
      selectedProcessingGroupId,
      selectedAccountId,
      selectedLedgerDate,
      appliedLedgerDate,
      selectedPerPage,
    } = this.state;
    const { loading } = this.props;

    return (
      <Row>
        <ClientProcessingGroupAccountFilter
          selectedAccountId={selectedAccountId}
          selectedClientId={selectedClientId}
          selectedProcessingGroupId={selectedProcessingGroupId}
          onChange={this.handleFilterChange}
          layout={{ sm: 2 }}
        />
        <Col sm={2}>
          <FormGroup>
            <NewDatePicker
              selectedDate={selectedLedgerDate.clone()}
              onChange={this.handleLedgerDateChange}
            />
          </FormGroup>
        </Col>
        <FilterControl
          onSubmit={this.handleFilterClick}
          onClear={this.handleClearFilter}
          className="inline"
          disabled={loading}
          nameOverride="Load data"
        />
        <FormGroup className="amount-container">
          <ControlLabel>Items per page</ControlLabel>
          <SelectFilter
            id="select_per_page"
            name="per_page"
            options={[25, 50, 100, 'All'].map(option => ({ label: option, value: option }))}
            selectedOption={selectedPerPage}
            onChange={this.handlePerPageChange}
          />
        </FormGroup>
        <Col sm={12}>
          <div className="selected-ledger-date">{appliedLedgerDate.format('D MMMM YYYY')}</div>
        </Col>
      </Row>
    );
  }
}

Filters.propTypes = {
  loading: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
  order: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc', null]),
  }).isRequired,
  /* eslint-disable react/forbid-prop-types */
  store: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

export { Filters };
