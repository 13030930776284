import { connect } from 'react-redux';

import { AccountWeightsDataTable } from '../components/dataTable';

const mapStateToProps = (state) => {
  const selectedDatesData = state.accountWeights.selectedDates.map(date => ({
    date,
    files: state.accountWeights.dates[date],
  }));

  return {
    selectedDates: selectedDatesData,
    comparisons: state.accountWeights.comparisons,
    error: state.accountWeights.error,
    loading: state.accountWeights.loading,
  };
};

export const AccountWeightsDataTableContainer = connect(mapStateToProps)(AccountWeightsDataTable);
