import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'i18n-js';
import { Table } from 'react-bootstrap';

function ReportConfigurationTable({ children, ...otherProps }) {
  return (
    <div className="table-responsive" {...otherProps}>
      <Table className="dragndrop-table">
        <thead>
          <tr>
            <th className="col-sm-2">{I18n.t('activerecord.attributes.reports/selectable_field.model')}</th>
            <th className="col-sm-2">{I18n.t('activerecord.attributes.reports/selectable_field.display_name')}</th>
            <th className="col-sm-3">{I18n.t('activerecord.attributes.reports/config_field.field_name_override')}</th>
            <th className="col-sm-4">{I18n.t('activerecord.attributes.reports/config_field.selectable_field_data_type_format')}</th>
            <th className="col-sm-1" />
          </tr>
        </thead>
        {children}
      </Table>
    </div>
  );
}

ReportConfigurationTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReportConfigurationTable;
