import moment from 'moment';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';
import { hedgeLineItemPropType } from '../../commonPropTypes';
import { Amount } from '../../../../shared/amount';
import { RawDataFileLink } from '../rawDataFileLink';
import { LineItemWeight } from './lineItemWeight';

export const LineItemDifferentCurrencyRow = React.memo(({ lineItem, hedgeEvaluatesOnBaseSide }) => {
  const {
    localCurrencyAmount, baseCurrencyAmount, valuationRate, weight,
    valuationDate, rawDataFile, normalizedAccountDatum,
  } = lineItem;

  const type = (normalizedAccountDatum && normalizedAccountDatum.sourceHoldingIdentifier) || I18n.t(`activerecord.attributes.currency_line_items/base.types.${lineItem.type}`);

  return (
    <tr className="currency_line_item_row" id={`line-item-${lineItem.id}`}>
      <td className="text-right">
        {
          hedgeEvaluatesOnBaseSide
            ? Amount(localCurrencyAmount)
            : Amount(baseCurrencyAmount)
        }
      </td>
      <td className="valuation-rate text-right">
        { Amount(valuationRate, { digits: 4 }) }
      </td>
      <td className="text-right">
        {
          hedgeEvaluatesOnBaseSide
            ? Amount(baseCurrencyAmount)
            : Amount(localCurrencyAmount)
        }
      </td>
      <td className="text-center">
        <LineItemWeight weight={weight} />
      </td>
      <td className="text-center">
        { moment(valuationDate).format('DD-MMM-YY') }
      </td>
      <td>{ type }</td>
      <td>
        {
          rawDataFile && (
            <RawDataFileLink id={rawDataFile.id} clientId={rawDataFile.clientId} />
          )
        }
      </td>
    </tr>
  );
});

LineItemDifferentCurrencyRow.propTypes = {
  lineItem: hedgeLineItemPropType.isRequired,
  hedgeEvaluatesOnBaseSide: PropTypes.bool.isRequired,
};
