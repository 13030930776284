import React from 'react';
import PropTypes from 'prop-types';
import { Percentage } from '../../../../shared';

export const FormattedPercentage = ({ percentage }) => (
  <Percentage percentage={percentage} precision={4} />
);

FormattedPercentage.propTypes = {
  percentage: PropTypes.string,
};

FormattedPercentage.defaultProps = {
  percentage: null,
};
