import { generateSortedProcessingGroupFieldContainer } from '../shared/sortedFieldContainerFactory';
import { generateSortedProcessingGroupValueContainer } from '../shared/sortedValueContainerFactory';

const SortedProcessingGroupNameFieldContainer = generateSortedProcessingGroupFieldContainer({
  currentField: 'processing_group_name',
  displayKey: 'activerecord.attributes.account.processing_group',
  options: { colSpan: 7 },
});

const SortedProcessingGroupNameValueContainer = generateSortedProcessingGroupValueContainer({
  currentField: 'processing_group_name',
  options: { colSpan: 7 },
});

const SortedProcessingGroupIdFieldContainer = generateSortedProcessingGroupFieldContainer({
  currentField: 'processing_group_id',
  displayKey: 'activerecord.attributes.processing_group.id',
  options: { colSpan: 1 },
});

const SortedProcessingGroupIdValueContainer = generateSortedProcessingGroupValueContainer({
  currentField: 'processing_group_id',
  options: { colSpan: 1 },
});

const SortedProcessingGroupStatusFieldContainer = generateSortedProcessingGroupFieldContainer({
  currentField: 'account_priority',
  displayKey: 'activerecord.attributes.processing_group.status',
  options: { colSpan: 3 },
});

const SortedProcessingGroupStatusValueContainer = generateSortedProcessingGroupValueContainer({
  currentField: 'account_priority',
  options: { colSpan: 3 },
});

export {
  SortedProcessingGroupNameFieldContainer,
  SortedProcessingGroupNameValueContainer,
  SortedProcessingGroupIdFieldContainer,
  SortedProcessingGroupIdValueContainer,
  SortedProcessingGroupStatusFieldContainer,
  SortedProcessingGroupStatusValueContainer,
};
