import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
} from 'react-bootstrap';
import I18n from 'i18n-js';

import TextField from '../TextField';
import SelectField from '../SelectField';
import NumberField from '../NumberField';
import AreaField from '../AreaField';

// eslint-disable-next-line import/no-unresolved
import constants from '../../../rails_contants';

const Component = (props) => {
  const {
    onSubmit,
    errors,
    formData,
    onChange,
  } = props;

  const handleChange = (e) => {
    onChange(e);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const timingTypeOptions = constants
    .timingTypes
    .map(x => ({ optionName: x, optionValue: x }));

  const executionMethodOptions = constants
    .executionMethodOptions
    .map(x => ({ optionName: x, optionValue: x }));

  const benchmarkLocationOptions = constants
    .benchmarkLocations
    .map(x => ({ optionName: x, optionValue: x }));

  const benchmarkTimeOptions = [...Array(24).keys()].map(x => ({ optionName: x, optionValue: x }));

  const benchmarkSourceOptions = constants
    .benchmarkSourceOptions
    .map(x => ({ optionName: x, optionValue: x }));

  const dateOptionOptions = constants
    .dateOptionOptions
    .map(x => ({ optionName: x, optionValue: x }));

  const missedTimingTypeOptions = constants
    .missedTimingTypeOptions
    .map(x => ({ optionName: x, optionValue: x }));

  const pricePointOptions = constants
    .pricePointOptions
    .map(x => ({ optionName: x, optionValue: x }));

  const forwardSwapPointOptionOptions = constants
    .forwardSwapPointOptionOptions
    .map(x => ({ optionName: x, optionValue: x }));


  return (
    <Form>
      <TextField
        name="name"
        onChange={handleChange}
        value={formData.name}
        errors={errors.name}
        label={I18n.t('activerecord.attributes.execution_subgroup.name')}
      />
      <SelectField
        name="timingType"
        onChange={handleChange}
        value={formData.timingType}
        errors={errors.timingType}
        options={timingTypeOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.timing_type')}
      />
      <SelectField
        name="executionMethodSpot"
        onChange={handleChange}
        value={formData.executionMethodSpot}
        errors={errors.executionMethodSpot}
        options={executionMethodOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.execution_method_spot')}
      />
      <SelectField
        name="executionMethodForward"
        onChange={handleChange}
        value={formData.executionMethodForward}
        errors={errors.executionMethodForward}
        options={executionMethodOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.execution_method_forward')}
      />
      <NumberField
        name="cutoffMinutesToNextBenchmark"
        onChange={handleChange}
        value={formData.cutoffMinutesToNextBenchmark}
        errors={errors.cutoffMinutesToNextBenchmark}
        label={I18n.t('activerecord.attributes.execution_subgroup.cutoff_minutes_to_next_benchmark')}
      />
      <SelectField
        name="benchmarkLocation"
        onChange={handleChange}
        value={formData.benchmarkLocation}
        errors={errors.benchmarkLocation}
        options={benchmarkLocationOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.benchmark_location')}
      />
      <SelectField
        name="benchmarkTime"
        onChange={handleChange}
        value={formData.benchmarkTime}
        errors={errors.benchmarkTime}
        options={benchmarkTimeOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.benchmark_time')}
      />
      <SelectField
        name="benchmarkSource"
        onChange={handleChange}
        value={formData.benchmarkSource}
        errors={errors.benchmarkSource}
        options={benchmarkSourceOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.benchmark_source')}
      />
      <SelectField
        name="dateOption"
        onChange={handleChange}
        value={formData.dateOption}
        errors={errors.dateOption}
        options={dateOptionOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.date_option')}
      />
      <NumberField
        name="minNumberCounterparties"
        onChange={handleChange}
        value={formData.minNumberCounterparties}
        errors={errors.minNumberCounterparties}
        label={I18n.t('activerecord.attributes.execution_subgroup.min_number_counterparties')}
      />
      <SelectField
        name="missedTimingType"
        onChange={handleChange}
        value={formData.missedTimingType}
        errors={errors.missedTimingType}
        options={missedTimingTypeOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.missed_timing_type')}
      />
      <SelectField
        name="missedExecutionMethodSpot"
        onChange={handleChange}
        value={formData.missedExecutionMethodSpot}
        errors={errors.missedExecutionMethodSpot}
        options={executionMethodOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.missed_execution_method_spot')}
      />
      <SelectField
        name="missedExecutionMethodForward"
        onChange={handleChange}
        value={formData.missedExecutionMethodForward}
        errors={errors.missedExecutionMethodForward}
        options={executionMethodOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.missed_execution_method_forward')}
      />
      <SelectField
        name="spotPricePoint"
        onChange={handleChange}
        value={formData.spotPricePoint}
        errors={errors.spotPricePoint}
        options={pricePointOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.spot_price_point')}
      />
      <SelectField
        name="spotPricePointSwaps"
        onChange={handleChange}
        value={formData.spotPricePointSwaps}
        errors={errors.spotPricePointSwaps}
        options={pricePointOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.spot_price_point_swaps')}
      />
      <SelectField
        name="forwardPricePoint"
        onChange={handleChange}
        value={formData.forwardPricePoint}
        errors={errors.forwardPricePoint}
        options={pricePointOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.forward_price_point')}
      />
      <SelectField
        name="forwardSwapPointOptions"
        onChange={handleChange}
        value={formData.forwardSwapPointOptions}
        errors={errors.forwardSwapPointOptions}
        options={forwardSwapPointOptionOptions}
        label={I18n.t('activerecord.attributes.execution_subgroup.forward_swap_point_options')}
      />
      <AreaField
        name="specialInstructionsNote"
        onChange={handleChange}
        value={formData.specialInstructionsNote}
        errors={errors.specialInstructionsNote}
        label={I18n.t('activerecord.attributes.execution_subgroup.special_instructions_note')}
      />
      <FormGroup>
        <Button onClick={handleSubmit}>Save</Button>
      </FormGroup>
    </Form>
  );
};

Component.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string,
    timingType: PropTypes.string,
    executionMethodSpot: PropTypes.string,
    executionMethodForward: PropTypes.string,
    cutoffMinutesToNextBenchmark: PropTypes.number,
    benchmarkLocation: PropTypes.string,
    benchmarkTime: PropTypes.number,
    benchmarkSource: PropTypes.string,
    dateOption: PropTypes.string,
    minNumberCounterparties: PropTypes.number,
    missedTimingType: PropTypes.number,
    missedExecutionMethodSpot: PropTypes.string,
    missedExecutionMethodForward: PropTypes.string,
    spotPricePoint: PropTypes.string,
    spotPricePointSwaps: PropTypes.string,
    forwardPricePoint: PropTypes.string,
    forwardSwapPointOptions: PropTypes.string,
    specialInstructionsNote: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    timingType: PropTypes.arrayOf(PropTypes.string),
    executionMethodSpot: PropTypes.arrayOf(PropTypes.string),
    executionMethodForward: PropTypes.arrayOf(PropTypes.string),
    cutoffMinutesToNextBenchmark: PropTypes.arrayOf(PropTypes.string),
    benchmarkLocation: PropTypes.arrayOf(PropTypes.string),
    benchmarkTime: PropTypes.arrayOf(PropTypes.string),
    benchmarkSource: PropTypes.arrayOf(PropTypes.string),
    dateOption: PropTypes.arrayOf(PropTypes.string),
    minNumberCounterparties: PropTypes.arrayOf(PropTypes.string),
    missedTimingType: PropTypes.arrayOf(PropTypes.string),
    missedExecutionMethodSpot: PropTypes.arrayOf(PropTypes.string),
    missedExecutionMethodForward: PropTypes.arrayOf(PropTypes.string),
    spotPricePoint: PropTypes.arrayOf(PropTypes.string),
    spotPricePointSwaps: PropTypes.arrayOf(PropTypes.string),
    forwardPricePoint: PropTypes.arrayOf(PropTypes.string),
    forwardSwapPointOptions: PropTypes.arrayOf(PropTypes.string),
    specialInstructionsNote: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Component;
