import React from 'react';
import PropTypes from 'prop-types';
import { RawDataFileTableRow } from './RawDataFileTableRow';

export const RawDataFileTableBody = (props) => {
  const { rawDataFiles, filterParams } = props;

  return (
    rawDataFiles.map(rawDataFile => (
      <RawDataFileTableRow key={rawDataFile.id} {...rawDataFile} filterParams={filterParams} />
    ))
  );
};

/* eslint-disable react/forbid-prop-types */
RawDataFileTableBody.propTypes = {
  rawDataFiles: PropTypes.array,
  filterParams: PropTypes.object,
};
/* eslint-enable react/forbid-prop-types */
