import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

class DestroyEntityButton extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  handleClick = (e) => {
    e.preventDefault();
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <a
        className="btn btn-delete remove_fields "
        href="#"
        onClick={this.handleClick}
      >
        { I18n.t('delete') }
      </a>
    );
  }
}

export { DestroyEntityButton };
