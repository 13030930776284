import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
export const ErrorPanel = ({ children }) => (
  <Panel bsStyle="danger">
    <Panel.Heading>
      <Panel.Title componentClass="h3">
        Error
      </Panel.Title>
    </Panel.Heading>
    <Panel.Body>
      { children }
    </Panel.Body>
  </Panel>
);

ErrorPanel.propTypes = {
  children: PropTypes.node.isRequired,
};
