import I18n from 'i18n-js';
import _ from 'lodash';

const statusToClass = (status) => {
  switch (status) {
  case 'processing':
  case 'processing_data':
  case 'generating_data':
  case 'pending_weight':
  case 'no_data':
  case 'normalizing':
  case 'ignoring':
  case 'approving':
    return 'badge-warning';
  case 'processed':
  case 'generated_data':
  case 'normalization_ok':
  case 'data_generated':
  case 'parsing_done':
  case 'empty_file':
    return 'badge-info';
  case 'aborted':
  case 'aborted_data_generation':
  case 'aborted_processing':
  case 'normalization_failed':
  case 'duplicated':
  case 'missing_currency':
  case 'weight_review_required':
    return 'badge-danger';
  default:
    return '';
  }
};

export const fileBadgeClassName = (status) => {
  switch (status) {
  case 'not_valid':
    return 'badge-danger';
  default:
    return statusToClass(status);
  }
};

export const accountFileBadgeClassName = (status) => {
  switch (status) {
  case 'not_valid':
    return 'badge-warning';
  default:
    return statusToClass(status);
  }
};

export const fileBadgeText = (status) => {
  switch (status) {
  case 'normalizing':
    return I18n.t('users_portal.raw_data_files.normalizing');
  case 'normalization_ok':
    return I18n.t('users_portal.raw_data_files.normalization_ok');
  case 'data_generated':
    return I18n.t('users_portal.raw_data_files.data_generated');
  case 'parsing_done':
    return I18n.t('users_portal.raw_data_files.parsing_successful');
  case 'empty_file':
    return I18n.t('users_portal.raw_data_files.empty_file');
  case 'normalization_failed':
    return I18n.t('users_portal.raw_data_files.normalization_failed');
  default:
    return _.startCase(status);
  }
};
