import PropTypes from 'prop-types';
import React from 'react';
import Client from '../../../client';
import { PROCESSING_GROUP } from '../../entityTypes';
import { ProcessingGroupFilter } from '../../../shared/filters/processingGroupFilter';
import { NewResourceModal } from './newResourceModal';
import { useEntityModalState } from '../hooks/useEntityModalState';

export const NewNoteProcessingGroupModal = ({ onSubmit, onClose, show }) => {
  const {
    entities, selectedEntityId, loading, handleChange, handleEnter,
  } = useEntityModalState(Client.getProcessingGroups, 'processingGroups');

  return (
    <NewResourceModal
      entity={{ type: PROCESSING_GROUP, id: selectedEntityId }}
      onSubmit={onSubmit}
      onClose={onClose}
      onEnter={handleEnter}
      show={show}
    >
      <ProcessingGroupFilter
        processingGroups={entities}
        loading={loading}
        onChange={handleChange}
        selectedProcessingGroupId={selectedEntityId}
      />
    </NewResourceModal>
  );
};

NewNoteProcessingGroupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
