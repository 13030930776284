const ADD_LINE_ITEM_MAP = 'ADD_LINE_ITEM_MAP';
const REMOVE_LINE_ITEM_MAP = 'REMOVE_LINE_ITEM_MAP';

const addLineItemMap = () => ({
  type: ADD_LINE_ITEM_MAP,
});

const removeLineItemMap = (id, uuid) => ({
  type: REMOVE_LINE_ITEM_MAP,
  payload: {
    id,
    uuid,
  },
});

export {
  ADD_LINE_ITEM_MAP,
  REMOVE_LINE_ITEM_MAP,
  addLineItemMap,
  removeLineItemMap,
};
