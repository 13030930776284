import React, { useCallback } from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { VALIDATION_STATUSES } from '../commonPropTypes';
import { LinkAsButton } from '../../../shared/linkAsButton';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { SheetValidationStatus } from '../../listing/components/sheetValidationStatus';

export const CurrencyBalanceSheetValidation = ({
  validationStatus, validatedBy, validating, onClick, id,
}) => {
  const { canValidate } = useAuthorizedResource({ modelClass: 'CurrencyBalanceSheet', modelId: id, modelAction: 'validate' });
  const handleValidationClick = useCallback(() => {
    if (window.confirm(I18n.t('currency_balance_sheets.show.sign_confirm'))) {
      onClick();
    }
  }, [onClick]);
  return (
    <React.Fragment>
      {
        validationStatus === VALIDATION_STATUSES.VALIDATED && (
          <SheetValidationStatus
            validationStatus={validationStatus}
            validatedByEmail={validatedBy}
            isValidating={false}
          />
        )
      }
      {
        validationStatus === VALIDATION_STATUSES.UNVALIDATED && canValidate && (
          <LinkAsButton
            className="btn btn-primary"
            disabled={validating}
            onClick={handleValidationClick}
            onDisabled={I18n.t('currency_balance_sheets.index.validating')}
          >
            { I18n.t('currency_balance_sheets.show.sign_button') }
          </LinkAsButton>
        )
      }
    </React.Fragment>
  );
};

CurrencyBalanceSheetValidation.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  validationStatus: PropTypes.oneOf(Object.values(VALIDATION_STATUSES)).isRequired,
  validatedBy: PropTypes.string,
  validating: PropTypes.bool.isRequired,
};

CurrencyBalanceSheetValidation.defaultProps = {
  validatedBy: null,
};
