import { useCallback, useState } from 'react';

export const useEntityModalState = (clientFunction, clientResponseKey) => {
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState(null);

  const retrieveClients = useCallback(() => {
    setLoading(true);
    clientFunction({ withoutNotes: true })
      .then(response => response.json())
      .then((data) => {
        setEntities(data[clientResponseKey]);
        setLoading(false);
      });
  }, [clientFunction, clientResponseKey]);

  const handleEnter = useCallback(() => {
    retrieveClients();
    setSelectedEntityId(null);
  }, [retrieveClients, setSelectedEntityId]);

  const handleChange = useCallback((option) => {
    setSelectedEntityId(option ? option.value : null);
  }, []);

  return {
    entities, selectedEntityId, loading, handleChange, handleEnter,
  };
};
