import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { DatasetOptionsPropTypes } from './commonPropTypes';

class DatasetOptions extends React.Component {
  static propTypes = {
    onOptionsChanged: PropTypes.func.isRequired,
    selectedOptions: DatasetOptionsPropTypes.isRequired,
  };

  handleOptionsChanged = (key, event) => {
    const { onOptionsChanged } = this.props;
    onOptionsChanged(key, event.target.checked);
  };

  render() {
    const { selectedOptions } = this.props;
    return Object.entries(selectedOptions).map((entry) => {
      const key = entry[0];
      const componentId = `option-${key}`;
      return (
        <div className="col-sm-12 row" key={componentId}>
          <div className="col-sm-2 checkbox-container">
            <label className="reports-config-label control control--checkbox" htmlFor={componentId}>
              {I18n.t(`activerecord.attributes.reports/config.options.${key}`) }
              <input
                type="checkbox"
                checked={selectedOptions[key]}
                id={componentId}
                className="reports-config-checkbox"
                onChange={this.handleOptionsChanged.bind(null, key)}
              />
              <span className="control__indicator" />
            </label>
          </div>
        </div>
      );
    });
  }
}

export { DatasetOptions };
