import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { SettlementSummary } from './settlementSummary';
import { SettlementLineItemsTable } from './settlementLineItemsTable';
import {
  accountPropTypes, currencyPropType, fxContractPropType, hedgeLineItemPropType,
} from '../commonPropTypes';
import { ExposureTradesTable } from './exposureTradesTable';

export const SettlementSection = (props) => {
  const {
    identifier, group, account, showLineItems, showTrades, isExpanded, onExpandOne,
  } = props;
  const { criteria } = group;

  const handleExpandClick = (e) => {
    e.preventDefault();
    onExpandOne(identifier);
  };

  return (
    <React.Fragment>
      <SettlementSummary
        {...props}
        onExpandClick={handleExpandClick}
        isExpanded={isExpanded}
      />
      <Collapse in={isExpanded}>
        <div>
          <div className="details-row bottom-separator">
            <div className="details-column vertical-separator table-response-sm">
              <SettlementLineItemsTable
                lineItems={group.lineItems}
                localCurrency={criteria.localCurrency}
                showContent={showLineItems}
              />
            </div>
            <div className="details-column table-responsive-sm">
              <ExposureTradesTable
                fxContracts={group.fxContracts}
                hedgeRatioEvaluation={account.hedgeRatioEvaluation}
                baseCurrency={account.baseCurrency}
                localCurrency={criteria.localCurrency}
                showContent={showTrades}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

SettlementSection.propTypes = {
  identifier: PropTypes.string.isRequired,
  account: accountPropTypes.isRequired,
  group: PropTypes.shape({
    lineItems: PropTypes.arrayOf(hedgeLineItemPropType).isRequired,
    fxContracts: PropTypes.arrayOf(fxContractPropType).isRequired,
    criteria: PropTypes.shape({
      localCurrency: currencyPropType.isRequired,
    }),
  }).isRequired,
  showLineItems: PropTypes.bool.isRequired,
  showTrades: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool,
  onExpandOne: PropTypes.func.isRequired,
};

SettlementSection.defaultProps = {
  isExpanded: false,
};
