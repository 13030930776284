import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import { Editor } from '../../listing/components/editor';
import '../../listing/components/noteModal.scss';
import { useNoteSubmit } from '../hooks/useNoteSubmit';
import { ClientProcessingGroupAccountFilter } from '../../../shared/filters/clientProcessingGroupAccountFilter';
import { ACCOUNT, CLIENT, PROCESSING_GROUP } from '../../entityTypes';
import { RelatedNotesButton } from '../../components/noteButtons';

export const NewResourceNoteModalContent = ({ entity, onSubmit, ...otherProps }) => {
  let initialState = {};
  if (entity) {
    const { id, type } = entity;
    initialState = { id, type };
  }
  const [entityState, setEntity] = useState(initialState);
  const [selectedEntities, setSelectedEntities] = useState(initialState);
  const { id: entityId, type: entityType } = entityState;
  const {
    setText, submitNote, submitting, text,
  } = useNoteSubmit({ onSubmit, entityType, entityId });

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Modal.Title>
          {entityType
            ? I18n.t('notes.new_note_modal.title', { entity_type: entityType })
            : I18n.t('notes.new_note_modal.title_no_entity') }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body bsClass="note-modal-body">
        {
          !entity && (
            <ClientProcessingGroupAccountFilter
              onChange={(newSelectedEntities) => {
                const {
                  selectedAccountId,
                  selectedProcessingGroupId,
                  selectedClientId,
                } = newSelectedEntities;
                let newType = null;
                if (selectedAccountId) {
                  newType = ACCOUNT;
                } else if (selectedProcessingGroupId) {
                  newType = PROCESSING_GROUP;
                } else if (selectedClientId) {
                  newType = CLIENT;
                }
                setEntity({
                  id: selectedAccountId || selectedProcessingGroupId || selectedClientId,
                  type: newType,
                });
                setSelectedEntities(prevSelectedEntities => ({
                  ...prevSelectedEntities,
                  ...newSelectedEntities,
                }));
              }}
              {...selectedEntities}
            />
          )
        }
        <div className="note-modal-body-content">
          <Editor onChange={newText => setText(newText)} />
        </div>
      </Modal.Body>
      <Modal.Footer className="clearfix">
        { otherProps && (
          <div className="pull-left">
            <RelatedNotesButton {...otherProps} asButton />
          </div>
        )}
        <div className="pull-right">
          <Button
            bsStyle="primary"
            onClick={submitNote}
            disabled={submitting || text.length === 0 || !entityId}
          >
            {I18n.t('notes.new_note_modal.submit')}
          </Button>
        </div>
      </Modal.Footer>
    </Fragment>
  );
};

NewResourceNoteModalContent.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};

NewResourceNoteModalContent.defaultProps = {
  entity: null,
};

export const NewNoteModal = (props) => {
  const { onClose, show, ...otherProps } = props;
  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      { show && <NewResourceNoteModalContent {...otherProps} /> }
    </Modal>
  );
};

NewNoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
