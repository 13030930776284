import PropTypes from 'prop-types';
import { Viewer as ToastViewer } from '@toast-ui/react-editor';
import React from 'react';
import _ from 'lodash';

export class Viewer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.viewerRef = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    if (_.isEqual(value, prevProps.value)) return;
    this.viewerRef.current.getInstance().setMarkdown(value);
  };

  render() {
    const { value } = this.props;
    return (
      <ToastViewer
        initialValue={value}
        /* Hack to avoid the user from marking or unmarking a task list item in preview mode */
        onChange={() => this.viewerRef.current.getInstance().setMarkdown(value)}
        ref={this.viewerRef}
      />
    );
  }
}

Viewer.propTypes = {
  value: PropTypes.string.isRequired,
};
