import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import business from 'moment-business';
import I18n from 'i18n-js';
import Datetime from 'react-datetime';
import './NewLedgerDatePicker.scss';

const LedgerDatePicker = ({
  value, onSelectedDate, dateFormat, isValidDate, inputProps,
}) => (
  <Datetime
    isValidDate={isValidDate}
    dateFormat={dateFormat}
    timeFormat={false}
    value={value}
    onChange={onSelectedDate}
    closeOnSelect
    inputProps={{
      className: 'form-control date-picker-form',
      ...inputProps,
    }}
  />
);

LedgerDatePicker.defaultProps = {
  value: null,
  isValidDate: current => business.isWeekDay(current),
};

LedgerDatePicker.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  isValidDate: PropTypes.func,
  onSelectedDate: PropTypes.func.isRequired,
  value: momentPropTypes.momentObj,
  /* eslint-disable-next-line react/forbid-prop-types */
  inputProps: PropTypes.object.isRequired, // see react-select docs
};

export const NewDatePicker = (props) => {
  const { onChange, selectedDate, inputProps } = props;

  const getBeforeSelectedLedgerDate = () => {
    if (!selectedDate) return null;

    return business
      .subtractWeekDays(selectedDate, 1)
      .format(`${I18n.t('sheets_filter_component.date_format')}`);
  };

  const getAfterSelectedLedgerDate = () => {
    if (!selectedDate) return null;

    return business
      .addWeekDays(selectedDate, 1)
      .format(`${I18n.t('sheets_filter_component.date_format')}`);
  };

  const handleBeforeLedgerDateChange = () => {
    onChange(moment(getBeforeSelectedLedgerDate(), I18n.t('sheets_filter_component.date_format')));
  };

  const handleAfterLedgerDateChange = () => {
    onChange(moment(getAfterSelectedLedgerDate(), I18n.t('sheets_filter_component.date_format')));
  };

  return (
    <div className="date-control-container">
      <span
        className="btn-before-date"
        title={getBeforeSelectedLedgerDate()}
        onClick={handleBeforeLedgerDateChange}
      >
        <i className="material-icons">{I18n.t('icons.navigate_before')}</i>
      </span>
      <div className="date-picker-container">
        <LedgerDatePicker
          value={selectedDate}
          onSelectedDate={onChange}
          dateFormat={`${I18n.t('sheets_filter_component.date_format')}`}
          inputProps={inputProps}
        />
      </div>
      <span
        className="btn-after-date"
        title={getAfterSelectedLedgerDate()}
        onClick={handleAfterLedgerDateChange}
      >
        <i className="material-icons">{I18n.t('icons.navigate_next')}</i>
      </span>
    </div>
  );
};

NewDatePicker.defaultProps = {
  selectedDate: null,
  inputProps: {},
};

NewDatePicker.propTypes = {
  selectedDate: momentPropTypes.momentObj,
  onChange: PropTypes.func.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  inputProps: PropTypes.object,
};
