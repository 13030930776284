/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SortedField } from './sortedField';

export class SortedCurrencyBalanceSheetField extends React.PureComponent {
  handleClick = ({ field, direction }) => {
    const { onClick, ledgerDate, processingGroupId } = this.props;
    onClick({ ledgerDate, processingGroupId }, { field, direction });
  };

  render = () => <SortedField {...this.props} onClick={this.handleClick} />
}

SortedCurrencyBalanceSheetField.propTypes = {
  processingGroupId: PropTypes.number,
  ledgerDate: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

SortedCurrencyBalanceSheetField.defaultProps = {
  processingGroupId: null,
};
