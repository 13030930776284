import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Client from '../../../../client';
import { ReportConfigurationForm } from '../../shared/components/reportConfigurationForm';
import Routes from '../../../../routes.js.erb';

class CreateReportConfigurationContainer extends React.Component {
  static propTypes = {
    datasets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    timezones: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedFields: [],
      notSelectedFields: [],
    };
  }

  handleSubmit = (payload) => {
    Client
      .createReportConfiguration(payload)
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          alert(I18n.t('users_portal.reports_configs.create.success'));
          /* eslint-disable no-undef */
          window.location.href = Routes.reports_configs_path();
          /* eslint-enable no-undef */
        } else {
          alert(data.message);
        }
      });
  };

  render() {
    const { selectedFields, notSelectedFields } = this.state;
    return (
      <ReportConfigurationForm
        selectedFields={selectedFields}
        notSelectedFields={notSelectedFields}
        {...this.props}
        edit={false}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export { CreateReportConfigurationContainer };
