import PropTypes from 'prop-types';

const availableRollPropTypes = PropTypes.shape({
  counterparty: PropTypes.shape({
    id: PropTypes.number.isRequired,
    shortName: PropTypes.string.isRequired,
  }),
  currency1: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isoCode: PropTypes.string.isRequired,
  }).isRequired,
  currency2: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isoCode: PropTypes.string.isRequired,
  }).isRequired,
  settlementDateToClose: PropTypes.string,
});

export { availableRollPropTypes };
