import FormControl from 'react-bootstrap/lib/FormControl';
import PropTypes from 'prop-types';
import React from 'react';

export const CurrencySelect = ({ currencies, ...otherProps }) => (
  <FormControl componentClass="select" {...otherProps}>
    <option value="" />
    {
      currencies.map(({ id, isoCode }) => (
        <option key={id} value={id}>{isoCode}</option>
      ))
    }
  </FormControl>
);

CurrencySelect.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    isoCode: PropTypes.string.isRequired,
  })).isRequired,
};
