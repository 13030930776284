import { useEffect, useState } from 'react';
import _ from 'lodash';
import Client from '../../../client';

const initialState = {
  loading: false,
  fxContractGroups: [],
  error: null,
};

const initialPivotCurrencyCode = 'USD';

export const useNetByPivot = (selectedTradeIds) => {
  const [state, setState] = useState(initialState);
  const [pivotCurrencyCode, setPivotCurrencyCode] = useState(initialPivotCurrencyCode);

  useEffect(() => {
    if (_.isEmpty(selectedTradeIds)) {
      setState(initialState);
    } else {
      setState(
        {
          loading: true,
          fxContractGroups: [],
          error: null,
        },
      );
      Client
        .netByPivotFxContracts(selectedTradeIds, pivotCurrencyCode)
        .then(response => response.json())
        .then((data) => {
          if (data.error) {
            setState({
              loading: false,
              error: data.error,
              fxContractGroups: [],
            });
          } else {
            setState({
              loading: false,
              fxContractGroups: data.data.fxContractGroups,
              error: null,
            });
          }
        });
    }
  }, [selectedTradeIds, pivotCurrencyCode]);

  return { state, pivotCurrencyCode, setPivotCurrencyCode };
};
