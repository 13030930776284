import { connect } from 'react-redux';

import { AccountWeightsFileInfo } from '../components/fileInfo';

const mapStateToProps = (state, props) => ({
  loading: state.accountWeights.loading,
  date: props.data.date,
  files: props.data.files,
  size: props.size,
  weightType: state.accountWeights.weightType,
  changeSelectedFile: props.changeSelectedFile,
});

export const AccountWeightsFileInfoContainer = connect(mapStateToProps)(AccountWeightsFileInfo);
