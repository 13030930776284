import PropTypes from 'prop-types';
import React from 'react';
import { EXCEPTION_BUSINESS_DAYS, EXCEPTION_FAR_OPEN_HEDGE } from '../others/capstockExceptionTypes';
import { capstockException } from './commonPropTypes';

const retrieveNestedAttributeFieldName = (type) => {
  if (type === EXCEPTION_BUSINESS_DAYS) {
    return 'capstock_settlement_dates_set_business_days_from_trade_dates_attributes';
  } if (type === EXCEPTION_FAR_OPEN_HEDGE) {
    return 'capstock_settlement_dates_use_far_open_hedges_attributes';
  }
  return '';
};

const generateFieldName = (type, mapIndex, exceptionIndex, fieldName) => {
  const nestedAttributesName = retrieveNestedAttributeFieldName(type);
  return `account[currency_line_item_maps_attributes][${mapIndex}][${nestedAttributesName}][${exceptionIndex}][${fieldName}]`;
};

const generateId = (type, mapIndex, exceptionIndex, fieldName) => {
  const nestedAttributesName = retrieveNestedAttributeFieldName(type);
  return `account_currency_line_item_maps_attributes_${mapIndex}_${nestedAttributesName}_${exceptionIndex}_${fieldName}`;
};

const IdInput = ({ exception, mapIndex, index }) => (
  <input
    type="hidden"
    className="form-control m-b"
    required
    id={generateId(exception.type, mapIndex, index, 'id')}
    name={generateFieldName(exception.type, mapIndex, index, 'id')}
    value={exception.id}
    readOnly
  />
);

IdInput.propTypes = {
  exception: PropTypes.shape(capstockException).isRequired,
  mapIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const DestroyInput = ({ exception, mapIndex, index }) => (
  <input
    type="hidden"
    className="form-control m-b"
    required
    id={generateId(exception.type, mapIndex, index, '_destroy')}
    name={generateFieldName(exception.type, mapIndex, index, '_destroy')}
    value="1"
    readOnly
  />
);

DestroyInput.propTypes = {
  exception: PropTypes.shape(capstockException).isRequired,
  mapIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export { IdInput, DestroyInput };
