/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import WebpackerReact from 'webpacker-react';
import I18n from 'i18n-js';
import { en } from '../../../config/locales/en.yml';
import { RollAccountContainer } from '../roll/account/rollAccountContainer';
import { EditReportConfigurationContainer } from '../reports/configuration/editing/containers/editReportConfigurationContainer';
import { CreateReportConfigurationContainer } from '../reports/configuration/creation/containers/createReportConfigurationContainer';
import { ProcessesListingContainer } from '../processes/listing/containers/processesListingContainer';
import { ProcessesCreateContainer } from '../processes/create/containters/processesCreateContainter';
import { AccountLineItems } from '../accounts/form/container/accountLineItems';
import NotificationsContainer from '../notifications/containers/NotificationsContainer';
import { CurrencyLineItemMapsSection } from '../accounts/form/components/currencyLineItemMapsSection';
import { RawDataFileTableRow } from '../rawDataFiles/components/RawDataFileTableRow';
import { AccountFlagsList } from '../currencyBalanceSheets/listing/components/accountFlagsList';
import '../reports/account_groups/form';
import '../rawDataFiles/edit';
import '../accounts/form/others/warning';
import { NewRawDataFilesTable } from '../rawDataFiles/components/NewRawDataFilesTable';
import { DashboardContainer } from '../rosy/dashboard/containers/dashboardContainer';
import { NotesListingContainer } from '../notes/listing/containers/notesListingContainer';
import { AccountNote, ProcessingGroupNote, ClientNote } from '../notes/components/resourceNote';
import OonaDashboardContainer from '../oona/dashboard/components/dashboardContainer';

import { CurrencyBalanceSheetsListingContainer } from '../currencyBalanceSheets/listing/containers/currencyBalanceSheetsListingContainer';
import { CurrencyBalanceSheetActions } from '../currencyBalanceSheets/show/components/currencyBalanceSheetActions';
import { SheetShowContainer } from '../currencyBalanceSheets/show/containers/sheetShowContainer';

import { AccountFileBadge } from '../rawDataFiles/components/AccountFileBadge';
import { HedgeSettlementDatePickerContainer } from '../hedgeSettlementDatePicker/containers/hedgeSettlementDatePickerContainer';
import '../quicksight';
import QuickView from '../notes/listing/components/quickView';
import { RawDataFileTableBody } from '../rawDataFiles/components/RawDataFileTableBody';
import { Loading } from '../shared/loading';
import { Filters as RawDataFilesFilters } from '../rawDataFiles/components/filters';
import { Filters as ReportsFilters } from '../reports/components/filters';
import ExecutionSettingsHome from '../executionSettings/containers/Home';

import { ProcessingDashboardContainer } from '../processingDashboard/containers/main';
import { AccountWeightsContainer } from '../accounts/weights/containers/main';

I18n.locale = 'en';
I18n.translations = {
  en,
};
WebpackerReact.setup({
  RollAccountContainer,
  EditReportConfigurationContainer,
  CreateReportConfigurationContainer,
  CurrencyBalanceSheetsListingContainer,
  CurrencyLineItemMapsSection,
  NotificationsContainer,
  AccountLineItems,
  RawDataFileTableRow,
  RawDataFileTableBody,
  NewRawDataFilesTable,
  DashboardContainer,
  NotesListingContainer,
  ClientNote,
  ProcessingGroupNote,
  AccountNote,
  AccountFlagsList,
  OonaDashboardContainer,
  CurrencyBalanceSheetActions,
  SheetShowContainer,
  HedgeSettlementDatePickerContainer,
  AccountFileBadge,
  QuickView,
  Loading,
  RawDataFilesFilters,
  ReportsFilters,

  ProcessesListingContainer,
  ProcessesCreateContainer,

  ProcessingDashboardContainer,

  AccountWeightsContainer,

  ExecutionSettingsHome,
});
