import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Item from './Item';
import Paginator from '../Paginator';

const Component = (props) => {
  const {
    items,
    onRemove,
    totalPages,
    currentPage,
    onPageChange,
  } = props;

  return (
    <>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th>id</th>
            <th>{I18n.t('activerecord.attributes.execution_trade_type.trade_purpose')}</th>
            <th>{I18n.t('activerecord.attributes.execution_trade_type.trade_sub_purpose')}</th>
            <th>{I18n.t('activerecord.attributes.execution_trade_type.created_at')}</th>
            <th>{I18n.t('activerecord.attributes.execution_trade_type.updated_at')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {
            items.map(item => <Item key={item.id} {...item} onRemove={onRemove} />)
          }
        </tbody>
      </Table>
      <div style={{ textAlign: 'center' }}>
        <Paginator
          totalPages={totalPages}
          currentPage={currentPage}
          onChange={onPageChange}
        />
      </div>
    </>

  );
};

Component.defaultProps = {
  items: [],
};

Component.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      tradePurpose: PropTypes.string.isRequired,
      tradeSubPurpose: PropTypes.string.isRequired,
    }),
  ),
  onRemove: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Component;
