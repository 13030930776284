import PropTypes from 'prop-types';

const ConfigSelectableFieldPropTypes = {
  fieldNameOverride: PropTypes.string,
  id: PropTypes.number,
  reportsSelectableFieldDataTypeFormatId: PropTypes.number,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  selectableField: PropTypes.shape({
    id: PropTypes.number.isRequired,
    model: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    availableFormats: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }),
};

const DatasetPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  performance: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    useAssetValuationDate: PropTypes.bool,
  }).isRequired,
});

const DatasetOptionsPropTypes = PropTypes.shape({
  useAssetValuationDate: PropTypes.bool,
});

export { ConfigSelectableFieldPropTypes, DatasetPropTypes, DatasetOptionsPropTypes };
