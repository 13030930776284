import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';

export const AccountDifferentCalendarDatesModal = ({ onHide, show, accounts }) => {
  const datesAccount = () => {
    const dateMaps = accounts.reduce((acc, account) => {
      const settings = account.accountScheduledRollSettings.length ? account.accountScheduledRollSettings : [{ scheduledRollDate: 'Empty', nextSettlementDate: 'Empty' }];
      settings.forEach((setting) => {
        const { scheduledRollDate, nextSettlementDate } = setting;

        let key = scheduledRollDate || 'Empty';

        if (account.scheduledRollDateCalendar) {
          key = `${scheduledRollDate || 'Empty'} / ${nextSettlementDate || 'Empty'}`;
        }

        acc[key] = acc[key] ? acc[key].concat(account) : [account];
      });
      return acc;
    }, {});

    return dateMaps;
  };

  return (
    <Modal show={show} onHide={onHide} id="rollCalendarMismatchError">
      <Modal.Header closeButton>
        <Modal.Title>Account settings mismatch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>
          The following accounts have different settings for the next roll dates:
        </h2>
        <br />
        { _.map(datesAccount(), ((dateAccounts, date) => (
          <div key={date} date-test-id={date}>
            <h4>
              {date}
            </h4>
            <ul>
              {
                dateAccounts.map(account => (
                  <li key={account.id}>
#
                    {account.id}
:
                    {account.name}
                  </li>
                ))
              }
            </ul>
          </div>
        )))}
        <br />
        <p>
          Please fix them before atempting rolling
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="default" onClick={onHide}>
          {I18n.t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AccountDifferentCalendarDatesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
