import PropTypes from 'prop-types';

const Money = (number, isoCode) => Number(number).toLocaleString('en-US', {
  style: 'currency',
  currency: isoCode,
  currencyDisplay: 'code',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

Money.propTypes = {
  number: PropTypes.string.isRequired,
  isoCode: PropTypes.string.isRequired,
};

export { Money };
