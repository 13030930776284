import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import AccountsSelector from '../../../shared/AccountsSelector/Index';
import Client from '../../../client';

const Component = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [accountIds, setAccountIds] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const httpResponse = await Client.getExecutionGroup(groupId);
    const { executionGroup } = await httpResponse.json();

    setAccountIds(executionGroup.accountIds);

    const res = await Client.getProcessingGroups({ clientId: executionGroup.client.id });
    const { processingGroups } = await res.json();

    setData(processingGroups.map(pg => (
      {
        id: pg.id,
        title: pg.displayName,
        items: pg.accounts.map(x => (
          { id: x.id, title: x.displayName }
        )),
      }
    )));
  }, [groupId]);

  const handleGroupSelected = (ids) => {
    setAccountIds([...accountIds, ...ids.filter(x => !accountIds.includes(x))]);
  };

  const handleGroupUnSelected = (ids) => {
    setAccountIds(accountIds.filter(x => !ids.includes(x)));
  };

  const handleItemSelected = (accountId) => {
    setAccountIds([...accountIds, accountId]);
  };

  const handleItemRemoved = (accountId) => {
    setAccountIds(accountIds.filter(x => x !== accountId));
  };

  const handleSubmit = async () => {
    const res = await Client.updateExecutionGroup(groupId, { executionGroup: { accountIds } });
    if (res.ok) {
      navigate('/execution_settings');
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <div className="view-container-actions">
        <Link to="/execution_settings" className="btn btn-success">Back</Link>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>Manage Accounts</h1>
        </div>
        <div className="col-sm-12">
          <AccountsSelector
            groups={data}
            onItemChecked={handleItemSelected}
            onItemUnChecked={handleItemRemoved}
            onGroupChecked={handleGroupSelected}
            onGroupUnChecked={handleGroupUnSelected}
            selectedItems={accountIds}
          />
          <Button onClick={handleSubmit}>Save</Button>
        </div>
      </div>
    </div>
  );
};

export default Component;
