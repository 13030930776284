import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroupItem,
  Checkbox,
} from 'react-bootstrap';

const Component = (props) => {
  const {
    id,
    title,
    checked,
    onCheck,
    onUnCheck,
  } = props;

  const handleChange = (e) => {
    if (e.target.checked) {
      onCheck(id);
    } else {
      onUnCheck(id);
    }
  };

  return (
    <ListGroupItem style={{ paddingLeft: 15 }}>
      <Checkbox checked={checked} onChange={handleChange}>
        {title}
      </Checkbox>
    </ListGroupItem>
  );
};

Component.propTypes = {
  id: PropTypes.oneOfType(PropTypes.string, PropTypes.number).isRequired,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  onUnCheck: PropTypes.func.isRequired,
};

export default Component;
