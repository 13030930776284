import PropTypes from 'prop-types';

const weight = PropTypes.shape({
  value: PropTypes.string,
  currencyId: PropTypes.number,
  currencyIsoCode: PropTypes.string,
});

const file = PropTypes.shape({
  date: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  lastRunMessage: PropTypes.string,
  publishedDate: PropTypes.string,
  weightType: PropTypes.string,
  weights: PropTypes.arrayOf(weight),
});

const dayData = PropTypes.shape({
  date: PropTypes.string,
  files: PropTypes.arrayOf(file),
});

const comparisonDatum = PropTypes.shape({
  firstId: PropTypes.number,
  secondId: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.shape({
    isoCode: PropTypes.string,
    firstValue: PropTypes.string,
    secondValue: PropTypes.string,
    difference: PropTypes.string,
  })),
});

export {
  weight, file, dayData, comparisonDatum,
};
