import React, { useState, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Client from '../../../client';
import { DateTime } from '../../../shared/date';
import { Loading } from '../../../shared/loading';

import './processesTableRow.scss';

const ProcessesTableRow = (props) => {
  const [showDetail, setShowDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState(null);

  const { process } = props;

  const fetchProcess = () => {
    Client.getProcess(process.id)
      .then(response => response.json())
      .then((processDetail) => {
        setIsLoading(false);
        setDetail(processDetail);
      });
  };

  const toggleDetail = (e) => {
    e.preventDefault();

    setShowDetail(!showDetail);

    if (detail) return;

    setIsLoading(true);

    fetchProcess();
  };

  const statusBadge = (status) => {
    switch (status) {
      case 'pending':
        return 'badge-warning';
      case 'failed':
        return 'badge-danger';

      default:
        return 'badge-success';
    }
  };

  return (
    <Fragment>
      <tr className={classNames('performance-process')}>
        <td>
          <a onClick={toggleDetail} className="process-link">{`# ${process.id} `}</a>
        </td>
        <td>{DateTime({ dateTime: process.createdAt })}</td>
        <td>{`${process.startDate} / ${process.endDate}`}</td>
        <td>{process.accountIds.sort().join(', ')}</td>
        <td>
          <span className={`badge ${statusBadge(process.status)}`}>{process.status}</span>
        </td>
      </tr>

      {showDetail && (
        <Fragment>
          {isLoading ? (
            <tr className="text-center">
              <td colSpan={9}>
                <Loading loading />
              </td>
            </tr>
          ) : (
            <Fragment>
              {detail
                  && detail.accounts.sort((a, b) => a.id - b.id).map(account => (
                    <tr key={account.id} className="process-detail">
                      <td colSpan={1}>
#
                        {account.id}
                      </td>
                      <td colSpan={3}>{account.name}</td>
                      <td>
                        <span className={`badge ${statusBadge(account.status)}`}>
                          {account.status}
                        </span>
                      </td>
                    </tr>
                  ))}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ProcessesTableRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  process: PropTypes.object.isRequired,
};

export { ProcessesTableRow };
