import {
  SELECT_BALANCE_SHEET,
  UNSELECT_BALANCE_SHEET,
  RECEIVE_BULK_REBALANCE_ACCOUNT,
  RECEIVE_CURRENCY_BALANCE_SHEETS,
} from '../actions/currencyBalanceSheets';

const initialState = {
  selected: {},
};

export default function currencyBalanceSheets(state = initialState, action) {
  let newSelectedState = {};
  switch (action.type) {
    case RECEIVE_CURRENCY_BALANCE_SHEETS: {
      newSelectedState = action.payload.currencyBalanceSheets.reduce((acc, balanceSheet) => {
        acc[balanceSheet.id] = true;
        return acc;
      }, {});
      return {
        ...state,
        selected: {
          ...state.selected,
          ...newSelectedState,
        },
      };
    }
    case SELECT_BALANCE_SHEET:
      newSelectedState = action.payload.ids.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      return {
        ...state,
        selected: {
          ...state.selected,
          ...newSelectedState,
        },
      };
    case UNSELECT_BALANCE_SHEET:
      newSelectedState = {
        ...state.selected,
      };
      action.payload.ids.forEach(id => delete newSelectedState[id]);
      return {
        ...state,
        selected: newSelectedState,
      };
    case RECEIVE_BULK_REBALANCE_ACCOUNT:
      newSelectedState = {
        ...state.selected,
      };
      action.payload.currencyBalanceSheets
        .forEach(currencyBalanceSheet => delete newSelectedState[currencyBalanceSheet.id]);
      return {
        ...state,
        selected: newSelectedState,
      };
    default:
      return state;
  }
}
