import React, {
  Fragment, useMemo, useState,
} from 'react';
import _ from 'lodash';
import I18n from 'i18n-js';
import {
  Alert, Button,
} from 'react-bootstrap';
import { FxContractsTable } from './fxContractsTable';
import { NetPairsSection } from './netPairsSection';
import { useFxContractsSelection } from '../../hooks/useSelectFxContracts';
import { useNetPairs } from '../../hooks/useNetPairs';
import { useNetByPivot } from '../../hooks/useNetByPivot';
import { NetByPivotSection } from './netByPivotSection';
import { Filters } from './filters';
import Client from '../../../../client';
import { FeatureFlagged } from '../../../../features/components/featureFlagged';
import { useSimulatedExecution } from '../../hooks/useSimulatedExecution';
import { Loading } from '../../../../shared/loading';
import { displayNumber } from '../../shared/displayNumber';
import { usePolledFxContractsFetch } from '../../hooks/usePolledFxContractsFetch';

const NewTradesTab = () => {
  const [searchState, setSearchState] = useState({});
  const filters = useMemo(() => {
    const { selectedClientId, selectedAccountId, selectedProcessingGroupId } = searchState;
    return {
      client_id: selectedClientId,
      account_id: selectedAccountId,
      processing_group_id: selectedProcessingGroupId,
      status: ['new'],
    };
  }, [searchState]);
  const [fxContractsState] = usePolledFxContractsFetch(filters);
  const selectableFxContractIds = _.map(fxContractsState.fxContracts, fx => fx.id);
  const {
    selectedFxContractIds,
    selectFxContracts,
    unselectFxContracts,
  } = useFxContractsSelection(selectableFxContractIds);
  const { state: netPairsState } = useNetPairs(selectedFxContractIds);
  const {
    state: netByPivotState,
    pivotCurrencyCode,
    setPivotCurrencyCode,
  } = useNetByPivot(selectedFxContractIds);

  const estimatedFxContracts = useMemo(
    () => {
      const currentFxContracts = fxContractsState.fxContracts;
      const extractFxContracts = fxContractGroups => (
        _.chain(fxContractGroups)
          .flatMap(fxContractGroup => fxContractGroup.fxContracts)
          .keyBy('id')
          .value()
      );

      const netPairsFxContracts = extractFxContracts(netPairsState.fxContractGroups);
      const netByPivotFxContracts = extractFxContracts(netByPivotState.fxContractGroups);

      if (_.isEmpty(netPairsFxContracts) && _.isEmpty(netByPivotFxContracts)) {
        return currentFxContracts;
      }

      return {
        ...currentFxContracts,
        ..._.pick(netPairsFxContracts, _.keys(currentFxContracts)),
        ..._.pick(netByPivotFxContracts, _.keys(currentFxContracts)),
      };
    }, [
      fxContractsState.fxContracts,
      netPairsState.fxContractGroups,
      netByPivotState.fxContractGroups,
    ],
  );

  const {
    handleSimulationClick,
    state: simulatedExecutionState,
    statuses: simulatedExecutionStatuses,
  } = useSimulatedExecution(Client, selectedFxContractIds);

  const loading = fxContractsState.loading || netPairsState.loading || netByPivotState.loading
    || simulatedExecutionState.loading;

  const additionalColumns = useMemo(() => ([
    {
      id: 'estimatedRate',
      display: () => I18n.t('oona.dashboard.index.fx_contracts.estimated_rate'),
      accessor: fxContract => fxContract.allInRate && displayNumber(fxContract.allInRate),
    },
  ]), []);

  return (
    <Fragment>
      {
        simulatedExecutionState.result === simulatedExecutionStatuses.SUCCESS && (
          <Alert bsStyle="success">
            {I18n.t('users_portal.fx_contracts.simulate_trade_execution.success')}
          </Alert>
        )
      }
      {
        simulatedExecutionState.result === simulatedExecutionStatuses.FAILURE && (
          <Alert bsStyle="danger">
            {I18n.t('users_portal.fx_contracts.simulate_trade_execution.no_fx_contracts')}
          </Alert>
        )
      }
      <div className="text-center">
        <Loading loading={loading} />
      </div>
      <Filters
        onFilter={setSearchState}
        loading={loading}
      />
      <FeatureFlagged feature="simulateTradeExecution">
        <Button
          bsStyle="primary"
          disabled={loading || _.isEmpty(selectedFxContractIds)}
          onClick={handleSimulationClick}
        >
          { I18n.t('users_portal.fx_contracts.simulate_trade_execution.trigger') }
        </Button>
      </FeatureFlagged>
      <FxContractsTable
        onSelect={selectFxContracts}
        onUnselect={unselectFxContracts}
        selectedFxContractIds={selectedFxContractIds}
        fxContracts={estimatedFxContracts}
        loading={loading}
        additionalColumns={additionalColumns}
      />
      <NetPairsSection
        {...netPairsState}
      />
      <NetByPivotSection
        {...netByPivotState}
        pivotCurrencyCode={pivotCurrencyCode}
        onPivotCurrencyChange={setPivotCurrencyCode}
      />
    </Fragment>
  );
};

export default NewTradesTab;
