import React from 'react';
import I18n from 'i18n-js';
import './settlementTotals.scss';
import { currencyPropType, selectedSettlementTotalProp, settlementTotalsProps } from '../../commonPropTypes';
import { Money } from '../../../../shared';
import { SETTLEMENT_TOTAL_OPTIONS } from './settlementTotalsSelector';

/* eslint-disable max-len */
const displaySelectedSettlementTotalLineItems = (selectedOption) => {
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.CAPSTOCK_ONLY) return I18n.t('settlement_totals.selected.currency_line_items.capstock_only');
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.NO_CAPSTOCK) return I18n.t('settlement_totals.selected.currency_line_items.no_capstock');
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.EVERYTHING) return I18n.t('settlement_totals.selected.currency_line_items.everything');

  throw new Error(`Unknown option ${selectedOption}`);
};

const displaySelectedSettlementTotalFxContracts = (selectedOption) => {
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.CAPSTOCK_ONLY) return I18n.t('settlement_totals.selected.fx_contracts.capstock_only');
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.NO_CAPSTOCK) return I18n.t('settlement_totals.selected.fx_contracts.no_capstock');
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.EVERYTHING) return I18n.t('settlement_totals.selected.fx_contracts.everything');

  throw new Error(`Unknown option ${selectedOption}`);
};

const getSelectedSettlementTotal = (settlementTotals, selectedOption) => {
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.CAPSTOCK_ONLY) return settlementTotals.capstockTotal;
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.NO_CAPSTOCK) return settlementTotals.nonCapstockTotal;
  if (selectedOption === SETTLEMENT_TOTAL_OPTIONS.EVERYTHING) return settlementTotals.total;

  throw new Error(`Unknown option ${selectedOption}`);
};
/* eslint-enable max-len */

export const SettlementTotals = ({
  settlementTotals, selectedSettlementTotalOption,
  baseCurrency,
}) => {
  const selectedSettlementTotal = getSelectedSettlementTotal(settlementTotals,
    selectedSettlementTotalOption);

  return (
    <div className="clearfix settlement-totals">
      <div className="pull-left settlement-total-line-items">
        <span className="settlement-total-amount">
          {Money(selectedSettlementTotal.currencyLineItemsBase, baseCurrency.isoCode)}
        </span>
        {' '}
        <span className="settlement-total-description">
          {displaySelectedSettlementTotalLineItems(selectedSettlementTotalOption)}
        </span>
      </div>
      <div className="pull-right settlement-total-fx-contracts">
        <span className="settlement-total-amount">
          {Money(selectedSettlementTotal.fxContractsBase, baseCurrency.isoCode)}
        </span>
        {' '}
        <span className="settlement-total-description">
          {displaySelectedSettlementTotalFxContracts(selectedSettlementTotalOption)}
        </span>
      </div>
    </div>
  );
};

SettlementTotals.propTypes = {
  baseCurrency: currencyPropType.isRequired,
  settlementTotals: settlementTotalsProps.isRequired,
  selectedSettlementTotalOption: selectedSettlementTotalProp.isRequired,
};
