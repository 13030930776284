import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { WrappedReactSelect } from '../wrappedReactSelect';

export const AccountFilter = ({
  accounts, onChange, selectedAccountId, loading, ...otherProps
}) => {
  const getAccountOptions = useCallback(() => {
    const accountOptions = accounts.map(account => ({
      value: account.id,
      label: `(${account.id}) ${account.name}`,
    }));
    accountOptions.unshift({ value: undefined, label: I18n.t('sheets_filter_component.accounts.all') });
    return accountOptions;
  }, [accounts]);

  const getSelectedAccount = useCallback(() => (
    getAccountOptions().find(client => client.value === selectedAccountId)
  ), [getAccountOptions, selectedAccountId]);

  return (
    <WrappedReactSelect
      className="filter-accounts"
      placeholder={I18n.t('sheets_filter_component.accounts.all')}
      options={getAccountOptions()}
      value={getSelectedAccount()}
      onChange={onChange}
      isLoading={loading}
      loadingMessage={I18n.t('loading')}
      {...otherProps}
    />
  );
};

AccountFilter.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedAccountId: PropTypes.number,
  /* eslint-disable-next-line react/forbid-prop-types */
  inputProps: PropTypes.object,
};

AccountFilter.getDefaultProps = {
  selectedAccountId: null,
  inputProps: {},
};
