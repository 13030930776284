import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

export const AccountNoRolleableModal = ({ onHide, show }) => (
  <Modal show={show} onHide={onHide} id="rollCalendarNoRolls">
    <Modal.Header closeButton>
      <Modal.Title>Accounts with no rollable trades</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h2 className="text-center">
          There are no accounts with rollable trades available at the moment
      </h2>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="default" onClick={onHide}>
        {I18n.t('close')}
      </Button>
    </Modal.Footer>
  </Modal>
);

AccountNoRolleableModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
