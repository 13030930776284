import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Routes from '../../routes.js.erb';
import { useApi } from '../../shared/useApi';
import { HedgeSettlementDatePicker } from '../components/hedgeSettlementDatePicker';

const usePickersApi = (url) => {
  const [pickResult, setPickResult] = useState({});
  const { loading, data, callApi } = useApi(url, { method: 'GET', fireOnMount: false });
  const clearPickResult = useCallback(() => {
    setPickResult({});
  }, [setPickResult]);
  useEffect(() => {
    if (loading) return;
    setPickResult(data || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), loading]);
  return {
    pickResult,
    callApi,
    loading,
    clearPickResult,
  };
};

const useFormFields = (originalState) => {
  const [fields, setFields] = useState(originalState);
  const updateField = (event) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };
  const resetFields = () => {
    setFields(originalState);
  };
  return {
    fields,
    updateField,
    resetFields,
  };
};

/* eslint-disable react/destructuring-assignment */
export const HedgeSettlementDatePickerContainer = (props) => {
  const originalState = {
    baseCurrencyId: props.baseCurrencyId.toString(),
    localCurrencyId: '',
    counterpartyId: '',
    strategy: '',
  };
  const { fields, updateField, resetFields } = useFormFields(originalState);
  const url = Routes.api_v1_account_hedge_settlement_date_pickers_path(props.accountId, {
    balance_sheet_id: props.balanceSheetId,
    base_currency_id: fields.baseCurrencyId,
    local_currency_id: fields.localCurrencyId,
    counterparty_id: fields.counterpartyId,
    strategy: fields.strategy,
  });
  const {
    pickResult, callApi, loading, clearPickResult,
  } = usePickersApi(url);
  const handleClose = useCallback(() => {
    resetFields();
    clearPickResult();
  }, [resetFields, clearPickResult]);
  return (
    <HedgeSettlementDatePicker
      onFieldChange={updateField}
      onSubmit={callApi}
      loading={loading}
      currencies={props.currencies}
      counterparties={props.counterparties}
      counterpartySettlementStrategies={props.counterpartySettlementStrategies}
      pickResult={pickResult}
      clearPickResult={clearPickResult}
      onClose={handleClose}
      {...fields}
    />
  );
};
/* eslint-enable react/destructuring-assignment */

HedgeSettlementDatePickerContainer.propTypes = {
  balanceSheetId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  baseCurrencyId: PropTypes.number.isRequired,
  counterparties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  currencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    isoCode: PropTypes.string.isRequired,
  })).isRequired,
  counterpartySettlementStrategies: PropTypes.arrayOf(PropTypes.string).isRequired,
};
