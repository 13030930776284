import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
} from 'react-bootstrap';
import I18n from 'i18n-js';

import TextField from '../TextField';
import AreaField from '../AreaField';
import SelectField from '../SelectField';

// eslint-disable-next-line import/no-unresolved
import constants from '../../../rails_contants';
import Client from '../../../client';

const Component = (props) => {
  const {
    onSubmit,
    errors,
    formData,
    onChange,
  } = props;

  const [clientOptions, setClients] = useState([]);

  const handleChange = (e) => {
    onChange(e);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const fetchClients = useCallback(async () => {
    const res = await Client.getClients();
    const { clients } = await res.json();
    setClients(clients);
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const nettingRestrictionsOptions = constants
    .nettingRestrictions
    .map(x => ({ optionName: x, optionValue: x }));

  return (
    <Form>
      <TextField
        name="name"
        onChange={handleChange}
        value={formData.name}
        errors={errors.name}
        label={I18n.t('activerecord.attributes.execution_group.name')}
      />
      <AreaField
        name="description"
        onChange={handleChange}
        value={formData.description}
        errors={errors.description}
        label={I18n.t('activerecord.attributes.execution_group.description')}
      />
      <SelectField
        name="nettingRestrictions"
        onChange={handleChange}
        value={formData.nettingRestrictions}
        errors={errors.nettingRestrictions}
        options={nettingRestrictionsOptions}
        label={I18n.t('activerecord.attributes.execution_group.netting_restrictions')}
      />
      <SelectField
        name="client"
        onChange={handleChange}
        value={formData.client}
        errors={errors.client}
        options={clientOptions.map(client => (
          { optionName: client.displayName, optionValue: client.id }
        ))}
        label={I18n.t('activerecord.attributes.execution_group.client')}
      />
      <FormGroup>
        <Button onClick={handleSubmit}>Save</Button>
      </FormGroup>
    </Form>
  );
};

Component.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    nettingRestrictions: PropTypes.string,
    client: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
    nettingRestrictions: PropTypes.arrayOf(PropTypes.string),
    client: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Component;
