import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import { Table } from 'react-bootstrap';
import { Checkbox } from '../../../../shared/checkbox';
import { displayNumber } from '../../shared/displayNumber';

export const FxContractsTable = ({
  fxContracts, loading, onSelect, onUnselect, selectedFxContractIds, additionalColumns,
  id,
}) => {
  const allFxContractIds = _.map(fxContracts, fx => fx.id);
  const allSelected = _.xor(allFxContractIds, selectedFxContractIds)
    .length === 0 && allFxContractIds.length !== 0;

  const noSelection = onSelect && onUnselect;
  return (
    <div>
      <Table hover id={id}>
        <thead>
          <tr>
            {
            noSelection && (
            <th scope="col">
              <Checkbox
                name="selectFxContracts"
                onCheck={onSelect}
                onUncheck={onUnselect}
                value={allFxContractIds}
                checked={allSelected}
                disabled={loading}
              />
            </th>
            )
          }
            <th scope="col">#</th>
            <th scope="col">{I18n.t('activerecord.attributes.fx_contract.account')}</th>
            <th scope="col">{I18n.t('activerecord.attributes.account.client')}</th>
            <th scope="col">{I18n.t('activerecord.attributes.account.processing_group')}</th>
            <th scope="col">{I18n.t('oona.dashboard.index.fx_contracts.given_currency')}</th>
            <th scope="col">{I18n.t('oona.dashboard.index.fx_contracts.given_currency_amount')}</th>
            <th scope="col">{I18n.t('oona.dashboard.index.fx_contracts.side')}</th>
            <th scope="col">{I18n.t('oona.dashboard.index.fx_contracts.counter_currency')}</th>
            <th scope="col">{I18n.t('activerecord.attributes.fx_contract.settlement_date')}</th>
            <th scope="col">{I18n.t('oona.dashboard.index.fx_contracts.settlement_amount')}</th>
            {
              additionalColumns.map(column => (
                <th scope="col" key={column.id}>
                  { column.display() }
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
          _.map(fxContracts, (fxContract) => {
            const isSelected = _.includes(selectedFxContractIds, fxContract.id);
            return (
              <tr key={fxContract.id} className={isSelected ? 'info' : ''}>
                {
                  noSelection && (
                    <th scope="row">
                      <Checkbox
                        name={`selectFxContract${fxContract.id}`}
                        onCheck={onSelect}
                        onUncheck={onUnselect}
                        value={[fxContract.id]}
                        checked={isSelected}
                        disabled={loading}
                      />
                    </th>
                  )
                }
                <th scope="row">
                  {fxContract.id}
                </th>
                <th scope="row">
                  {fxContract.account.id}
                  {' '}
                  {fxContract.account.shortName}
                </th>
                <th scope="row">
                  {fxContract.client.id}
                  {' '}
                  {fxContract.client.companyName || fxContract.client.shortCompanyName}
                </th>
                <th scope="row">
                  {fxContract.processingGroup && `(${fxContract.processingGroup.id}) ${fxContract.processingGroup.name}`}
                  {!fxContract.processingGroup && I18n.t('processing_groups.none') }
                </th>
                <th scope="row">{fxContract.givenCurrency.isoCode}</th>
                <th scope="row">
                  {fxContract.fixedSide === 'sell' && displayNumber(-parseFloat(fxContract.givenAmount))}
                  {fxContract.fixedSide !== 'sell' && displayNumber(fxContract.givenAmount)}
                </th>
                <th scope="row">{fxContract.fixedSide.toUpperCase()}</th>
                <th scope="row">{fxContract.settlementCurrency.isoCode}</th>
                <th scope="row">{fxContract.settlementDate}</th>
                <th scope="row">
                  {fxContract.settlementAmount && fxContract.fixedSide === 'buy' && displayNumber(-parseFloat(fxContract.settlementAmount))}
                  {fxContract.settlementAmount && fxContract.fixedSide !== 'buy' && displayNumber(fxContract.settlementAmount)}
                </th>
                {
                  additionalColumns.map(column => (
                    <th scope="col" key={`${column.id}-${fxContract.id}`}>
                      { column.accessor(fxContract) }
                    </th>
                  ))
                }
              </tr>
            );
          })
        }
        </tbody>
      </Table>
    </div>
  );
};

FxContractsTable.propTypes = {
  id: PropTypes.string,
  fxContracts: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    givenCurrency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isoCode: PropTypes.string.isRequired,
    }),
    givenAmount: PropTypes.string.isRequired,
    fixedSide: PropTypes.string.isRequired,
    settlementCurrency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isoCode: PropTypes.string.isRequired,
    }),
    settlementDate: PropTypes.string.isRequired,
    account: PropTypes.shape({
      id: PropTypes.number.isRequired,
      shortName: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  selectedFxContractIds: PropTypes.arrayOf(PropTypes.number),
  additionalColumns: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.func.isRequired,
    accessor: PropTypes.func.isRequired,
  })),
};

FxContractsTable.defaultProps = {
  id: '',
  onSelect: null,
  onUnselect: null,
  selectedFxContractIds: [],
  additionalColumns: [],
};
