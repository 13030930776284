/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import NotificationsPopup from './NotificationsPopup';

const NotificationsBell = ({ notifications }) => (
  <OverlayTrigger
    trigger="click"
    rootClose
    placement="bottom"
    overlay={
      (
        <NotificationsPopup
          notifications={notifications}
          positionLeft={() => (this.left)}
          positionTop={() => (this.top)}
        />
      )
    }
  >
    <div className="notifications-bell">
      <a className="count-info" data-container=".navbar-right">
        <i className="material-icons">notifications</i>
      </a>
    </div>
  </OverlayTrigger>
);

NotificationsBell.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NotificationsBell;
