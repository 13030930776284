import { uuid } from '../../../utils/uuid';
import {
  ADD_LINE_ITEM_MAP,
  REMOVE_LINE_ITEM_MAP,
} from '../actions/currencyLineItemMaps';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_LINE_ITEM_MAP: {
      return [
        ...state,
        {
          id: null, type: '', action: '', uuid: uuid(),
        },
      ];
    }
    case REMOVE_LINE_ITEM_MAP: {
      const { uuid: currencyLineItemMapUuid } = action.payload;
      return state.filter(map => map.uuid !== currencyLineItemMapUuid);
    }
    default:
      return state;
  }
}
