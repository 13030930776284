import PropTypes from 'prop-types';
import React from 'react';

const AddSelectableFieldButton = React.memo(({ onClick }) => (
  <i className="material-icons add-icon" onClick={onClick}>
    add
  </i>
));

AddSelectableFieldButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { AddSelectableFieldButton };
