/* eslint-disable max-len */
import { connect } from 'react-redux';
import { fetchProcessingGroupsMetadata } from '../actions/processingGroups';

import { Filters } from '../components/filters';

const mapStateToProps = (state, props) => ({
  order: state.processingGroups.order,
  loading: state.processingGroups.loading,
  store: props.store,
});

export const FiltersContainer = connect(mapStateToProps, {
  onFilter: fetchProcessingGroupsMetadata,
})(Filters);
