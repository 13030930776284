import React, { useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from './Form';
import Client from '../../../client';
import useForm from '../../hooks/useForm';

const Component = () => {
  const { groupId } = useParams();

  const navigate = useNavigate();

  const {
    setFormData,
    setFormErrors,
    formData,
    formErrors,
  } = useForm({});

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchGroup = useCallback(async () => {
    const httpResponse = await Client.getExecutionGroup(groupId);
    const { executionGroup } = await httpResponse.json();
    setFormData({ ...executionGroup, client: executionGroup.client.id });
  }, [groupId, setFormData]);

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  const handleSubmit = async () => {
    const res = await Client.updateExecutionGroup(
      groupId, { ...formData, clientId: formData.client },
    );

    if (res.ok) {
      navigate('/execution_settings');
      return;
    }

    const { errors } = await res.json();
    setFormErrors(errors);
  };

  return (
    <div>
      <div className="view-container-actions">
        <Link to="/execution_settings" className="btn btn-success">Back</Link>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>Edit Execution Group</h1>
        </div>
        <div className="col-sm-12">
          <Form
            onSubmit={handleSubmit}
            errors={formErrors}
            formData={formData}
            onChange={handleFormChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
