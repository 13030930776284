import React from 'react';
import PropTypes from 'prop-types';

import { Overlay, Popover } from 'react-bootstrap';

import { Viewer as ToastViewer } from '@toast-ui/react-editor';

export default class PopoverStickOnHover extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.state = {
      showPopover: false,
    };
  }

  componentWillUnmount() {
    if (this.setTimeoutConst) {
      clearTimeout(this.setTimeoutConst);
    }
  }

  handleMouseEnter() {
    const { delay, onMouseEnter } = this.props;

    this.setTimeoutConst = setTimeout(() => {
      this.setState({ showPopover: true }, () => {
        if (onMouseEnter) {
          onMouseEnter();
        }
      });
    }, delay);
  }

  handleMouseLeave() {
    clearTimeout(this.setTimeoutConst);
    this.setState({ showPopover: false });
  }

  /* eslint-disable no-underscore-dangle */
  render() {
    const { reason, children } = this.props;
    const { showPopover } = this.state;

    const renderChild = React.Children.map(children, child => (
      React.cloneElement(child, {
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
        ref: (node) => {
          this._child = node;
          const { ref } = child;

          if (typeof ref === 'function') {
            ref(node);
          }
        },
      })
    ))[0];

    return (
      <>
        {renderChild}
        <Overlay
          show={showPopover}
          placement="top"
          target={this._child}
          shouldUpdatePosition
        >
          <Popover
            onMouseEnter={() => {
              this.setState({ showPopover: true });
            }}
            onMouseLeave={this.handleMouseLeave}
            positionLeft={this.left}
            positionTop={this.top}
            id="popover"
          >
            <div style={{ padding: '4px 8px' }}>
              <ToastViewer initialValue={reason} />
            </div>
          </Popover>
        </Overlay>
      </>
    );
    /* eslint-enable no-underscore-dangle */
  }
}

PopoverStickOnHover.defaultProps = {
  delay: 20,
  onMouseEnter: () => {},
};

PopoverStickOnHover.propTypes = {
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  children: PropTypes.element.isRequired,
  reason: PropTypes.string.isRequired,
};
