import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import RailsContants from '../../rails_contants.js.erb';
import { WrappedReactSelect } from '../wrappedReactSelect';

export const AccountTypeFilter = ({ onChange, selectedAccountType, ...otherProps }) => {
  const getAccountTypeOptions = useCallback(() => {
    const accountOptions = RailsContants.accountTypes.map(type => ({
      value: type,
      label: type,
    }));

    accountOptions.unshift({
      value: null,
      label: I18n.t('all'),
    });

    return accountOptions;
  }, []);

  return (
    <WrappedReactSelect
      className="filter-account-types"
      placeholder={I18n.t('all')}
      options={getAccountTypeOptions()}
      value={selectedAccountType}
      onChange={onChange}
      {...otherProps}
    />
  );
};

AccountTypeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedAccountType: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
};

AccountTypeFilter.getDefaultProps = {
  selectedAccountType: null,
  inputProps: {},
};
