import React from 'react';
import PropTypes from 'prop-types';
import Routes from '../../../routes.js.erb';
import { Loading } from '../../../shared/loading';
import { useApi } from '../../../shared/useApi';
import CurrencyBalanceSheetDetailsContainer from './CurrencyBalanceSheetDetailsContainer';

export const CurrencyBalanceSheetContainer = ({ ids, currentIndex, ...otherProps }) => {
  const id = ids[currentIndex];
  const { loading, data } = useApi(Routes.api_v1_currency_balance_sheet_path({ id, format: 'json' }));
  const currencyBalanceSheet = data ? data.currencyBalanceSheet : null;
  const canLoadNext = currencyBalanceSheet && ids[currentIndex + 1];

  return (
    <React.Fragment>
      <div className="currency-balance-sheet-row">
        <div className="text-center">
          <Loading loading={loading} />
        </div>
        {
          currencyBalanceSheet && (
            <CurrencyBalanceSheetDetailsContainer
              currencyBalanceSheet={currencyBalanceSheet}
              {...otherProps}
            />
          )
        }
      </div>
      {
        canLoadNext && (
          <CurrencyBalanceSheetContainer
            ids={ids}
            currentIndex={currentIndex + 1}
            {...otherProps}
          />
        )
      }
    </React.Fragment>
  );
};

CurrencyBalanceSheetContainer.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentIndex: PropTypes.number,
};

CurrencyBalanceSheetContainer.defaultProps = {
  currentIndex: 0,
};
