/* eslint-disable max-len */
import React from 'react';
import I18n from 'i18n-js';
import { FormGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/es/Col';
import Row from 'react-bootstrap/es/Row';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProcessingGroups } from '../actions/currencyBalanceSheets';
import Client from '../../../client';
import { ClientProcessingGroupAccountFilter } from '../../../shared/filters/clientProcessingGroupAccountFilter';
import '../../../shared/buttonFilterGroup.scss';
import { NewDatePicker } from '../../../shared/newLedgerDatePicker/NewLedgerDatePicker';
import { FilterControl } from '../../../shared/filters/FilterControl';
import { WrappedReactSelect } from '../../../shared/wrappedReactSelect';

class Filters extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onFilter: PropTypes.func.isRequired,
    order: PropTypes.shape({
      field: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(['asc', 'desc', null]),
    }).isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.currentDate = moment();

    this.state = {
      selectedAccountId: null,
      selectedClientId: null,
      selectedProcessingGroupId: null,
      selectedLedgerDate: this.currentDate,
      appliedLedgerDate: this.currentDate,
      flags: [],
      loadingFlags: false,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange = (selectedState) => {
    this.setState(selectedState);
  };

  componentDidMount = () => {
    this.fetchFlags();

    const clientId = parseInt(new URLSearchParams(window.location.search).get('client_id'), 10) || null;
    const processingGroupId = parseInt(new URLSearchParams(window.location.search).get('processing_group_id'), 10) || null;

    this.setState(
      {
        selectedClientId: clientId,
        selectedProcessingGroupId: processingGroupId,
      },
      this.applyFilters,
    );
  };

  applyFilters = () => {
    const { onFilter, order } = this.props;
    const {
      selectedClientId,
      selectedAccountId,
      selectedProcessingGroupId,
      selectedLedgerDate,
    } = this.state;
    const filters = {
      clientId: selectedClientId,
      accountId: selectedAccountId,
      processingGroupId: selectedProcessingGroupId,
      flagId: this.getSelectedFlagId(),
      ledgerDate: this.getSelectedLedgerDate(),
    };

    this.setState({ appliedLedgerDate: selectedLedgerDate });

    onFilter(filters, order);
  };

  fetchFlags = () => {
    Client.getFlags()
      .then(response => response.json())
      .then((data) => {
        this.setState({
          flags: data.flags,
          loadingFlags: false,
        });
      });
  };

  getFlagOptions = () => {
    const { flags } = this.state;
    const flagOptions = flags.map(account => ({
      value: account.id,
      label: account.name,
    }));
    flagOptions.unshift({
      value: null,
      label: I18n.t('sheets_filter_component.flags.all'),
    });
    return flagOptions;
  };

  handleFlagChange = (option) => {
    this.setState({
      selectedFlag: option,
    });
  };

  handleLedgerDateChange = (ledgerDate) => {
    this.setState({
      selectedLedgerDate: ledgerDate,
    });
  };

  getSelectedFlagId = () => {
    const { selectedFlag } = this.state;
    return (selectedFlag || {}).value;
  };

  getSelectedLedgerDate = () => {
    const { selectedLedgerDate } = this.state;
    return selectedLedgerDate.format('YYYY-MM-DD');
  };

  handleFilterClick = (e) => {
    e.preventDefault();
    this.applyFilters();
  };

  handleClearFilter = () => {
    this.setState({
      selectedClientId: null,
      selectedProcessingGroupId: null,
      selectedAccountId: null,
      selectedFlag: null,
      selectedLedgerDate: this.currentDate,
    });
  };

  render() {
    const {
      selectedClientId,
      selectedProcessingGroupId,
      selectedAccountId,
      selectedLedgerDate,
      selectedFlag,
      loadingFlags,
      appliedLedgerDate,
    } = this.state;
    const { loading } = this.props;

    return (
      <Row>
        <ClientProcessingGroupAccountFilter
          selectedAccountId={selectedAccountId}
          selectedClientId={selectedClientId}
          selectedProcessingGroupId={selectedProcessingGroupId}
          onChange={this.handleFilterChange}
          layout={{ sm: 2 }}
        />
        <Col sm={2}>
          <div className="form-group">
            <WrappedReactSelect
              className="filter-flags"
              placeholder={I18n.t('sheets_filter_component.flags.all')}
              options={this.getFlagOptions()}
              value={selectedFlag}
              onChange={this.handleFlagChange}
              isLoading={loadingFlags}
              loadingMessage={I18n.t('loading')}
            />
          </div>
        </Col>
        <Col sm={2}>
          <FormGroup>
            <NewDatePicker
              selectedDate={selectedLedgerDate}
              onChange={this.handleLedgerDateChange}
              inputProps={{
                placeholder: I18n.t('sheets_filter_component.ledger_date'),
              }}
            />
          </FormGroup>
        </Col>
        <FilterControl
          onSubmit={this.handleFilterClick}
          onClear={this.handleClearFilter}
          disabled={loading}
        />
        <Col sm={12}>
          <div className="selected-ledger-date">{appliedLedgerDate.format('D MMMM YYYY')}</div>
        </Col>
      </Row>
    );
  }
}

const anyProcessingGroupIsLoadingSheets = processingGroups => Object.values(processingGroups)
  .map(pg => pg.isFetchingCurrencyBalanceSheets)
  .some(isFetchingCurrencyBalanceSheets => isFetchingCurrencyBalanceSheets);

const mapStateToProps = state => ({
  order: state.processingGroups.order,
  loading: anyProcessingGroupIsLoadingSheets(
    state.processingGroups.processingGroups,
  ),
});

export const FiltersContainer = connect(mapStateToProps, {
  onFilter: fetchProcessingGroups,
})(Filters);
