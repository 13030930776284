import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
} from 'react-bootstrap';
import I18n from 'i18n-js';

import Client from '../../../client';
import SelectField from '../SelectField';

const LocalCurrencyForm = (props) => {
  const [currencies, setCurrencies] = useState([]);

  const {
    formData,
    onChange,
    onSubmit,
    errors,
  } = props;

  const handleChange = (e) => {
    onChange(e);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  useEffect(() => {
    Client
      .getCurrencies()
      .then(response => response.json())
      .then(response => setCurrencies(_.sortBy(response.data, 'isoCode')));
  }, []);

  const currencyOptions = currencies.map(currency => (
    { optionName: currency.isoCode, optionValue: currency.id }
  ));

  return (
    <Form>
      <SelectField
        name="currency"
        onChange={handleChange}
        value={formData.currency}
        errors={errors.currency}
        options={currencyOptions}
        label={I18n.t('activerecord.attributes.execution_local_currency.currency')}
      />

      <FormGroup>
        <Button onClick={handleSubmit}>Save</Button>
      </FormGroup>
    </Form>
  );
};

LocalCurrencyForm.propTypes = {
  formData: PropTypes.shape({
    currency: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    currency: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export { LocalCurrencyForm };
