import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import moment from 'moment';
import { CurrencyBalanceSheetHeader } from './currencyBalanceSheetHeader';
import { CurrencyBalanceSheetProps } from '../commonPropTypes';
import { CbsSectionTitle } from '../shared/cbsSectionTitle';
import { SettlementTotals } from './settlementTotals/settlementTotals';
import { Money } from '../../../shared';
import { ExposureSection } from './exposureSection';
import { SettlementSection } from './settlementSection';
import {
  useSectionsToggling,
  areAllExpanded,
  generateHedgeIdentifier,
  generateSettlementIdentifier,
} from '../hooks/useSectionsToggling';


export const CurrencyBalanceSheet = ({
  currencyBalanceSheet, onValidate, selectedSettlementTotalOption,
}) => {
  const {
    hedges, account, settlementTotals, settlementGroups,
    account: { baseCurrency }, id,
  } = currencyBalanceSheet;
  const [showLineItems, setShowLineItems] = useState(true);
  const [showTrades, setShowTrades] = useState(true);

  const handleLineItemsToggle = useCallback(() => {
    setShowLineItems(!showLineItems);
  }, [setShowLineItems, showLineItems]);

  const handleTradesToggle = useCallback(() => {
    setShowTrades(!showTrades);
  }, [setShowTrades, showTrades]);

  const {
    sectionsState,
    toggleAllSections,
    toggleSection,
  } = useSectionsToggling(currencyBalanceSheet);

  const onExpandAllClick = (e) => {
    e.preventDefault();
    toggleAllSections();
  };

  return (
    <React.Fragment>
      <CurrencyBalanceSheetHeader
        currencyBalanceSheet={currencyBalanceSheet}
        onExpandClick={onExpandAllClick}
        isExpanded={areAllExpanded(sectionsState)}
        onValidate={onValidate}
      />
      {
        (hedges.length > 0) && (
          <section className="hedge-section">
            <CbsSectionTitle
              title={I18n.t('currency_balance_sheet.exposure_section_title')}
              leftSubTitle={I18n.t('currency_balance_sheet.currency_exposures.title')}
              rightSubTitle={I18n.t('currency_balance_sheet.currency_contracts.title')}
              onLineItemsToggle={handleLineItemsToggle}
              onTradesToggle={handleTradesToggle}
              showLineItems={showLineItems}
              showTrades={showTrades}
            />
            {
              hedges.map((hedgeGroup, index) => {
                const sectionIdentifier = generateHedgeIdentifier(id, index);
                return (
                  <ExposureSection
                    key={JSON.stringify(hedgeGroup.criteria)}
                    group={hedgeGroup}
                    account={account}
                    showLineItems={showLineItems}
                    showTrades={showTrades}
                    identifier={sectionIdentifier}
                    onExpandOne={identifier => toggleSection(identifier)}
                    isExpanded={sectionsState[sectionIdentifier]}
                  />
                );
              })
            }
          </section>
        )
      }
      {
        (settlementGroups.length > 0) && (
          <section className="settlement-section">
            <CbsSectionTitle
              title={I18n.t('currency_balance_sheet.settlement_section_title')}
              leftSubTitle={I18n.t('shared.currency_balance_sheets.show.pending_cash_settlements')}
              rightSubTitle={I18n.t('shared.currency_balance_sheets.show.closed_contracts')}
              onLineItemsToggle={handleLineItemsToggle}
              onTradesToggle={handleTradesToggle}
              showLineItems={showLineItems}
              showTrades={showTrades}
            >
              <SettlementTotals
                selectedSettlementTotalOption={selectedSettlementTotalOption}
                settlementTotals={settlementTotals}
                baseCurrency={currencyBalanceSheet.account.baseCurrency}
              />
            </CbsSectionTitle>
            {
              settlementGroups.map(settlementGroup => (
                <React.Fragment key={settlementGroup.settlementDate}>
                  <div className="settlement-sub-total bottom-separator">
                    <div className="date">
                      { moment(settlementGroup.settlementDate).format('DD-MMMM-YYYY') }
                    </div>
                    <div className="sub-total">
                      {
                        Money(settlementGroup.settlementGroupTotal, baseCurrency.isoCode)
                      }
                    </div>
                  </div>
                  <div>
                    {
                      settlementGroup.settlements.map((settlement) => {
                        const sectionIdentifier = generateSettlementIdentifier(id,
                          settlement.criteria);
                        return (
                          <SettlementSection
                            key={JSON.stringify(settlement.criteria)}
                            group={settlement}
                            account={account}
                            showLineItems={showLineItems}
                            showTrades={showTrades}
                            identifier={sectionIdentifier}
                            onExpandOne={identifier => toggleSection(identifier)}
                            isExpanded={sectionsState[sectionIdentifier]}
                          />
                        );
                      })
                    }
                  </div>
                </React.Fragment>
              ))
            }
          </section>
        )
      }
    </React.Fragment>
  );
};

CurrencyBalanceSheet.propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types */
  currencyBalanceSheet: PropTypes.shape(CurrencyBalanceSheetProps).isRequired,
  onValidate: PropTypes.func,
  selectedSettlementTotalOption: PropTypes.string.isRequired,
};

CurrencyBalanceSheet.defaultProps = {
  onValidate: () => {},
};
