import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Datetime from 'react-datetime';
import moment from 'moment';
import _ from 'lodash';
import {
  Button, Modal, FormGroup, ControlLabel, Form, HelpBlock, Alert,
} from 'react-bootstrap';
import { Checkbox } from '../../../shared/checkbox';

const ProcessModal = (props) => {
  const {
    show, onClose, onSubmit, submitErrors,
  } = props;

  const yesterday = moment(Date.now()).add(-1, 'day');
  const today = moment(Date.now());
  const tomorrow = moment(Date.now()).add(1, 'day');

  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [calculateValuations, setCalculateValuations] = useState(false);

  const checkCalculateValuations = () => {
    setCalculateValuations(!calculateValuations);
  };

  const validateForm = () => {
    let valid = true;

    const startDateMoment = moment(startDate);

    try {
      if (_.isEmpty(startDate)) {
        valid = false;
        setStartDateError('You must enter a date');
      }

      if (startDateMoment.isAfter(endDate, 'day')) {
        valid = false;
        setStartDateError('The date should be earlier or equal than the end date');
      }

      if (startDateMoment.isAfter(yesterday, 'day')) {
        valid = false;
        setStartDateError('The date should be in the past');
      }
    } catch (error) {
      valid = false;
      setStartDateError('Invalid Date');
    }

    const endDateMoment = moment(endDate);

    try {
      if (_.isEmpty(endDate)) {
        valid = false;
        setEndDateError('You must enter a date');
      }

      if (endDateMoment.isSameOrBefore(startDate, 'day')) {
        valid = false;
        setEndDateError('The date should be after the start date');
      }
    } catch (error) {
      valid = false;
      setEndDateError('Invalid Date');
    }

    return valid;
  };

  const handleSubmitForm = (e) => {
    setStartDateError(null);
    setEndDateError(null);

    e.preventDefault();

    const valid = validateForm();

    if (valid) {
      onSubmit({ startDate, endDate, calculateValuations });
    }
  };

  const isStartDateValid = current => current.isBefore(today, 'day');
  const isEndDateValid = current => current.isAfter(startDate, 'day') && current.isBefore(tomorrow, 'day');

  return (
    <Modal show={show} onHide={onClose} id="rollModal">
      <Form onSubmit={handleSubmitForm}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('users_portal.processes.modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitErrors.length > 0 && <Alert bsStyle="danger"><ul>{submitErrors.map(err => <li>{err}</li>)}</ul></Alert>}

          <FormGroup validationState={startDateError ? 'error' : ''}>
            <ControlLabel>{I18n.t('users_portal.processes.modal.from_date')}</ControlLabel>

            <Datetime
              closeOnSelect
              isValidDate={isStartDateValid}
              onChange={setStartDate}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              inputProps={{
                name: 'startDate',
                placeholder: 'Start Date',
                className: 'form-control date-picker-form',
              }}
              value={startDate}
            />
            <HelpBlock>{startDateError}</HelpBlock>
          </FormGroup>
          <FormGroup validationState={endDateError ? 'error' : ''}>
            <ControlLabel>{I18n.t('users_portal.processes.modal.to_date')}</ControlLabel>

            <Datetime
              closeOnSelect
              isValidDate={isEndDateValid}
              dateFormat="YYYY-MM-DD"
              onChange={setEndDate}
              inputProps={{
                name: 'endDate',
                placeholder: 'End Date',
                className: 'form-control date-picker-form',
              }}
              timeFormat={false}
              value={endDate}
            />
            <HelpBlock>{endDateError}</HelpBlock>
          </FormGroup>
          <FormGroup>
            <Checkbox
              name="calculateValuations"
              label={I18n.t('users_portal.processes.modal.calculate_valuations')}
              onCheck={checkCalculateValuations}
              onUncheck={checkCalculateValuations}
              checked={calculateValuations}
              value
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" type="submit">
            {I18n.t('create')}
          </Button>
          <Button bsStyle="default" onClick={onClose}>
            {I18n.t('close')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ProcessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  submitErrors: PropTypes.arrayOf(PropTypes.string),
};

ProcessModal.defaultProps = {
  submitErrors: [],
};

export { ProcessModal };
