import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Routes from '../../routes.js.erb';

const NoteButton = ({
  children, asButton, clientId, processingGroupId, accountId, ...otherProps
}) => (
  <a
    href={
      /* eslint-disable-next-line no-undef */
      Routes.notes_path({
        processing_group_id: processingGroupId,
        client_id: clientId,
        account_id: accountId,
      })
    }
    className={asButton ? 'btn btn-primary' : null}
    title={I18n.t('notes.open_button_text')}
    target="_blank"
    rel="noopener noreferrer"
    {...otherProps}
  >
    {children}
  </a>
);

NoteButton.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  asButton: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  processingGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NoteButton.defaultProps = {
  clientId: null,
  processingGroupId: null,
  accountId: null,
};

export const RelatedNotesButton = props => (
  <NoteButton {...props} className="btn btn-default">
    {I18n.t('notes.buttons.see_related')}
  </NoteButton>
);

export const AddNoteTrigger = props => (
  <NoteButton {...props} href="#">
    {I18n.t('notes.buttons.edit')}
  </NoteButton>
);

export const NewNoteTrigger = props => (
  <NoteButton {...props} href="#">
    {I18n.t('notes.buttons.new')}
  </NoteButton>
);

export const NoteTriggerPlaceholder = props => (
  <NoteButton
    onClick={event => event.preventDefault()}
    disabled
    {...props}
  >
    {I18n.t('notes.buttons.loading')}
  </NoteButton>
);
