import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import 'react-datetime/css/react-datetime.css';

function NewSettlementDatePicker({
  originalSettlementDate, value, onSelectedDate, required, disabled, placeholder, holidays,
}) {
  const date = moment(originalSettlementDate, 'YYYY-MM-DD');

  const isInHolidays = current => (
    holidays && holidays.find(holiday => moment(holiday).isSame(current))
  );

  const isValidDate = (current) => {
    const isWeekday = current.day() > 0 && current.day() < 6;
    return current.isSameOrAfter(date) && isWeekday && !isInHolidays(current);
  };

  return (
    <Datetime
      closeOnSelect
      dateFormat="YYYY-MM-DD"
      inputProps={{ required, disabled, placeholder }}
      isValidDate={isValidDate}
      onChange={onSelectedDate}
      timeFormat={false}
      value={value}
      viewDate={date}
    />
  );
}

NewSettlementDatePicker.propTypes = {
  disabled: PropTypes.bool.isRequired,
  originalSettlementDate: PropTypes.string.isRequired,
  onSelectedDate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool.isRequired,
  holidays: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([
    PropTypes.string,
    momentPropTypes.momentObj,
  ]),
};

NewSettlementDatePicker.defaultProps = {
  placeholder: '',
  holidays: [],
  value: null,
};

export { NewSettlementDatePicker };
