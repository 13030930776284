import connect from 'react-redux/es/connect/connect';
import { ProcessingGroupBalanceSheets } from '../components/processingGroupBalanceSheets';
import { selectBalanceSheet, unselectBalanceSheet } from '../actions/currencyBalanceSheets';

const mapStateToProps = (state, props) => {
  const { processingGroupId } = props;
  const processingGroup = state.processingGroups.processingGroups[processingGroupId];
  const { currencyBalanceSheetsPaginationMetadata } = processingGroup;
  return {
    isFetchingCurrencyBalanceSheets: processingGroup.isFetchingCurrencyBalanceSheets,
    totalBalanceSheets: currencyBalanceSheetsPaginationMetadata
      ? currencyBalanceSheetsPaginationMetadata.totalCount : null,
    currencyBalanceSheetIds: processingGroup.currencyBalanceSheetIds,
    selected: processingGroup.currencyBalanceSheetIds.length === 0 ? false
      : processingGroup.currencyBalanceSheetIds.every(id => state.selectedSheets.selected[id]),
    processingGroupId,
  };
};

const mapDispatchToProps = {
  onSelect: selectBalanceSheet,
  onUnselect: unselectBalanceSheet,
};

export const ProcessingGroupBalanceSheetsContainer = connect(mapStateToProps,
  mapDispatchToProps)(ProcessingGroupBalanceSheets);
