import React from 'react';

import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import I18n from 'i18n-js';

import { file as filePropType } from './commonPropTypes';

const AccountWeightsWeightTable = ({ selectedFile, loading }) => {
  if (!selectedFile || !(selectedFile.weights?.length > 1) || loading) {
    return null;
  }

  return (
    <Table className="account-weights-weight">
      <thead>
        <tr>
          <th>{ I18n.t('users_portal.account_weights.iso_code') }</th>
          <th className="date-cell">{ selectedFile.date }</th>
        </tr>
      </thead>
      <tbody>
        { selectedFile.weights.map(weight => (
          <tr key={weight.currencyIsoCode}>
            <td>{weight.currencyIsoCode}</td>
            <td className="number-cell">{weight.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

AccountWeightsWeightTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  selectedFile: filePropType,
};

AccountWeightsWeightTable.defaultProps = {
  selectedFile: null,
};

export { AccountWeightsWeightTable };
