import PropTypes from 'prop-types';
import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import I18n from 'i18n-js';
import _ from 'lodash';
import { ClientProcessingGroupAccountFilter } from '../../../shared/filters/clientProcessingGroupAccountFilter';
import Client from '../../../client';
import '../../../shared/buttonFilterGroup.scss';
import { EditNoteModal } from '../components/editNoteModal';
import { NotesSection } from '../components/notesSection';
import { ACCOUNT, CLIENT, PROCESSING_GROUP } from '../../entityTypes';
import { NewNoteProcessingGroupModal } from '../../create/components/newNoteProcessingGroupModal';
import { NewNoteAccountModal } from '../../create/components/newNoteAccountModal';
import { NewNoteClientModal } from '../../create/components/newNoteClientModal';
import { FilterControl } from '../../../shared/filters/FilterControl';

// URLSearchParams not compatible with IE11 (https://caniuse.com/#feat=urlsearchparams). We don't care now but just FYI.
const extractIdParam = (search, paramName) => {
  const urlParams = new URLSearchParams(search);
  return urlParams.get(paramName) ? parseInt(urlParams.get(paramName), 10) : null;
};

const refreshPageWithQueryParams = (window, params) => {
  const { history, location } = window;
  const queryParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
  const newUrl = `${location.protocol}//${location.host}${location.pathname}?${queryParams}`;
  history.pushState({ path: newUrl }, '', newUrl);
};

const useNotes = () => {
  const initialState = {
    selectedClientId: extractIdParam(window.location.search, 'client_id'),
    selectedAccountId: extractIdParam(window.location.search, 'account_id'),
    selectedProcessingGroupId: extractIdParam(window.location.search, 'processing_group_id'),
  };
  const [selectedEntities, setSelectedEntities] = useState(initialState);
  const [search, setSearch] = useState(initialState);
  const [notesState, setNotes] = useState({
    notes: {},
    loadingNotes: false,
  });

  const fetchNotes = useCallback((query) => {
    const { selectedProcessingGroupId, selectedClientId, selectedAccountId } = query;
    let entityType = null;
    let entityId = null;
    if (selectedAccountId) {
      entityType = ACCOUNT;
      entityId = selectedAccountId;
    } else if (selectedProcessingGroupId) {
      entityType = PROCESSING_GROUP;
      entityId = selectedProcessingGroupId;
    } else if (selectedClientId) {
      entityType = CLIENT;
      entityId = selectedClientId;
    }
    setNotes({
      notes: {},
      loadingNotes: true,
    });
    Client
      .getNotes(entityType, entityId)
      .then(response => response.json())
      .then(({ notes: newNotes }) => {
        setNotes({
          loadingNotes: false,
          notes: _.keyBy(newNotes, 'id'),
        });
      });
  }, []);

  useEffect(() => { fetchNotes(search); }, [search, fetchNotes]);
  useEffect(() => {
    const { selectedProcessingGroupId, selectedClientId, selectedAccountId } = search;
    const params = _.pickBy({
      processing_group_id: selectedProcessingGroupId,
      client_id: selectedClientId,
      account_id: selectedAccountId,
    }, _.identity);
    refreshPageWithQueryParams(window, params);
  }, [search]);

  const { notes, loadingNotes } = notesState;
  return {
    notes, setNotes, loadingNotes, setSearch, selectedEntities, setSelectedEntities,
  };
};

const NewModal = ({ show, type, ...otherProps }) => {
  if (type === CLIENT) {
    return <NewNoteClientModal show={show} {...otherProps} />;
  } if (type === ACCOUNT) {
    return <NewNoteAccountModal show={show} {...otherProps} />;
  } if (type === PROCESSING_GROUP) {
    return <NewNoteProcessingGroupModal show={show} {...otherProps} />;
  }
  return null;
};

NewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([CLIENT, ACCOUNT, PROCESSING_GROUP]).isRequired,
};

export const NotesListingContainer = () => {
  const {
    notes, setNotes, loadingNotes, selectedEntities, setSelectedEntities, setSearch,
  } = useNotes();
  const [selectedNote, setSelectedNote] = useState(null);
  const [newModalState, setNewModal] = useState({ show: false, type: null });
  const noteObjects = Object.values(notes);
  const handleNewNoteSubmitted = useCallback((note) => {
    setNotes(oldNotes => ({
      ...oldNotes,
      notes: {
        ...oldNotes.notes,
        [note.id]: note,
      },
    }));
    setNewModal(prevNewModalState => ({
      ...prevNewModalState,
      show: false,
    }));
  }, [setNotes, setNewModal]);

  return (
    <div className="view-container-content">
      <Row>
        <ClientProcessingGroupAccountFilter
          layout={{ sm: 3 }}
          onChange={(selectedObject) => {
            setSelectedEntities({
              ...selectedEntities,
              ...selectedObject,
            });
          }}
          {...selectedEntities}
        />
        <Col sm={3}>
          <FilterControl
            className="text-right"
            onSubmit={(event) => {
              event.preventDefault();
              setSearch(selectedEntities);
            }}
            onClear={(event) => {
              event.preventDefault();
              const newSelectedEntities = {
                selectedProcessingGroupId: null,
                selectedClientId: null,
                selectedAccountId: null,
              };
              setSelectedEntities(newSelectedEntities);
              setSearch(newSelectedEntities);
            }}
            disabled={loadingNotes}
          />
        </Col>
      </Row>
      <Row>
        { loadingNotes && (
          <Col sm={12}>
            {I18n.t('notes.listing.loading')}
          </Col>
        )}
        {
          !loadingNotes && (
            <Fragment>
              <NotesSection
                title={I18n.t('notes.listing.client_section_title')}
                notes={noteObjects.filter(note => note.entityType === CLIENT)}
                onEdit={note => setSelectedNote(note)}
                onClickNew={(event) => {
                  event.preventDefault();
                  setNewModal({ show: true, type: CLIENT });
                }}
              />
              <NotesSection
                title={I18n.t('notes.listing.processing_group_section_title')}
                notes={noteObjects.filter(note => note.entityType === PROCESSING_GROUP)}
                onEdit={note => setSelectedNote(note)}
                onClickNew={(event) => {
                  event.preventDefault();
                  setNewModal({ show: true, type: PROCESSING_GROUP });
                }}
              />
              <NotesSection
                title={I18n.t('notes.listing.account_section_title')}
                notes={noteObjects.filter(note => note.entityType === ACCOUNT)}
                onEdit={note => setSelectedNote(note)}
                onClickNew={(event) => {
                  event.preventDefault();
                  setNewModal({ show: true, type: ACCOUNT });
                }}
              />
            </Fragment>
          )
        }
      </Row>
      <EditNoteModal
        show={!!selectedNote}
        note={selectedNote}
        onClose={() => setSelectedNote(null)}
        onSubmit={(updatedNote) => {
          setSelectedNote(null);
          setNotes(prevState => ({
            ...prevState,
            notes: {
              ...prevState.notes,
              [updatedNote.id]: updatedNote,
            },
          }));
        }}
        onDelete={(noteId) => {
          setSelectedNote(null);
          setNotes(prevState => ({
            ...prevState,
            notes: _.omit(prevState.notes, [noteId]),
          }));
        }}
      />
      <NewModal
        {...newModalState}
        onSubmit={handleNewNoteSubmitted}
        onClose={(e) => {
          e.preventDefault();
          setNewModal(prevState => ({
            ...prevState,
            show: false,
          }));
        }}
      />
    </div>
  );
};
