import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../../rawDataFiles/components/NewRawDataFilesTable.scss';
import moment from 'moment';

const unusualRecordPropType = PropTypes.shape({
  account: PropTypes.shape({
    shortName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  evaluatedAt: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

const UnusualRecordsTableRow = ({ unusualRecord }) => (
  <tr>
    <td>
      <h6>{unusualRecord.name}</h6>
      <div>{unusualRecord.description}</div>
    </td>
    <td>{unusualRecord.client.name}</td>
    <td>{unusualRecord.account ? `(${unusualRecord.account.id}) ${unusualRecord.account.shortName}` : ''}</td>
    <td>{unusualRecord.evaluatedAt}</td>
    <td>{unusualRecord.occurredAt}</td>
    <td>
      <a
        href={unusualRecord.path}
        target="_blank"
        rel="noopener noreferrer"
      >
        {I18n.t('view')}
      </a>
    </td>
  </tr>
);

UnusualRecordsTableRow.propTypes = {
  unusualRecord: unusualRecordPropType.isRequired,
};

const isUnusualOfTheDay = unusualRecord => moment(unusualRecord.evaluatedAt).isSame(moment(), 'day');

export const UnusualRecordsTable = ({ unusualRecords }) => {
  if (unusualRecords.length === 0) {
    return (
      <h2 className="text-center">
        {I18n.t('rosy.dashboard.index.unusual_records_section.no_data')}
      </h2>
    );
  }
  return (
    <div className="table-responsive with-dropdown">
      <table className="table">
        <thead>
          <tr>
            <th>{I18n.t('activerecord.attributes.rosy/record_usualness.description')}</th>
            <th>{I18n.t('activerecord.attributes.rosy/record_usualness.client')}</th>
            <th>{I18n.t('activerecord.attributes.rosy/record_usualness.account')}</th>
            <th>{I18n.t('activerecord.attributes.rosy/record_usualness.evaluated_at')}</th>
            <th>{I18n.t('activerecord.attributes.rosy/record_usualness.occurred_at')}</th>
          </tr>
        </thead>
        <tbody>
          <TransitionGroup component={null} appear exit={false}>
            {
              unusualRecords.map(unusualRecord => (isUnusualOfTheDay(unusualRecord)
                ? (
                  <CSSTransition
                    key={unusualRecord.id}
                    timeout={1000}
                    classNames="updated-files-table-row"
                  >
                    <UnusualRecordsTableRow unusualRecord={unusualRecord} />
                  </CSSTransition>
                ) : (
                  <UnusualRecordsTableRow unusualRecord={unusualRecord} />
                )))
            }
          </TransitionGroup>
        </tbody>
      </table>
    </div>
  );
};

UnusualRecordsTable.propTypes = {
  unusualRecords: PropTypes.arrayOf(unusualRecordPropType).isRequired,
};
