import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from './Form';
import Client from '../../../client';
import useForm from '../../hooks/useForm';

export default () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const subgroupsIndexUrl = `/execution_settings/groups/${groupId}/subgroups`;

  const {
    setFormData,
    setFormErrors,
    formData,
    formErrors,
  } = useForm({ name: '' });

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const res = await Client.createExecutionSubgroup({ ...formData, executionGroupId: groupId });

    if (res.ok) {
      navigate(subgroupsIndexUrl);
      return;
    }

    const { errors } = await res.json();
    setFormErrors(errors);
  };

  return (
    <div>
      <div className="view-container-actions">
        <Link to={subgroupsIndexUrl} className="btn btn-success">Back</Link>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>New Execution Subgroup</h1>
        </div>
        <div className="col-sm-12">
          <Form
            onSubmit={handleSubmit}
            errors={formErrors}
            onChange={handleFormChange}
            formData={formData}
          />
        </div>
      </div>
    </div>
  );
};
