import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'i18n-js';
import { currencyLineItemMap as currencyLineItemMapPropTypes } from './commonPropTypes';
import { CurrencyLineItemMapIdInput } from './currencyLineItemMapIdInput';
import { CapstockExceptionsContainer } from '../container/capstockExceptionsContainer';
import { DestroyEntityButton } from '../../../shared/destroyEntityButton';

const CAPSTOCK_LINE_ITEM_MAPS = [
  'CurrencyLineItemMaps::EstimatedFeeRebateCharge',
  'CurrencyLineItemMaps::EstimatedSubsReds',
  'CurrencyLineItemMaps::EstimatedSwitchInOut',
  'CurrencyLineItemMaps::EstimatedTransferInOut',
  'CurrencyLineItemMaps::FinalFeeRebateCharge',
  'CurrencyLineItemMaps::FinalSubsReds',
  'CurrencyLineItemMaps::FinalSwitchInOut',
  'CurrencyLineItemMaps::FinalTransferInOut',
];

class CurrencyLineItemMapFormGroup extends React.Component {
  static propTypes = {
    ...currencyLineItemMapPropTypes,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      type: props.type,
    };
  }

  handleRemove = () => {
    const { id, uuid, onRemove } = this.props;
    onRemove(id, uuid);
  };

  handleTypeChange = (e) => {
    this.setState({ type: e.target.value });
  };

  shouldRenderExceptions = () => {
    const { type } = this.state;
    return CAPSTOCK_LINE_ITEM_MAPS.includes(type);
  };

  render() {
    const {
      action, id, index, uuid,
    } = this.props;
    const { type } = this.state;
    const actionFieldId = `account_currency_line_item_maps_attributes_${index}_action`;
    const typeFieldId = `account_currency_line_item_maps_attributes_${index}_type`;
    return (
      <React.Fragment>
        <div className="nested-fields clearfix">
          <div className="form-group col-sm-4">
            <label htmlFor={actionFieldId} className="sr-only">
              {I18n.t('activerecord.attributes.currency_line_item_maps/base.action')}
            </label>
            <div className="input-group">
              <span className="input-group-addon">{I18n.t('activerecord.attributes.currency_line_item_maps/base.action')}</span>
              <select
                className="form-control"
                required
                name={`account[currency_line_item_maps_attributes][${index}][action]`}
                id={actionFieldId}
                defaultValue={action}
              >
                <option value="" />
                <option value="spot">{I18n.t('activerecord.attributes.currency_line_item_maps/base.actions.spot')}</option>
                <option value="hedge">{I18n.t('activerecord.attributes.currency_line_item_maps/base.actions.hedge')}</option>
                <option value="spot_and_hedge">{I18n.t('activerecord.attributes.currency_line_item_maps/base.actions.spot_and_hedge')}</option>
                <option value="do_nothing">{I18n.t('activerecord.attributes.currency_line_item_maps/base.actions.do_nothing')}</option>
              </select>
            </div>
          </div>

          <div className="form-group col-sm-4">
            <label htmlFor={typeFieldId} className="sr-only">
              {I18n.t('activerecord.attributes.currency_line_item_maps/base.type')}
            </label>
            <div className="input-group">
              <span className="input-group-addon">{I18n.t('activerecord.attributes.currency_line_item_maps/base.type')}</span>
              <select
                className="form-control"
                required
                name={`account[currency_line_item_maps_attributes][${index}][type]`}
                id={typeFieldId}
                value={type}
                onChange={this.handleTypeChange}
              >
                <option value="" />
                <option value="CurrencyLineItemMaps::CurrencyExposure">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::CurrencyExposure')}</option>
                <option value="CurrencyLineItemMaps::Dividend">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::Dividend')}</option>
                <option value="CurrencyLineItemMaps::EstimatedFeeRebateCharge">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::EstimatedFeeRebateCharge')}</option>
                <option value="CurrencyLineItemMaps::EstimatedSubsReds">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::EstimatedSubsReds')}</option>
                <option value="CurrencyLineItemMaps::EstimatedSwitchInOut">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::EstimatedSwitchInOut')}</option>
                <option value="CurrencyLineItemMaps::EstimatedTransferInOut">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::EstimatedTransferInOut')}</option>
                <option value="CurrencyLineItemMaps::FinalFeeRebateCharge">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::FinalFeeRebateCharge')}</option>
                <option value="CurrencyLineItemMaps::FinalSubsReds">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::FinalSubsReds')}</option>
                <option value="CurrencyLineItemMaps::FinalSwitchInOut">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::FinalSwitchInOut')}</option>
                <option value="CurrencyLineItemMaps::FinalTransferInOut">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::FinalTransferInOut')}</option>
                <option value="CurrencyLineItemMaps::ProfitAndLoss">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::ProfitAndLoss')}</option>
                <option value="CurrencyLineItemMaps::AccruedIncome">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::AccruedIncome')}</option>
                <option value="CurrencyLineItemMaps::Nav">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::Nav')}</option>
                <option value="CurrencyLineItemMaps::PayableOrReceivable">{I18n.t('activerecord.attributes.currency_line_item_maps/base.types.CurrencyLineItemMaps::PayableOrReceivable')}</option>
              </select>
            </div>
          </div>

          <div className="form-group col-sm-4 text-right">
            <DestroyEntityButton onClick={this.handleRemove} />
          </div>
        </div>

        { id && <CurrencyLineItemMapIdInput id={id} index={index} /> }

        <div className="clearfix">
          {
            this.shouldRenderExceptions() && (
              <CapstockExceptionsContainer uuid={uuid} mapIndex={index} />
            )
          }
        </div>
      </React.Fragment>
    );
  }
}

export { CurrencyLineItemMapFormGroup };
