import moment from 'moment';
import PropTypes from 'prop-types';

export const DateTime = ({ dateTime, format = 'YYYY-MM-DD HH:mm:ss' }) => moment(dateTime).format(format);
DateTime.propTypes = {
  dateTime: PropTypes.string.isRequired,
};

export const Date = ({ date, format }) => moment(date).format(format);
Date.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
};
Date.defaultProps = {
  format: 'YYYY-MM-DD',
};
