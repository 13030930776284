import React from 'react';
import classNames from 'classnames';

import MDSpinner from 'react-md-spinner';
import Routes from '../../../routes.js.erb';

import { AccountsTableContainer } from '../../containers/accounts/tableContainer';
import { LinkAsButton } from '../../../shared/linkAsButton';

import { processingGroup as processingGroupPropTypes } from '../commonPropTypes';

import Columns from '../shared/columns';

export class ProcessingGroupRow extends React.Component {
  state = {
    showAccounts: false,
  };

  componentDidMount() {
    const { toggleAccounts } = this.props;

    if (toggleAccounts) {
      this.accountsToggle();
    }
  }

  handleAccountsToggle = (e) => {
    e.preventDefault();
    this.accountsToggle();
  };

  accountsToggle = () => {
    const { showAccounts: stateShowAccounts } = this.state;
    this.setState({ showAccounts: !stateShowAccounts });
  };

  render = () => {
    const {
      id,
      name,
      loading,
      error,
    } = this.props;

    const { showAccounts } = this.state;

    const sharedLinks = [
      Routes.raw_data_files_path({ processing_group: id }),
      '',
      Routes.fx_contracts_path({ processing_group_ids: [id] }),
    ];

    return (
      <>
        <tr
          className={classNames('processing-group-header', 'pg-header', {
            'processing-group-selected': showAccounts,
          })}
        >
          <td>
            {id
              ? (
                <LinkAsButton
                  onClick={this.handleAccountsToggle}
                  data-test-class="processingGroupId"
                  data-test-id={`processingGroupId${id || ''}`}
                  className="toggle-pg"
                  disabled={loading || Boolean(error)}
                >
                  {id}
                </LinkAsButton>
              )
              : ''
            }
          </td>
          <td>
            <LinkAsButton
              onClick={this.handleAccountsToggle}
              data-test-class="processingGroupName"
              data-test-id={`processingGroupName${id || 0}`}
              className="toggle-pg"
              disabled={loading || Boolean(error)}
            >
              {name}
            </LinkAsButton>
          </td>
          { error || loading
            ? (
              <td colSpan={15} className="text-left">
                { error
                  ? <h5 className="text-left">{error}</h5>
                  : (
                    <div className="loading-container">
                      <MDSpinner />
                    </div>
                  )
                }
              </td>
            )
            : <Columns {...this.props} sharedLinks={sharedLinks} />
          }
        </tr>
        <AccountsTableContainer expanded={showAccounts} processingGroupId={id} />
      </>
    );
  };
}

ProcessingGroupRow.propTypes = {
  ...processingGroupPropTypes,
};

ProcessingGroupRow.defaultProps = {};
