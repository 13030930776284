import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { SummaryColumn } from './summaryColumn';
import { Percentage } from '../../../shared';

export const WeightSummary = ({ weight }) => (
  <SummaryColumn title="" subtitle={I18n.t('currency_balance_sheet.weight')}>
    <Percentage percentage={weight} precision={4} />
  </SummaryColumn>
);

WeightSummary.propTypes = {
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
