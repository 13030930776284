import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import MDSpinner from 'react-md-spinner';

export const Loading = ({
  total, currentCount, loading, withText,
}) => {
  if (!loading) {
    return null;
  }
  return (
    <Fragment>
      <div>
        <MDSpinner />
      </div>
      { withText
        && (
        <div className="m-t-sm">
          {
            currentCount
              ? I18n.t('loaded_current_of_total', { current_count: currentCount, total })
              : I18n.t('loading')
          }
        </div>
        )
      }
    </Fragment>
  );
};

Loading.propTypes = {
  total: PropTypes.number,
  currentCount: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  withText: PropTypes.bool,
};

Loading.defaultProps = {
  total: null,
  currentCount: null,
  withText: true,
};
