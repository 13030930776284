import React from 'react';
import PropTypes from 'prop-types';

import { SortedAccountNameFieldContainer, SortedAccountIdFieldContainer } from '../../containers/accounts/sortedFieldsContainers';

import SharedHeaders from '../shared/headers';

class AccountHeaders extends React.PureComponent {
  render() {
    const { processingGroupId } = this.props;

    return (
      <tr className="processing-group-header-row account-row-header account-row">
        <SortedAccountIdFieldContainer
          processingGroupId={processingGroupId}
          data-test-id={`sortByAccountNameProcessingGroup${processingGroupId || ''}`}
        />
        <SortedAccountNameFieldContainer
          processingGroupId={processingGroupId}
          data-test-id={`sortByAccountNameProcessingGroup${processingGroupId || ''}`}
        />
        <SharedHeaders />
      </tr>
    );
  }
}

AccountHeaders.propTypes = {
  processingGroupId: PropTypes.number,
};

AccountHeaders.defaultProps = {
  processingGroupId: null,
};

export default AccountHeaders;
