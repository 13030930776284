import React from 'react';
import PropTypes from 'prop-types';

const CurrencyLineItemMapIdInput = ({ id, index }) => (
  <input
    type="hidden"
    value={id}
    readOnly
    name={`account[currency_line_item_maps_attributes][${index}][id]`}
    id={`account_currency_line_item_maps_attributes_${index}_id`}
  />
);

CurrencyLineItemMapIdInput.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export { CurrencyLineItemMapIdInput };
