// This should go on a shared folder for the entire app

import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { orderedClassname } from '../shared/orderedClassname';
import { currentDirection as currentDirectionPropTypes } from './commonPropTypes';

export class SortedField extends React.PureComponent {
  /* eslint-disable react/sort-comp */
  static directionMap = {
    [null]: 'asc',
    asc: 'desc',
    desc: 'asc',
  };
  /* eslint-enable react/sort-comp */

  static arrowClass = {
    asc: 'arrow_downward',
    desc: 'arrow_upward',
  };

  toggleDirection = () => {
    const { currentDirection } = this.props;
    return SortedField.directionMap[currentDirection];
  };

  handleClick = (e) => {
    e.preventDefault();

    const { disabled } = this.props;
    if (disabled) return;

    const {
      onClick, field,
    } = this.props;
    onClick({ field, direction: this.toggleDirection() });
  };

  render = () => {
    /* eslint-disable react/prop-types */
    const {
      displayKey, currentDirection, options, 'data-test-id': dataTestId, disabled,
    } = this.props;
    /* eslint-enable react/prop-types */
    const className = classNames(
      { 'order-header': true },
      orderedClassname(currentDirection),
    );

    return (
      <th
        className={className}
        onClick={this.handleClick}
        width={options.width}
        colSpan={options.colSpan}
        disabled={disabled}
      >
        <a href="#" className="order-link" data-test-id={dataTestId}>
          { I18n.t(displayKey) }
          { currentDirection && <i className="material-icons">{ SortedField.arrowClass[currentDirection] }</i>}
        </a>
      </th>
    );
  }
}

SortedField.propTypes = {
  displayKey: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  currentDirection: currentDirectionPropTypes,
  options: PropTypes.shape({
    width: PropTypes.string,
    colSpan: PropTypes.number,
  }),
  disabled: PropTypes.bool,
};

SortedField.defaultProps = {
  disabled: false,
  currentDirection: null,
  options: {
    width: null,
    colSpan: null,
  },
};
