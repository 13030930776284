import React from 'react';
import PropTypes from 'prop-types';
import { PanelGroup } from 'react-bootstrap';
import Group from './Group';

const Component = (props) => {
  const {
    onItemChecked,
    onItemUnChecked,
    onGroupChecked,
    onGroupUnChecked,
    selectedItems,
    groups,
  } = props;

  const handleItemChecked = (accountId) => {
    onItemChecked(accountId);
  };

  const handleItemUnChecked = (accountId) => {
    onItemUnChecked(accountId);
  };

  return (
    <PanelGroup>
      {groups.map(group => (
        <Group
          key={group.id}
          {...group}
          onCheck={handleItemChecked}
          onUnCheck={handleItemUnChecked}
          onGroupCheck={onGroupChecked}
          onGroupUnCheck={onGroupUnChecked}
          selectedItems={selectedItems}
        />
      ))}
    </PanelGroup>
  );
};

Component.propTypes = {
  onItemChecked: PropTypes.func.isRequired,
  onItemUnChecked: PropTypes.func.isRequired,
  onGroupChecked: PropTypes.func.isRequired,
  onGroupUnChecked: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      })),
    }),
  ).isRequired,
};

export default Component;
