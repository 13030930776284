import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import momentPropTypes from 'react-moment-proptypes';
import { AdminOnly } from '../../../shared/adminOnly';
import { AccountNote } from '../../../notes/components/resourceNote';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { RollAccountContainer } from '../../../roll/account/rollAccountContainer';
import Routes from '../../../routes.js.erb';
import { Loading } from '../../../shared/loading';
import { PrintButton } from './printButton';
import SettlementTotalsSelector from './settlementTotals/settlementTotalsSelector';
import { accountScheduledRollSettingForDatePropTypes } from '../commonPropTypes';

export const CurrencyBalanceSheetActions = AdminOnly(({
  currencyBalanceSheetId, accountId, clientId, processingGroupId, ledgerDate,
  isValidated, selectedSettlementTotalOption, onChangeSelectedSettlementTotal,
  currentAccountScheduledRollSetting, nextAccountScheduledRollSetting,
}) => {
  const {
    canRoll, canRebalance, canConvertProfitAndLoss, refreshPermissions, loading,
  } = useAuthorizedResource([
    { modelClass: 'Account', modelId: accountId, modelAction: 'roll' },
    { modelClass: 'Account', modelId: accountId, modelAction: 'rebalance' },
    { modelClass: 'Account', modelId: accountId, modelAction: 'convert_profit_and_loss' },
  ], true);

  useEffect(() => {
    refreshPermissions();
  }, [refreshPermissions, isValidated]);

  return (
    <React.Fragment>
      <SettlementTotalsSelector
        selectedOption={selectedSettlementTotalOption}
        onChange={onChangeSelectedSettlementTotal}
      />
      <AccountNote
        accountId={accountId}
        clientId={clientId}
        processingGroupId={processingGroupId}
        showTriggerAsButton
      />
      {
        canRoll && (
          <RollAccountContainer
            currencyBalanceSheetIds={[currencyBalanceSheetId]}
            currentAccountScheduledRollSetting={currentAccountScheduledRollSetting}
            nextAccountScheduledRollSetting={nextAccountScheduledRollSetting}
            buttonClass="btn btn-primary"
          >
            {' '}
Roll
            {' '}
          </RollAccountContainer>
        )
      }
      {
        canRebalance && (
          <a
            className="btn btn-primary"
            href={Routes.rebalance_account_path(accountId)}
            data-method="put"
          >
            {I18n.t('clients_portal.accounts.rebalance.rebalance_to_target')}
          </a>
        )
      }
      {
        canConvertProfitAndLoss && (
          <a className="btn btn-primary" href={Routes.account_profit_and_loss_local_conversions_path(accountId)} data-method="post">
            {I18n.t('currency_balance_sheets.show.convert_profit_and_loss_to_local_currency')}
          </a>
        )
      }
      <PrintButton ledgerDate={ledgerDate} accountId={accountId} />
      <Loading loading={loading} />
    </React.Fragment>
  );
});

CurrencyBalanceSheetActions.propTypes = {
  currencyBalanceSheetId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  processingGroupId: PropTypes.number,
  clientId: PropTypes.number.isRequired,
  ledgerDate: PropTypes.oneOfType([
    PropTypes.string,
    momentPropTypes.momentObj,
  ]).isRequired,
  isValidated: PropTypes.bool.isRequired,
  currentAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
  nextAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
  selectedSettlementTotalOption: PropTypes.string.isRequired,
  onChangeSelectedSettlementTotal: PropTypes.func.isRequired,
};

CurrencyBalanceSheetActions.defaultProps = {
  processingGroupId: null,
};
