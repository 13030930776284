/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SortedField } from '../sortedField';

export class SortedAccountField extends React.PureComponent {
  handleClick = ({ field, direction }) => {
    const { onClick, ledgerDate, processingGroupId } = this.props;

    onClick({ ledgerDate, processingGroupId }, { field, direction });
  };

  render = () => <SortedField {...this.props} onClick={this.handleClick} />
}

SortedAccountField.propTypes = {
  processingGroupId: PropTypes.number,
  ledgerDate: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SortedAccountField.defaultProps = {
  processingGroupId: null,
  onClick: () => {},
};
