import {
  REQUEST_PROCESSING_GROUPS,
  RECEIVE_PROCESSING_GROUPS,
  UPDATE_PROCESSING_GROUP,
  ERROR_PROCESSING_GROUPS,
  RECEIVE_CURRENCY_BALANCE_SHEETS,
  REQUEST_CURRENCY_BALANCE_SHEETS,
  ERROR_CURRENCY_BALANCE_SHEETS,
} from '../actions/currencyBalanceSheets';


const initialState = {
  isFetching: true,
  error: '',
  processingGroups: {},
  processingGroupIds: [],
  order: {
    field: 'processing_group_name',
    direction: 'asc',
  },
};

const isFirstPage = page => page === 1 || page === undefined || page === null;

export default function processingGroupReducer(state = initialState, action) {
  /* eslint-disable no-case-declarations */

  switch (action.type) {
    case UPDATE_PROCESSING_GROUP:
      const { payload: { processingGroup: newProcessingGroup } } = action;

      const processingGroups = { ...state.processingGroups };
      const oldProcessingGroup = processingGroups[newProcessingGroup.id];

      newProcessingGroup.isFetchingCurrencyBalanceSheets = false;
      newProcessingGroup.currencyBalanceSheetIds = oldProcessingGroup.currencyBalanceSheetIds;
      processingGroups[newProcessingGroup.id] = newProcessingGroup;

      if (!state.processingGroupIds.includes(newProcessingGroup.id)) {
        state.processingGroupIds.push(newProcessingGroup.id);
      }

      return {
        ...state,
        processingGroups,
        isFetching: false,
      };
    case REQUEST_PROCESSING_GROUPS:
      return {
        isFetching: true,
        processingGroups: {},
        processingGroupIds: [],
        filters: action.payload.filters,
        order: action.payload.order,
        error: '',
      };
    case ERROR_PROCESSING_GROUPS:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    case ERROR_CURRENCY_BALANCE_SHEETS: {
      const { payload: { processingGroupId, error } } = action;

      return {
        ...state,
        processingGroups: {
          ...state.processingGroups,
          [processingGroupId]: {
            ...state.processingGroups[processingGroupId],
            isFetchingCurrencyBalanceSheets: false,
            error,
          },
        },
      };
    }
    case REQUEST_CURRENCY_BALANCE_SHEETS:
      return {
        ...state,
        processingGroups: {
          ...state.processingGroups,
          [action.payload.processingGroupId]: {
            ...state.processingGroups[action.payload.processingGroupId],
            isFetchingCurrencyBalanceSheets: true,
            error: '',
            currencyBalanceSheetIds: isFirstPage(action.payload.page) ? []
              : state.processingGroups[action.payload.processingGroupId].currencyBalanceSheetIds,
          },
        },
      };
    case RECEIVE_PROCESSING_GROUPS: {
      const { payload: { processingGroups: newProcessingGroups } } = action;
      const processingGroupsMap = newProcessingGroups.reduce((accumulator, processingGroup) => {
        accumulator[processingGroup.id] = {
          ...accumulator[processingGroup.id] || {},
          ...processingGroup,
          currencyBalanceSheetIds: [],
          isFetchingCurrencyBalanceSheets: false,
        };
        return accumulator;
      }, {});
      return {
        ...state,
        isFetching: false,
        error: '',
        processingGroups: processingGroupsMap,
        processingGroupIds: newProcessingGroups.map(pg => pg.id),
        order: action.payload.order,
      };
    }
    case RECEIVE_CURRENCY_BALANCE_SHEETS: {
      const { payload: { processingGroupId, currencyBalanceSheets, paginationMetadata } } = action;
      const currencyBalanceSheetIds = currencyBalanceSheets.map(cbs => cbs.id);
      const pgSheetIds = state.processingGroups[processingGroupId].currencyBalanceSheetIds;
      return {
        ...state,
        processingGroups: {
          ...state.processingGroups,
          [processingGroupId]: {
            ...state.processingGroups[processingGroupId],
            error: '',
            currencyBalanceSheetIds: [...pgSheetIds, ...currencyBalanceSheetIds],
            currencyBalanceSheetsPaginationMetadata: paginationMetadata,
            isFetchingCurrencyBalanceSheets: !!paginationMetadata.nextPage,
          },
        },
      };
    }
    default:
      return state;

  /* eslint-enable no-case-declarations */
  }
}
