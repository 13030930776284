/* eslint-disable */
// Extracted from https://gist.github.com/ghinda/8442a57f22099bdb2e34

function toFormData(obj, form, namespace) {
  const fd = form || new FormData();
  let formKey;

  for (const property in obj) {
    if (obj.hasOwnProperty(property) && (obj[property] !== null && obj[property] !== undefined)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        toFormData(obj[property], fd, formKey);
      } else { // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}

export default toFormData;
/* eslint-enable¡ */
