import PropTypes from 'prop-types';

export const Percentage = ({ percentage, precision }) => Number(percentage).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: precision });

Percentage.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  precision: PropTypes.number,
};

Percentage.defaultProps = {
  precision: 2,
};
