import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { useApi } from '../../../shared/useApi';
import Routes from '../../../routes.js.erb';
import { CurrencyBalanceSheetContainer } from './currencyBalanceSheetContainer';
import { Loading } from '../../../shared/loading';
import { SheetNavigator } from '../components/sheetNavigator';
import { CurrencyBalanceSheetActions } from '../components/currencyBalanceSheetActions';
import { AccountFlagsList } from '../../listing/components/accountFlagsList';
import { accountFlag } from '../../listing/components/commonPropTypes';
import { CurrencyBalanceSheetProps, VALIDATION_STATUSES } from '../commonPropTypes';
import { NextSheetButton, PreviousSheetButton } from '../components/previousAndNextSheetButtons';
import { PrintButton } from '../components/printButton';
import { SETTLEMENT_TOTAL_OPTIONS } from '../components/settlementTotals/settlementTotalsSelector';
import CurrencyBalanceSheetDetailsContainer from './CurrencyBalanceSheetDetailsContainer';

import Client from '../../../client';

const SheetListing = ({
  mostRecentSheet, mostRecentLedgerDate, accountFlags, remainingIds,
}) => {
  const [accountFlagsState, setAccountFlagsState] = useState([]);

  const [isValidated, setValidated] = useState(
    mostRecentSheet.validationStatus === VALIDATION_STATUSES.VALIDATED,
  );
  const [
    selectedSettlementTotalOption,
    setSelectedSettlementTotalOption,
  ] = useState(SETTLEMENT_TOTAL_OPTIONS.EVERYTHING);
  const setAsValidated = useCallback(() => {
    setValidated(true);
  }, [setValidated]);

  useEffect(() => {
    setAccountFlagsState(accountFlags);
  }, [accountFlags]);

  const { previousSheet } = mostRecentSheet;
  const { nextSheet } = mostRecentSheet;

  // TODO: Mirar aca
  const accountScheduledRollSetting = mostRecentSheet.account.accountScheduledRollSettingForDate;
  const { nextAccountScheduledRollSetting } = mostRecentSheet.account;

  const onCreateAccountFlag = async ({
    accountIds, flagId, action, note,
  }) => {
    const response = await Client.createAccountFlag(accountIds, flagId, action, note);
    const json = await response.json();
    const newFlag = json[0];

    const newFlags = accountFlagsState.map((flag) => {
      if (flag.checker === newFlag.checker) {
        return newFlag;
      }

      return flag;
    });

    setAccountFlagsState(newFlags);
  };

  const fetchAccountFlagNotes = async (data) => {
    const response = await Client.listAccountFlags(data);
    const json = await response.json();

    // This is done to map the format used by the store on CBS-listing
    const accountFlagsNotes = {
      [json.flagId]: {
        [json.clientId]: {
          notes: json.notes,
          usingAllClients: json.usingAllClients,
        },
      },
    };

    return accountFlagsNotes;
  };

  return (
    <div className="currency-balance-sheet-container">
      <div className="currency-balance-sheet-header">
        <div className="title">
          { mostRecentSheet.account.name }
          <AccountFlagsList
            accountFlags={accountFlagsState}
            onCreateAccountFlag={onCreateAccountFlag}
            currencyBalanceSheetId={mostRecentSheet.id}
            processingGroupId={mostRecentSheet.processingGroupId}
            clientId={mostRecentSheet.clientId}
            onFetchAccountFlagNotes={fetchAccountFlagNotes}
          />
        </div>
        <div className="options">
          {
            mostRecentLedgerDate === mostRecentSheet.ledgerDate ? (
              <CurrencyBalanceSheetActions
                currencyBalanceSheetId={mostRecentSheet.id}
                accountId={mostRecentSheet.account.id}
                clientId={mostRecentSheet.clientId}
                ledgerDate={mostRecentSheet.ledgerDate}
                isValidated={isValidated}
                onChangeSelectedSettlementTotal={setSelectedSettlementTotalOption}
                selectedSettlementTotalOption={selectedSettlementTotalOption}
                currentAccountScheduledRollSetting={accountScheduledRollSetting}
                nextAccountScheduledRollSetting={nextAccountScheduledRollSetting}
              />
            ) : (
              <React.Fragment>
                <a
                  className="btn btn-primary"
                  href={Routes.account_currency_balance_sheet_path(mostRecentSheet.account.id,
                    mostRecentLedgerDate)}
                >
                  {I18n.t('shared.currency_balance_sheets.show.navigate_to_most_recent')}
                </a>
                <PrintButton
                  ledgerDate={mostRecentSheet.ledgerDate}
                  accountId={mostRecentSheet.account.id}
                />
              </React.Fragment>
            )
          }
        </div>
        <SheetNavigator
          selectedAccountId={mostRecentSheet.account.id}
          selectedProcessingGroupId={mostRecentSheet.processingGroupId}
          selectedClientId={mostRecentSheet.clientId}
          ledgerDate={mostRecentSheet.ledgerDate}
        />
        <div className="clearfix">
          {
            previousSheet && (
              <div className="pull-left">
                <PreviousSheetButton previousSheet={previousSheet} />
              </div>
            )
          }
          {
            nextSheet && (
              <div className="pull-right">
                <NextSheetButton nextSheet={nextSheet} />
              </div>
            )
          }
        </div>
      </div>
      <div className="currency-balance-sheet-row">
        <CurrencyBalanceSheetDetailsContainer
          currencyBalanceSheet={mostRecentSheet}
          onValidate={setAsValidated}
          selectedSettlementTotalOption={selectedSettlementTotalOption}
        />
      </div>
      {
        remainingIds.length !== 0 && (
          <CurrencyBalanceSheetContainer
            ids={remainingIds}
            selectedSettlementTotalOption={selectedSettlementTotalOption}
          />
        )
      }
    </div>
  );
};

SheetListing.propTypes = {
  remainingIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  accountFlags: PropTypes.arrayOf(accountFlag).isRequired,
  mostRecentSheet: PropTypes.shape(CurrencyBalanceSheetProps).isRequired,
  mostRecentLedgerDate: PropTypes.string.isRequired,
};

export const SheetShowContainer = (props) => {
  const { ids, ...otherProps } = props;
  const [mostRecentId, ...remainingIds] = ids;
  const { loading, data } = useApi(Routes.api_v1_currency_balance_sheet_path({ id: mostRecentId, format: 'json' }));
  const mostRecentSheet = data ? data.currencyBalanceSheet : null;

  if (loading || !mostRecentSheet) {
    return (
      <div className="currency-balance-sheet-container">
        <div className="currency-balance-sheet-header">
          <div className="text-center">
            <Loading loading />
          </div>
        </div>
      </div>
    );
  }

  return (
    <SheetListing
      {...otherProps}
      remainingIds={remainingIds}
      mostRecentSheet={mostRecentSheet}
    />
  );
};

SheetShowContainer.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  accountFlags: PropTypes.arrayOf(accountFlag).isRequired,
  mostRecentLedgerDate: PropTypes.string.isRequired,
};
