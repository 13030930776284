import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import { arrayMove } from 'react-sortable-hoc';
import {
  Button, ControlLabel, Form, FormControl, FormGroup,
} from 'react-bootstrap';
import { appendUuid, uuid as generateUuid } from '../../../../utils/uuid';
import { ConfigSelectableFieldPropTypes, DatasetPropTypes, DatasetOptionsPropTypes } from './commonPropTypes';
import { ReportFieldsConfiguration } from './reportFieldsConfiguration';
import { DatasetOptions } from './datasetOptions';
import Client from '../../../../client';

class ReportConfigurationForm extends React.Component {
  static propTypes = {
    edit: PropTypes.bool.isRequired,
    datasets: PropTypes.arrayOf(DatasetPropTypes),
    fileFormat: PropTypes.string,
    headerRow: PropTypes.bool,
    name: PropTypes.string,
    notSelectedFields: PropTypes.arrayOf(
      PropTypes.shape(ConfigSelectableFieldPropTypes),
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
    path: PropTypes.string,
    reportsDatasetId: PropTypes.number,
    scheduleConfiguration: PropTypes.shape({
      time: PropTypes.string.isRequired,
      timeZone: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
    selectedFields: PropTypes.arrayOf(
      PropTypes.shape(ConfigSelectableFieldPropTypes),
    ).isRequired,
    selectedOptions: DatasetOptionsPropTypes,
    timezones: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    reportsDatasetId: null,
    datasets: [],
    name: '',
    fileFormat: '',
    headerRow: false,
    scheduleConfiguration: null,
    path: '',
    selectedOptions: {},
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      ...{
        reportsDatasetId: props.reportsDatasetId,
        name: props.name,
        fileFormat: props.fileFormat,
        headerRow: props.headerRow,
        loadingDataset: false,
        scheduleConfiguration: props.scheduleConfiguration ? props.scheduleConfiguration : {},
        path: props.path || '',
        orderingItemId: null,
        selectedOptions: props.selectedOptions,
      },
      ...this.setSelectedAndNotSelectedFields(props.selectedFields, props.notSelectedFields),
    };
  }

  setSelectedAndNotSelectedFields = (selectedFields, notSelectedFields) => {
    /*
      We have to append a random uuid to the fields because there is no easy way
      to generate the key from the given fields. The obvious approach would be grabbing the
      selectableField id, accessor and name, but these can be duplicated several times so we cannot
      guarantee it's uniqueness.
     */
    const newSelectedFields = appendUuid(selectedFields);
    const newNotSelectedFields = appendUuid(notSelectedFields);
    const fields = {};
    newSelectedFields.forEach((field) => { fields[field.uuid] = field; });
    newNotSelectedFields.forEach((field) => { fields[field.uuid] = field; });
    return {
      fields,
      selectedFields: newSelectedFields.map(field => field.uuid),
      notSelectedFields: newNotSelectedFields.map(field => field.uuid),
    };
  };

  addSelectableField = (index, event) => {
    event.preventDefault();
    const { selectedFields, notSelectedFields } = this.state;
    const newSelectedFields = _.clone(notSelectedFields);
    const fieldToAdd = _.pullAt(newSelectedFields, index);
    this.setState({
      selectedFields: [...selectedFields, ...fieldToAdd],
      notSelectedFields: newSelectedFields,
    });
  };

  removeSelectableField = (index, event) => {
    event.preventDefault();
    const { selectedFields, notSelectedFields } = this.state;
    const newSelectedFields = _.clone(selectedFields);
    const fieldToAdd = _.pullAt(newSelectedFields, index);
    this.setState({
      selectedFields: newSelectedFields,
      notSelectedFields: [...notSelectedFields, ...fieldToAdd],
    });
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const { selectedFields } = this.state;
    this.setState({
      selectedFields: arrayMove(selectedFields, oldIndex, newIndex),
      orderingItemId: null,
    });
  };

  handleUpdateBeforeSortStart = ({ index }) => {
    this.setState({
      orderingItemId: index,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      selectedFields, fields, reportsDatasetId, fileFormat, headerRow,
      name, template, scheduleConfiguration, path, selectedOptions,
    } = this.state;
    const { onSubmit } = this.props;
    const configFields = selectedFields.map((uuid, index) => {
      const selectedField = fields[uuid];
      if (selectedField.type === 'Reports::ConfigSelectableField') {
        const defaultFormatId = selectedField
          .selectableField
          .reportsSelectableFieldDataTypeFormatId;
        const formatId = selectedField.reportsSelectableFieldDataTypeFormatId;
        return {
          type: selectedField.type,
          field_name_override: selectedField.fieldNameOverride,
          display_order: index,
          reports_selectable_field_id: selectedField.selectableField.id,
          reports_selectable_field_data_type_format_id: formatId || defaultFormatId,
        };
      }
      return {
        type: selectedField.type,
        field_name_override: selectedField.fieldNameOverride,
        display_order: index,
        value: selectedField.value,
      };
    });
    const payload = {
      config_fields_attributes: configFields,
      reports_dataset_id: reportsDatasetId,
      file_format: fileFormat,
      header_row: headerRow,
      name,
      path,
      ...selectedOptions,
    };
    if (template) {
      payload.template = template;
    }
    if (!!scheduleConfiguration.time && !!scheduleConfiguration.timeZone) {
      payload.config_schedule_attributes = {
        time: scheduleConfiguration.time,
        time_zone: scheduleConfiguration.timeZone,
        id: scheduleConfiguration.id,
      };
    } else if (scheduleConfiguration.id) {
      payload.config_schedule_attributes = {
        _destroy: true,
        id: scheduleConfiguration.id,
      };
    }
    onSubmit({
      reports_config: payload,
    });
  };

  handleChangeSelectableFieldChange = (uuid, changes) => {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [uuid]: {
          ...fields[uuid],
          ...changes,
        },
      },
    });
  };

  handleFieldChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleReportDatasetIdChange = (event) => {
    const datasetId = event.target.value === '' ? '' : Number(event.target.value);
    this.setState({
      fields: {},
      selectedFields: [],
      notSelectedFields: [],
      reportsDatasetId: datasetId,
      selectedOptions: {},
    });
    if (!_.isNumber(datasetId)) return;
    this.setState({
      selectedOptions: this.selectedDataset(datasetId).options,
      loadingDataset: true,
    });
    Client
      .getReportDataset(datasetId)
      .then(response => response.json())
      .then((data) => {
        const selectableFields = data.selectableFields.map((selectableField) => {
          const { reportsSelectableFieldDataTypeFormatId } = selectableField;
          return {
            type: 'Reports::ConfigSelectableField',
            selectableField,
            reportsSelectableFieldDataTypeFormatId,
          };
        });
        this.setState(this.setSelectedAndNotSelectedFields([], selectableFields));
        this.setState({ loadingDataset: false });
      });
  };

  handleFileFormatChange = (event) => {
    this.handleFieldChange('fileFormat', event.target.value);
  };

  handleHeaderRowChange = (event) => {
    this.handleFieldChange('headerRow', event.target.checked);
  };

  handleNameChange = (event) => {
    this.handleFieldChange('name', event.target.value);
  };

  handlePathChange = (event) => {
    this.handleFieldChange('path', event.target.value);
  };

  handleTimeChange = (event) => {
    const { target: { value } } = event;
    this.setState(({ scheduleConfiguration }) => ({
      scheduleConfiguration: {
        ...scheduleConfiguration,
        time: value,
      },
    }));
  };

  handleTimeZoneChange = (event) => {
    const { target: { value } } = event;
    this.setState(({ scheduleConfiguration }) => ({
      scheduleConfiguration: {
        ...scheduleConfiguration,
        timeZone: value,
      },
    }));
  };

  handleTemplateChange = (event) => {
    this.setState({
      template: event.target.files[0],
    });
  };

  handleAddFixedField = () => {
    const newUuid = generateUuid();
    const newFixedField = {
      uuid: newUuid,
      type: 'Reports::ConfigFixedField',
      fieldNameOverride: '',
    };
    const { fields, selectedFields } = this.state;
    const newFields = fields;
    newFields[newUuid] = newFixedField;
    this.setState({
      fields: newFields,
      selectedFields: _.concat(selectedFields, newUuid),
    });
  };

  handleRemoveFixedField = (uuid, event) => {
    event.preventDefault();
    const { selectedFields, fields } = this.state;
    const newSelectedFields = _.clone(selectedFields);
    _.remove(newSelectedFields, fieldUuid => fieldUuid === uuid);
    this.setState({
      fields: _.omit(fields, uuid),
      selectedFields: newSelectedFields,
    });
  };

  handleAddFormulaField = () => {
    const newUuid = generateUuid();
    const newFormulaField = {
      uuid: newUuid,
      type: 'Reports::ConfigFormulaField',
      fieldNameOverride: '',
    };
    const { fields, selectedFields } = this.state;
    const newFields = fields;
    newFields[newUuid] = newFormulaField;
    this.setState({
      fields: newFields,
      selectedFields: _.concat(selectedFields, newUuid),
    });
  };

  handleRemoveFormulaField = (uuid, event) => {
    event.preventDefault();
    const { selectedFields, fields } = this.state;
    const newSelectedFields = _.clone(selectedFields);
    _.remove(newSelectedFields, fieldUuid => fieldUuid === uuid);
    this.setState({
      fields: _.omit(fields, uuid),
      selectedFields: newSelectedFields,
    });
  };

  handleOptionsChange = (key, value) => {
    this.setState(prevState => ({
      selectedOptions: {
        ...prevState.selectedOptions,
        [key]: value,
      },
    }));
  };

  saveButton = () => (
    <Button bsStyle="primary" type="submit">
      {I18n.t('users_portal.save')}
    </Button>
  );

  selectedDataset = (reportsDatasetId) => {
    const { datasets } = this.props;
    return datasets.filter(d => d.id === reportsDatasetId)[0];
  };

  renderFieldsConfiguration() {
    const {
      loadingDataset, reportsDatasetId, fields, selectedFields, notSelectedFields, orderingItemId,
    } = this.state;
    if (loadingDataset) {
      return I18n.t('loading');
    }
    if (!_.isNumber(reportsDatasetId)) {
      return null;
    }
    return (
      <ReportFieldsConfiguration
        fields={fields}
        selectedFields={selectedFields}
        notSelectedFields={notSelectedFields}
        onAddField={this.addSelectableField}
        onRemoveField={this.removeSelectableField}
        onSortEnd={this.handleSortEnd}
        updateBeforeSortStart={this.handleUpdateBeforeSortStart}
        onChange={this.handleChangeSelectableFieldChange}
        onAddFixedField={this.handleAddFixedField}
        onRemoveFixedField={this.handleRemoveFixedField}
        onAddFormulaField={this.handleAddFormulaField}
        onRemoveFormulaField={this.handleRemoveFormulaField}
        orderingItemId={orderingItemId}
      >
        <div className="col-sm-12 text-right">{ this.saveButton() }</div>
      </ReportFieldsConfiguration>
    );
  }

  renderSchedules = (selectedTime, selectedTimeZone, timezones) => (
    <React.Fragment>
      <FormGroup controlId="reports_schedule" className="col-sm-2">
        <ControlLabel>{I18n.t('activerecord.attributes.reports/config_schedule.time')}</ControlLabel>
        <FormControl
          className="form-field-in"
          type="time"
          value={selectedTime}
          onChange={this.handleTimeChange}
        />
      </FormGroup>
      <FormGroup className="col-sm-2">
        <ControlLabel>{I18n.t('activerecord.attributes.reports/config_schedule.time_zone')}</ControlLabel>
        <FormControl
          className="form-field-in"
          componentClass="select"
          value={selectedTimeZone}
          onChange={this.handleTimeZoneChange}
        >
          <option value="" />
          {
            timezones.map(
              timeZone => (
                <option
                  key={timeZone}
                  value={timeZone}
                >
                  {timeZone}
                </option>
              ),
            )
          }
        </FormControl>
      </FormGroup>
    </React.Fragment>
  );

  render() {
    const { edit, datasets, timezones } = this.props;
    const {
      reportsDatasetId, loadingDataset, name, fileFormat, headerRow, scheduleConfiguration,
      path, selectedOptions, template,
    } = this.state;
    const selectedDataset = this.selectedDataset(reportsDatasetId);
    const isPerformanceDataset = selectedDataset ? selectedDataset.performance : false;
    const { time, timeZone } = scheduleConfiguration;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup controlId="reports_config_reports_dataset_id" className="col-sm-4">
          <ControlLabel>{I18n.t('activerecord.attributes.reports/config.dataset')}</ControlLabel>
          <FormControl
            className="form-field-in"
            componentClass="select"
            value={reportsDatasetId}
            onChange={this.handleReportDatasetIdChange}
            disabled={edit || loadingDataset}
            required
          >
            <option value="" />
            {
              datasets.map(dataset => (
                <option key={dataset.id} value={dataset.id}>{dataset.name}</option>
              ))
            }
          </FormControl>
        </FormGroup>
        <FormGroup controlId="reports_config_name" className="col-sm-4">
          <ControlLabel>{I18n.t('activerecord.attributes.reports/config.name')}</ControlLabel>
          <FormControl className="form-field-in" type="text" value={name} onChange={this.handleNameChange} />
        </FormGroup>
        <FormGroup controlId="reports_path" className="col-sm-4">
          <ControlLabel>{I18n.t('activerecord.attributes.reports/config.path')}</ControlLabel>
          <FormControl className="form-field-in" type="text" value={path} onChange={this.handlePathChange} />
        </FormGroup>
        { this.renderSchedules(time, timeZone, timezones) }
        {
          !isPerformanceDataset ? (
            <React.Fragment>
              <FormGroup controlId="reports_config_file_format" className="col-sm-4">
                <ControlLabel>{I18n.t('activerecord.attributes.reports/config.file_format')}</ControlLabel>
                <FormControl
                  className="form-field-in"
                  componentClass="select"
                  value={fileFormat}
                  onChange={this.handleFileFormatChange}
                  disabled={edit}
                  required
                >
                  <option value="" />
                  <option value="csv">{I18n.t('activerecord.attributes.reports/config.file_formats.csv')}</option>
                  <option value="xlsx">{I18n.t('activerecord.attributes.reports/config.file_formats.xlsx')}</option>
                </FormControl>
              </FormGroup>
              <FormGroup controlId="reports_config_template" className="col-sm-2">
                <ControlLabel>{I18n.t('activerecord.attributes.reports/config.template')}</ControlLabel>
                <input id="file" className="input-file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" onChange={this.handleTemplateChange} />
                <label htmlFor="file" className="input-file">
                  {(template && template.name) || I18n.t('users_portal.reports_configs.form.choose_file')}
                </label>
              </FormGroup>
              <div className="col-sm-2 checkbox-container">
                <label className="reports-config-label control control--checkbox" htmlFor="header-row">
                  {I18n.t('activerecord.attributes.reports/config.header_row') }
                  <input type="checkbox" checked={headerRow} id="header-row" className="reports-config-checkbox" onChange={this.handleHeaderRowChange} />
                  <span className="control__indicator" />
                </label>
              </div>
              {
                selectedDataset && (
                  <DatasetOptions
                    onOptionsChanged={this.handleOptionsChange}
                    selectedOptions={selectedOptions}
                  />
                )
              }
              { this.renderFieldsConfiguration() }
            </React.Fragment>
          ) : (
            <div className="col-sm-12 text-right">{ this.saveButton() }</div>
          )
        }
      </Form>
    );
  }
}

export { ReportConfigurationForm };
