import PropTypes from 'prop-types';

export const statusPropType = PropTypes.oneOf([
  'processing',
  'processing_data',
  'generating_data',
  'ignoring',
  'approving',
  'pending_weight',
  'no_data',
  'normalizing',
  'processed',
  'ignored',
  'generated_data',
  'normalization_ok',
  'data_generated',
  'parsing_done',
  'empty_file',
  'not_valid',
  'aborted',
  'aborted_data_generation',
  'aborted_processing',
  'normalization_failed',
  'weight_review_required',
  'unprocessed',
  'duplicated',
  'missing_currency',
]);
