const categoryBadge = (category) => {
  const categoriesMap = {
    positive: 'badge-success category-badge-success',
    negative: 'badge-danger category-badge-error',
    warning: 'badge-warning category-badge-warning',
    informational: 'badge-info category-badge-info',
    neutral: 'badge-default category-badge-default',
  };
  return categoriesMap[category];
};

const actionClasses = {
  added: 'status-tag-added',
  reviewed: 'status-tag-reviewed',
  removed: 'status-tag-removed',
  removal_requested: 'status-tag-reviewed status-tag-removal-requested',
};

export const flagClassName = ({ category, action }) => `badge category-badge ${categoryBadge(category.toLowerCase())} ${actionClasses[action]}`;
