import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { ConfigSelectableFieldPropTypes } from './commonPropTypes';
import { RemoveSelectableFieldButton } from './removeSelectableFieldButton';
import { AddSelectableFieldButton } from './addSelectableFieldButton';

class ReportConfigSelectableField extends React.PureComponent {
  static propTypes = {
    ...ConfigSelectableFieldPropTypes,
    ...{
      fieldIndex: PropTypes.number,
      onAdd: PropTypes.func,
      onChange: PropTypes.func,
      onRemove: PropTypes.func,
      isMoving: PropTypes.bool,
    },
  };

  handleFieldNameOverrideChange = (event) => {
    const { currentTarget: { value: fieldNameOverride } } = event;
    const { onChange, uuid } = this.props;
    onChange(uuid, { fieldNameOverride });
  };

  handleReportsSelectableFieldDataTypeFormatId = (event) => {
    const { currentTarget: { value } } = event;
    const reportsSelectableFieldDataTypeFormatId = Number(value);
    const { onChange, uuid } = this.props;
    onChange(uuid, { reportsSelectableFieldDataTypeFormatId });
  };

  handleRemove = (event) => {
    const { onRemove, fieldIndex } = this.props;
    onRemove(fieldIndex, event);
  };

  handleAdd = (event) => {
    const { onAdd, fieldIndex } = this.props;
    onAdd(fieldIndex, event);
  };

  render() {
    const {
      fieldNameOverride, reportsSelectableFieldDataTypeFormatId,
      selectableField: {
        id, model, accessor, displayName, availableFormats,
      },
      disabled, onRemove, onAdd, isMoving,
    } = this.props;

    return (
      <tr data-selectable-field-id={id} className={`dragndrop-row ${(isMoving) ? 'highlighted-row' : ''}`}>
        <td className="model-column">
          { model }
          {' '}
          <br />
          /
          { accessor }
        </td>
        <td className="display-name-column">
          { displayName }
        </td>
        <td className="field-name-column">
          <FormControl
            type="text"
            defaultValue={fieldNameOverride}
            disabled={disabled}
            onChange={this.handleFieldNameOverrideChange}
            id="field_name_override"
            className="form-field-in"
          />
        </td>
        <td className="data-type-column">
          <FormControl
            componentClass="select"
            defaultValue={reportsSelectableFieldDataTypeFormatId}
            disabled={disabled}
            onChange={this.handleReportsSelectableFieldDataTypeFormatId}
            id="reports_selectable_field_data_type_format_id"
            className="form-field-in"
          >
            {
              availableFormats.map(availableFormat => (
                <option
                  key={availableFormat.id}
                  value={availableFormat.id}
                >
                  {availableFormat.name}
                </option>
              ))
            }
          </FormControl>
        </td>
        <td className="actions-icons">
          { onRemove && <RemoveSelectableFieldButton onClick={this.handleRemove} />}
          { onAdd && <AddSelectableFieldButton onClick={this.handleAdd} /> }
        </td>
      </tr>
    );
  }
}

export default ReportConfigSelectableField;
