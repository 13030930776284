import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  FormControl,
  Button,
  HelpBlock,
} from 'react-bootstrap';

import I18n from 'i18n-js';
import { WrappedReactSelect } from '../../../shared/wrappedReactSelect';

const getNotesOptions = notes => (
  notes.map((note, index) => ({
    value: index,
    label: note,
  }))
);

const getSelectOptions = notes => ([
  {
    label: I18n.t('currency_balance_sheets.account_flag_notes.common_notes'),
    options: getNotesOptions(notes.commonNotes),
  },
  {
    label: I18n.t('currency_balance_sheets.account_flag_notes.recent_notes'),
    options: getNotesOptions(notes.recentNotes),
  },
]);

const initialState = {
  manualNote: '',
  select_note: { options: [], selected: '' },
  few_notes: false,
  loading: true,
};

export const RemovalModal = ({
  accountFlagsNotes: accountFlagsNotesParams,
  flag, clientId, onFetchAccountFlagNotes,
  visible, setVisible, onSubmit,
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const fetchData = async () => {
      if (!flag.flagId || !clientId) {
        setState({
          ...state,
          loading: false,
          select_note: {
            ...state.select_note,
            options: [{
              label: I18n.t('currency_balance_sheets.account_flag_notes.error_on_data'),
              value: 0,
            }],
          },
        });
        return;
      }

      const data = await onFetchAccountFlagNotes({ flagId: flag.flagId, clientId });

      if (data) {
        const noteData = data && data[flag.flagId]
          && data[flag.flagId][clientId] && data[flag.flagId][clientId];

        setState({
          ...state,
          select_note: { ...state.select_note, options: getSelectOptions(noteData.notes) },
          few_notes: noteData.usingAllClients,
          loading: false,
        });
      }
    };

    // eslint-disable-next-line no-unused-expressions
    visible && fetchData().catch(() => setState({
      ...state,
      loading: false,
      select_note: {
        ...state.select_note,
        options: [{
          label: I18n.t('currency_balance_sheets.account_flag_notes.error_on_fetch'),
          value: 0,
        }],
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (accountFlagsNotesParams) {
      const noteData = accountFlagsNotesParams && accountFlagsNotesParams[flag.flagId]
        && accountFlagsNotesParams[flag.flagId][clientId]
        && accountFlagsNotesParams[flag.flagId][clientId];

      if (!noteData) {
        return;
      }

      setState({
        ...state,
        select_note: { ...state.select_note, options: getSelectOptions(noteData.notes) },
        few_notes: noteData.usingAllClients,
        loading: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountFlagsNotesParams]);

  const handleSubmit = async (event) => {
    const action = flag.category === 'negative' ? 'removal_requested' : 'removed';
    const note = state.manualNote ? state.manualNote : state.select_note.label;

    onSubmit(event, action, note);
  };

  return (
    <Modal show={visible} onHide={() => { setVisible(false); }}>
      <Modal.Header>
        <Modal.Title>
          Request flag removal -
          {' '}
          {flag.displayName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{I18n.t('currency_balance_sheets.account_flag_notes.title')}</h4>
        <h5>{I18n.t('currency_balance_sheets.account_flag_notes.subtitle')}</h5>
        <WrappedReactSelect
          placeholder={I18n.t('currency_balance_sheets.account_flag_notes.select_a_note')}
          options={state.select_note.options}
          value={state.select_note.selected}
          onChange={(selected) => {
            setState({
              ...state,
              select_note: { options: state.select_note.options, ...selected },
            });
          }}
          isDisabled={Boolean(state.manualNote)}
          isLoading={state.loading}
          loadingMessage={() => I18n.t('loading')}
        />
        { state.few_notes
          && (
          <HelpBlock className="pull-right text-danger" style={{ maxWidth: '60%', textAlign: 'right' }}>
            {I18n.t('currency_balance_sheets.account_flag_notes.few_notes')}
          </HelpBlock>
          )
        }
        <FormControl
          className="m-t-sm"
          componentClass="textarea"
          placeholder={I18n.t('currency_balance_sheets.account_flag_notes.manual_note')}
          style={{ height: 100 }}
          value={state.manualNote}
          onChange={(e) => { setState({ ...state, manualNote: e.target.value }); }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { setVisible(false); }}>Cancel</Button>
        <Button
          bsStyle="primary"
          onClick={handleSubmit}
          disabled={!state.manualNote && !state.select_note.label}
        >
            Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemovalModal.propTypes = {
  clientId: PropTypes.number.isRequired,
  flag: PropTypes.shape({
    flagId: PropTypes.number.isRequired,
    displayName: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
  onFetchAccountFlagNotes: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  accountFlagsNotes: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

RemovalModal.defaultProps = {
  visible: false,
  accountFlagsNotes: {},
};
