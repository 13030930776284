import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { FormattedPercentage } from './formattedPercentage';
import { RelativeWeightInfoPopup } from './relativeWeightInfoPopup';
import { hedgeLineItemPropType } from '../../commonPropTypes';

export const LineItemWeight = React.memo(({ weight }) => {
  if (!weight) { return '-'; }

  return (
    weight.isRelative ? (
      <OverlayTrigger overlay={RelativeWeightInfoPopup({ weight })} placement="top">
        <span style={{ cursor: 'pointer' }}>
          { FormattedPercentage({ percentage: weight.value }) }
        </span>
      </OverlayTrigger>
    ) : (
      FormattedPercentage({ percentage: weight.value })
    )
  );
});

LineItemWeight.propTypes = {
  weight: hedgeLineItemPropType.weight,
};

LineItemWeight.defaultProps = {
  weight: null,
};
