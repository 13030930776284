import React from 'react';
import PropTypes from 'prop-types';
import { WrappedReactSelect } from '../wrappedReactSelect';

export const SelectFilter = (props) => {
  const {
    id, name, nullOption, selectedOption, options, onChange,
  } = props;

  if (nullOption) {
    options.unshift({ label: nullOption, value: '' });
  }

  const value = options?.find(option => option.value === selectedOption);

  return (
    <WrappedReactSelect
      options={options}
      name={name}
      id={id}
      placeholder={nullOption}
      value={value}
      onChange={option => onChange(option ? option.value : null)}
    />
  );
};

SelectFilter.defaultProps = {
  selectedOption: null,
  nullOption: null,
};

SelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nullOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
