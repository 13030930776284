import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

export const AccountDifferentCalendarSettingsModal = ({ onHide, show, accounts }) => (
  <Modal show={show} onHide={onHide} id="rollCalendarErrorModal">
    <Modal.Header closeButton>
      <Modal.Title>Account settings mismatch</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h2>
          The following accounts have different settings for using a roll/settlement
          calendar or just a single next roll date:
      </h2>
      <br />

      <div className="calendar-accounts">
        <h4>Calendar:</h4>
        <ul>
          { accounts.filter(account => account.scheduledRollDateCalendar).map(account => (
            <li key={account.id}>
#
              {account.id}
:
              {account.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="single-date-accounts">
        <h4>Single Next Roll Date:</h4>
        <ul>
          { accounts.filter(account => !account.scheduledRollDateCalendar).map(account => (
            <li key={account.id}>
#
              {account.id}
:
              {account.name}
            </li>
          ))}
        </ul>
      </div>
      <br />
      <p>
          Please fix them before atempting rolling
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="default" onClick={onHide}>
        {I18n.t('close')}
      </Button>
    </Modal.Footer>
  </Modal>
);

AccountDifferentCalendarSettingsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
