import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { buildReduxStore } from '../../../shared/buildReduxStore';
import accountFlagsNotes from '../reducers/accountFlagsNotes';
import currencyBalanceSheets from '../reducers/currencyBalanceSheets';
import processingGroups from '../reducers/processingGroups';
import selectedSheets from '../reducers/select';
import validating from '../reducers/validating';
import processingGroupSorting from '../reducers/processingGroupSorting';
import rebalancing from '../reducers/rebalancing';
import { CurrencyBalanceSheetsContainer } from './currencyBalanceSheetsTableContainer';
import { FiltersContainer } from './filtersContainer';

const rootReducer = combineReducers({
  currencyBalanceSheets,
  processingGroups,
  selectedSheets,
  validating,
  rebalancing,
  processingGroupSorting,
  accountFlagsNotes,
});

const store = buildReduxStore({ rootReducer });

const CurrencyBalanceSheetsListingContainer = props => (
  <Provider store={store}>
    <FiltersContainer />
    <CurrencyBalanceSheetsContainer {...props} />
  </Provider>
);

export { CurrencyBalanceSheetsListingContainer };
