import React from 'react';
import Select from 'react-select';

export const WrappedReactSelect = props => (
  <Select
    classNamePrefix="react-select"
    styles={{
      menu: provided => ({
        ...provided,
        zIndex: 999,
      }),
    }}
    {...props}
  />
);
