import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Routes from '../../../routes.js.erb';

export const PrintButton = ({ accountId, ledgerDate }) => (
  <a className="btn btn-primary" href={Routes.print_account_currency_balance_sheet_path(accountId, ledgerDate)}>
    {I18n.t('currency_balance_sheets.show.print_button')}
  </a>
);

PrintButton.propTypes = {
  accountId: PropTypes.number.isRequired,
  ledgerDate: PropTypes.string.isRequired,
};
