/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SortedField } from './sortedField';

export class SortedProcessingGroupField extends React.PureComponent {
  handleClick = ({ resource, field, direction }) => {
    const { onClick, currentFilters } = this.props;
    onClick(currentFilters, { resource, field, direction });
  };

  render = () => <SortedField {...this.props} onClick={this.handleClick} />
}

SortedProcessingGroupField.propTypes = {
  currentFilters: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
