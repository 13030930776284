import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'react-bootstrap';
import I18n from 'i18n-js';
import Datetime from 'react-datetime';
import momentPropTypes from 'react-moment-proptypes';
import { WrappedReactSelect } from '../../../shared/wrappedReactSelect';

export const Filters = ({
  models, onChange, onSubmit, selectedModel, selectedEvaluatedAt,
}) => {
  const modelOptions = models.map(model => ({
    value: model,
    label: model,
  }));
  modelOptions.unshift({ value: null, label: 'All' });

  return (
    <div className="row">
      <Col sm={6}>
        <FormGroup>
          <WrappedReactSelect
            placeholder={I18n.t('rosy.dashboard.index.filters.placeholders.model')}
            options={modelOptions}
            value={selectedModel ? { value: selectedModel, label: selectedModel } : null}
            onChange={option => (option
              ? onChange({ model: option.value })
              : onChange({ model: null }))}
          />
        </FormGroup>
      </Col>
      <Col sm={6}>
        <FormGroup>
          <Datetime
            closeOnSelect
            dateFormat="YYYY-MM-DD"
            onChange={evaluatedAt => onChange({ evaluatedAt })}
            timeFormat={false}
            value={selectedEvaluatedAt}
            viewDate={selectedEvaluatedAt}
          />
        </FormGroup>
      </Col>
      <Col sm={12}>
        <button className="btn btn-secondary" type="submit" onClick={onSubmit}>{I18n.t('search')}</button>
      </Col>
    </div>
  );
};

Filters.propTypes = {
  models: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedModel: PropTypes.string,
  selectedEvaluatedAt: momentPropTypes.momentObj.isRequired,
};

Filters.defaultProps = {
  selectedModel: null,
};
