import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';

const ActionsSelect = ({
  id, action, name, onChange,
}) => (
  <select
    className="form-control m-b"
    required
    id={id}
    name={name}
    value={action}
    onChange={onChange}
  >
    <option value="" />
    <option value="inflow">
      {I18n.t('activerecord.attributes.capstock_settlement_date_exceptions.actions.inflow')}
    </option>
    <option value="outflow">
      {I18n.t('activerecord.attributes.capstock_settlement_date_exceptions.actions.outflow')}
    </option>
    <option value="inflow_outflow">
      {I18n.t('activerecord.attributes.capstock_settlement_date_exceptions.actions.inflow_outflow')}
    </option>
  </select>
);

ActionsSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { ActionsSelect };
