import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './NewRawDataFilesTable.scss';
import Client from '../../client';
import { RawDataFileTableRow } from './RawDataFileTableRow';

const POLL_NEW_FILES_MILLISECONDS = 10 * 1000;

class NewRawDataFilesTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { newRawDataFiles: {} };
    this.now = moment().format();
    this.newRawDataFilesCall = this.newRawDataFilesCall.bind(this);
    this.setNewTimestamp = this.setNewTimestamp.bind(this);
  }

  componentDidMount() {
    setInterval(this.newRawDataFilesCall, POLL_NEW_FILES_MILLISECONDS);
  }

  setNewTimestamp(newRawDataFiles) {
    if (_.isEmpty(newRawDataFiles)) return;
    this.now = _.chain(newRawDataFiles)
      .map(file => file.createdAt)
      .max()
      .value();
  }

  newRawDataFilesCall() {
    Client.getRawDataFiles(this.now)
      .then(response => response.json())
      .then((data) => {
        this.setState((oldState) => {
          const newRawDataFiles = data.rawDataFiles;
          this.setNewTimestamp(newRawDataFiles);
          const newFilesMap = {};
          _.each(newRawDataFiles, (file) => {
            newFilesMap[file.id] = file;
          });
          return {
            newRawDataFiles: {
              ...oldState.newRawDataFiles,
              ...newFilesMap,
            },
          };
        });
      });
  }

  files() {
    const { newRawDataFiles } = this.state;
    const filesOrderedByMostRecent = _.chain(newRawDataFiles)
      .values()
      .orderBy('createdAt', 'desc')
      .value();
    return filesOrderedByMostRecent;
  }

  render() {
    const files = this.files();
    if (_.isEmpty(files)) return null;
    const { filterParams } = this.props;
    return (
      [
        <h3 key="header">{I18n.t('users_portal.raw_data_files.updated_files_table')}</h3>,
        <table key="table" className="table table-condensed">
          <thead>
            <tr>
              <th className="order-header">{I18n.t('activerecord.attributes.raw_data_files/base.id')}</th>
              <th className="order-header">{I18n.t('activerecord.attributes.raw_data_files/base.file_file_name')}</th>
              <th className="order-header">{I18n.t('activerecord.attributes.raw_data_files/base.raw_account_data_map_set')}</th>
              <th className="order-header">{I18n.t('activerecord.attributes.raw_data_files/base.published_date')}</th>
              <th className="order-header">{I18n.t('activerecord.attributes.raw_data_files/base.created_at')}</th>
              <th className="order-header">{I18n.t('activerecord.attributes.raw_data_files/base.status')}</th>
              <th className="order-header">{I18n.t('activerecord.attributes.raw_data_files/base.origin')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <TransitionGroup component={null} appear exit={false}>
              {
              files.map(newRawDataFile => (
                /* The dynamic key is required so the component animates when the file row is
                re-rendered because of an update */
                <CSSTransition
                  key={`${newRawDataFile.id}-${newRawDataFile.status}`}
                  timeout={1000}
                  classNames="updated-files-table-row"
                >
                  <RawDataFileTableRow
                    filterParams={filterParams}
                    {...newRawDataFile}
                  />
                </CSSTransition>
              ))
            }
            </TransitionGroup>
          </tbody>
        </table>,
      ]
    );
  }
}

NewRawDataFilesTable.propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types */
  filterParams: PropTypes.object,
};

NewRawDataFilesTable.defaultProps = {
  filterParams: {},
};


export { NewRawDataFilesTable };
