import connect from 'react-redux/es/connect/connect';

import { SortedAccountField } from '../../components/account/sortedField';

const generateSortedAccountFieldContainer = ({ currentField, displayKey, options }) => {
  const mapStateToProps = (state) => {
    const { filters } = state.processingGroups;

    return {
      field: currentField,
      currentDirection: null,
      displayKey,
      ledgerDate: filters.ledgerDate,
      options,
    };
  };

  return connect(mapStateToProps)(SortedAccountField);
};


const SortedAccountIdFieldContainer = generateSortedAccountFieldContainer({
  currentField: 'account_id',
  displayKey: 'activerecord.attributes.account.id',
  options: { width: '5%' },
});


const SortedAccountNameFieldContainer = generateSortedAccountFieldContainer({
  currentField: 'account_name',
  displayKey: 'activerecord.attributes.account.account_name',
  options: { width: '20%' },
});

export {
  SortedAccountNameFieldContainer,
  SortedAccountIdFieldContainer,
};
