import React, { useEffect } from 'react';

import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { createCableCarRoute } from 'redux-cablecar';
import { buildReduxStore } from '../../shared/buildReduxStore';

import { NotificationsCounterContainer } from './NotificationsCounterContainer';
import { NotificationsBellContainer } from './NotificationsBellContainer';
import notifications from '../reducers/notifications';

const rootReducer = combineReducers({ notifications });

const store = buildReduxStore({ rootReducer, useCablecar: true });

const options = {
  params: {
    user_id: window.currentUser.id,
  },
};

const NotificationsContainer = () => {
  useEffect(() => {
    const cablecar = createCableCarRoute();
    cablecar.connect(store, 'NotificationsChannel', options);
  }, []);

  return (
    <Provider store={store}>
      <div className="notifications-box">
        <NotificationsBellContainer />
        <NotificationsCounterContainer />
      </div>
    </Provider>
  );
};

export default NotificationsContainer;
