import React, { useEffect } from 'react';
import I18n from 'i18n-js';
import { MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { useApi } from '../../../shared/useApi';
import Routes from '../../../routes.js.erb';
import Client from '../../../client';

export const BulkProfitAndLossButton = (props) => {
  const { processingGroupId, dropdownOpen } = props;

  const { canCreate: isAuthorized } = useAuthorizedResource([
    { modelClass: 'BulkProfitAndLosses', modelAction: 'create' },
  ], dropdownOpen);

  const {
    loading: loadingBulkCreation,
    data: bulkableResponse,
    callApi: fetchCanBulkApi,
  } = useApi(Routes.bulkable_profit_and_loss_api_v1_processing_group_path(
    { format: 'json', id: processingGroupId },
  ), { method: 'GET', fireOnMount: false });

  useEffect(() => {
    if (isAuthorized && dropdownOpen) {
      fetchCanBulkApi();
    }
  }, [isAuthorized, dropdownOpen, fetchCanBulkApi]);

  const handleProfitAndLossConversion = (e) => {
    e.preventDefault();
    Client
      .bulkProfitAndLossConversion(processingGroupId)
      .then(() => {
        alert(I18n.t('users_portal.processing_groups.bulk_profit_and_loss.success'));
        fetchCanBulkApi();
      });
  };

  if (!isAuthorized) {
    return null;
  }

  const canBulk = bulkableResponse && bulkableResponse.bulkable;
  const disabled = !canBulk || loadingBulkCreation;

  return (
    <MenuItem eventKey="bulkPL" onClick={handleProfitAndLossConversion} disabled={disabled}>
      {I18n.t('currency_balance_sheets.index.bulk_profit_and_loss_all')}
    </MenuItem>
  );
};

BulkProfitAndLossButton.propTypes = {
  processingGroupId: PropTypes.number.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
};
