import { connect } from 'react-redux';

import { ProcessingGroupTable } from '../../components/processingGroup/table';

const mapStateToProps = state => ({
  loading: state.processingGroups.loading,
  error: state.processingGroups.error,
  processingGroupIds: state.processingGroups.data.processingGroupIds,
});

export const ProcessingGroupTableContainer = connect(mapStateToProps)(ProcessingGroupTable);
