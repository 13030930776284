import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Paginator from '../Paginator';
import { LocalCurrencyItem } from './Item';

const LocalCurrenciesList = (props) => {
  const {
    items,
    onRemove,
    totalPages,
    currentPage,
    onPageChange,
  } = props;

  return (
    <>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th>id</th>
            <th>{I18n.t('activerecord.attributes.execution_local_currency.currency')}</th>
            <th>{I18n.t('activerecord.attributes.execution_local_currency.created_at')}</th>
            <th>{I18n.t('activerecord.attributes.execution_local_currency.updated_at')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map(item => <LocalCurrencyItem key={item.id} {...item} onRemove={onRemove} />)}
        </tbody>
      </Table>

      <div style={{ textAlign: 'center' }}>
        <Paginator
          totalPages={totalPages}
          currentPage={currentPage}
          onChange={onPageChange}
        />
      </div>
    </>
  );
};

LocalCurrenciesList.defaultProps = {
  items: [],
};

LocalCurrenciesList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isoCode: PropTypes.string.isRequired,
      }),
    }),
  ),
  onRemove: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export { LocalCurrenciesList };
