/* eslint-disable max-len */
import Client from '../../../client';

const REQUEST_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS = 'REQUEST_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS';
const RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS = 'RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS';
const RECEIVE_SEND_ORDER = 'RECEIVE_SEND_ORDER';

const requestValidateMostRecentBalanceSheetsInProcessingGroup = id => ({
  type: REQUEST_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS,
  payload: {
    id,
  },
});

const receiveValidateMostRecentBalanceSheetsInProcessingGroup = (id, { currencyBalanceSheets }) => ({
  type: RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS,
  payload: {
    currencyBalanceSheets,
    id,
  },
});

const receiveSendOrder = message => ({
  type: RECEIVE_SEND_ORDER,
  payload: {
    message,
  },
});

const fetchValidateMostRecentBalanceSheetsInProcessingGroup = (id, filters) => (
  (dispatch) => {
    dispatch(requestValidateMostRecentBalanceSheetsInProcessingGroup(id));
    return Client
      .putValidateMostRecentBalanceSheetsInProcessingGroup(id, filters)
      .then(
        response => response.json(),
        error => console.log('error occurred', error),
      )
      .then(json => dispatch(receiveValidateMostRecentBalanceSheetsInProcessingGroup(id, json)));
  }
);

const fetchValidateAndSendMostRecentBalanceSheetsInProcessingGroup = (id, filters) => (
  async (dispatch) => {
    dispatch(requestValidateMostRecentBalanceSheetsInProcessingGroup(id));
    const response = await Client.putValidateMostRecentBalanceSheetsInProcessingGroup(id, filters);
    const { currencyBalanceSheets } = await response.json();

    dispatch(receiveValidateMostRecentBalanceSheetsInProcessingGroup(id, { currencyBalanceSheets }));

    const responseB = await Client.sendOrder(currencyBalanceSheets.map(b => b.id));
    const { message } = await responseB.json();
    dispatch(receiveSendOrder(message));
  }
);

export {
  REQUEST_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS,
  RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS,
  RECEIVE_SEND_ORDER,
  receiveSendOrder,
  fetchValidateMostRecentBalanceSheetsInProcessingGroup,
  fetchValidateAndSendMostRecentBalanceSheetsInProcessingGroup,
};
