import React, { useCallback, useEffect, useState } from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { usePrevious } from './usePrevious';
import { WrappedReactSelect } from './wrappedReactSelect';

/*
  If you are looking for a select that:
  - Requires their options to be recalculated when the URL changes.
  - Can handle a defaultValue for an option that will come after first request.
  - Requires checking that the current value is included in the new options.
  - Fire an "onChange" event when this happens.
  Then this component is for you. react-select doesn't handle this case of scenario
  in a non-trivial way.
 */

export const CustomAsyncSelect = (props) => {
  const {
    allowBlank, blankPlaceholder, defaultOptionValue, optionsUrl, onChange,
    buildOptions, ...otherProps
  } = props;
  const [firstRequest, setFirstRequest] = useState(true);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(undefined);
  const previousOptionsUrl = usePrevious(optionsUrl);
  const handleOptionChange = useCallback((option) => {
    setValue(option);
    onChange(option ? option.value : undefined);
  }, [setValue, onChange]);

  const findSelectedOptionFromValue = (currentOptions, currentValue) => (
    currentOptions.find(option => option.value === currentValue) || undefined
  );

  const refreshStateFromOptions = useCallback((data) => {
    const newOptions = [...buildOptions(data)];
    if (allowBlank) {
      newOptions.unshift({ value: undefined, label: blankPlaceholder });
    }
    setOptions(newOptions);
    setFirstRequest(false);

    let option;
    if (firstRequest) {
      option = findSelectedOptionFromValue(newOptions, defaultOptionValue);
    } else {
      option = findSelectedOptionFromValue(newOptions, value ? value.value : undefined);
    }

    handleOptionChange(option);
  }, [buildOptions, allowBlank, firstRequest, handleOptionChange, blankPlaceholder,
    defaultOptionValue, value]);

  useEffect(() => {
    if (optionsUrl === previousOptionsUrl) {
      return;
    }
    setLoading(true);
    fetch(optionsUrl)
      .then(res => res.json())
      .then((data) => {
        setLoading(false);
        refreshStateFromOptions(data);
      });
  }, [optionsUrl, previousOptionsUrl, setLoading, refreshStateFromOptions]);

  if (firstRequest) {
    return (
      <WrappedReactSelect
        isLoading={loading}
        {...otherProps}
      />
    );
  }

  return (
    <WrappedReactSelect
      {...otherProps}
      isLoading={loading}
      options={options}
      value={value}
      onChange={handleOptionChange}
    />
  );
};

CustomAsyncSelect.defaultProps = {
  allowBlank: false,
  blankPlaceholder: I18n.t('none'),
  defaultOptionValue: null,
};

CustomAsyncSelect.propTypes = {
  allowBlank: PropTypes.bool,
  blankPlaceholder: PropTypes.string,
  buildOptions: PropTypes.func.isRequired,
  defaultOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  optionsUrl: PropTypes.string.isRequired,
};
