import { useEffect, useState } from 'react';
import _ from 'lodash';
import Client from '../../../client';

const initialState = { loading: false, fxContractGroups: [], error: null };

export const useNetPairs = (selectedTradeIds) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (_.isEmpty(selectedTradeIds)) {
      setState(initialState);
    } else {
      setState({ loading: true, fxContractGroups: [], error: null });
      Client
        .netPairsFxContracts(selectedTradeIds)
        .then(response => response.json())
        .then((data) => {
          if (data.error) {
            setState({ loading: false, error: data.error, fxContractGroups: [] });
          } else {
            setState({ loading: false, fxContractGroups: data.data.fxContractGroups, error: null });
          }
        });
    }
  }, [selectedTradeIds]);

  return { state };
};
