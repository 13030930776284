import React from 'react';
import PropTypes from 'prop-types';

export const SummaryColumn = ({
  title, subtitle, valueClassName, children,
}) => (
  <div className="summary-column">
    <div className={`value ${valueClassName}`}>
      { children }
      <span className="value-title">{title}</span>
    </div>
    <div className="value-subtitle">
      { subtitle }
    </div>
  </div>
);

SummaryColumn.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  valueClassName: PropTypes.string,
  children: PropTypes.node,
};

SummaryColumn.defaultProps = {
  valueClassName: '',
  children: null,
};
