import { useCallback, useState } from 'react';

const initialState = {
  loading: false,
  result: null,
};

const SUCCESS = Symbol('SUCCESS');
const FAILURE = Symbol('FAILURE');

export const useSimulatedExecution = (Client, selectedFxContractIds) => {
  const [state, setState] = useState(initialState);

  const handleSimulationClick = useCallback(() => {
    setState({ loading: true, result: null });
    Client
      .simulateTradeExecution(selectedFxContractIds)
      .then((r) => {
        if (r.status === 204) {
          setState({ loading: false, result: SUCCESS });
        } else {
          setState({ loading: false, result: FAILURE });
        }
      });
  }, [Client, selectedFxContractIds]);

  return {
    handleSimulationClick,
    state,
    statuses: {
      SUCCESS,
      FAILURE,
    },
  };
};
