import PropTypes from 'prop-types';

export const NotePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  entity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
});
