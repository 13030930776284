import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const NotificationsItem = ({
  subject, body, createdAt, path,
}) => (
  <li className="list-group-item">
    <a target="_blank" rel="noopener noreferrer" className="notification-link" href={path}>
      <div className="notification-title">
        <span className="notification-subject text-left">{_.truncate(subject, { length: 25 })}</span>
        <span className="notification-date text-right">{createdAt}</span>
      </div>
      <div className="notification-body">{_.truncate(body, { length: 150 })}</div>
    </a>
  </li>
);

NotificationsItem.propTypes = {
  path: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default NotificationsItem;
