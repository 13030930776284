import _ from 'lodash';
import {
  ADD_CAPSTOCK_EXCEPTION,
  REMOVE_CAPSTOCK_EXCEPTION,
  CHANGE_CAPSTOCK_EXCEPTION,
} from '../actions/capstockExceptions';
import { uuid as generateUuid } from '../../../utils/uuid';

const initialState = {};

const updateCapstockException = (state, currencyLineItemMapUuid,
  exceptionUuid, updatedException) => {
  const newExceptions = [
    ...state[currencyLineItemMapUuid],
  ];
  const exceptionToUpdateIndex = newExceptions.findIndex(exc => exc.uuid === exceptionUuid);
  newExceptions[exceptionToUpdateIndex] = {
    ...newExceptions[exceptionToUpdateIndex],
    ...updatedException,
  };
  return newExceptions;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CAPSTOCK_EXCEPTION: {
      const { uuid } = action.payload;
      return {
        ...state,
        [uuid]: [
          ...(state[uuid] || []),
          {
            id: null,
            type: '',
            dateProvided: null,
            action: '',
            businessDays: null,
            uuid: generateUuid(),
          },
        ],
      };
    }
    case REMOVE_CAPSTOCK_EXCEPTION: {
      const { currencyLineItemMapUuid, uuid } = action.payload;
      /* eslint-disable no-underscore-dangle */
      const updatedExceptions = updateCapstockException(state, currencyLineItemMapUuid, uuid,
        { _destroy: true });
      return {
        ...state,
        [currencyLineItemMapUuid]: _.reject(updatedExceptions, ex => ex._destroy && ex.id === null),
      };
      /* eslint-enable no-underscore-dangle */
    }
    case CHANGE_CAPSTOCK_EXCEPTION: {
      const { currencyLineItemMapUuid, uuid, updatedException } = action.payload;
      const updatedExceptions = updateCapstockException(state, currencyLineItemMapUuid,
        uuid, updatedException);
      return {
        ...state,
        [currencyLineItemMapUuid]: updatedExceptions,
      };
    }
    default:
      return state;
  }
}
