import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import RailsContants from '../../../rails_contants.js.erb';
import { WrappedReactSelect } from '../../../shared/wrappedReactSelect';

export const ProcessStatusFilter = ({
  onChange,
  selectedProcessStatus,
  ...otherProps
}) => {
  const getStatusesOptions = useCallback(() => {
    const statusOptions = RailsContants.processStatuses.map(type => ({
      value: type,
      label: capitalize(type),
    }));

    statusOptions.unshift({
      value: null,
      label: 'All statuses',
    });

    return statusOptions;
  }, []);

  return (
    <WrappedReactSelect
      className="filter-process-status"
      placeholder="All statuses"
      options={getStatusesOptions()}
      value={selectedProcessStatus}
      onChange={onChange}
      {...otherProps}
    />
  );
};

ProcessStatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedProcessStatus: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
};

ProcessStatusFilter.getDefaultProps = {
  selectedProcessStatus: null,
  inputProps: {},
};
