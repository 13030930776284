import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import I18n from 'i18n-js';
import { Filters } from '../components/filters';
import { UnusualFilesArrivalTable } from '../components/unusualFilesArrivalTable';
import Client from '../../../client';
import { UnusualRecordsTable } from '../components/unusualRecordsTable';

const fetchUnusualFiles = evaluatedAt => (
  Client.getUnusualFiles(evaluatedAt).then(response => response.json())
);

const fetchUnusualRecords = (evaluatedAt, model) => (
  Client.getUnusualRecords(evaluatedAt, model).then(response => response.json())
);

export class DashboardContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      evaluatedAt: moment(),
      model: null,
      unusualFiles: {},
      unusualRecords: {},
    };
    this.FETCH_DATA_INTERVAL_SECONDS = 10 * 1000;
    this.fetchNewIntervalId = null;
    this.fetchData = this.fetchData.bind(this);
    this.onFilterSubmit = this.onFilterSubmit.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.fetchNewIntervalId = setInterval(() => this.fetchNew(), this.FETCH_DATA_INTERVAL_SECONDS);
  }

  componentWillUnmount() {
    this.clearInterval(this.fetchNewIntervalId);
  }

  onFilterChange(filter) {
    this.setState(filter);
  }

  onFilterSubmit(event) {
    event.preventDefault();
    this.fetchData();
  }

  fetchNew() {
    const evaluatedAtTimestamp = moment().format('YYYY-MM-DD');
    fetchUnusualFiles(evaluatedAtTimestamp)
      .then(({ unusualFiles }) => {
        this.setState(prevState => (
          _.merge(prevState.unusualFiles, _.keyBy(unusualFiles, o => o.id))
        ));
      });
    fetchUnusualRecords(evaluatedAtTimestamp, null)
      .then(({ unusualRecords }) => {
        this.setState(prevState => (
          _.merge(prevState.unusualRecords, _.keyBy(unusualRecords, o => o.id))
        ));
      });
  }

  fetchData() {
    const { evaluatedAt, model } = this.state;
    const evaluatedAtTimestamp = evaluatedAt.format('YYYY-MM-DD');
    fetchUnusualFiles(evaluatedAtTimestamp)
      .then(({ unusualFiles }) => {
        this.setState({
          unusualFiles: _.keyBy(unusualFiles, o => o.id),
        });
      });
    fetchUnusualRecords(evaluatedAtTimestamp, model)
      .then(({ unusualRecords }) => {
        this.setState({
          unusualRecords: _.keyBy(unusualRecords, o => o.id),
        });
      });
  }

  render() {
    const {
      evaluatedAt, model, unusualFiles, unusualRecords,
    } = this.state;
    const { models } = this.props;
    const sortedUnusualRecords = _.orderBy(unusualRecords, ['evaluatedAt'], ['desc']);
    return ([
      <div key="header" className="view-container-header">
        <h1>{I18n.t('rosy.dashboard.index.dashboard_header')}</h1>
      </div>,
      <div key="content" className="view-container-content">
        <Filters
          models={models}
          onChange={this.onFilterChange}
          onSubmit={this.onFilterSubmit}
          selectedModel={model}
          selectedEvaluatedAt={evaluatedAt}
        />
        <hr />
        <UnusualFilesArrivalTable unusualFiles={_.orderBy(unusualFiles, ['arrivalDate', 'medianArrivalTime'], ['desc', 'desc'])} />
        <UnusualRecordsTable unusualRecords={sortedUnusualRecords} />
      </div>,
    ]);
  }
}


DashboardContainer.propTypes = {
  models: PropTypes.arrayOf(PropTypes.string).isRequired,
};
