import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyBalanceSheetProps } from '../commonPropTypes';
import { CurrencyBalanceSheet } from '../components/currencyBalanceSheet';

const CurrencyBalanceSheetDetailsContainer = ({ currencyBalanceSheet, ...otherProps }) => (
  <CurrencyBalanceSheet currencyBalanceSheet={currencyBalanceSheet} {...otherProps} />
);

export default CurrencyBalanceSheetDetailsContainer;

CurrencyBalanceSheetDetailsContainer.propTypes = {
  currencyBalanceSheet: PropTypes.shape(CurrencyBalanceSheetProps).isRequired,
};
