import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';
import Routes from '../../routes.js.erb';
import NotificationsItem from './NotificationsItem';

const NotificationsPopup = ({ notifications, positionLeft, positionTop }) => (
  <Popover id="notifications-popover" placement="bottom" positionTop={positionTop + 10} positionLeft={positionLeft} style={{ zIndex: 9999 }}>
    <ul className="list-group">
      {
        notifications.map(
          notification => <NotificationsItem key={notification.id} {...notification} />,
        )
      }

      { notifications.length
        ? (
          <li className="notification-all text-center">
            <a className="notification-all-link" href={Routes.notifications_path({ all: true })}>View all</a>
          </li>
        )
        : (
          <div className="notification-all text-center">
          There is no notifications
          </div>
        )
      }
    </ul>
  </Popover>
);

NotificationsPopup.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  positionLeft: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
  positionTop: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
};

export default NotificationsPopup;
