import connect from 'react-redux/es/connect/connect';

import { fetchProcessingGroupsMetadata } from '../../actions/processingGroups';

import { SortedProcessingGroupField } from '../../components/processingGroup/sortedField';

const generateSortedProcessingGroupFieldContainer = ({ currentField, displayKey, options }) => {
  const mapStateToProps = (state) => {
    const { order, filters } = state.processingGroups;
    const { field, direction } = order;

    return {
      field: currentField,
      currentFilters: filters,
      currentDirection: (field === currentField) ? direction : null,
      displayKey,
      options,
    };
  };

  const mapDispatchToProps = { onClick: fetchProcessingGroupsMetadata };

  return connect(mapStateToProps, mapDispatchToProps)(SortedProcessingGroupField);
};

const SortedProcessingGroupNameFieldContainer = generateSortedProcessingGroupFieldContainer({
  currentField: 'processing_group_name',
  displayKey: 'activerecord.attributes.account.processing_group',
  options: { colSpan: 1 },
});

const SortedProcessingGroupIdFieldContainer = generateSortedProcessingGroupFieldContainer({
  currentField: 'processing_group_id',
  displayKey: 'activerecord.attributes.processing_group.id',
  options: { colSpan: 0 },
});

export {
  SortedProcessingGroupNameFieldContainer,
  SortedProcessingGroupIdFieldContainer,
};
