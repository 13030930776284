import React, { useState } from 'react';
import _ from 'lodash';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { Loading } from '../../shared/loading';
import Routes from '../../routes.js.erb';
import { useAuthorizedResource } from '../../shared/useAuthorizedResource';

export const RawDataFileActions = ({
  id, clientId, filterParams, processed,
}) => {
  const [didDropdownOpenAtLeastOnce, setDidDropdownOpenAtLeastOnce] = useState(false);
  const {
    canShow, canDelete, canEdit, loading,
  } = useAuthorizedResource([
    { modelClass: 'RawDataFiles::Base', modelId: id, modelAction: 'show' },
    { modelClass: 'RawDataFiles::Base', modelId: id, modelAction: 'delete' },
    { modelClass: 'RawDataFiles::Base', modelId: id, modelAction: 'edit' },
  ], didDropdownOpenAtLeastOnce);

  let actionParams = {};
  if (!_.isEmpty(filterParams)) {
    actionParams = {
      selected_client: filterParams.selected_client,
      status: filterParams.selected_status,
      account: filterParams.selected_account,
      processing_group: filterParams.selected_processing_group,
      type: filterParams.selected_type,
      file_file_name: filterParams.file_file_name,
      raw_account_data_map_set: filterParams.raw_account_data_map_set,
      published_date: filterParams.published_date,
    };
  }

  return (
    <DropdownButton
      bsStyle="primary"
      title={I18n.t('actions')}
      id={`raw_data_file_actions_${id}`}
      onToggle={open => (open ? setDidDropdownOpenAtLeastOnce(true) : null)}
    >
      {
          (loading) && (
            <MenuItem eventKey="loading">
              <Loading loading />
            </MenuItem>
          )
        }
      {
          canShow && (
            <MenuItem href={Routes.download_raw_data_file_path({ id })} target="_blank" rel="noopener noreferrer">
              <span className="bold">{I18n.t('download')}</span>
            </MenuItem>
          )
        }
      {
          canShow && (
            <MenuItem href={Routes.raw_data_file_path({ id }, { client: clientId })} data-test-id={`raw-data-file-${id}-details`}>
              <span className="bold">{I18n.t('details')}</span>
            </MenuItem>
          )
        }
      {
          processed && canEdit && (
            <MenuItem href={Routes.edit_raw_data_file_path({ id }, { client: clientId })} data-test-id={`raw-data-file-${id}-edit`} rel="noopener noreferrer">
              <span className="bold">{I18n.t('edit')}</span>
            </MenuItem>
          )
        }
      {
          canDelete && (
            <MenuItem
              href={Routes.raw_data_file_path({ id }, { client: clientId, ...actionParams })}
              rel="nofollow"
              data-confirm="Are you sure?"
              data-method="delete"
              data-test-id={`raw-data-file-${id}-delete`}
            >
              <span className="bold">
                {I18n.t('delete')}
              </span>
            </MenuItem>
          )
        }
    </DropdownButton>
  );
};

RawDataFileActions.propTypes = {
  id: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  filterParams: PropTypes.object.isRequired,
  processed: PropTypes.bool.isRequired,
};
