import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const CbsSectionTitle = ({
  title, leftSubTitle, rightSubTitle, children, onLineItemsToggle,
  onTradesToggle, showLineItems, showTrades,
}) => (
  <div>
    <div className="section-title">{title}</div>
    { children }
    <div className="details-row-title bottom-separator">
      <div className="details-title">
        { leftSubTitle }
        <a className="visibility-button" role="button" onClick={onLineItemsToggle} tabIndex={0} id={`${_.kebabCase(title)}-left-visibility`}>
          <i className="material-icons">{(showLineItems) ? 'visibility_off' : 'visibility'}</i>
        </a>
      </div>
      <div className="details-title">
        { rightSubTitle }
        <a className="visibility-button" role="button" onClick={onTradesToggle} tabIndex={0} id={`${_.kebabCase(title)}-right-visibility`}>
          <i className="material-icons">{(showTrades) ? 'visibility_off' : 'visibility'}</i>
        </a>
      </div>
    </div>
  </div>
);

CbsSectionTitle.propTypes = {
  title: PropTypes.string,
  leftSubTitle: PropTypes.string,
  rightSubTitle: PropTypes.string,
  children: PropTypes.element,
  showLineItems: PropTypes.bool,
  showTrades: PropTypes.bool,
  onLineItemsToggle: PropTypes.func.isRequired,
  onTradesToggle: PropTypes.func.isRequired,
};

CbsSectionTitle.defaultProps = {
  title: '',
  leftSubTitle: '',
  rightSubTitle: '',
  children: null,
  showLineItems: true,
  showTrades: true,
};
