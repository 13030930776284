import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  ListGroup,
  Checkbox,
} from 'react-bootstrap';
import Item from './Item';

const Component = (props) => {
  const {
    title,
    items,
    selectedItems,
    onCheck,
    onUnCheck,
    onGroupCheck,
    onGroupUnCheck,
  } = props;

  const [open, setOpen] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      onGroupCheck(items.map(x => x.id));
    } else {
      onGroupUnCheck(items.map(x => x.id));
    }
  };

  const handleItemChecked = (accountId) => {
    onCheck(accountId);
  };

  const handleItemUnChecked = (accountId) => {
    onUnCheck(accountId);
  };

  const isGroupChecked = items
    .map(x => x.id)
    .every(x => selectedItems.includes(x));

  return (
    <Panel expanded={open}>
      <Panel.Heading>
        <a onClick={handleToggle} className="pull-right" style={{ padding: 10 }}>toggle</a>
        <Checkbox
          style={{ width: 300 }}
          onChange={handleSelectAll}
          checked={isGroupChecked}
        >
          {title}
        </Checkbox>
      </Panel.Heading>
      <Panel.Collapse>
        <ListGroup>
          {items.map(item => (
            <Item
              key={item.id}
              {...item}
              checked={selectedItems.includes(item.id)}
              onCheck={handleItemChecked}
              onUnCheck={handleItemUnChecked}
            />
          ))}
        </ListGroup>
      </Panel.Collapse>
    </Panel>
  );
};

Component.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType(PropTypes.string, PropTypes.number).isRequired,
      title: PropTypes.string,
    }),
  ).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheck: PropTypes.func.isRequired,
  onUnCheck: PropTypes.func.isRequired,
  onGroupCheck: PropTypes.func.isRequired,
  onGroupUnCheck: PropTypes.func.isRequired,
};

export default Component;
