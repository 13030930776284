/* eslint-disable max-len */
import Client from '../../client';

const REQUEST_PD_PROCESSING_GROUPS_METADATA = 'REQUEST_PD_PROCESSING_GROUPS_METADATA';
const RECEIVE_PD_PROCESSING_GROUPS_METADATA = 'RECEIVE_PD_PROCESSING_GROUPS_METADATA';
const ERROR_PD_PROCESSING_GROUPS_METADATA = 'ERROR_PD_PROCESSING_GROUPS_METADATA';

const RECEIVE_PD_PROCESSING_GROUP = 'RECEIVE_PD_PROCESSING_GROUP';
const ERROR_PD_PROCESSING_GROUP = 'ERROR_PD_PROCESSING_GROUP';

const requestProcessingGroupsMetadata = (filters, order, page, forceReload) => ({
  type: REQUEST_PD_PROCESSING_GROUPS_METADATA,
  payload: {
    filters,
    order,
    page,
    forceReload,
  },
});

const receiveProcessingGroupsMetadata = payload => ({
  type: RECEIVE_PD_PROCESSING_GROUPS_METADATA,
  payload,
});

const errorProcessingGroupsMetadata = error => ({
  type: ERROR_PD_PROCESSING_GROUPS_METADATA,
  payload: {
    error,
  },
});

const fetchProcessingGroupsMetadata = (filters = {}, order = {}, page = 1, forceReload = false) => (
  async (dispatch) => {
    dispatch(requestProcessingGroupsMetadata(filters, order, page, forceReload));

    const response = await Client.getProcessingDashboardProcessingGroupsMetadata(filters, order, page);

    const json = await response.json();

    if (response.ok) {
      dispatch(receiveProcessingGroupsMetadata(json));
    } else {
      dispatch(errorProcessingGroupsMetadata(json.message));
    }
  }
);

const requestProcessingGroupsData = (ids, filters) => (
  Client.getProcessingDashboardProcessingGroupsData(ids, filters)
);

export {
  REQUEST_PD_PROCESSING_GROUPS_METADATA,
  RECEIVE_PD_PROCESSING_GROUPS_METADATA,
  ERROR_PD_PROCESSING_GROUPS_METADATA,
  RECEIVE_PD_PROCESSING_GROUP,
  ERROR_PD_PROCESSING_GROUP,
  fetchProcessingGroupsMetadata,
  requestProcessingGroupsData,
};
