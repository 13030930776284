import { applyMiddleware, createStore } from 'redux';

import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { createCableCarRoute } from 'redux-cablecar';

const commonMiddlewares = [thunk, logger];

const buildReduxStore = ({ rootReducer, storeData, useCablecar }) => {
  const cablecar = createCableCarRoute();
  const middlewares = [...commonMiddlewares, ...(useCablecar ? [cablecar.createMiddleware()] : [])];
  const enhancers = applyMiddleware(...middlewares);

  if (storeData) {
    return createStore(
      rootReducer,
      storeData,
      enhancers,
    );
  }

  return createStore(
    rootReducer,
    enhancers,
  );
};

export { buildReduxStore };
