import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { Button, Modal } from 'react-bootstrap';
import { RollForm } from './rollForm';
import { accountScheduledRollSettingForDatePropTypes, accountPropTypes } from '../../../currencyBalanceSheets/show/commonPropTypes';

const RollModal = (props) => {
  const {
    show, onClose, onRoll,
  } = props;

  return (
    <Modal show={show} onHide={onClose} id="rollModal">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('shared.currency_balance_sheets.open_trades_roll_modal.modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RollForm {...props} onSubmit={onRoll} />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="default" onClick={onClose}>
          {I18n.t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RollModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRoll: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  currentAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
  nextAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
  accounts: PropTypes.arrayOf(accountPropTypes),
};

RollModal.defaultProps = {
  accounts: [],
  currentAccountScheduledRollSetting: null,
  nextAccountScheduledRollSetting: null,
};

export { RollModal };
