import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import { WrappedReactSelect } from '../../../shared/wrappedReactSelect';

export const TypesSelect = (props) => {
  const { selectedType, types, onChange } = props;
  const options = types.map(type => (
    { label: I18n.t(`activerecord.attributes.raw_data_files/base.all_types.${type}`), value: type }
  ));
  options.unshift({ label: I18n.t('users_portal.raw_data_files.index.filter.all_types'), value: undefined });
  const value = options.find(option => option.value === selectedType);

  return (
    <WrappedReactSelect
      options={options}
      name="type"
      id="select_type"
      placeholder={I18n.t('users_portal.raw_data_files.index.filter.all_types')}
      value={value}
      onChange={option => onChange(option ? option.value : null)}
    />
  );
};

TypesSelect.defaultProps = {
  selectedType: null,
};

TypesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};
