import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

export const AccountNotValidatedModal = ({
  onHide, show, processingGroupId, currencyBalanceSheetIds, accountsNotValidated,
}) => (
  <Modal show={show} onHide={onHide} id="rollProcessingGroupAccountNotValidated">
    <Modal.Header closeButton>
      <Modal.Title>Accounts not validated</Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
      <h3 className="text-center">
        {
          processingGroupId > 0
          && `Unable to roll Processing Group ${processingGroupId} because there are rollable accounts within the processing group that are not yet validated`
        }
        {
          currencyBalanceSheetIds.length > 0
          && 'Unable to roll Account selection because the following accounts are not yet validated'
        }
      </h3>
      <br />
      <ul>
        { accountsNotValidated.map(account => (
          <li key={account.id}>
            {`# ${account.id} : ${account.name}`}
          </li>
        ))}
      </ul>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="default" onClick={onHide}>
        {I18n.t('close')}
      </Button>
    </Modal.Footer>
  </Modal>
);

AccountNotValidatedModal.defaultProps = {
  processingGroupId: 0,
  currencyBalanceSheetIds: [],
};

AccountNotValidatedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  processingGroupId: PropTypes.number,
  currencyBalanceSheetIds: PropTypes.arrayOf(PropTypes.number),
  accountsNotValidated: PropTypes.arrayOf(PropTypes.object).isRequired,
};
