import React from 'react';
import PropTypes from 'prop-types';
import { flagSummary } from './commonPropTypes';
import FlagSummary from './flagSummary';

/* eslint-disable react/no-array-index-key */
export const FlagSummaryList = ({
  accountFlagsNotes, flagSummaries, processingGroupId, onBulkCreateAccountFlag,
  onFetchAccountFlagNotes, currentFilters, clientId,
}) => (
  <span>
    {flagSummaries.map((summary, i) => (
      <FlagSummary
        summary={summary}
        key={summary.category + i}
        accountFlagsNotes={accountFlagsNotes}
        clientId={clientId}
        processingGroupId={processingGroupId}
        onBulkCreateAccountFlag={onBulkCreateAccountFlag}
        onFetchAccountFlagNotes={onFetchAccountFlagNotes}
        currentFilters={currentFilters}
      />
    ))}
  </span>
);
/* eslint-enabled react/no-array-index-key */

FlagSummaryList.propTypes = {
  flagSummaries: PropTypes.arrayOf(flagSummary).isRequired,
  clientId: PropTypes.number.isRequired,
  processingGroupId: PropTypes.number.isRequired,
  onBulkCreateAccountFlag: PropTypes.func.isRequired,
  onFetchAccountFlagNotes: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  accountFlagsNotes: PropTypes.object.isRequired,
  currentFilters: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};
