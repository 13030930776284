import React from 'react';
import { Popover } from 'react-bootstrap';
import { FormattedPercentage } from './formattedPercentage';
import { hedgeLineItemPropType } from '../../commonPropTypes';

export const RelativeWeightInfoPopup = ({ weight }) => (
  <Popover>
    <div style={{ whiteSpace: 'pre-wrap', padding: '1em' }}>
      {
          weight.type === 'NormalizedAccountData::RelativeWeights::HoldingsMinusWeights' ? (
            <>
              { FormattedPercentage({ percentage: weight.holdingsValue }) }
              { ' Holding - ' }
              { FormattedPercentage({ percentage: weight.weightValue }) }
              { ' Weight' }
            </>
          ) : (
            <>
              { FormattedPercentage({ percentage: weight.weightValue }) }
              { ' Weight - ' }
              { FormattedPercentage({ percentage: weight.holdingsValue }) }
              { ' Holding' }
            </>
          )
        }
    </div>
  </Popover>
);

RelativeWeightInfoPopup.propTypes = {
  weight: hedgeLineItemPropType.weight,
};

RelativeWeightInfoPopup.defaultProps = {
  weight: null,
};
