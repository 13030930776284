import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import { LinkAsButton } from '../linkAsButton';

export const FilterControl = (props) => {
  const {
    className, disabled, onClear, onSubmit, nameOverride,
  } = props;

  return (
    <div className={`btn-toolbar btn-filter-group ${className}`}>
      <Button
        bsStyle="primary"
        type="submit"
        onClick={onSubmit}
        disabled={disabled}
      >
        {nameOverride || I18n.t('filter')}
      </Button>
      <LinkAsButton
        className="clear-filter-link"
        onClick={onClear}
        disabled={disabled}
      >
        {I18n.t('clear_filter')}
      </LinkAsButton>
    </div>
  );
};

FilterControl.defaultProps = {
  disabled: false,
  className: '',
  nameOverride: null,
};

FilterControl.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  className: PropTypes.string,
  nameOverride: PropTypes.string,
};
