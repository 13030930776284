import PropTypes from 'prop-types';
import React from 'react';
import Client from '../../../client';
import { ACCOUNT } from '../../entityTypes';
import { AccountFilter } from '../../../shared/filters/accountFilter';
import { NewResourceModal } from './newResourceModal';
import { useEntityModalState } from '../hooks/useEntityModalState';

export const NewNoteAccountModal = ({ onSubmit, onClose, show }) => {
  const {
    entities, selectedEntityId, loading, handleChange, handleEnter,
  } = useEntityModalState(Client.getAccounts, 'accounts');

  return (
    <NewResourceModal
      entity={{ type: ACCOUNT, id: selectedEntityId }}
      onSubmit={onSubmit}
      onClose={onClose}
      onEnter={handleEnter}
      show={show}
    >
      <AccountFilter
        accounts={entities}
        loading={loading}
        onChange={handleChange}
        selectedAccountId={selectedEntityId}
      />
    </NewResourceModal>
  );
};

NewNoteAccountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
