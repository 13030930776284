import PropTypes from 'prop-types';

const currentDirection = PropTypes.oneOf(['asc', 'desc', null]);

const processedData = {
  status: PropTypes.string.isRequired,
  reason: PropTypes.string,
  value: PropTypes.string,
};

const sharedColumns = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tradeFlag: PropTypes.shape(processedData),
  cbs: PropTypes.shape(processedData),
  data: PropTypes.shape(processedData),
  estimates: PropTypes.arrayOf(PropTypes.shape(processedData)),
  finals: PropTypes.arrayOf(PropTypes.shape(processedData)),
  rebal: PropTypes.arrayOf(PropTypes.shape(processedData)),
  roll: PropTypes.arrayOf(PropTypes.shape(processedData)),
  profitOrLoss: PropTypes.arrayOf(PropTypes.shape(processedData)),
  rollDate: PropTypes.shape(processedData),
  exposureDate: PropTypes.shape(processedData),
  valueDate: PropTypes.shape(processedData),
  npv: PropTypes.shape(processedData),
  statusCircle: PropTypes.shape(processedData),
};

const account = PropTypes.shape({
  ...sharedColumns,
});

const processingGroup = {
  ...sharedColumns,
  accounts: PropTypes.arrayOf(account),
};

export {
  account, processingGroup, currentDirection, processedData, sharedColumns,
};
