import React from 'react';
import PropType from 'prop-types';
import I18n from 'i18n-js';
import { Col, Table } from 'react-bootstrap';
import { NotePropType } from '../../commonPropTypes';
import { NoteSectionRow } from './noteSectionRow';
import './notesSection.scss';

export const NotesSection = ({
  title, notes, onEdit, onClickNew,
}) => (
  <Col sm={12}>
    <div className="notes-section-header">
      <h1>{title}</h1>
      <button
        type="button"
        className="btn btn-primary"
        onClick={onClickNew}
      >
        {I18n.t('notes.buttons.new')}
      </button>
    </div>
    {notes.length === 0 && I18n.t('notes.no_data')}
    {notes.length !== 0 && (
    <Table striped condensed hover>
      <thead>
        <tr>
          <th />
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {
            notes.map(note => <NoteSectionRow key={note.id} note={note} onEdit={onEdit} />)
          }
      </tbody>
    </Table>
    )}
  </Col>
);

NotesSection.propTypes = {
  notes: PropType.arrayOf(NotePropType).isRequired,
  onClickNew: PropType.func.isRequired,
  onEdit: PropType.func.isRequired,
  title: PropType.string.isRequired,
};
