import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import I18n from 'i18n-js';
import { Col } from 'react-bootstrap';
import { ClientProcessingGroupAccountFilter } from '../../../shared/filters/clientProcessingGroupAccountFilter';
import { LinkAsButton } from '../../../shared/linkAsButton';
import Routes from '../../../routes.js.erb';
import { LedgerDatePicker } from './ledgerDatePicker';

export const SheetNavigator = ({
  selectedAccountId, selectedProcessingGroupId, selectedClientId, ledgerDate,
}) => {
  const [state, setState] = useState({
    selectedAccountId,
    selectedProcessingGroupId,
    selectedClientId,
  });
  const [currentLedgerDate, setCurrentLedgerDate] = useState(ledgerDate);
  const currentAccountId = state.selectedAccountId;

  return (
    <Row>
      <ClientProcessingGroupAccountFilter
        selectedAccountId={currentAccountId}
        selectedClientId={state.selectedClientId}
        selectedProcessingGroupId={state.selectedProcessingGroupId}
        onChange={setState}
        layout={{ sm: 3 }}
      />
      <Col sm={2}>
        <div className="form-group">
          <LedgerDatePicker
            selectedAccountId={currentAccountId}
            ledgerDate={currentLedgerDate}
            onLedgerDateChange={setCurrentLedgerDate}
          />
        </div>
      </Col>
      <Col sm={1}>
        <div className="form-group">
          {
            (!currentAccountId || !currentLedgerDate) ? (
              <LinkAsButton
                className="btn btn-secondary"
                disabled={!currentAccountId || !currentLedgerDate}
                href="#"
              >
                {I18n.t('filter')}
              </LinkAsButton>
            ) : (
              <a
                className="btn btn-secondary"
                href={Routes.account_currency_balance_sheet_path(
                  currentAccountId, currentLedgerDate,
                )}
              >
                {I18n.t('filter')}
              </a>
            )
          }
        </div>
      </Col>
    </Row>
  );
};

SheetNavigator.propTypes = {
  selectedAccountId: PropTypes.number.isRequired,
  selectedProcessingGroupId: PropTypes.number,
  selectedClientId: PropTypes.number.isRequired,
  ledgerDate: PropTypes.string.isRequired,
};

SheetNavigator.defaultProps = {
  selectedProcessingGroupId: null,
};
