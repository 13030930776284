import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Routes from '../../../routes.js.erb';
import { CustomAsyncSelect } from '../../../shared/customAsyncSelect';

export const FileNamesSelect = (props) => {
  const {
    onChange,
    selectedClientId,
    selectedAccountId,
    selectedProcessingGroupId,
    selectedStatus,
    selectedType,
    selectedFileName,
    selectedScope,
  } = props;

  const optionsUrl = Routes.file_names_api_v1_raw_data_files_path(
    {
      format: 'json',
      client: selectedClientId,
      account: selectedAccountId,
      processing_group: selectedProcessingGroupId,
      status: selectedStatus,
      type: selectedType,
      scope: selectedScope,
    },
  );

  return (
    <CustomAsyncSelect
      name="file_file_name"
      id="select_file_name"
      placeholder={I18n.t('users_portal.raw_data_files.index.filter.all_file_names')}
      allowBlank
      blankPlaceholder={I18n.t('users_portal.raw_data_files.index.filter.all_file_names')}
      optionsUrl={optionsUrl}
      buildOptions={data => (
        data.fileNames.map(fileName => ({ value: fileName, label: fileName }))
      )}
      defaultOptionValue={selectedFileName}
      onChange={onChange}
    />
  );
};

FileNamesSelect.defaultProps = {
  selectedAccountId: null,
  selectedClientId: null,
  selectedProcessingGroupId: null,
  selectedStatus: null,
  selectedType: null,
  selectedFileName: null,
  selectedScope: null,
};

FileNamesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedAccountId: PropTypes.number,
  selectedClientId: PropTypes.number,
  selectedFileName: PropTypes.string,
  selectedProcessingGroupId: PropTypes.number,
  selectedScope: PropTypes.string,
  selectedStatus: PropTypes.string,
  selectedType: PropTypes.string,
};
