import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import I18n from 'i18n-js';

import { dayData as dayDataPropType, comparisonDatum as comparisonDatumPropType } from './commonPropTypes';

function getColor(rawValue) {
  let value = Math.max(0, Math.min(0.5 + (parseFloat(rawValue.replace('%', ''))), 1));

  if (value > 0.45 && value < 0.55) {
    return '';
  }

  if (value > 1) {
    value = 1;
  } else if (value < 0) {
    value = 0;
  }

  const hue = ((value) * 120).toString(10);
  return ['hsl(', hue, ', 80%, 40%)'].join('');
}

const AccountWeightsComparisonTable = ({ selectedDates, comparison }) => {
  if (selectedDates.length !== 2 || !comparison || comparison.length === 0) {
    return null;
  }

  return (
    <Table className="account-weights-comparison">
      <thead>
        <tr>
          <th>{ I18n.t('users_portal.account_weights.iso_code') }</th>
          <th className="date-cell">{ selectedDates[0].date }</th>
          <th className="date-cell">{ selectedDates[1].date }</th>
          <th>{ I18n.t('users_portal.account_weights.difference') }</th>
        </tr>
      </thead>
      <tbody>
        { comparison.map(row => (
          <tr key={row.isoCode}>
            <td>{row.isoCode}</td>
            <td className="number-cell">{row.firstValue}</td>
            <td className="number-cell">{row.secondValue}</td>
            <td className="number-cell" style={{ color: getColor(row.difference) }}>{row.difference}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

AccountWeightsComparisonTable.propTypes = {
  selectedDates: PropTypes.arrayOf(dayDataPropType).isRequired,
  comparison: PropTypes.arrayOf(comparisonDatumPropType),
};

AccountWeightsComparisonTable.defaultProps = {
  comparison: null,
};

export { AccountWeightsComparisonTable };
