import React from 'react';
import { Link } from 'react-router-dom';
import List from './List';
import Client from '../../../client';
import useIndex from '../../hooks/useIndex';

export default () => {
  const {
    currentPage,
    totalPages,
    items,
    order,
    setOrder,
    setCurrentPage,
    setItems,
  } = useIndex({
    indexCallback: Client.getExecutionGroups,
    defaultOrder: {
      field: 'client',
      direction: 'asc',
    },
  });

  const changeOrder = async (direction) => {
    setOrder(direction);
  };

  const removeItem = async (groupId) => {
    const res = await Client.removeExecutionGroup(groupId);
    if (res.ok) {
      setItems(items.filter(x => x.id !== groupId));
    } else {
      // TODO: alert error
    }
  };

  return (
    <>
      <div className="view-container-actions">
        <Link to="/execution_settings/groups/new" className="btn btn-success">New Execution Group</Link>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>Execution Groups</h1>
        </div>
        <div className="col-sm-12">
          <List
            items={items}
            onRemove={removeItem}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            order={order}
            onOrderChange={changeOrder}
          />
        </div>
      </div>
    </>
  );
};
