import React from 'react';
import PropTypes from 'prop-types';
import { accountFlag } from './commonPropTypes';
import { AccountFlag } from './accountFlag';

export const AccountFlagsList = ({
  accountFlags, currencyBalanceSheetId,
  processingGroupId, onCreateAccountFlag,
  currentFilters, onFetchAccountFlagNotes,
  clientId, accountFlagsNotes, ...other
}) => (
  <span {...other}>
    {
      accountFlags.map(aFlag => (
        <AccountFlag
          clientId={clientId}
          currencyBalanceSheetId={currencyBalanceSheetId}
          processingGroupId={processingGroupId}
          key={aFlag.id}
          flag={aFlag}
          onCreateAccountFlag={onCreateAccountFlag}
          accountFlagsNotes={accountFlagsNotes}
          onFetchAccountFlagNotes={onFetchAccountFlagNotes}
          currentFilters={currentFilters}
        />
      ))
    }
  </span>
);

AccountFlagsList.propTypes = {
  processingGroupId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  clientId: PropTypes.number.isRequired,
  accountFlags: PropTypes.arrayOf(accountFlag).isRequired,
  onCreateAccountFlag: PropTypes.func.isRequired,
  onFetchAccountFlagNotes: PropTypes.func.isRequired,
  currencyBalanceSheetId: PropTypes.number.isRequired,
  /* eslint-disable react/forbid-prop-types */
  accountFlagsNotes: PropTypes.object,
  currentFilters: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

AccountFlagsList.defaultProps = {
  accountFlagsNotes: {},
  currentFilters: {},
};
