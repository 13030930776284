import React, { useState } from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { FormGroup, Col } from 'react-bootstrap';
import _ from 'lodash';
import { ClientProcessingGroupAccountFilter } from '../../../shared/filters/clientProcessingGroupAccountFilter';
import { NewDatePicker } from '../../../shared/newLedgerDatePicker/NewLedgerDatePicker';
import { FilterControl } from '../../../shared/filters/FilterControl';
import { SelectFilter } from '../../../shared/filters/SelectFilter';
import Routes from '../../../routes.js.erb';

export const Filters = (props) => {
  const {
    statuses, dataset, report_groups: reportGroups, report_configs: reportConfigs,
    selectedClientId: defaultClientId,
    selectedProcessingGroupId: defaultProcessingGroupId,
    selectedAccountId: defaultAccountId,
    selectedStatus: defaultStatus,
    selectedDatasetId: defaultDatasetId,
    selectedReportGroupId: defaultReportGroupId,
    selectedReportConfigurationId: defaultReportConfigurationId,
  } = props;

  const [clientPgAccountState, setClientPgAccountState] = useState({
    selectedClientId: defaultClientId,
    selectedAccountId: defaultAccountId,
    selectedProcessingGroupId: defaultProcessingGroupId,
  });

  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);

  const [selectedDatasetId, setSelectedDatasetId] = useState(defaultDatasetId);
  const [selectedReportGroupId, setSelectedReportGroupId] = useState(defaultReportGroupId);
  const [selectedReportConfigurationId,
    setSelectedReportConfigurationId] = useState(defaultReportConfigurationId);
  const [selectedEndDate, setEndDate] = useState(null);

  const statusesOptions = statuses.map(opt => ({ label: _.startCase(opt), value: opt }));
  const datasetOptions = dataset.map(opt => ({ label: opt.name, value: opt.id }));
  const reportGroupOptions = reportGroups.map(opt => ({ label: `(${opt.id}) ${opt.name}`, value: opt.id }));
  const reportConfigOptions = reportConfigs.map(opt => ({ label: `(${opt.id}) ${opt.name}`, value: opt.id }));

  return (
    <form action={Routes.reports_path()}>
      <ClientProcessingGroupAccountFilter
        layout={{ sm: 3 }}
        onChange={setClientPgAccountState}
        accountInputProps={{ name: 'account' }}
        clientInputProps={{ name: 'selected_client' }}
        processingGroupInputProps={{ name: 'processing_group' }}
        {...clientPgAccountState}
      />
      <Col sm={3}>
        <FormGroup>
          <SelectFilter
            id="select_status"
            name="status"
            nullOption={I18n.t('users_portal.reports_reports.index.filters.all_statuses')}
            options={statusesOptions}
            selectedOption={selectedStatus}
            onChange={setSelectedStatus}
          />
        </FormGroup>
      </Col>
      {datasetOptions.length > 0
        && (
        <Col sm={3}>
          <FormGroup>
            <SelectFilter
              id="select_dataset"
              name="dataset"
              nullOption={I18n.t('users_portal.reports_reports.index.filters.all_datasets')}
              options={datasetOptions}
              selectedOption={selectedDatasetId}
              onChange={setSelectedDatasetId}
            />
          </FormGroup>
        </Col>
        )
      }
      {reportGroupOptions.length > 0
        && (
        <Col sm={3}>
          <FormGroup>
            <SelectFilter
              id="select_report_groups"
              name="report_group"
              nullOption={I18n.t('users_portal.reports_reports.index.filters.all_report_groups')}
              options={reportGroupOptions}
              selectedOption={selectedReportGroupId}
              onChange={setSelectedReportGroupId}
            />
          </FormGroup>
        </Col>
        )
      }
      {reportConfigOptions.length > 0
        && (
        <Col sm={3}>
          <FormGroup>
            <SelectFilter
              id="select_report_configurations"
              name="report_config"
              nullOption={I18n.t('users_portal.reports_reports.index.filters.all_report_configurations')}
              options={reportConfigOptions}
              selectedOption={selectedReportConfigurationId}
              onChange={setSelectedReportConfigurationId}
            />
          </FormGroup>
        </Col>
        )
      }
      <Col sm={3}>
        <FormGroup>
          <NewDatePicker
            selectedDate={selectedEndDate}
            onChange={setEndDate}
            inputProps={
              {
                placeholder: 'All End Dates',
                id: 'select_end_date',
                name: 'end_date',
              }
            }
          />
        </FormGroup>
      </Col>

      <Col sm={12}>
        <FilterControl
          onSubmit={() => {}}
          onClear={() => {
            window.location.href = Routes.reports_path();
          }}
        />
      </Col>
    </form>
  );
};

Filters.defaultProps = {
  selectedAccountId: null,
  selectedClientId: null,
  selectedFileName: null,
  selectedProcessingGroupId: null,
  selectedStatus: null,
  selectedDatasetId: null,
  selectedReportGroupId: null,
  selectedReportConfigurationId: null,
};

Filters.propTypes = {
  selectedAccountId: PropTypes.number,
  selectedClientId: PropTypes.number,
  selectedFileName: PropTypes.string,
  selectedProcessingGroupId: PropTypes.number,
  selectedStatus: PropTypes.string,
  selectedDatasetId: PropTypes.number,
  selectedReportGroupId: PropTypes.number,
  selectedReportConfigurationId: PropTypes.number,

  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataset: PropTypes.arrayOf(PropTypes.shape).isRequired,
  report_groups: PropTypes.arrayOf(PropTypes.shape).isRequired,
  report_configs: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
