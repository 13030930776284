import React, { useState, useEffect } from 'react';
import I18n from 'i18n-js';
import { Col, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Client from '../../../client';
import { ProcessesFilter } from '../../shared/components/processesFilter';
import { ProcessesTableRow } from '../components/processesTableRow';
import { Loading } from '../../../shared/loading';

const ProcessesListingContainer = () => {
  const [filterState, setFilterState] = useState({
    selectedClientId: null,
    selectedProcessingGroupId: null,
    selectedAccountType: null,
    selectedProcessStatus: null,
  });
  const [pageMetadata, setPageMetadata] = useState({
    current_page: 1,
    nextPage: 1,
    totalPages: 1,
    totalCount: 1,
  });
  const [loading, setLoading] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchProcesses = (
    clientId,
    processingGroupId,
    accountType,
    processStatus,
    page,
  ) => {
    Client.getProcesses({
      clientId,
      processingGroupId,
      accountType,
      processStatus,
      page,
    })
      .then(response => response.json())
      .then((response) => {
        setLoading(false);
        setProcesses(response.processes);
        setPageMetadata(response.pageMetadata);
      });
  };

  const onFilterChange = (newFilterState) => {
    setFilterState(newFilterState);
    setCurrentPage(1);
  };

  useEffect(() => {
    setLoading(true);

    fetchProcesses(
      filterState.selectedClientId,
      filterState.selectedProcessingGroupId,
      filterState.selectedAccountType,
      filterState.selectedProcessStatus,
      currentPage,
    );
  }, [filterState, currentPage]);

  const handlePageChange = (data) => {
    const pageIndex = data.selected;
    setCurrentPage(pageIndex + 1);
  };

  return (
    <React.Fragment>
      <Row>
        <ProcessesFilter
          layout={{ sm: 3 }}
          onChange={onFilterChange}
          clientInputProps={{ name: 'selected_client' }}
          processingGroupInputProps={{ name: 'processing_group' }}
          accountTypeInputProps={{ name: 'account_types' }}
          processStatusInputProps={{ name: 'process_statuses' }}
          {...filterState}
        />
      </Row>
      <Row>
        <div className="pagination-wrapper text-right">
          <ReactPaginate
            forcePage={currentPage - 1}
            pageCount={pageMetadata.totalPages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            pageClassName="waves-effect"
            activeClassName="active"
            previousClassName="first"
            prevClassName="last"
            previousLabel={<i className="material-icons md-18">arrow_back</i>}
            nextLabel={<i className="material-icons md-18">arrow_forward</i>}
          />
        </div>
      </Row>
      <Row>
        <Col sm={12}>
          {loading ? (
            <div className="text-center">
              <Loading loading />
            </div>
          ) : (
            <Table id="performance-processes-table">
              <thead>
                <tr>
                  <th>{I18n.t('users_portal.processes.table.id')}</th>
                  <th>{I18n.t('users_portal.processes.table.created_at')}</th>
                  <th>{I18n.t('users_portal.processes.table.date_range')}</th>
                  <th>{I18n.t('users_portal.processes.table.accounts')}</th>
                  <th>{I18n.t('users_portal.processes.table.status')}</th>
                </tr>
              </thead>
              <tbody>
                {processes.map(process => (
                  <ProcessesTableRow process={process} key={process.id} />
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Row>
        <div className="pagination-wrapper text-right">
          <ReactPaginate
            forcePage={currentPage - 1}
            pageCount={pageMetadata.totalPages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            pageClassName="waves-effect"
            activeClassName="active"
            previousClassName="first"
            prevClassName="last"
            previousLabel={<i className="material-icons md-18">arrow_back</i>}
            nextLabel={<i className="material-icons md-18">arrow_forward</i>}
          />
        </div>
      </Row>
    </React.Fragment>
  );
};

export { ProcessesListingContainer };
