import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const LinkAsButton = ({
  className, children, disabled, onClick, onDisabled, ...props
}) => (
  <a
    className={classNames(className, 'btn-link', { disabled })}
    onClick={disabled ? e => e.preventDefault() : onClick}
    role="button"
    aria-disabled={disabled}
    href="#"
    {...props}
  >
    { !disabled && children }
    { disabled && !onDisabled && children }
    { disabled && onDisabled }
  </a>
);

LinkAsButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onDisabled: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
};

LinkAsButton.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  onClick: () => {},
  onDisabled: null,
};
