import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { NetGroupCounterparty } from '../shared/netGroupCounterparty';
import { Date } from '../../../../shared';
import { displayNumber } from '../../shared/displayNumber';
import { ErrorPanel } from '../../../../shared/errorPanel';

export const NetPairsSection = ({ error, fxContractGroups }) => (
  <div>
    {error && <ErrorPanel>{error}</ErrorPanel>}
    {
      fxContractGroups.length !== 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                {I18n.t('oona.net_fx_contracts.net_pairs.groups_table.columns.pair')}
              </th>
              <th scope="col">
                {I18n.t('oona.net_fx_contracts.net_pairs.groups_table.columns.base_currency')}
              </th>
              <th scope="col">
                {I18n.t('oona.net_fx_contracts.net_pairs.groups_table.columns.base_side')}
              </th>
              <th scope="col">
                {I18n.t('oona.net_fx_contracts.net_pairs.groups_table.columns.settlement_date')}
              </th>
              <th scope="col">
                {I18n.t('oona.net_fx_contracts.net_pairs.groups_table.columns.net_amount_in_base_currency')}
              </th>
              <th scope="col">
                {I18n.t('oona.net_fx_contracts.net_pairs.groups_table.columns.counterparty')}
              </th>
            </tr>
          </thead>
          <tbody>
            {
            fxContractGroups.map(fxContractGroup => (
              <tr key={fxContractGroup.fxContracts.map(fx => fx.id).join('-')}>
                <th scope="row">{fxContractGroup.currencyPair}</th>
                <th scope="row">{fxContractGroup.baseCurrency.isoCode}</th>
                <th scope="row">{fxContractGroup.baseSide}</th>
                <th scope="row">
                  <Date date={fxContractGroup.settlementDate} />
                </th>
                <th scope="row">
                  {displayNumber(fxContractGroup.netAmountInBaseCurrency)}
                </th>
                <th scope="row">
                  <NetGroupCounterparty counterparties={fxContractGroup.counterparties} />
                </th>
              </tr>
            ))
          }
          </tbody>
        </table>
      )
    }
  </div>
);

NetPairsSection.propTypes = {
  fxContractGroups: PropTypes.arrayOf(PropTypes.shape({
    currencyPair: PropTypes.string.isRequired,
    settlementDate: PropTypes.string.isRequired,
    baseCurrency: PropTypes.shape({
      isoCode: PropTypes.string,
      id: PropTypes.number,
    }).isRequired,
    baseSide: PropTypes.string.isRequired,
    netAmountInBaseCurrency: PropTypes.string.isRequired,
  })).isRequired,
  error: PropTypes.string,
};

NetPairsSection.defaultProps = {
  error: null,
};
