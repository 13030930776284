import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import { currencyLineItemMap as currencyLineItemMapPropTypes } from './commonPropTypes';
import { CurrencyLineItemMapFormGroup } from './currencyLineItemMapFormGroup';
import { CurrencyLineItemMapIdInput } from './currencyLineItemMapIdInput';

const CurrencyLineItemMapsSection = (props) => {
  const {
    onRemove, onAdd, currencyLineItemMaps, destroyed,
  } = props;
  return (
    <div id="currency-line-item-maps">
      {
        currencyLineItemMaps.map((currencyLineItemMap, index) => (
          <CurrencyLineItemMapFormGroup
            key={currencyLineItemMap.uuid}
            {...currencyLineItemMap}
            index={index}
            onRemove={onRemove}
          />
        ))
      }
      {
        destroyed.map((id, index) => {
          const startingIndex = currencyLineItemMaps.length + index;
          return (
            <React.Fragment key={id}>
              <CurrencyLineItemMapIdInput id={id} index={startingIndex} />
              <input
                type="hidden"
                name={`account[currency_line_item_maps_attributes][${startingIndex}][_destroy]`}
                id={`account_currency_line_item_maps_attributes_${startingIndex}__destroy`}
                value="1"
                readOnly
              />
            </React.Fragment>
          );
        })
      }
      <div className="links col-sm-6">
        <Button bsStyle="primary" onClick={onAdd}>
          {I18n.t('users_portal.accounts.form.new_currency_line_item_map')}
        </Button>
      </div>
    </div>
  );
};

CurrencyLineItemMapsSection.propTypes = {
  currencyLineItemMaps: PropTypes.arrayOf(
    PropTypes.shape(currencyLineItemMapPropTypes),
  ),
  destroyed: PropTypes.arrayOf(PropTypes.number),
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

CurrencyLineItemMapsSection.defaultProps = {
  currencyLineItemMaps: [],
  destroyed: [],
};

export { CurrencyLineItemMapsSection };
