import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApi } from '../../../shared/useApi';
import Routes from '../../../routes.js.erb';
import { CurrencyBalanceSheetValidation } from '../components/currencyBalanceSheetValidation';
import { VALIDATION_STATUSES } from '../commonPropTypes';

export const CurrencyBalanceSheetValidationContainer = ({
  validationStatus, validatedBy, id, onValidate,
}) => {
  const { loading, data, callApi } = useApi(
    Routes.bulk_validate_api_v1_currency_balance_sheets_path({ ids: [id] }),
    { method: 'PUT', fireOnMount: false },
  );
  const [validationState, setValidationState] = useState({ validationStatus, validatedBy });
  useEffect(() => {
    if (data) {
      const {
        validationStatus: updatedValidationStatus,
        validatedByEmail: updatedValidatedByEmail,
      } = data.currencyBalanceSheets[0];
      setValidationState({
        validationStatus: updatedValidationStatus,
        validatedBy: updatedValidatedByEmail,
      });
      onValidate();
    }
  }, [data, onValidate]);

  return (
    <CurrencyBalanceSheetValidation
      validationStatus={validationState.validationStatus}
      validatedBy={validationState.validatedBy}
      validating={loading}
      onClick={callApi}
      id={id}
    />
  );
};

CurrencyBalanceSheetValidationContainer.propTypes = {
  validationStatus: PropTypes.oneOf(Object.values(VALIDATION_STATUSES)).isRequired,
  validatedBy: PropTypes.string,
  onValidate: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

CurrencyBalanceSheetValidationContainer.defaultProps = {
  validatedBy: null,
};
