import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

import { FormGroup, ControlLabel, Radio } from 'react-bootstrap';

const AccountSettingFormGroup = (props) => {
  const {
    controlId,
    name,
    namePluralized,
    onChange,
    state,
    options,
  } = props;

  return (
    <FormGroup controlId={controlId}>
      <ControlLabel>{I18n.t(`activerecord.attributes.account.${name}`)}</ControlLabel>
      <div className="column-container">
        {
            options.map(option => (
              <Radio
                key={`${controlId}${option.value}`}
                checked={option.value === state}
                disabled={option.disabled}
                name={name}
                onChange={onChange}
                value={option.value}
              >
                {I18n.t(`activerecord.attributes.account.${namePluralized}.${option.value}`)}
              </Radio>
            ))
          }
      </div>
    </FormGroup>
  );
};

AccountSettingFormGroup.propTypes = {
  controlId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  namePluralized: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { AccountSettingFormGroup };
