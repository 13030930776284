import React from 'react';

import PropTypes from 'prop-types';

import { sharedColumns as sharedColumnsPropTypes } from '../commonPropTypes';

import { HoverCell, HoverCellArray, ValueCell } from '../cell/cells';

export default class Columns extends React.PureComponent {
  render = () => {
    /* eslint-disable camelcase */
    const {
      trade_flag,
      cbs,
      data,
      exposure_date,
      estimates,
      finals,
      rebal,
      roll,
      profit_or_loss,
      value_date,
      roll_date,
      npv,
      status_circle,
      other_trades,
      reports,
      sharedLinks,
    } = this.props;
    /* eslint-disable camelcase */
    return (
      <>
        <td><HoverCell {...data} link={sharedLinks[0]} /></td>
        <td><HoverCell {...cbs} link={sharedLinks[1]} /></td>
        <td><HoverCell isCircle {...trade_flag} link={sharedLinks[2]} /></td>

        <td><ValueCell {...npv} postString="%" defaultValue=" - " /></td>
        <td><ValueCell {...roll_date} /></td>
        <td className="right-divider"><ValueCell {...exposure_date} /></td>

        <td><HoverCellArray data={estimates} links={sharedLinks} /></td>
        <td className="right-divider"><HoverCellArray data={finals} links={sharedLinks} /></td>

        <td className="right-divider"><HoverCellArray data={other_trades} links={sharedLinks} length={3} /></td>

        <td><HoverCellArray data={rebal} links={sharedLinks} /></td>
        <td><HoverCellArray data={roll} links={sharedLinks.slice(1)} /></td>
        <td><HoverCellArray data={profit_or_loss} links={sharedLinks.slice(1)} /></td>

        <td><HoverCell isCircle {...reports} /></td>
        <td><HoverCell isCircle {...status_circle} /></td>

        <td><ValueCell {...value_date} /></td>
      </>
    );
  };
}

Columns.propTypes = {
  ...sharedColumnsPropTypes,
  sharedLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Columns.defaultProps = {};
