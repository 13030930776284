import React from 'react';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import I18n from 'i18n-js';
import Client from '../../../client';
import useIndex from '../../hooks/useIndex';
import { LocalCurrenciesList } from './List';

const apiCallback = _.curry(Client.getExecutionLocalCurrencies);

export default () => {
  const { groupId, subgroupId } = useParams();
  const subgroupsUrl = `/execution_settings/groups/${groupId}/subgroups`;

  const {
    currentPage,
    totalPages,
    items,
    setCurrentPage,
    setItems,
  } = useIndex({
    indexCallback: apiCallback(subgroupId),
    defaultOrder: {},
  });

  const removeItem = async (currencyId) => {
    const res = await Client.removeExecutionLocalCurrency(currencyId);

    if (res.ok) {
      setItems(items.filter(x => x.id !== currencyId));
    } else {
      const message = I18n.t('activerecord.errors.models.execution_subgroups.not_unique_in_group');
      alert(`Cannot delete Execution Local Currency: ${message}`);
    }
  };

  return (
    <>
      <div className="view-container-actions">
        <Link to={subgroupsUrl} className="btn btn-success">Back</Link>
        <Link to={`${subgroupsUrl}/${subgroupId}/local_currencies/new`} className="btn btn-success">New Execution Local Currency</Link>
      </div>

      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>Execution Local Currencies</h1>
        </div>

        <div className="col-sm-12">
          <LocalCurrenciesList
            items={items}
            onRemove={removeItem}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};
