import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { DateTime } from '../../../shared/date';

const Item = (props) => {
  const {
    id,
    tradePurpose,
    tradeSubPurpose,
    createdAt,
    updatedAt,
    onRemove,
  } = props;

  const handleRemove = async () => {
    onRemove(id);
  };

  return (
    <tr>
      <td>{id}</td>
      <td>{tradePurpose ? I18n.t(`activerecord.attributes.fx_contract.trade_purposes.${tradePurpose}`) : ''}</td>
      <td>{tradeSubPurpose}</td>
      <td>
        <DateTime dateTime={createdAt} />
      </td>
      <td>
        <DateTime dateTime={updatedAt} />
      </td>
      <td className="pull-right">
        <a
          className="btn btn-success"
          onClick={handleRemove}
        >
          Delete
        </a>
      </td>
    </tr>
  );
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  tradePurpose: PropTypes.string.isRequired,
  tradeSubPurpose: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Item;
