import React, { Fragment } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Client from '../../client';
import './clientProcessingGroupAccountFilter.scss';
import { AccountFilter } from './accountFilter';
import { ClientFilter } from './clientFilter';
import { ProcessingGroupFilter } from './processingGroupFilter';

export class ClientProcessingGroupAccountFilter extends React.Component {
  static propTypes = {
    selectedClientId: PropTypes.number,
    selectedProcessingGroupId: PropTypes.number,
    selectedAccountId: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    /* eslint-disable react/forbid-prop-types */
    layout: PropTypes.object,
    accountInputProps: PropTypes.object,
    clientInputProps: PropTypes.object,
    processingGroupInputProps: PropTypes.object,
    /* eslint-enable react/forbid-prop-types */
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      clients: [],
      accounts: [],
      processingGroups: [],
      loadingClients: true,
      loadingAccounts: true,
      loadingProcessingGroups: true,
    };
  }

  static getDefaultProps = {
    selectedClientId: null,
    selectedProcessingGroupId: null,
    selectedAccountId: null,
    layout: {},
    accountInputProps: {},
    clientInputProps: {},
    processingGroupInputProps: {},
  };

  componentDidMount = () => {
    const { selectedClientId } = this.props;
    this.fetchClients();
    this.fetchProcessingGroups(selectedClientId);
    this.fetchAccounts(selectedClientId);
  };

  componentDidUpdate = (prevProps) => {
    const { selectedClientId } = this.props;
    if (_.isEqual(selectedClientId, prevProps.selectedClientId)) return;
    this.fetchProcessingGroups(selectedClientId);
    this.fetchAccounts(selectedClientId);
  };

  fetchAccounts = (clientId) => {
    Client
      .getAccounts({ clientId })
      .then(response => response.json())
      .then((data) => {
        this.setState({
          accounts: data.accounts,
          loadingAccounts: false,
        });
      });
  };

  handleClientChange = (option) => {
    const { onChange } = this.props;
    const selectedClientId = option ? option.value : null;
    onChange({
      selectedClientId,
      selectedAccountId: null,
      selectedProcessingGroupId: null,
    });
  };

  handleAccountChange = (option) => {
    const { selectedClientId, selectedProcessingGroupId, onChange } = this.props;
    onChange({
      selectedClientId,
      selectedProcessingGroupId,
      selectedAccountId: option ? option.value : null,
    });
  };

  handleProcessingGroupChange = (option) => {
    const { onChange } = this.props;
    const newSelectedProcessingGroupId = option ? option.value : null;
    if (newSelectedProcessingGroupId != null) {
      onChange({
        selectedProcessingGroupId: newSelectedProcessingGroupId,
      });
    } else {
      onChange({
        selectedProcessingGroupId: null,
        selectedAccountId: null,
      });
    }
  };

  fetchClients() {
    Client
      .getClients()
      .then(response => response.json())
      .then((data) => {
        this.setState({
          clients: data.clients,
          loadingClients: false,
        });
      });
  }

  async fetchProcessingGroups(clientId) {
    const response = await Client.getProcessingGroups({ clientId });
    const data = await response.json();

    if (response.ok) {
      this.setState({
        processingGroups: data.processingGroups,
        loadingProcessingGroups: false,
      });
    } else {
      this.setState({
        processingGroups: [],
        loadingProcessingGroups: false,
      });
    }
  }

  render() {
    const {
      loadingAccounts, loadingClients, loadingProcessingGroups, clients, accounts,
      processingGroups,
    } = this.state;
    const {
      selectedClientId, selectedAccountId, selectedProcessingGroupId, layout,
      clientInputProps, processingGroupInputProps, accountInputProps,
    } = this.props;
    return (
      <Fragment>
        <Col {...layout}>
          <div className="form-group">
            <ClientFilter
              clients={clients}
              loading={loadingClients}
              onChange={this.handleClientChange}
              selectedClientId={selectedClientId}
              {...clientInputProps}
            />
          </div>
        </Col>
        <Col {...layout}>
          <div className="form-group">
            <ProcessingGroupFilter
              processingGroups={processingGroups}
              selectedProcessingGroupId={selectedProcessingGroupId}
              onChange={this.handleProcessingGroupChange}
              loading={loadingProcessingGroups}
              {...processingGroupInputProps}
            />
          </div>
        </Col>
        <Col {...layout}>
          <div className="form-group">
            <AccountFilter
              accounts={accounts}
              selectedAccountId={selectedAccountId}
              onChange={this.handleAccountChange}
              loading={loadingAccounts}
              {...accountInputProps}
            />
          </div>
        </Col>
      </Fragment>
    );
  }
}
