import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { NewNoteTrigger, AddNoteTrigger, NoteTriggerPlaceholder } from './noteButtons';
import Client from '../../client';
import { NewResourceNoteModalContent } from '../create/components/newResourceNoteModalContent';
import { EditNoteModalContent } from '../listing/components/editNoteModal';
import { ACCOUNT, CLIENT, PROCESSING_GROUP } from '../entityTypes';
import { AdminOnly } from '../../shared/adminOnly';
import { Loading } from '../../shared/loading';

export const ProcessingGroupNote = AdminOnly((props) => {
  const { processingGroupId } = props;
  return (
    <ResourceNote
      entity={{ type: PROCESSING_GROUP, id: processingGroupId }}
      {...props}
    />
  );
});

ProcessingGroupNote.propTypes = {
  processingGroupId: PropTypes.number.isRequired,
};

export const AccountNote = AdminOnly((props) => {
  const { accountId } = props;
  return (
    <ResourceNote
      entity={{ type: ACCOUNT, id: accountId }}
      {...props}
    />
  );
});

AccountNote.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export const ClientNote = AdminOnly((props) => {
  const { clientId } = props;
  return (
    <ResourceNote
      entity={{ type: CLIENT, id: clientId }}
      {...props}
    />
  );
});

ClientNote.propTypes = {
  clientId: PropTypes.number.isRequired,
};

class ResourceNote extends React.Component {
  static propTypes = {
    entity: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    showTriggerAsButton: PropTypes.bool,
  };

  static defaultProps = {
    showTriggerAsButton: false,
  };

  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNoteSubmit = this.handleNoteSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = {
      show: false,
      loadingNote: false,
      peekingNote: true,
      note: null,
    };
  }

  componentDidMount() {
    const { entity } = this.props;
    const { id: entityId, type: entityType } = entity;
    Client
      .peekEntityNote(entityType, entityId)
      .then((response) => {
        this.setState({
          peekingNote: false,
          noteExists: response.ok,
        });
      });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(e) {
    e.preventDefault();
    const { entity } = this.props;
    const { id: entityId, type: entityType } = entity;
    this.setState({ show: true, loadingNote: true });
    Client
      .getEntityNote(entityType, entityId)
      .then(response => response.json())
      .then((response) => {
        this.setState({
          note: response.note ? response.note : null,
          loadingNote: false,
          noteExists: !!response.note,
        });
      });
  }

  handleNoteSubmit(note) {
    this.setState({ note, show: false, noteExists: true });
  }

  handleDelete() {
    this.setState({ note: null, show: false, noteExists: false });
  }

  render() {
    const {
      show, loadingNote, note, noteExists, peekingNote,
    } = this.state;
    const { entity, showTriggerAsButton, ...otherProps } = this.props;
    if (peekingNote) return <NoteTriggerPlaceholder asButton={showTriggerAsButton} />;
    return (
      <Fragment>
        { noteExists && (
          <AddNoteTrigger asButton={showTriggerAsButton} onClick={this.handleShow}>
            {otherProps.children}
          </AddNoteTrigger>
        )}
        { !noteExists && (
          <NewNoteTrigger asButton={showTriggerAsButton} onClick={this.handleShow} />
        )}
        <Modal show={show} onHide={this.handleClose} backdrop="static">
          {
            loadingNote && (
              <Modal.Body>
                <div className="text-center">
                  <Loading loading />
                </div>
              </Modal.Body>
            )
          }
          {
            !loadingNote && !note && (
              <NewResourceNoteModalContent
                entity={entity}
                onSubmit={this.handleNoteSubmit}
                {...otherProps}
              />
            )
          }
          {
            !loadingNote && note && (
              <EditNoteModalContent
                note={note}
                onSubmit={this.handleNoteSubmit}
                onDelete={this.handleDelete}
                {...otherProps}
              />
            )
          }
        </Modal>
      </Fragment>
    );
  }
}
