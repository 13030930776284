import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { Table, Alert } from 'react-bootstrap';

import Row from 'react-bootstrap/es/Row';
import Col from 'react-bootstrap/es/Col';

import ProcessingGroupHeaders from './headers';

import { ProcessingGroupRowContainer } from '../../containers/processingGroups/rowContainer';
import { Loading } from '../../../shared/loading';

class ProcessingGroupTable extends React.PureComponent {
  render() {
    const {
      loading, error, processingGroupIds,
    } = this.props;

    const toggleSheets = !isEmpty((new URLSearchParams(window.location.search)).get('toggle'));


    return (
      <Row>
        { error
          && (
            <Alert bsStyle="danger">
              An error occurred while loading the processing dashboard:
              {' '}
              {error}
            </Alert>
          )
        }

        <Col sm={12}>
          <div className="table-responsive with-dropdown">
            { (processingGroupIds.length > 0 || loading) ? (
              <Table id="processing-dashboard-table" className="center-container">
                <ProcessingGroupHeaders />
                <tbody>
                  { loading
                    ? (
                      <tr>
                        <td colSpan={18} className="text-center p-h-sm full-width">
                          <Loading
                            loading={loading}
                          />
                        </td>
                      </tr>
                    )
                    : processingGroupIds.map(processingGroupId => (
                      <ProcessingGroupRowContainer
                        key={processingGroupId}
                        id={processingGroupId}
                        toggleSheets={toggleSheets}
                      />
                    ))
                  }
                </tbody>
              </Table>
            ) : (processingGroupIds.length === 0 && !loading) && (
              <h4>No processing groups data was found for the selected date</h4>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

ProcessingGroupTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  processingGroupIds: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
};

export { ProcessingGroupTable };
