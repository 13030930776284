import React, { memo } from 'react';
import I18n from 'i18n-js';

const headers = () => (
  <>
    <th>{I18n.t('users_portal.processing_dashboard.headers.data')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.cbs')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.trade_flag')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.npv')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.roll_date')}</th>
    <th className="right-divider">{I18n.t('users_portal.processing_dashboard.headers.exposure_date')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.estimates')}</th>
    <th className="right-divider">{I18n.t('users_portal.processing_dashboard.headers.finals')}</th>
    <th className="right-divider">{I18n.t('users_portal.processing_dashboard.headers.other_trades')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.rebal')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.roll')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.p_l')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.reports')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.status')}</th>
    <th>{I18n.t('users_portal.processing_dashboard.headers.value_date')}</th>
  </>
);

headers.propTypes = {};

export default memo(headers);
