import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';

import Row from 'react-bootstrap/es/Row';
import Col from 'react-bootstrap/es/Col';

import I18n from 'i18n-js';

import { AccountWeightsFileInfoContainer } from '../containers/fileInfoContainer';
import { AccountWeightsComparisonTable } from './comparisonTable';
import { AccountWeightsWeightTable } from './weightTable';

import { dayData as dayDataPropType, comparisonDatum as comparisonDatumPropType } from './commonPropTypes';

const size = selectedDates => (selectedDates.length > 1 ? 5 : 6);

const findComparison = (selectedIds, comparisons) => {
  if (selectedIds?.length === 2 && comparisons?.length > 0) {
    const [firstId, secondId] = selectedIds;

    const selectedComparison = comparisons.find(comparison => (
      (comparison.firstId === firstId && comparison.secondId === secondId)
      || (comparison.firstId === secondId && comparison.secondId === firstId)
    ));

    if (selectedComparison) {
      return selectedComparison.values;
    }
  }

  return null;
};

const AccountWeightsDataTable = ({
  loading, error, selectedDates, comparisons,
}) => {
  const [selectedComparison, setSelectedComparison] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    setSelectedIds([]);
    setSelectedComparison(null);
  }, [selectedDates]);

  useEffect(() => {
    setSelectedComparison(findComparison(selectedIds, comparisons));
  }, [comparisons, selectedIds]);

  if (error) {
    return (
      <div className="view-container-content">
        <Alert bsStyle="danger">
          An error occurred while loading the raw data files:
          {' '}
          {error}
        </Alert>
      </div>
    );
  }

  /* eslint-disable no-param-reassign */
  const changeSelectedFile = (idx, date, file) => {
    if (file) {
      const ids = selectedIds;
      ids[idx] = file.id;
      file.date = date;

      setSelectedIds(ids);
      setSelectedComparison(findComparison(selectedIds, comparisons));
    }

    setSelectedFile(file);
  };
  /* eslint-enable no-param-reassign */

  if (selectedDates.length === 0) {
    return (
      <h4 className="m-t m-b-sm m-l">
        { I18n.t('users_portal.account_weights.no_date_selected') }
      </h4>
    );
  }

  return (
    <>
      <Row className="account-weights-container">
        <Col xs={1} />
        { selectedDates.map((data, index) => (
          <AccountWeightsFileInfoContainer
            key={data.date}
            data={data}
            size={size(selectedDates)}
            changeSelectedFile={id => changeSelectedFile(index, data.date, id)}
          />
        ))}
        <Col xs={1} />
      </Row>

      { selectedComparison ? (
        <AccountWeightsComparisonTable
          selectedDates={selectedDates}
          comparison={selectedComparison}
        />
      ) : (
        <AccountWeightsWeightTable selectedFile={selectedFile} loading={loading} />
      )}
    </>
  );
};

AccountWeightsDataTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  selectedDates: PropTypes.arrayOf(dayDataPropType),
  comparisons: PropTypes.arrayOf(comparisonDatumPropType),
};

AccountWeightsDataTable.defaultProps = {
  selectedDates: [],
  comparisons: [],
};

export { AccountWeightsDataTable };
