import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LineItemDifferentCurrencyRow } from './lineItemDifferentCurrencyRow';
import { currencyPropType, hedgeLineItemPropType } from '../../commonPropTypes';

export const LineItemDifferentCurrencyTable = (props) => {
  const {
    lineItems, localCurrency, baseCurrency, hedgeRatioEvaluation, showContent,
  } = props;
  const hedgeEvaluatesOnBaseSide = hedgeRatioEvaluation === 'account_base_currency';

  return (
    <table className={classNames('table', 'currency-exposures-table', { hidden: !showContent })}>
      <thead>
        <tr>
          <th className="text-right">{hedgeEvaluatesOnBaseSide ? localCurrency.isoCode : baseCurrency.isoCode }</th>
          <th />
          <th className="text-right">{hedgeEvaluatesOnBaseSide ? baseCurrency.isoCode : localCurrency.isoCode }</th>
          <th className="text-center">{I18n.t('currency_balance_sheet.currency_exposures.weight')}</th>
          <th className="text-center">{I18n.t('currency_balance_sheet.currency_exposures.date')}</th>
          <th>{I18n.t('currency_balance_sheet.currency_exposures.type')}</th>
        </tr>
      </thead>
      <tbody>
        {
        lineItems.map(lineItem => (
          <LineItemDifferentCurrencyRow
            key={lineItem.id}
            lineItem={lineItem}
            hedgeEvaluatesOnBaseSide={hedgeEvaluatesOnBaseSide}
          />
        ))
      }
      </tbody>
    </table>
  );
};

LineItemDifferentCurrencyTable.propTypes = {
  lineItems: PropTypes.arrayOf(hedgeLineItemPropType).isRequired,
  localCurrency: currencyPropType.isRequired,
  baseCurrency: currencyPropType.isRequired,
  hedgeRatioEvaluation: PropTypes.string.isRequired,
  showContent: PropTypes.bool.isRequired,
};
