import { setupAccountProcessingGroupTable } from '../../shared/processingGroupAccountsTable';

(() => {
  if (document.getElementsByClassName('reports_account_groups-edit').length === 0
    && document.getElementsByClassName('reports_account_groups-new').length === 0) return;

  const setupFtpDestinationForm = () => {
    const element = document.getElementById('reports_account_group_ftp_destination_attributes_uri');
    const destroyCheckbox = document.getElementById('reports_account_group_ftp_destination_attributes__destroy');
    element.addEventListener('change', (event) => {
      const text = event.target.value;
      destroyCheckbox.checked = text.length === 0;
    });
  };

  document.addEventListener('DOMContentLoaded', () => {
    setupAccountProcessingGroupTable();
    setupFtpDestinationForm();
  });
})();
