import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Col, Row, Table } from 'react-bootstrap';
import I18n from 'i18n-js';
import _, { isEmpty } from 'lodash';
import Client from '../../../client';
import { Checkbox } from '../../../shared/checkbox';
import { ProcessesFilter } from '../../shared/components/processesFilter';
import { ProcessModal } from '../components/processModal';

const ProcessesCreateContainer = () => {
  const [filterState, setFilterState] = useState({
    selectedClientId: null,
    selectedProcessingGroupId: null,
  });

  const [accounts, setAccounts] = useState([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const [showProcessModal, setShowProcessModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  const fetchAccounts = (clientId, processingGroupId, accountType) => {
    Client.getAccounts({ clientId, processingGroupId, accountType })
      .then(response => response.json())
      .then((data) => {
        setAccounts(data.accounts);
      });
  };

  useEffect(() => {
    fetchAccounts(
      filterState.selectedClientId,
      filterState.selectedProcessingGroupId,
      filterState.selectedAccountType,
    );
  }, [filterState]);

  const accountIds = () => accounts.map(account => account.id);

  const onCheck = (id) => {
    const newSelectedAccountIds = selectedAccountIds.concat(id);
    setSelectedAccountIds(newSelectedAccountIds);
  };

  const onUncheck = (id) => {
    const newSelectedAccountIds = selectedAccountIds.filter(accountId => accountId !== id);
    setSelectedAccountIds(newSelectedAccountIds);
  };

  const onCheckAll = () => {
    const newSelectedAccountIds = selectedAccountIds.concat(accountIds());
    setSelectedAccountIds(newSelectedAccountIds);
  };

  const onUncheckAll = () => {
    const newSelectedAccountIds = _.difference(selectedAccountIds, accountIds());
    setSelectedAccountIds(newSelectedAccountIds);
  };

  const isAccountSelected = id => selectedAccountIds.includes(id);

  const areAllAccountSelected = () => {
    const includeAllSelected = _.difference(accountIds(), selectedAccountIds).length === 0;
    return accountIds().length > 0 && includeAllSelected;
  };

  const openProcessModal = () => {
    setShowProcessModal(true);
  };

  const closeProcessModal = () => {
    setShowProcessModal(false);
  };

  const handleSubmit = ({ startDate, endDate, calculateValuations }) => {
    setSubmitErrors([]);

    Client.createProcess({
      account_ids: selectedAccountIds,
      start_date: startDate,
      end_date: endDate,
      calculate_valuations: calculateValuations,
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json();
        }
        return window.location.replace('/processes');
      })
      .then((body) => {
        setSubmitErrors(body.errors);
      });
  };

  const setFilter = (filter) => {
    const newFilter = { ...filterState, ...filter };
    setFilterState(newFilter);
  };

  return (
    <React.Fragment>
      <Row>
        <ProcessesFilter
          layout={{ sm: 3 }}
          onChange={setFilter}
          clientInputProps={{ name: 'selected_client' }}
          processingGroupInputProps={{ name: 'processing_group' }}
          {...filterState}
        />
        <div className="btn-toolbar btn-filter-group ">
          <button
            type="button"
            className="btn btn-primary create"
            onClick={openProcessModal}
            disabled={isEmpty(selectedAccountIds)}
          >
            {I18n.t('users_portal.processes.new_process')}
          </button>
        </div>
      </Row>

      <Row>
        <Col sm={12}>
          <div className="table-responsive with-dropdown">
            <Table id="currency-balance-sheets-table">
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      name="All"
                      value
                      onCheck={onCheckAll}
                      onUncheck={onUncheckAll}
                      checked={areAllAccountSelected()}
                    />
                  </th>
                  <th>{I18n.t('activerecord.attributes.processes.id')}</th>
                  <th>{I18n.t('activerecord.attributes.processes.name')}</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map(account => (
                  <tr
                    key={account.id}
                    className={classNames('processing-group-header', 'pg-header')}
                  >
                    <td>
                      <Checkbox
                        name={`account${account.id}`}
                        checked={isAccountSelected(account.id)}
                        onCheck={onCheck}
                        onUncheck={onUncheck}
                        value={account.id}
                      />
                    </td>
                    <td>
#
                      {account.id}
                    </td>
                    <td>{account.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <ProcessModal
        show={showProcessModal}
        onClose={closeProcessModal}
        onSubmit={handleSubmit}
        submitErrors={submitErrors}
      />
    </React.Fragment>
  );
};

export { ProcessesCreateContainer };
