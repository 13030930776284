import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import { useApi } from '../../../shared/useApi';
import Routes from '../../../routes.js.erb';
import { usePrevious } from '../../../shared/usePrevious';

const transformToMomentDate = date => moment(date, 'YYYY-MM-DD');

export const LedgerDatePicker = React.memo(
  ({ selectedAccountId, ledgerDate, onLedgerDateChange }) => {
    const { data: ledgerDatesData, callApi: refreshDates } = useApi(
      selectedAccountId ? Routes.api_v1_account_ledger_dates_path(selectedAccountId) : null,
      { method: 'GET', fireOnMount: false },
    );
    const ledgerDates = ledgerDatesData ? ledgerDatesData.ledgerDates : [];
    const dates = useMemo(() => ledgerDates.map(transformToMomentDate),
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      [JSON.stringify(ledgerDates), transformToMomentDate]);
    const isValidDate = useCallback(
      current => !!dates.find(date => date.isSame(current, 'day')),
      [dates],
    );
    const previousSelectedAccountId = usePrevious(selectedAccountId);

    useEffect(() => {
      if (selectedAccountId !== previousSelectedAccountId && selectedAccountId) {
        refreshDates();
      }
    }, [selectedAccountId, previousSelectedAccountId, refreshDates]);

    useEffect(() => {
      if (previousSelectedAccountId === undefined) return;
      if (selectedAccountId !== previousSelectedAccountId) onLedgerDateChange(null);
    }, [selectedAccountId, previousSelectedAccountId, onLedgerDateChange]);

    return (
      <Datetime
        closeOnSelect
        dateFormat="YYYY-MM-DD"
        isValidDate={isValidDate}
        onChange={newCurrent => onLedgerDateChange(newCurrent ? newCurrent.format('YYYY-MM-DD') : null)}
        timeFormat={false}
        inputProps={{ name: 'ledger_date' }}
        value={ledgerDate ? transformToMomentDate(ledgerDate) : null}
        viewDate={dates.length !== 0 ? moment.max(dates) : moment()}
      />
    );
  },
);

LedgerDatePicker.propTypes = {
  selectedAccountId: PropTypes.number.isRequired,
  ledgerDate: PropTypes.string,
  onLedgerDateChange: PropTypes.func.isRequired,
};

LedgerDatePicker.defaultProps = {
  ledgerDate: null,
};
