import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from './Form';
import Client from '../../../client';
import useForm from '../../hooks/useForm';

const Component = () => {
  const { groupId } = useParams();
  const { subgroupId } = useParams();
  const subgroupsIndexUrl = `/execution_settings/groups/${groupId}/subgroups`;

  const navigate = useNavigate();

  const {
    setFormData,
    setFormErrors,
    formData,
    formErrors,
  } = useForm({ name: '' });

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = useCallback(async () => {
    const res = await Client.getExecutionSubgroup(subgroupId);
    const { executionSubgroup } = await res.json();
    setFormData(executionSubgroup);
  }, [subgroupId, setFormData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async () => {
    const httpResponse = await Client.updateExecutionSubgroup(subgroupId, formData);
    const dataResponse = await httpResponse.json();
    if (httpResponse.ok) {
      navigate(subgroupsIndexUrl);
      return;
    }
    setFormErrors(dataResponse.errors);
  };

  return (
    <div>
      <div className="view-container-actions">
        <a onClick={(e) => { e.preventDefault(); navigate(subgroupsIndexUrl); }} className="btn btn-success">Back</a>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>Edit Execution Subgroup</h1>
        </div>
        <div className="col-sm-12">
          <Form
            onSubmit={handleSubmit}
            errors={formErrors}
            formData={formData}
            onChange={handleFormChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
