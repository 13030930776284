import PropTypes from 'prop-types';
import { SETTLEMENT_TOTAL_OPTIONS } from './components/settlementTotals/settlementTotalsSelector';

export const VALIDATION_STATUSES = {
  VALIDATED: 'validated',
  UNVALIDATED: 'unvalidated',
  UNCHANGED: 'unchanged',
};

export const currencyPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  isoCode: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
});

export const hedgeLineItemPropType = PropTypes.shape({
  localCurrencyAmount: PropTypes.string.isRequired,
  baseCurrencyAmount: PropTypes.string.isRequired,
  valuationDate: PropTypes.string.isRequired,
  settlementDate: PropTypes.string,
  type: PropTypes.string.isRequired,
  localCurrency: currencyPropType.isRequired,
  baseCurrency: currencyPropType.isRequired,
  weight: PropTypes.shape({
    isRelative: PropTypes.bool,
    type: PropTypes.string,
    holdingsValue: PropTypes.string,
    weightValue: PropTypes.string,
    value: PropTypes.string,
  }),
});

export const accountScheduledRollSettingForDatePropTypes = PropTypes.shape({
  scheduledRollDate: PropTypes.string.isRequired,
  nextSettlementDate: PropTypes.string.isRequired,
});

export const accountPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  baseCurrency: currencyPropType.isRequired,
  hedgeRatioEvaluation: PropTypes.string.isRequired,
  currencyExposureSettings: PropTypes.arrayOf(
    PropTypes.shape({
      localCurrency: currencyPropType.isRequired,
      lowerBound: PropTypes.string.isRequired,
      upperBound: PropTypes.string.isRequired,
    }),
  ).isRequired,
  accountScheduledRollSettingForDate: accountScheduledRollSettingForDatePropTypes,
});

const fxShape = {
  allInRate: PropTypes.string,
  buyAmount: PropTypes.string,
  sellAmount: PropTypes.string,
  tradeDate: PropTypes.string,
  settlementDate: PropTypes.string.isRequired,
  fixedSide: PropTypes.oneOf(['buy', 'sell']).isRequired,
  tradeStatus: PropTypes.string.isRequired,
  buyCurrency: currencyPropType,
  sellCurrency: currencyPropType,
  counterparty: PropTypes.shape({
    id: PropTypes.number.isRequired,
    shortName: PropTypes.string.isRequired,
  }),
};
fxShape.openingFxContract = PropTypes.shape(fxShape);
export const fxContractPropType = PropTypes.shape(fxShape);

const settlementTotal = PropTypes.shape({
  fxContractsBase: PropTypes.string.isRequired,
  currencyLineItemsBase: PropTypes.string.isRequired,
});
export const settlementTotalsProps = PropTypes.shape({
  capstockTotal: settlementTotal.isRequired,
  nonCapstockTotal: settlementTotal.isRequired,
  total: settlementTotal.isRequired,
});

export const selectedSettlementTotalProp = PropTypes.oneOf(Object.keys(SETTLEMENT_TOTAL_OPTIONS));

const TotalBaseValuesShape = {
  allSubsReds: PropTypes.string.isRequired,
  crystallizedProfitAndLoss: PropTypes.string.isRequired,
  currencyExposure: PropTypes.string.isRequired,
  marketValue: PropTypes.string.isRequired,
  nav: PropTypes.string.isRequired,
  payableOrReceivable: PropTypes.string.isRequired,
  profitAndLoss: PropTypes.string.isRequired,
};

export const CurrencyBalanceSheetProps = {
  id: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  processingGroupId: PropTypes.number,
  creationTime: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  baseHedgeTotals: PropTypes.shape(TotalBaseValuesShape).isRequired,
  validationStatus: PropTypes.oneOf(Object.values(VALIDATION_STATUSES)).isRequired,
  validatedBy: PropTypes.string,
  account: accountPropTypes.isRequired,
  hedges: PropTypes.arrayOf(
    PropTypes.shape({
      criteria: PropTypes.shape({
        localCurrency: currencyPropType.isRequired,
      }).isRequired,
      currencyExposureHedgeInLocalCurrency: PropTypes.string.isRequired,
      currencyExposureHedgeInBaseCurrency: PropTypes.string.isRequired,
      totalInBaseCurrency: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      totalInLocalCurrency: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      nextSettlementDate: PropTypes.string,
      currencyHedgeRatio: PropTypes.shape({
        localCurrency: currencyPropType.isRequired,
        isOutOfBounds: PropTypes.bool.isRequired,
        percentage: PropTypes.string.isRequired,
      }).isRequired,
      lineItems: PropTypes.arrayOf(
        hedgeLineItemPropType,
      ).isRequired,
      fxContracts: PropTypes.arrayOf(
        fxContractPropType,
      ).isRequired,
      weightSummary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  settlementGroups: PropTypes.arrayOf(
    PropTypes.shape({
      settlementDate: PropTypes.string.isRequired,
      settlements: PropTypes.arrayOf(
        PropTypes.shape({
          daysElapsed: PropTypes.number.isRequired,
          currencyExposureTotalInLocalCurrency: PropTypes.string.isRequired,
          totalInBaseCurrency: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]).isRequired,
          totalInLocalCurrency: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]).isRequired,
          currencyCoverageRatio: PropTypes.shape({
            isoCode: PropTypes.string.isRequired,
            isOutOfBounds: PropTypes.bool.isRequired,
            percentage: PropTypes.string,
            lowerBound: PropTypes.string.isRequired,
            upperBound: PropTypes.string.isRequired,
          }).isRequired,
          criteria: PropTypes.shape({
            localCurrency: currencyPropType.isRequired,
            settlementDate: PropTypes.string.isRequired,
          }).isRequired,
          lineItems: PropTypes.arrayOf(
            hedgeLineItemPropType,
          ).isRequired,
          fxContracts: PropTypes.arrayOf(
            fxContractPropType,
          ).isRequired,
          weightSummary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired,
      ).isRequired,
    }),
  ).isRequired,
  settlementTotals: settlementTotalsProps,
};
