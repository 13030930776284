import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Client from '../client';

const buildPermissionKey = ({ modelAction }) => `can${_.chain(modelAction).camelCase().upperFirst().value()}`;

const buildInitialStateFromRules = rules => _.reduce(rules, (state, rule) => ({
  ...state,
  [buildPermissionKey(rule)]: false,
}), {});

const cachedStorage = new Map();
const buildStoreKey = (modelClass, modelId, modelAction) => [modelClass, modelId, modelAction]
  .filter(val => !!val)
  .map(val => val.toString())
  .join('_');

const getCachedPermission = (rule) => {
  const { modelClass, modelId, modelAction } = rule;
  const cachedResult = cachedStorage.get(buildStoreKey(modelClass, modelId, modelAction));
  return cachedResult || null;
};

const setCachedPermission = (rule) => {
  const { modelClass, modelId, modelAction } = rule;
  cachedStorage.set(buildStoreKey(modelClass, modelId, modelAction), rule);
};

const fetchUserPermissions = (rules, setLoading) => {
  setLoading(true);
  return Client
    .userAuthorized(rules)
    .then(response => response.json());
};

/* FIXME: If in a single request one would ask for the same action for different resources, i.e:
   Show CurrencyBalanceSheet
   Show Note
   Then one permission would override the other one because of how this has been modelled,
   the state returns an object with keys "can${ACTION}" so we would have two "canShow"
   resulting in one overriding
   the other. We need a better payload to differentiate this.
   A workaround of this is using multiple times the hook when this happens,
   or use an alias i.e "read" instead of "show" */

/*
  Example:
  rules = [
    { modelClass: 'RawDataFiles::Base', modelId: id, modelAction: 'show' },
    { modelClass: 'RawDataFiles::Base', modelId: id, modelAction: 'destroy' }
  ]
  initialState = {
    canShow: false,
    canDestroy: false,
    loading: false,
  }
  state = {
    canShow: false | true,
    canDestroy: false | true,
    loading: false | true,
  }
 */

export const useAuthorizedResource = (userRules, doRequestOnMount = true) => {
  const rules = !_.isArray(userRules) ? [userRules] : userRules;
  const [permissions, setPermissions] = useState(buildInitialStateFromRules(rules));
  const [loading, setLoading] = useState(false);

  const setAuthorizationsState = useCallback((retrievedPermissions) => {
    retrievedPermissions.forEach(permission => setCachedPermission(permission));
    const newPermissions = retrievedPermissions.reduce((total, currentPermission) => ({
      ...total,
      [buildPermissionKey(currentPermission)]: currentPermission.authorized,
    }), {});
    setPermissions(newPermissions);
  }, [setPermissions]);

  const doFetch = useCallback((permissionsToFetch, cachedPermissions = []) => {
    fetchUserPermissions(permissionsToFetch, setLoading)
      .then(({ permissions: fetchedPermissions }) => {
        setLoading(false);
        setAuthorizationsState([
          ...fetchedPermissions,
          ...cachedPermissions,
        ]);
      });
  }, [setLoading, setAuthorizationsState]);

  useEffect(() => {
    if (!doRequestOnMount) return;

    const permissionsToFetch = _.reject(rules, rule => getCachedPermission(rule));
    const cachedPermissions = rules.map(rule => getCachedPermission(rule)).filter(val => !!val);

    if (_.isEmpty(permissionsToFetch)) {
      setAuthorizationsState(cachedPermissions);
    } else {
      doFetch(permissionsToFetch, cachedPermissions);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [JSON.stringify(rules), setAuthorizationsState, doRequestOnMount]);

  const refreshPermissions = useCallback(() => {
    doFetch(rules);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [JSON.stringify(rules)]);

  return {
    ...permissions,
    loading,
    refreshPermissions,
  };
};
