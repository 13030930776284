import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { SummaryColumn } from './summaryColumn';
import { Percentage } from '../../../shared';
import { Amount } from '../../../shared/amount';
import { WeightSummary } from './weightSummary';

const timeElapsed = (daysElapsed) => {
  if (daysElapsed > 0) return `(t + ${daysElapsed})`;
  if (daysElapsed < 0) return `(t - ${Math.abs(daysElapsed)})`;
  return '';
};

export const SettlementSummary = (props) => {
  const {
    account: {
      hedgeRatioEvaluation,
      baseCurrency,
    },
    group,
    isExpanded,
    onExpandClick,
    identifier,
  } = props;
  const {
    currencyExposureTotalInLocalCurrency,
    currencyCoverageRatio,
    daysElapsed,
    criteria: {
      localCurrency, settlementDate,
    },
    totalInBaseCurrency,
    totalInLocalCurrency,
    weightSummary,
  } = group;
  const hedgeEvaluatesOnBaseSide = hedgeRatioEvaluation === 'account_base_currency';
  const areDifferentCurrencies = localCurrency.isoCode !== baseCurrency.isoCode;
  const fxContractsLocalSummary = (
    <SummaryColumn title={localCurrency.isoCode} subtitle={I18n.t('currency_balance_sheet.summary.local_currency')}>
      { Amount(totalInLocalCurrency) }
    </SummaryColumn>
  );
  const fxContractsBaseSummary = (
    <SummaryColumn title={baseCurrency.isoCode} subtitle={I18n.t('currency_balance_sheet.summary.base_currency')}>
      { Amount(totalInBaseCurrency) }
    </SummaryColumn>
  );
  const weightSummaryComponent = !_.isNull(weightSummary)
    && <WeightSummary weight={weightSummary} />;
  return (
    <div className="summary-row bottom-separator">
      <div className="summary-column" />
      <SummaryColumn title={localCurrency.isoCode} subtitle={I18n.t('currency_balance_sheet.summary.local_currency')}>
        { Amount(currencyExposureTotalInLocalCurrency) }
      </SummaryColumn>
      { weightSummaryComponent }
      <SummaryColumn
        title={I18n.t('shared.currency_balance_sheets.show.coverage_ratio')}
        subtitle={timeElapsed(daysElapsed)}
      >
        {
          currencyCoverageRatio.percentage && (
            <Percentage percentage={currencyCoverageRatio.percentage} />
          )
        }
      </SummaryColumn>
      <div className="summary-column">
        <div className="value settlement-date">
          { moment(settlementDate).format('DD-MMMM-YYYY') }
        </div>
        <div className="value-subtitle">
          {I18n.t('currency_balance_sheet.summary.next_settlement_date')}
        </div>
      </div>
      {
        !areDifferentCurrencies && (
          <React.Fragment>
            <div className="summary-column" />
            <div className="summary-column" />
          </React.Fragment>
        )
      }
      {
        areDifferentCurrencies && (hedgeEvaluatesOnBaseSide ? (
          <React.Fragment>
            { fxContractsBaseSummary }
            { fxContractsLocalSummary }
          </React.Fragment>
        ) : (
          <React.Fragment>
            { fxContractsLocalSummary }
            { fxContractsBaseSummary }
          </React.Fragment>
        )
        )}
      <a className="collapse-button" role="button" onClick={onExpandClick} tabIndex={0} id={`expand-${identifier}`}>
        <i className="material-icons">{(isExpanded) ? 'unfold_less' : 'unfold_more'}</i>
      </a>
    </div>
  );
};

SettlementSummary.propTypes = {
  account: PropTypes.shape({
    hedgeRatioEvaluation: PropTypes.string.isRequired,
    baseCurrency: PropTypes.shape({
      isoCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  group: PropTypes.shape({
    currencyExposureTotalInLocalCurrency: PropTypes.string.isRequired,
    currencyCoverageRatio: PropTypes.shape({
      isoCode: PropTypes.string.isRequired,
      percentage: PropTypes.string,
      isOutOfBounds: PropTypes.bool.isRequired,
      lowerBound: PropTypes.string.isRequired,
      upperBound: PropTypes.string.isRequired,
    }).isRequired,
    daysElapsed: PropTypes.number.isRequired,
    criteria: PropTypes.shape({
      localCurrency: PropTypes.shape({
        isoCode: PropTypes.string.isRequired,
      }),
      settlementDate: PropTypes.string.isRequired,
    }),
    totalInBaseCurrency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    totalInLocalCurrency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    weightSummary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  isExpanded: PropTypes.bool,
  onExpandClick: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
};

SettlementSummary.defaultProps = {
  isExpanded: false,
};
