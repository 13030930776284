/* eslint-disable-next-line no-restricted-globals */
import I18n from 'i18n-js';
import { WARNABLE_COMBINATIONS } from './warnableCombinations.js.erb';

const warnOnSubmit = (event) => {
  const formData = new FormData(event.currentTarget);
  const rollCurrencyConfiguration = formData.get('account[roll_currency_configuration]');
  const hedgeRatioEvaluation = formData.get('account[hedge_ratio_evaluation]');
  const rollStrategy = formData.get('account[roll_strategy]');
  const profitAndLossConversion = formData.get('account[realized_profit_and_loss_conversion_strategy]');
  const profitAndLossHedging = formData.get('account[realized_profit_and_loss_hedge_strategy]');

  const matches = combination => (
    rollCurrencyConfiguration === combination.roll_currency_configuration
    && hedgeRatioEvaluation === combination.hedge_ratio_evaluation
    && rollStrategy === combination.roll_strategy
    && profitAndLossConversion === combination.realized_profit_and_loss_conversion_strategy
    && profitAndLossHedging === combination.realized_profit_and_loss_hedge_strategy
  );

  const matchingCombination = WARNABLE_COMBINATIONS.find(matches);

  if (!matchingCombination) return;

  const message = I18n.t('users_portal.accounts.form.dangerous_combination', {
    roll_currency_configuration: I18n.t(`activerecord.attributes.account.roll_currency_configurations.${rollCurrencyConfiguration}`),
    hedge_ratio_evaluation: I18n.t(`activerecord.attributes.account.hedge_ratio_evaluations.${hedgeRatioEvaluation}`),
    roll_strategy: I18n.t(`activerecord.attributes.account.roll_strategies.${rollStrategy}`),
    realized_profit_and_loss_conversion_strategy: I18n.t(`activerecord.attributes.account.realized_profit_and_loss_conversion_strategies.${profitAndLossConversion}`),
    realized_profit_and_loss_hedge_strategy: I18n.t(`activerecord.attributes.account.realized_profit_and_loss_hedge_strategies.${profitAndLossHedging}`),
  });

  /* eslint-disable-next-line no-restricted-globals */
  const confirmed = confirm(message);
  if (!confirmed) event.preventDefault();

  const submitButton = document.querySelector('[type="submit"]');
  submitButton.setAttribute('disabled', 'disabled');
};

document.addEventListener('DOMContentLoaded', () => {
  const accountForm = document.getElementById('new_account') || document.getElementById('edit_account');
  if (!accountForm) return;

  accountForm.addEventListener('submit', warnOnSubmit);
});
