import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

const Paginator = ({
  filters, loading, onClick, order, page, totalPages,
}) => {
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (pageLoading && !loading) {
      setPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handlePageChange = (data) => {
    const pageIndex = data.selected;

    setPageLoading(true);

    onClick(filters, order, pageIndex + 1);
  };

  if ((loading && !pageLoading) || !totalPages) return <></>;


  return (
    <div className="pagination-wrapper text-center">
      <ReactPaginate
        forcePage={page - 1}
        pageCount={totalPages}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        pageClassName="waves-effect"
        activeClassName="active"
        previousClassName="first"
        prevClassName="last"
        previousLabel={<i className="material-icons md-18">arrow_back</i>}
        nextLabel={<i className="material-icons md-18">arrow_forward</i>}
      />
    </div>
  );
};

Paginator.propTypes = {
  filters: PropTypes.shape({
    selectedAccountId: PropTypes.number,
    selectedProcessingGroupId: PropTypes.number,
    selectedClientId: PropTypes.number,
    ledgerDate: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  order: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc', null]),
  }).isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export { Paginator };
