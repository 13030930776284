import React from 'react';
import PropTypes from 'prop-types';

const NotificationsCounter = ({ quantity }) => (
  <div className="notifications-counter">
    <span className="label label-info">{quantity}</span>
  </div>
);

NotificationsCounter.propTypes = {
  quantity: PropTypes.number.isRequired,
};

export default NotificationsCounter;
