import PropTypes from 'prop-types';
import React from 'react';
import { accountFileBadgeClassName, fileBadgeText } from '../shared/commonHelpers';
import { statusPropType } from '../shared/commonPropTypes';

export const AccountFileBadge = ({ status, counter }) => {
  const badge = (
    <span className={`badge ${accountFileBadgeClassName(status)}`}>
      {(counter) ? `${counter} ${fileBadgeText(status)}` : `${fileBadgeText(status)}`}
    </span>
  );

  return badge;
};

AccountFileBadge.propTypes = {
  status: statusPropType.isRequired,
  counter: PropTypes.number,
};
AccountFileBadge.defaultProps = {
  counter: null,
};
