import PropTypes from 'prop-types';

export const FeatureFlagged = ({ feature, children, showWhen }) => {
  const featureFlag = window.features[feature];

  if (featureFlag && showWhen === 'enabled') {
    return children;
  } if (!featureFlag && showWhen === 'disabled') {
    return children;
  }
  return null;
};

FeatureFlagged.defaultProps = {
  showWhen: 'enabled',
};

FeatureFlagged.propTypes = {
  showWhen: PropTypes.oneOf(['enabled', 'disabled']),
};
