import React from 'react';
import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { RemoveSelectableFieldButton } from './removeSelectableFieldButton';

class ReportConfigFixedField extends React.PureComponent {
  static propTypes = {
    fieldNameOverride: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onRemoveFixedField: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    value: PropTypes.string,
    isMoving: PropTypes.bool,
  };

  static defaultProps = {
    fieldNameOverride: '',
    value: '',
    isMoving: null,
  };

  handleValueChange = (event) => {
    const { currentTarget: { value } } = event;
    const { onChange, uuid } = this.props;
    onChange(uuid, { value });
  };

  handleFieldNameOverrideChange = (event) => {
    const { currentTarget: { value } } = event;
    const { onChange, uuid } = this.props;
    onChange(uuid, { fieldNameOverride: value });
  };

  handleRemove = (event) => {
    const { onRemoveFixedField, uuid } = this.props;
    onRemoveFixedField(uuid, event);
  };

  render() {
    const { fieldNameOverride, value, isMoving } = this.props;
    return (
      <tr className={`dragndrop-row ${(isMoving) ? 'highlighted-row' : ''}`}>
        <td colSpan={2}>
          <FormControl
            type="text"
            placeholder={I18n.t('activerecord.attributes.reports/config_field.field_name_override')}
            defaultValue={fieldNameOverride}
            onChange={this.handleFieldNameOverrideChange}
            className="form-field-in"
          />
        </td>
        <td colSpan={2}>
          <FormControl
            type="text"
            placeholder={I18n.t('activerecord.attributes.reports/config_field.value')}
            defaultValue={value}
            onChange={this.handleValueChange}
            className="form-field-in"
          />
        </td>
        <td>
          <RemoveSelectableFieldButton onClick={this.handleRemove} />
        </td>
      </tr>
    );
  }
}

export default ReportConfigFixedField;
