// Selectors

const accountInputsOfProcessingGroup = processingGroupId => (
  Array.from(document.querySelectorAll(`#accounts-table input[data-processing-group-id="${processingGroupId}"]`))
);

const processingGroupInputs = processingGroupId => (
  Array.from(document.querySelectorAll(`#processing-groups-table input[data-id="${processingGroupId}"]`))
);

const inputsByClient = (model, clientId) => (
  Array.from(document.querySelectorAll(`#${model}-table input[data-client-id="${clientId}"]`))
);

const includeNewAccountInputs = () => Array.from(document.querySelectorAll('.include-new-account'));

// Utils

const updateAccountCounts = (clientId, amount) => {
  const elem = document.getElementById(`client-account-counts-${clientId}`);

  if (elem) {
    elem.innerText = parseInt(elem.innerText, 10) + amount;
  }
};

const selectAllAccountsByClient = (clientId) => {
  let amount = 0;

  inputsByClient('accounts', clientId)
    .forEach((el) => {
      if (!el.checked) {
        amount += 1;
        /* eslint no-param-reassign: ["error", { "props": false }] */
        el.checked = true;
      }
    });

  updateAccountCounts(clientId, amount);
};

const toggleProcessingGroup = (id, checked) => {
  let amount = 0;

  accountInputsOfProcessingGroup(id)
    .forEach((el) => {
      if (el.checked !== checked) {
        amount += 1;
        /* eslint no-param-reassign: ["error", { "props": false }] */
        el.checked = checked;
      }
    });

  return amount;
};

const checkProcessingGroup = (id, allChecked) => {
  processingGroupInputs(id)
    .forEach((el) => {
      if (el.checked && !allChecked) {
        /* eslint no-param-reassign: ["error", { "props": false }] */
        el.checked = false;
      }
    });
};

// Actions

const processingGroupCheckboxClicked = (event) => {
  const { target: { dataset: { id: processingGroupId, clientId }, checked } } = event;

  const sign = checked ? 1 : -1;
  const amount = toggleProcessingGroup(processingGroupId, checked);

  updateAccountCounts(clientId, amount * sign);
};

const accountCheckboxClicked = (event) => {
  const { target: { dataset: { processingGroupId, clientId }, checked } } = event;
  const allChecked = accountInputsOfProcessingGroup(processingGroupId).every(el => el.checked);

  checkProcessingGroup(processingGroupId, allChecked);

  const amount = checked ? 1 : -1;
  updateAccountCounts(clientId, amount);
};

const selectAllAccountsByClientClicked = (event) => {
  const { target: { dataset: { client_id: clientId } } } = event;

  selectAllAccountsByClient(clientId);
};

const selectAllProcessingGroupsByClient = (event) => {
  const { target: { dataset: { client_id: clientId } } } = event;
  let amount = 0;

  inputsByClient('processing-groups', clientId)
    .forEach((el) => {
      if (!el.checked) {
        /* eslint no-param-reassign: ["error", { "props": false }] */
        el.checked = true;
        const processingGroupId = el.dataset.id;
        amount += toggleProcessingGroup(processingGroupId, true);
      }
    });

  updateAccountCounts(clientId, amount);
};

const selectAllAccounts = () => {
  const clients = Array.from(document.getElementsByClassName('client-datagrid'));

  clients.forEach(({ dataset: { id } }) => selectAllAccountsByClient(id));
};

const selectAllIncludeNewAccounts = () => {
  includeNewAccountInputs().forEach((el) => {
    /* eslint no-param-reassign: ["error", { "props": false }] */
    el.checked = true;
  });
};

// Initializers

const setupAccountCheckboxHandler = () => {
  document
    .querySelectorAll('#accounts-table input')
    .forEach((element) => {
      element.addEventListener('change', accountCheckboxClicked);
    });
};

const setupProcessingGroupCheckboxHandler = () => {
  const processingGroupCheckboxes = document.querySelectorAll('#processing-groups-table input');

  processingGroupCheckboxes.forEach((element) => {
    element.addEventListener('change', processingGroupCheckboxClicked);
  });
};

const setupSelectAllButtons = () => {
  document
    .querySelectorAll('.reports-select-all-accounts')
    .forEach((element) => {
      element.addEventListener('click', selectAllAccountsByClientClicked);
    });

  document
    .querySelectorAll('.reports-select-all-processing-groups')
    .forEach((element) => {
      element.addEventListener('click', selectAllProcessingGroupsByClient);
    });

  const selectAllAccountsButton = document.getElementById('reports_select_all_clients');
  if (selectAllAccountsButton) {
    selectAllAccountsButton.addEventListener('click', selectAllAccounts);
  }

  const includeAllAccountsButton = document.getElementById('reports_include_all_new_accounts');
  if (includeAllAccountsButton) {
    includeAllAccountsButton.addEventListener('click', selectAllIncludeNewAccounts);
  }
};

export const setupAccountProcessingGroupTable = () => {
  setupProcessingGroupCheckboxHandler();
  setupAccountCheckboxHandler();
  setupSelectAllButtons();
};
