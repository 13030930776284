export const labelClassForOperation = side => (side === 'buy' ? 'label label-primary' : 'label label-danger');
export const rightOperationSide = ({ sellCurrency }, baseCurrency) => (sellCurrency.isoCode === baseCurrency.isoCode ? 'sell' : 'buy');
export const leftOperationSide = ({ sellCurrency }, baseCurrency) => (sellCurrency.isoCode === baseCurrency.isoCode ? 'buy' : 'sell');
export const leftCurrency = ({ sellCurrency, buyCurrency }, baseCurrency) => (
  sellCurrency.isoCode === baseCurrency.isoCode ? buyCurrency : sellCurrency
);
export const rightCurrency = ({ sellCurrency, buyCurrency }, baseCurrency) => (
  sellCurrency.isoCode === baseCurrency.isoCode ? sellCurrency : buyCurrency
);
export const amountForOperation = (side, buyAmount, sellAmount) => (side === 'sell' ? sellAmount : buyAmount);
export const rightAmount = (fxContract, baseCurrency) => {
  const { estimatedOrFinalBuyAmount, estimatedOrFinalSellAmount } = fxContract;
  return amountForOperation(
    rightOperationSide(fxContract, baseCurrency),
    estimatedOrFinalBuyAmount,
    estimatedOrFinalSellAmount,
  );
};
export const leftAmount = (fxContract, baseCurrency) => {
  const { estimatedOrFinalBuyAmount, estimatedOrFinalSellAmount } = fxContract;
  return amountForOperation(
    leftOperationSide(fxContract, baseCurrency),
    estimatedOrFinalBuyAmount,
    estimatedOrFinalSellAmount,
  );
};
