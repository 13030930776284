import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

const actioner = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
});

const accountFlag = PropTypes.shape({
  id: PropTypes.number,
  displayName: PropTypes.string,
  priority: PropTypes.number,
  actioner,
  category: PropTypes.string,
  payload: PropTypes.any,
  checker: PropTypes.string,
  accountId: PropTypes.number,
  flagId: PropTypes.number,
  action: PropTypes.string,
  history: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    actioner: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    note: PropTypes.string,
  })),
});

const flagSummary = PropTypes.shape({
  id: PropTypes.number,
  category: PropTypes.string,
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.string,
  note: PropTypes.string,
  priority: PropTypes.number.isRequired,
  accountIds: PropTypes.arrayOf(PropTypes.number),
  actioner,
});

const processingGroup = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  flagSummaries: PropTypes.arrayOf(flagSummary).isRequired,
});

const currencyBalanceSheet = PropTypes.shape({
  id: PropTypes.number.isRequired,
  validationStatus: PropTypes.string.isRequired,
  validatedByEmail: PropTypes.string,
  ledgerDate: PropTypes.oneOfType([
    PropTypes.string,
    momentPropTypes.momentObj,
  ]),
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    shortName: PropTypes.string.isRequired,
  }).isRequired,
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyName: PropTypes.string.isRequired,
    shortCompanyName: PropTypes.string.isRequired,
  }).isRequired,
  accountFlags: PropTypes.arrayOf(accountFlag).isRequired,
  totalExposure: PropTypes.shape({
    amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isoCode: PropTypes.string.isRequired,
    }),
  }),
  totalHedge: PropTypes.shape({
    amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isoCode: PropTypes.string.isRequired,
    }),
  }),
  // TODO: Is it ok that API returns string or number??
  totalNetTradeAmount: PropTypes.shape({
    amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isoCode: PropTypes.string.isRequired,
    }),
  }),
  hedgeRatio: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  checkedAt: PropTypes.string.isRequired,
});

const currentDirection = PropTypes.oneOf(['asc', 'desc', null]);

export {
  currencyBalanceSheet, accountFlag, processingGroup, currentDirection, flagSummary,
};
