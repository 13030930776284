import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import I18n from 'i18n-js';

export const SETTLEMENT_TOTAL_OPTIONS = {
  CAPSTOCK_ONLY: 'CAPSTOCK_ONLY',
  NO_CAPSTOCK: 'NO_CAPSTOCK',
  EVERYTHING: 'EVERYTHING',
};

const displayOption = (option) => {
  const displays = {
    [SETTLEMENT_TOTAL_OPTIONS.CAPSTOCK_ONLY]: I18n.t('settlement_totals.options.capstock_only'),
    [SETTLEMENT_TOTAL_OPTIONS.NO_CAPSTOCK]: I18n.t('settlement_totals.options.no_capstock'),
    [SETTLEMENT_TOTAL_OPTIONS.EVERYTHING]: I18n.t('settlement_totals.options.everything'),
  };
  return displays[option];
};

const SettlementTotalsSelector = ({ selectedOption, onChange }) => {
  const changeToCapstockOnly = useCallback(() => onChange(SETTLEMENT_TOTAL_OPTIONS.CAPSTOCK_ONLY),
    [onChange]);
  const changeToNonCapstock = useCallback(() => onChange(SETTLEMENT_TOTAL_OPTIONS.NO_CAPSTOCK),
    [onChange]);
  const changeToEverything = useCallback(() => onChange(SETTLEMENT_TOTAL_OPTIONS.EVERYTHING),
    [onChange]);
  return (
    <DropdownButton
      id="settlement_total"
      bsStyle="primary"
      title={I18n.t('settlement_totals.selector_title', { option: displayOption(selectedOption) })}
    >
      <MenuItem eventKey="everything" onSelect={changeToEverything}>
        { displayOption(SETTLEMENT_TOTAL_OPTIONS.EVERYTHING) }
      </MenuItem>
      <MenuItem eventKey="capstockOnly" onSelect={changeToCapstockOnly}>
        { displayOption(SETTLEMENT_TOTAL_OPTIONS.CAPSTOCK_ONLY) }
      </MenuItem>
      <MenuItem eventKey="noCapstock" onSelect={changeToNonCapstock}>
        { displayOption(SETTLEMENT_TOTAL_OPTIONS.NO_CAPSTOCK) }
      </MenuItem>
    </DropdownButton>
  );
};


SettlementTotalsSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.oneOf(Object.keys(SETTLEMENT_TOTAL_OPTIONS)).isRequired,
};

export default SettlementTotalsSelector;
