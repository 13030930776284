import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import moment from 'moment';
import { CurrencyBalanceSheetProps } from '../commonPropTypes';
import { CurrencyBalanceSheetValidationContainer } from '../containers/currencyBalanceSheetValidationContainer';
import { TotalBaseValuesSelect } from './totalBaseValuesSelect';

export const CurrencyBalanceSheetHeader = ({
  currencyBalanceSheet, isExpanded, onExpandClick, onValidate,
}) => {
  const {
    id, creationTime, reason, validationStatus, validatedBy,
    account: { baseCurrency: { isoCode: baseIsoCode } },
  } = currencyBalanceSheet;

  return (
    <div className="header-row bottom-separator" id={id}>
      <div className="header-column">
        <span className="reason">
          {I18n.t(`activerecord.attributes.currency_balance_sheet.reasons.${reason}`)}
        </span>
        <span className="reason-details">
          <span>
            { I18n.t('created') }
            :
            { ' ' }
            { moment(creationTime).format('LTS') }
          </span>
          <span>
            { I18n.t('id') }
            :
            { ' ' }
            { id }
          </span>
        </span>
      </div>
      <TotalBaseValuesSelect
        currencyBalanceSheet={currencyBalanceSheet}
        baseIsoCode={baseIsoCode}
      />
      <div className="header-column validation">
        <CurrencyBalanceSheetValidationContainer
          validationStatus={validationStatus}
          validatedBy={validatedBy}
          id={id}
          onValidate={onValidate}
        />
      </div>
      <a className="collapse-button" role="button" onClick={onExpandClick} tabIndex={0} id={`expandDetails${id}`}>
        <i className="material-icons">{(isExpanded) ? 'unfold_less' : 'unfold_more'}</i>
      </a>
    </div>
  );
};

CurrencyBalanceSheetHeader.propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types */
  currencyBalanceSheet: PropTypes.shape(CurrencyBalanceSheetProps).isRequired,
  onExpandClick: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};
