import connect from 'react-redux/es/connect/connect';
import { AccountRow } from '../../components/account/row';

const mapStateToProps = (state, props) => {
  const account = state
    .processingGroups
    .data
    .processingGroups[props.processingGroupId]
    .accounts
    .find(acc => acc.id === props.id);

  return ({
    ledgerDate: state.processingGroups.filters.ledgerDate,
    account,
  });
};

export const AccountRowContainer = connect(mapStateToProps)(AccountRow);
