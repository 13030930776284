import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import { Percentage } from '../../../shared';
import Routes from '../../../routes.js.erb';

const buttonClassnames = sheet => classNames('btn', 'btn-xs', {
  'btn-danger': sheet.currencyHedgeRatio.isOutOfBounds,
  'btn-primary': !sheet.currencyHedgeRatio.isOutOfBounds,
});

const formatDate = ledgerDate => moment(ledgerDate).format('DD-MMM-YY');

const smallSheet = PropTypes.shape({
  id: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  ledgerDate: PropTypes.string.isRequired,
  currencyHedgeRatio: PropTypes.shape({
    percentage: PropTypes.string.isRequired,
    isOutOfBounds: PropTypes.bool.isRequired,
  }),
});

const url = sheet => Routes.account_currency_balance_sheet_path(sheet.accountId, sheet.ledgerDate);

export const PreviousSheetButton = ({ previousSheet }) => (
  <a
    id="previous_sheet"
    href={url(previousSheet)}
    className={buttonClassnames(previousSheet)}
  >
    { '<<' }
    { ' ' }
    { formatDate(previousSheet.ledgerDate) }
    { ' ' }
    { '(' }
    {<Percentage percentage={previousSheet.currencyHedgeRatio.percentage} /> }
    { ')' }
  </a>
);

PreviousSheetButton.propTypes = {
  previousSheet: smallSheet.isRequired,
};

export const NextSheetButton = ({ nextSheet }) => (
  <a
    id="next_sheet"
    href={url(nextSheet)}
    className={buttonClassnames(nextSheet)}
  >
    { formatDate(nextSheet.ledgerDate) }
    { ' ' }
    { '(' }
    {<Percentage percentage={nextSheet.currencyHedgeRatio.percentage} /> }
    { ')' }
    { ' ' }
    { '>>' }
  </a>
);

NextSheetButton.propTypes = {
  nextSheet: smallSheet.isRequired,
};
