import { useState } from 'react';

const useForm = (defaultData) => {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState(defaultData);

  return {
    setFormData,
    setFormErrors,
    formErrors,
    formData,
  };
};

export default useForm;
