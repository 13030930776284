import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import { CurrencyBalanceSheetProps } from '../commonPropTypes';
import { Money } from '../../../shared';
import { WrappedReactSelect } from '../../../shared/wrappedReactSelect';


export const TotalBaseValuesSelect = ({ currencyBalanceSheet }) => {
  const {
    account: { baseCurrency: { isoCode: baseIsoCode } }, baseHedgeTotals,
  } = currencyBalanceSheet;

  const baseTotalOptions = Object.keys(baseHedgeTotals)
    .map(key => ({
      label: I18n.t(`currency_balance_sheet.total_hedges.${_.snakeCase(key)}`),
      value: baseHedgeTotals[key],
    }))
    .filter(obj => parseFloat(obj.value) !== 0.0);

  const getBaseMarketValue = baseTotalOptions.find(total => (
    total.label === I18n.t('currency_balance_sheet.total_hedges.market_value')
  ));

  const defaultTotalValue = (getBaseMarketValue) ? getBaseMarketValue.value : null;

  const [selectedTotalValue, setSelectedTotalValue] = useState(defaultTotalValue);

  const onSelectChange = (selectedOption) => {
    const { value } = selectedOption;
    setSelectedTotalValue(value);
  };

  return (
    <React.Fragment>
      <div className="header-column total-values">
        <span>
          {
            (selectedTotalValue) && (
              Money(selectedTotalValue, baseIsoCode)
            )
          }
        </span>
      </div>
      <div className="header-column total-values-select">
        {
          (baseTotalOptions.length > 0) && (
            <WrappedReactSelect
              options={baseTotalOptions}
              defaultValue={getBaseMarketValue}
              onChange={onSelectChange}
              className="totals-select-container"
              classNamePrefix="totals-select"
            />
          )
        }
      </div>
    </React.Fragment>
  );
};

TotalBaseValuesSelect.propTypes = {
  currencyBalanceSheet: PropTypes.shape(CurrencyBalanceSheetProps).isRequired,
};
