import {
  REQUEST_ACCOUNT_FLAG_NOTES,
  RECEIVE_ACCOUNT_FLAG_NOTES,
} from '../actions/accountFlagsNotes';

const initialState = {};

export default function currencyBalanceSheets(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ACCOUNT_FLAG_NOTES:
      return {
        ...state,
      };

    case RECEIVE_ACCOUNT_FLAG_NOTES: {
      const {
        payload: {
          clientId, flagId, notes, usingAllClients,
        },
      } = action;

      return {
        ...state,
        [flagId]: {
          ...state[flagId],

          [clientId]: {
            notes,
            usingAllClients,
          },
        },
      };
    }

    default:
      return state;
  }
}
