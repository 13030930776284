import { connect } from 'react-redux';

import { changeWeightsDate } from '../actions/accountWeights';
import { AccountWeightsCalendar } from '../components/calendar';

const mapStateToProps = (state, props) => ({
  loading: state.accountWeights.loading,
  error: state.accountWeights.error,
  accountId: state.accountWeights.accountId,
  latestWeightsDate: state.accountWeights.latestWeightsDate,
  previousValuationDate: props.previousValuationDate,
});

export const AccountWeightsCalendarContainer = connect(mapStateToProps, {
  onChange: changeWeightsDate,
})(AccountWeightsCalendar);
