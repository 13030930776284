import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AccountFileBadge } from './AccountFileBadge';
import { FileBadge } from './FileBadge';
import { RawDataFileActions } from './rawDataFileActions';
import { statusPropType } from '../shared/commonPropTypes';

export const RawDataFileTableRow = (props) => {
  const {
    id, publishedDate, createdAt, status, fileName, rawAccountDataMapSetName, origin,
    accountFilesStatuses,
  } = props;

  const accountFileLabels = accountFilesStatuses.map(
    ([accountStatus, counter]) => <AccountFileBadge key={`${id}-${accountStatus}-badge`} status={accountStatus} counter={counter} />,
  );

  const dataFileLabel = <FileBadge status={status} />;

  let printableLabels = '';

  if ((status === 'data_generated' || status === 'parsing_done') && accountFilesStatuses.length > 0) {
    printableLabels = accountFileLabels;
  } else {
    printableLabels = dataFileLabel;
  }
  return (
    <tr>
      <td key={`${id}-id`}>{id}</td>
      <td key={`${id}-filename`}>{fileName}</td>
      <td key={`${id}-set`}>{rawAccountDataMapSetName}</td>
      <td key={`${id}-origin`}>{origin}</td>
      <td key={`${id}-publishedDate`}>{moment(publishedDate).format('MMM DD, YYYY')}</td>
      <td key={`${id}-createdAt`}>{moment(createdAt).format('MMM DD, YYYY HH:mm')}</td>
      <td key={`${id}-status`}>{printableLabels}</td>
      <td key={`${id}-actions`}><RawDataFileActions {...props} /></td>
    </tr>
  );
};

RawDataFileTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  rawAccountDataMapSetName: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  status: statusPropType.isRequired,
  origin: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  /* eslint-disable react/forbid-prop-types */
  filterParams: PropTypes.object,
  /* eslint-disable-next-line react/require-default-props */
  accountFilesStatuses: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  processed: PropTypes.bool.isRequired,
};

RawDataFileTableRow.defaultProps = {
  filterParams: {},
};
