import { useState } from 'react';

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  return {
    setCurrentPage,
    setTotalPages,
    currentPage,
    totalPages,
  };
};

export default usePagination;
