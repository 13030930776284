import React from 'react';
import I18n from 'i18n-js';

import { Provider } from 'react-redux';
import { combineReducers } from 'redux';

import { buildReduxStore } from '../../shared/buildReduxStore';

import processingGroups from '../reducers/processingGroups';

import { ProcessingGroupTableContainer } from './processingGroups/tableContainer';
import { FiltersContainer } from './filtersContainer';
import { PaginatorContainer } from './paginatorContainer';

const rootReducer = combineReducers({
  processingGroups,
});

const store = buildReduxStore({ rootReducer, useCablecar: true });

const ProcessingDashboardContainer = () => (
  <Provider store={store}>
    <div className="view-container-header">
      <h1>{ I18n.t('users_portal.processing_dashboard.title') }</h1>
    </div>
    <div className="view-container-content">
      <div className="col-sm-12">
        <FiltersContainer store={store} />
        <PaginatorContainer />
        <ProcessingGroupTableContainer />
        <PaginatorContainer />
      </div>
    </div>
  </Provider>
);

export { ProcessingDashboardContainer };
