import connect from 'react-redux/es/connect/connect';
import { AccountsTable } from '../../components/account/table';

const mapStateToProps = (state, props) => {
  const { processingGroupId } = props;
  const processingGroup = state.processingGroups.data.processingGroups[processingGroupId];

  return {
    accounts: processingGroup.accounts,
  };
};

export const AccountsTableContainer = connect(mapStateToProps)(AccountsTable);
