import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import I18n from 'i18n-js';
import moment from 'moment';
import { currencyPropType, fxContractPropType } from '../commonPropTypes';
import Routes from '../../../routes.js.erb';
import {
  leftAmount,
  leftCurrency,
  leftOperationSide,
  rightAmount,
  rightCurrency,
  rightOperationSide,
} from '../shared/tradeHelpers';
import { Money } from '../../../shared';
import { SafeLink } from '../../../shared/safeLink';

const FxContractAttribute = ({ attribute, children }) => (
  <li>
    <b>
      {I18n.t(`shared.currency_balance_sheets.opening_trade.${attribute}`)}
    </b>
    :
    {' '}
    {children}
  </li>
);

FxContractAttribute.propTypes = {
  attribute: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const OpeningFxContractPopover = ({ baseCurrency, openingFxContract }) => {
  const { counterparty, tradePurpose } = openingFxContract;
  const popover = (
    <Popover id="opening-fx-contract-popover" placement="top">
      <div className="opening-trade-header">
        <span>
          {I18n.t('shared.currency_balance_sheets.opening_trade.title')}
        </span>
        <SafeLink target="_blank" href={Routes.admin_fx_contract_path(openingFxContract.id)}>
          <i className="material-icons">launch</i>
        </SafeLink>
      </div>
      <ul className="opening-trade-content">
        <FxContractAttribute attribute="id">
          {openingFxContract.id}
        </FxContractAttribute>
        <FxContractAttribute attribute="counterparty">
          {counterparty.shortName}
        </FxContractAttribute>
        <FxContractAttribute attribute={leftOperationSide(openingFxContract, baseCurrency)}>
          {
            Money(leftAmount(openingFxContract, baseCurrency),
              leftCurrency(openingFxContract, baseCurrency).isoCode)
          }
        </FxContractAttribute>
        <FxContractAttribute attribute={rightOperationSide(openingFxContract, baseCurrency)}>
          {
            Money(rightAmount(openingFxContract, baseCurrency),
              rightCurrency(openingFxContract, baseCurrency).isoCode)
          }
        </FxContractAttribute>
        <FxContractAttribute attribute="traded_at">
          { moment(openingFxContract.tradeDate).format('DD-MMM-YY') }
        </FxContractAttribute>
        <FxContractAttribute attribute="settled_at">
          { moment(openingFxContract.settlementDate).format('DD-MMM-YY') }
        </FxContractAttribute>
        <FxContractAttribute attribute="purpose">
          { I18n.t(`activerecord.attributes.fx_contract.trade_purposes.${tradePurpose}`) }
        </FxContractAttribute>
        <FxContractAttribute attribute="status">
          <span className="text-capitalize">
            { openingFxContract.tradeStatus }
          </span>
        </FxContractAttribute>
      </ul>
    </Popover>
  );
  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <SafeLink href="#" onClick={e => e.preventDefault()}>
        <i className="material-icons">info</i>
      </SafeLink>
    </OverlayTrigger>
  );
};

OpeningFxContractPopover.propTypes = {
  baseCurrency: currencyPropType.isRequired,
  openingFxContract: fxContractPropType.isRequired,
};
