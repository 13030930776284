import Client from '../../../client';
import { receiveSendOrder } from './processingGroups';

const REQUEST_PROCESSING_GROUPS = 'REQUEST_PROCESSING_GROUPS';
const RECEIVE_PROCESSING_GROUPS = 'RECEIVE_PROCESSING_GROUPS';
const UPDATE_PROCESSING_GROUP = 'UPDATE_PROCESSING_GROUP';
const ERROR_PROCESSING_GROUPS = 'ERROR_PROCESSING_GROUPS';

const REQUEST_CURRENCY_BALANCE_SHEETS = 'REQUEST_CURRENCY_BALANCE_SHEET';
const RECEIVE_CURRENCY_BALANCE_SHEETS = 'RECEIVE_CURRENCY_BALANCE_SHEETS';
const ERROR_CURRENCY_BALANCE_SHEETS = 'ERROR_CURRENCY_BALANCE_SHEET';

const REQUEST_BULK_VALIDATE_BALANCE_SHEET = 'REQUEST_BULK_VALIDATE_BALANCE_SHEET';
const RECEIVE_BULK_VALIDATE_BALANCE_SHEET = 'RECEIVE_BULK_VALIDATE_BALANCE_SHEET';

const SELECT_BALANCE_SHEET = 'SELECT_BALANCE_SHEET';
const UNSELECT_BALANCE_SHEET = 'UNSELECT_BALANCE_SHEET';

const REQUEST_BULK_REBALANCE_ACCOUNT = 'REQUEST_REBALANCE_ACCOUNT';
const RECEIVE_BULK_REBALANCE_ACCOUNT = 'RECEIVE_REBALANCE_ACCOUNT';

const requestProcessingGroups = (filters, order) => ({
  type: REQUEST_PROCESSING_GROUPS,
  payload: {
    filters,
    order,
  },
});

const receiveProcessingGroups = ({ processingGroups }, order) => ({
  type: RECEIVE_PROCESSING_GROUPS,
  payload: {
    processingGroups,
    order,
  },
});

const updateProcessingGroup = processingGroup => ({
  type: UPDATE_PROCESSING_GROUP,
  payload: {
    processingGroup,
  },
});

const errorProcessingGroups = error => ({
  type: ERROR_PROCESSING_GROUPS,
  payload: {
    error,
  },
});

const fetchProcessingGroups = (filters = {}, order = {}) => (
  async (dispatch) => {
    dispatch(requestProcessingGroups(filters, order));
    const response = await Client.getProcessingGroups(filters, order);

    const json = await response.json();
    if (response.ok) {
      dispatch(receiveProcessingGroups(json, order));
    } else {
      dispatch(errorProcessingGroups(json.message));
    }
  }
);

const requestCurrencyBalanceSheets = ({ processingGroupId, ledgerDate }, order, page) => ({
  type: REQUEST_CURRENCY_BALANCE_SHEETS,
  payload: {
    processingGroupId,
    ledgerDate,
    order,
    page,
  },
});

const receiveCurrencyBalanceSheets = ({ currencyBalanceSheets, paginationMetadata },
  processingGroupId, order) => (
  {
    type: RECEIVE_CURRENCY_BALANCE_SHEETS,
    payload: {
      currencyBalanceSheets,
      processingGroupId,
      order,
      paginationMetadata,
    },
  });

const errorCurrencyBalanceSheets = (processingGroupId, error) => ({
  type: ERROR_CURRENCY_BALANCE_SHEETS,
  payload: {
    processingGroupId,
    error,
  },
});

const fetchCurrencyBalanceSheets = ({
  processingGroupId, ledgerDate, flagId, accountId, clientId,
}, order, page) => (
  async (dispatch) => {
    dispatch(requestCurrencyBalanceSheets({ processingGroupId, ledgerDate }, order, page));
    const response = await Client
      .getCurrencyBalanceSheets({
        processingGroupId, ledgerDate, flagId, accountId, clientId,
      }, order, page);

    const json = await response.json();
    if (response.ok) {
      dispatch(receiveCurrencyBalanceSheets(json, processingGroupId, order));
    } else {
      dispatch(errorCurrencyBalanceSheets(processingGroupId, json.message));
    }
  }
);

const requestBulkValidateBalanceSheet = ids => ({
  type: REQUEST_BULK_VALIDATE_BALANCE_SHEET,
  payload: {
    ids,
  },
});

const receiveBulkValidateBalanceSheet = currencyBalanceSheets => ({
  type: RECEIVE_BULK_VALIDATE_BALANCE_SHEET,
  payload: {
    currencyBalanceSheets,
  },
});

const fetchCurrencyBalanceSheetBulkValidation = ids => (
  (dispatch) => {
    dispatch(requestBulkValidateBalanceSheet(ids));
    return Client
      .bulkValidateCurrencyBalanceSheets(ids)
      .then(
        response => response.json(),
        error => console.log('error occurred', error),
      )
      .then(json => dispatch(receiveBulkValidateBalanceSheet(json.currencyBalanceSheets)));
  }
);

const fetchCurrencyBalanceSheetBulkValidationAndSend = ids => (
  async (dispatch) => {
    dispatch(requestBulkValidateBalanceSheet(ids));

    const response = await Client.bulkValidateCurrencyBalanceSheets(ids);
    const { currencyBalanceSheets } = await response.json();

    dispatch(receiveBulkValidateBalanceSheet(currencyBalanceSheets));

    const responseB = await Client.sendOrder(currencyBalanceSheets.map(b => b.id));
    const { message } = await responseB.json();
    dispatch(receiveSendOrder(message));
  }
);

const requestBulkRebalanceAccount = ids => ({
  type: REQUEST_BULK_REBALANCE_ACCOUNT,
  payload: {
    ids,
  },
});

const receiveBulkRebalanceAccount = currencyBalanceSheets => ({
  type: RECEIVE_BULK_REBALANCE_ACCOUNT,
  payload: {
    currencyBalanceSheets,
  },
});

const fetchBulkAccountRebalance = ids => (
  (dispatch) => {
    dispatch(requestBulkRebalanceAccount(ids));
    return Client
      .rebalanceAccounts(ids)
      .then(
        response => response.json(),
        error => console.log('error occurred', error),
      )
      .then(json => dispatch(receiveBulkRebalanceAccount(json.currencyBalanceSheets)));
  }
);

const selectBalanceSheet = id => (
  {
    type: SELECT_BALANCE_SHEET,
    payload: {
      ids: Array.isArray(id) ? id : [id],
    },
  }
);

const unselectBalanceSheet = id => (
  {
    type: UNSELECT_BALANCE_SHEET,
    payload: {
      ids: Array.isArray(id) ? id : [id],
    },
  }
);

const RECEIVE_ACCOUNT_FLAG = 'RECEIVE_ACCOUNT_FLAG';

const receiveAccountFlag = (currencyBalanceSheetId, accountFlags) => ({
  type: RECEIVE_ACCOUNT_FLAG,
  payload: {
    currencyBalanceSheetId,
    accountFlags,
  },
});

const createAccountFlag = (data) => {
  const {
    accountIds, flagId, action, currencyBalanceSheetId, note, processingGroupId, filters,
  } = data;

  return async (dispatch) => {
    const response = await Client.createAccountFlag(accountIds, flagId, action, note);
    const json = await response.json();

    dispatch(receiveAccountFlag(currencyBalanceSheetId, json));

    const processingGroupsRes = await Client.getProcessingGroups({ ...filters, processingGroupId });
    const processingGroupsData = await processingGroupsRes.json();

    dispatch(updateProcessingGroup(processingGroupsData.processingGroups[0], {}));
  };
};

const RECEIVE_BULK_ACCOUNT_FLAGS = 'RECEIVE_BULK_ACCOUNT_FLAGS';

const receiveBulkAccountFlags = accountFlags => ({
  type: RECEIVE_BULK_ACCOUNT_FLAGS,
  payload: {
    accountFlags,
  },
});

const bulkCreateAccountFlag = (data) => {
  const {
    accountIds, flagId, action, note, processingGroupId, filters,
  } = data;

  return async (dispatch) => {
    const response = await Client.createAccountFlag(accountIds, flagId, action, note);
    const json = await response.json();

    dispatch(receiveBulkAccountFlags(json));

    const processingGroupsRes = await Client.getProcessingGroups({ ...filters, processingGroupId });
    const processingGroupsData = await processingGroupsRes.json();

    dispatch(updateProcessingGroup(processingGroupsData.processingGroups[0], {}));
  };
};

export {
  REQUEST_PROCESSING_GROUPS,
  RECEIVE_PROCESSING_GROUPS,
  UPDATE_PROCESSING_GROUP,
  ERROR_PROCESSING_GROUPS,
  RECEIVE_CURRENCY_BALANCE_SHEETS,
  REQUEST_CURRENCY_BALANCE_SHEETS,
  ERROR_CURRENCY_BALANCE_SHEETS,
  REQUEST_BULK_VALIDATE_BALANCE_SHEET,
  RECEIVE_BULK_VALIDATE_BALANCE_SHEET,
  REQUEST_BULK_REBALANCE_ACCOUNT,
  RECEIVE_BULK_REBALANCE_ACCOUNT,
  SELECT_BALANCE_SHEET,
  UNSELECT_BALANCE_SHEET,
  RECEIVE_ACCOUNT_FLAG,
  RECEIVE_BULK_ACCOUNT_FLAGS,
  fetchCurrencyBalanceSheets,
  fetchProcessingGroups,
  fetchCurrencyBalanceSheetBulkValidation,
  fetchCurrencyBalanceSheetBulkValidationAndSend,
  fetchBulkAccountRebalance,
  selectBalanceSheet,
  unselectBalanceSheet,
  createAccountFlag,
  bulkCreateAccountFlag,
};
