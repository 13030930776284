import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import { RollModal } from '../shared/components/rollModal';
import Client from '../../client';
import { AccountNoRolleableModal } from '../shared/components/accountNoRolleableModal';
import { AccountNotValidatedModal } from '../shared/components/accountNotValidatedModal';
import { AccountDifferentCalendarSettingsModal } from '../shared/components/accountDifferentCalendarSettingsModal';
import { AccountDifferentCalendarDatesModal } from '../shared/components/accountDifferentCalendarDatesModal';
import { accountScheduledRollSettingForDatePropTypes } from '../../currencyBalanceSheets/show/commonPropTypes';

class RollAccountContainer extends React.Component {
  static propTypes = {
    currencyBalanceSheetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
    nextAccountScheduledRollSetting: accountScheduledRollSettingForDatePropTypes,
    buttonClass: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    currentAccountScheduledRollSetting: null,
    nextAccountScheduledRollSetting: null,
    buttonClass: '',
  };

  state = {
    loading: false,
    show: false,
    response: {},
  };

  componentDidMount() {
    this.checkAvailableRolls();
  }

  componentDidUpdate(prevProps) {
    const { currencyBalanceSheetIds } = this.props;

    if (!_.isEqual(_.sortBy(currencyBalanceSheetIds),
      _.sortBy(prevProps.currencyBalanceSheetIds))) {
      this.checkAvailableRolls();
    }
  }

  checkAvailableRolls = () => {
    const { currencyBalanceSheetIds } = this.props;

    if (currencyBalanceSheetIds.length) {
      this.setState({ loading: true });

      Client
        .getAccountAvailableRolls(currencyBalanceSheetIds)
        .then(response => response.json())
        .then((response) => {
          this.setState({ loading: false, response });
        });
    }
  }

  allAccountWithSameCalendarSettings = () => {
    const { response } = this.state;
    const { accounts = [] } = response;

    return accounts.every(account => (
      account.scheduledRollDateCalendar === accounts[0].scheduledRollDateCalendar
    ));
  }

  allAccountWithSameRollDates = () => {
    const { response } = this.state;
    const { accounts = [] } = response;

    const dateMaps = accounts.map((account) => {
      const scheduledRollDates = account.accountScheduledRollSettings.map(setting => (
        setting.scheduledRollDate
      ));

      const nextSettlementDates = account.accountScheduledRollSettings.map(setting => (
        setting.nextSettlementDate
      ));

      return [scheduledRollDates, nextSettlementDates];
    });

    const valid = dateMaps.every((dates) => {
      const comparationSettings = dateMaps[0];

      return _.isEqual(dates[0].sort(), comparationSettings[0].sort())
        && _.isEqual(dates[1].sort(), comparationSettings[1].sort());
    });

    return valid;
  }

  handleRoll = (data, onError) => {
    const { response } = this.state;
    const { accounts = [] } = response;

    if (accounts.length && accounts.length > 1) {
      const accountIds = accounts.map(account => account.id);

      /* eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }] */
      return Client
        .createAccountBulkRoll(accountIds, data)
        .then(bulkResponse => bulkResponse.json())
        .then(({
          success, message, _newSheetPath, error,
        }) => {
          if (success) {
            alert(message);
            this.handleClose();
          } else {
            alert(error);
            onError();
          }
        });
    }

    if (!accounts.length) {
      return null;
    }

    return Client
      .createAccountRoll(accounts[0].id, data)
      .then(accountResponse => accountResponse.json())
      .then(({
        success, message, newSheetPath, error,
      }) => {
        if (success) {
          alert(message);
          window.location.href = newSheetPath;
        } else {
          alert(error);
          onError();
        }
      });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (e) => {
    e.preventDefault();

    this.setState({ show: true });
  };

  renderModal() {
    const { response, show } = this.state;
    const {
      availableRolls = [], accounts = [], processingGroupSetting,
      accountsNotValidated = [],
    } = response;
    const { currencyBalanceSheetIds } = this.props;

    if (!availableRolls.length) {
      return (
        <AccountNoRolleableModal show={show} onHide={this.handleClose} />
      );
    }

    if (accountsNotValidated.length) {
      return (
        <AccountNotValidatedModal
          show={show}
          onHide={this.handleClose}
          currencyBalanceSheetIds={currencyBalanceSheetIds}
          accountsNotValidated={accountsNotValidated}
        />
      );
    }

    if (!this.allAccountWithSameCalendarSettings()) {
      return (
        <AccountDifferentCalendarSettingsModal
          show={show}
          onHide={this.handleClose}
          accounts={accounts}
        />
      );
    }

    if (!this.allAccountWithSameRollDates()) {
      return (
        <AccountDifferentCalendarDatesModal
          show={show}
          onHide={this.handleClose}
          accounts={accounts}
        />
      );
    }

    return (
      <RollModal
        accounts={accounts}
        show={show}
        onClose={this.handleClose}
        onRoll={this.handleRoll}
        {...response}
        {...processingGroupSetting}
      />
    );
  }

  render() {
    const { loading } = this.state;
    const { buttonClass, children } = this.props;

    if (loading) {
      return (
        I18n.t('shared.currency_balance_sheets.open_trades_roll_modal.loading')
      );
    }

    return (
      <React.Fragment>
        <a onClick={this.handleShow} className={buttonClass}>
          { children }
        </a>

        { this.renderModal() }
      </React.Fragment>
    );
  }
}

export { RollAccountContainer };
