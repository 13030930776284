import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { CurrencyBalanceSheetRowContainer } from '../containers/currencyBalanceSheetRowContainer';
import { SortedAccountNameFieldContainer, SortedAccountIdFieldContainer } from '../containers/sortedAccountNameFieldContainer';
import { SortedAccountPriorityFieldContainer } from '../containers/sortedAccountPriorityFieldContainer';
import { Loading } from '../../../shared/loading';
import { Checkbox } from '../../../shared/checkbox';

class ProcessingGroupBalanceSheets extends React.PureComponent {
  render() {
    const {
      expanded, currencyBalanceSheetIds, isFetchingCurrencyBalanceSheets, selected,
      processingGroupId, totalBalanceSheets, onSelect, onUnselect,
    } = this.props;
    if (!expanded) return null;
    const noBalanceSheets = !isFetchingCurrencyBalanceSheets
      && currencyBalanceSheetIds.length === 0;
    return (
      <React.Fragment>
        {
          isFetchingCurrencyBalanceSheets && (
            <tr className="text-center">
              <td colSpan={9}>
                <Loading
                  loading={isFetchingCurrencyBalanceSheets}
                  currentCount={currencyBalanceSheetIds.length}
                  total={totalBalanceSheets}
                />
              </td>
            </tr>
          )
        }
        <tr className="processing-group-header-row">
          <th />
          <th className="text-right">
            {
              processingGroupId !== 0 && (
                <Checkbox
                  spanOptions={{ 'data-test-id': `processingGroupSelect${processingGroupId}` }}
                  name={`selectAllRows${processingGroupId}`}
                  checked={selected}
                  onCheck={onSelect}
                  onUncheck={onUnselect}
                  value={currencyBalanceSheetIds}
                />
              )
            }
          </th>
          <SortedAccountIdFieldContainer
            processingGroupId={processingGroupId}
            data-test-id={`sortByAccountNameProcessingGroup${processingGroupId || ''}`}
          />
          <SortedAccountNameFieldContainer
            processingGroupId={processingGroupId}
            data-test-id={`sortByAccountNameProcessingGroup${processingGroupId || ''}`}
          />
          <th className="text-right">{I18n.t('activerecord.attributes.currency_balance_sheet.total_exposure')}</th>
          <th className="text-right">{I18n.t('activerecord.attributes.currency_balance_sheet.total_hedge')}</th>
          <th className="text-right">{I18n.t('activerecord.attributes.currency_balance_sheet.total_net_trade_amount')}</th>
          <th className="text-center">{I18n.t('activerecord.attributes.currency_balance_sheet.hedge_ratio')}</th>
          <SortedAccountPriorityFieldContainer
            processingGroupId={processingGroupId}
            data-test-id={`sortByAccountStatus${processingGroupId || ''}`}
          />
          <th />
          <th />
        </tr>
        { noBalanceSheets ? (
          <tr className="text-center">
            <td colSpan={9}>{ I18n.t('currency_balance_sheets.index.no_balance_sheets_for_processing_group') }</td>
          </tr>
        ) : null }
        {
          currencyBalanceSheetIds.map(currencyBalanceSheetId => (
            <CurrencyBalanceSheetRowContainer
              key={currencyBalanceSheetId}
              id={currencyBalanceSheetId}
              processingGroupId={processingGroupId}
            />
          ))
        }
      </React.Fragment>
    );
  }
}

ProcessingGroupBalanceSheets.propTypes = {
  currencyBalanceSheetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  expanded: PropTypes.bool.isRequired,
  isFetchingCurrencyBalanceSheets: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  processingGroupId: PropTypes.number,
  totalBalanceSheets: PropTypes.number,
};

ProcessingGroupBalanceSheets.defaultProps = {
  processingGroupId: null,
  totalBalanceSheets: null,
};

export { ProcessingGroupBalanceSheets };
