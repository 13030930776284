import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ControlLabel, Modal,
} from 'react-bootstrap';
import I18n from 'i18n-js';
import FormControl from 'react-bootstrap/lib/FormControl';
import Col from 'react-bootstrap/lib/Col';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Form from 'react-bootstrap/lib/Form';
import _ from 'lodash';
import { CurrencySelect } from './currencySelect';
import { Loading } from '../../shared/loading';
import { HedgeSettlementDatePickerResult } from './hedgeSettlementDatePickerResult';

export const HedgeSettlementDatePicker = ({
  baseCurrencyId, localCurrencyId, counterpartyId, currencies, onFieldChange, counterparties,
  counterpartySettlementStrategies, strategy, onSubmit, loading, pickResult, onClose,
  clearPickResult,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    onClose();
    setShow(false);
  };
  const onTriggerClicked = () => setShow(true);
  const strategyWithCounterparty = strategy.endsWith('per_counterparty');
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <React.Fragment>
      <div className="text-right">
        <Button bsStyle="primary" onClick={onTriggerClicked}>
          {I18n.t('currency_balance_sheets.index.hedge_settlement_date_picker.modal.trigger')}
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} id="rollModal">
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('currency_balance_sheets.index.hedge_settlement_date_picker.modal.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Loading loading={loading} />
          </div>
          {
            !_.isEmpty(pickResult) && !loading && (
              <HedgeSettlementDatePickerResult {...pickResult} onDismiss={clearPickResult} />
            )
          }
          <div className="text-center" />
          <Form horizontal onSubmit={handleSubmit}>
            <FormGroup controlId="baseCurrencyId">
              <Col componentClass={ControlLabel} sm={2}>
                {I18n.t('activerecord.attributes.account.base_currency')}
              </Col>
              <Col sm={10}>
                <CurrencySelect
                  currencies={currencies}
                  value={baseCurrencyId}
                  onChange={onFieldChange}
                  name="baseCurrencyId"
                  required
                  disabled={loading}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="localCurrencyId">
              <Col componentClass={ControlLabel} sm={2}>
                {I18n.t('local_currency')}
              </Col>
              <Col sm={10}>
                <CurrencySelect
                  currencies={currencies}
                  value={localCurrencyId}
                  onChange={onFieldChange}
                  name="localCurrencyId"
                  required
                  disabled={loading}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="strategy">
              <Col componentClass={ControlLabel} sm={2}>
                {I18n.t('strategy')}
              </Col>
              <Col sm={10}>
                <FormControl
                  componentClass="select"
                  name="strategy"
                  value={strategy}
                  onChange={onFieldChange}
                  required
                  disabled={loading}
                >
                  <option />
                  {
                    counterpartySettlementStrategies.map(strategyValue => (
                      <option key={strategyValue} value={strategyValue}>
                        {I18n.t(`activerecord.attributes.account.capstock_counterparty_settlement_strategies.${strategyValue}`)}
                      </option>
                    ))
                  }
                </FormControl>
              </Col>
            </FormGroup>
            {
              strategyWithCounterparty && (
                <FormGroup controlId="counterpartyId">
                  <Col componentClass={ControlLabel} sm={2}>
                    {I18n.t('activerecord.attributes.account.counterparty')}
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      componentClass="select"
                      name="counterpartyId"
                      value={counterpartyId}
                      onChange={onFieldChange}
                      required
                      disabled={loading}
                    >
                      <option value="" />
                      {
                        counterparties.map(({ id, name }) => (
                          <option key={id} value={id}>{name}</option>
                        ))
                      }
                    </FormControl>
                  </Col>
                </FormGroup>
              )
            }
            <FormGroup>
              <Col sm={12}>
                <Button bsStyle="primary" type="submit" disabled={loading} className="pull-right">
                  {I18n.t('submit')}
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

HedgeSettlementDatePicker.propTypes = {
  baseCurrencyId: PropTypes.string.isRequired,
  localCurrencyId: PropTypes.string,
  counterpartyId: PropTypes.string,
  strategy: PropTypes.string,
  counterpartySettlementStrategies: PropTypes.arrayOf(PropTypes.string).isRequired,
  counterparties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  currencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    isoCode: PropTypes.string.isRequired,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  pickResult: PropTypes.shape({
    dates: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  clearPickResult: PropTypes.func.isRequired,
};

HedgeSettlementDatePicker.defaultProps = {
  localCurrencyId: '',
  counterpartyId: '',
  strategy: '',
};
