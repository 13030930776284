import {
  REQUEST_WEIGHTS,
  RECEIVE_WEIGHTS,
  ERROR_WEIGHTS,
} from '../actions/accountWeights';

const initialState = {
  accountWeights: {
    loading: false,
    accountId: null,
    dates: {},
    selectedDates: [],
    comparisons: [],
    error: '',
  },
};

export default function accountWeightsReducer(state = initialState, action) {
  /* eslint-disable no-case-declarations */
  switch (action.type) {
  case REQUEST_WEIGHTS:
    const {
      payload: { dates },
    } = action;

    return {
      ...state,
      loading: true,
      selectedDates: dates,
      comparisons: [],
      error: '',
    };
  case RECEIVE_WEIGHTS:
    const {
      payload: { firstDate, secondDate, comparisons },
    } = action;

    if (!firstDate) {
      return {
        ...state,
        dates: {},
        loading: false,
      };
    }

    let newState = {
      [firstDate.valuationDate]: firstDate.files,
    };

    if (secondDate) {
      newState = {
        ...newState,
        [secondDate.valuationDate]: secondDate.files,
      };
    }

    return {
      ...state,
      loading: false,
      dates: {
        ...state.dates,
        ...newState,
      },
      comparisons,
    };
  case ERROR_WEIGHTS:
    return {
      ...state,
      loading: false,
      error: action.payload.error,
    };
  default:
    return state;
  /* eslint-enable no-case-declarations */
  }
}
