import { useCallback, useEffect, useReducer } from 'react';
import _ from 'lodash';

export const useFxContractsSelection = (selectableFxContractIds) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'selectFxContracts':
        return _.uniq([
          ...state,
          ...action.payload.ids,
        ]);
      case 'unselectFxContracts':
        return _.without(state, ...action.payload.ids);
      default:
        throw new Error(`unknown type ${action.type}`);
    }
  };

  const [selectedFxContractIds, dispatch] = useReducer(reducer, []);

  const selectFxContracts = useCallback(ids => (
    dispatch({ type: 'selectFxContracts', payload: { ids } })
  ), [dispatch]);

  const unselectFxContracts = useCallback(ids => (
    dispatch({ type: 'unselectFxContracts', payload: { ids } })
  ), [dispatch]);

  useEffect(() => {
    const idsNotMoreRelevant = _.difference(selectedFxContractIds, selectableFxContractIds);
    unselectFxContracts(idsNotMoreRelevant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableFxContractIds.toString(), selectedFxContractIds.toString(), unselectFxContracts]);
  return { selectedFxContractIds, selectFxContracts, unselectFxContracts };
};
