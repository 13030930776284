import moment from 'moment';
import I18n from 'i18n-js';
import React from 'react';
import { hedgeLineItemPropType } from '../../commonPropTypes';
import { Amount } from '../../../../shared/amount';
import { RawDataFileLink } from '../rawDataFileLink';
import { LineItemWeight } from './lineItemWeight';

export const LineItemSameCurrencyRow = React.memo(({ lineItem }) => {
  const {
    localCurrencyAmount, weight, valuationDate, rawDataFile, normalizedAccountDatum,
  } = lineItem;

  const type = (normalizedAccountDatum && normalizedAccountDatum.sourceHoldingIdentifier) || I18n.t(`activerecord.attributes.currency_line_items/base.types.${lineItem.type}`);

  return (
    <tr className="currency_line_item_row" id={`line-item-${lineItem.id}`}>
      <td className="text-right">{ Amount(localCurrencyAmount) }</td>
      <td className="text-center">
        <LineItemWeight weight={weight} />
      </td>
      <td className="text-center">{ moment(valuationDate).format('DD-MMM-YY') }</td>
      <td>{ type }</td>
      <td>
        {
          rawDataFile && (
            <RawDataFileLink id={rawDataFile.id} clientId={rawDataFile.clientId} />
          )
        }
      </td>
    </tr>
  );
});

LineItemSameCurrencyRow.propTypes = {
  lineItem: hedgeLineItemPropType.isRequired,
};
