import { generateSortedCurrencyBalanceSheetFieldContainer } from '../shared/sortedFieldContainerFactory';
import { generateSortedCurrencyBalanceSheetValueContainer } from '../shared/sortedValueContainerFactory';

const SortedAccountPriorityFieldContainer = generateSortedCurrencyBalanceSheetFieldContainer({
  currentField: 'account_priority',
  displayKey: 'activerecord.attributes.currency_balance_sheet.status',
});

const SortedAccountPriorityValueContainer = generateSortedCurrencyBalanceSheetValueContainer({
  currentField: 'account_priority',
});

export { SortedAccountPriorityFieldContainer, SortedAccountPriorityValueContainer };
