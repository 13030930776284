import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Dropdown,
  MenuItem,
} from 'react-bootstrap';
import I18n from 'i18n-js';
import moment from 'moment';
import { RemovalModal } from './removalModal';
import { ValidateRemovalModal } from './validateRemovalModal';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { flagClassName } from '../../../shared/flagCategoriesBadgeHelper';
import Routes from '../../../routes.js.erb';
import { DateTime } from '../../../shared';

import { accountFlag } from './commonPropTypes';

export const CustomToggle = React.forwardRef((props, ref) => {
  const {
    children, flag, onClick, top,
  } = props;

  return (
    <span
      ref={ref}
      className={flagClassName(flag)}
      onClick={onClick}
    >
      { flag.action === 'removal_requested' && (
        <i className="material-icons" style={{ top }}>cancel</i>
      )}
      {children}
    </span>
  );
});

export const AccountFlag = (props) => {
  const {
    flag, onCreateAccountFlag, currencyBalanceSheetId, clientId, processingGroupId,
    currentFilters, accountFlagsNotes, onFetchAccountFlagNotes,
  } = props;

  const {
    payload, checker, accountId, flagId, action: flagAction, history, displayName,
  } = flag;

  const {
    canDestroy,
  } = useAuthorizedResource([
    { modelClass: 'AccountFlag', modelAction: 'destroy' },
  ]);


  const [showRemovalModal, setShowRemovalModal] = useState(false);
  const [showValidateRemovalModal, setShowValidateRemovalModal] = useState(false);

  const handleAction = (e, action, note = null) => {
    e.preventDefault();
    onCreateAccountFlag({
      flagId,
      accountIds: [accountId],
      action,
      currencyBalanceSheetId,
      processingGroupId,
      filters: currentFilters,
      note,
    });

    setShowRemovalModal(false);
    setShowValidateRemovalModal(false);
  };

  const removalNotePopup = removalNote => (
    <Popover id={`popover-action-${checker}-${accountId}`}>
      <div style={{ whiteSpace: 'pre-wrap', padding: '1em' }}>
        { removalNote || I18n.t('currency_balance_sheets.account_flag_notes.empty') }
      </div>
    </Popover>
  );

  const canValidateDestroy = actionerId => actionerId !== window.currentUser.id;

  const isRemoval = action => action === 'removal_requested' || action === 'removed';
  const actionName = action => I18n.t(action, { scope: 'activerecord.attributes.account_flag.actions' });

  const historyPopup = (
    <Popover
      id={`popover-${checker}-${accountId}`}
    >
      <ListGroup>
        {
          history.map(line => (
            <ListGroupItem key={line.id}>
              <b>
                {(isRemoval(line.action)) ? (
                  <OverlayTrigger overlay={removalNotePopup(line.note)} placement="top">
                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      { actionName(line.action) }
                    </span>
                  </OverlayTrigger>
                )
                  : actionName(line.action)
                }
              </b>
              &nbsp;
              by
              &nbsp;
              <b>
                {line.actioner}
              </b>
              &nbsp;
              on
              &nbsp;
              <DateTime dateTime={line.createdAt} format="DD-MM-YYYY" />
              &nbsp;
              at
              &nbsp;
              <DateTime dateTime={line.createdAt} format="HH:mm:ss" />
            </ListGroupItem>
          ))
        }
        <ListGroupItem style={{ textAlign: 'center' }}>
          <a href={Routes.admin_account_flags_path({ q: { account_id_eq: accountId, flag_id_eq: flagId } })} target="blank">see more...</a>
        </ListGroupItem>
      </ListGroup>
    </Popover>
  );

  return (
    <Dropdown id={`account-flag-${checker}-${accountId}`}>
      <CustomToggle bsRole="toggle" flag={flag} top="8px">
        {displayName}
      </CustomToggle>

      <RemovalModal
        accountFlagsNotes={accountFlagsNotes}
        flag={flag}
        clientId={clientId}
        onFetchAccountFlagNotes={onFetchAccountFlagNotes}
        onSubmit={(event, action, note) => handleAction(event, action, note)}
        setVisible={setShowRemovalModal}
        visible={showRemovalModal}
      />

      <ValidateRemovalModal
        flag={flag}
        onSubmit={(event, action, note) => handleAction(event, action, note)}
        canValidate={canValidateDestroy(flag.actioner.id)}
        setVisible={setShowValidateRemovalModal}
        visible={showValidateRemovalModal}
      />

      <Dropdown.Menu>
        {
          flagAction === 'added' && (
            <MenuItem onClick={event => handleAction(event, 'reviewed')}>
              Mark As&nbsp;
              { I18n.t('activerecord.attributes.account_flag.actions.reviewed') }
            </MenuItem>
          )
        }

        {
          flagAction === 'reviewed' && (
            <MenuItem onClick={event => handleAction(event, 'added')}>Undo Review</MenuItem>
          )
        }

        {
          !isRemoval(flagAction) && canDestroy && (
            <MenuItem onClick={() => { setShowRemovalModal(true); }}>
              { flag.category === 'negative' ? 'Request flag removal' : 'Remove flag' }
            </MenuItem>
          )
        }

        {
          (flagAction === 'removal_requested') && canDestroy && (
            <MenuItem onClick={() => { setShowValidateRemovalModal(true); }}>
              { canValidateDestroy(flag.actioner.id) ? 'Validate manual removal' : 'View removal request' }
            </MenuItem>
          )
        }

        <OverlayTrigger overlay={historyPopup} placement="left" trigger="focus">
          <MenuItem>
            Show History
          </MenuItem>
        </OverlayTrigger>

        {payload && payload.file_id && (
          <React.Fragment>
            <MenuItem divider />
            <MenuItem
              href={Routes.raw_data_file_path(payload.file_id)}
              target="blank"
            >
              Show Data
            </MenuItem>
          </React.Fragment>
        )}

        {payload && payload.content_type && (
          <React.Fragment>
            <MenuItem divider />
            <MenuItem
              href={Routes.raw_data_files_path({ account_id_in_alias: accountId, type: payload.content_type, published_date: moment(payload.arrival_date).format('DD MMMM YY') })}
              target="blank"
            >
              Show files
            </MenuItem>
          </React.Fragment>
        )}

        {payload && payload.missing_currencies && (
          <React.Fragment>
            <MenuItem divider />
            <MenuItem
              href={Routes.edit_account_path(accountId, { currencies: payload.missing_currencies })}
              target="blank"
            >
              Add Currencies
            </MenuItem>
          </React.Fragment>
        )}

        {checker === 'trades_with_calleo' && (
          <React.Fragment>
            <MenuItem divider />
            <MenuItem
              href={Routes.fx_contracts_path({
                trade_status: [1],
                account_ids: [accountId],
              })}
              target="blank"
            >
              Show Trades
            </MenuItem>
          </React.Fragment>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  flag: accountFlag.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func, undefined]),
  top: PropTypes.string.isRequired,
  action: PropTypes.string,
};

CustomToggle.defaultProps = {
  onClick: undefined,
  action: '',
};

AccountFlag.propTypes = {
  flag: accountFlag.isRequired,
  onCreateAccountFlag: PropTypes.func.isRequired,
  onFetchAccountFlagNotes: PropTypes.func.isRequired,
  currencyBalanceSheetId: PropTypes.number.isRequired,
  processingGroupId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  /* eslint-disable react/forbid-prop-types */
  accountFlagsNotes: PropTypes.object,
  currentFilters: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

AccountFlag.defaultProps = {
  currentFilters: {},
  accountFlagsNotes: {},
};
