import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import Col from 'react-bootstrap/es/Col';
import I18n from 'i18n-js';
import { buildReduxStore } from '../../../shared/buildReduxStore';

import accountWeightsReducer from '../reducers/accountWeights';

import { AccountWeightsCalendarContainer } from './calendarContainer';
import { AccountWeightsDataTableContainer } from './dataTableContainer';


const rootReducer = combineReducers({
  accountWeights: accountWeightsReducer,
});

const initialState = (accountId, valuationDate, weightType) => ({
  accountWeights: {
    loading: false,
    latestWeightsDate: valuationDate,
    accountId,
    weightType,
    dates: {},
    selectedDates: [],
    comparison: [],
    error: '',
  },
});

const AccountWeightsContainer = ({
  accountId, valuationDate, previousValuationDate, weightType,
}) => {
  const store = buildReduxStore({
    rootReducer,
    storeData: initialState(accountId, valuationDate, weightType),
  });

  return (
    <Provider store={store}>
      <Col xs={12} sm={6} md={4}>
        <AccountWeightsCalendarContainer previousValuationDate={previousValuationDate} />
      </Col>
      <Col xs={12} sm={6} md={8} className="view-container d-inline p-h-xs">
        <h1 className="text-align-center m-t-xs">{ I18n.t('users_portal.account_weights.title') }</h1>

        <AccountWeightsDataTableContainer weightType={weightType} />
      </Col>
    </Provider>
  );
};

AccountWeightsContainer.propTypes = {
  accountId: PropTypes.number.isRequired,
  valuationDate: PropTypes.string,
  previousValuationDate: PropTypes.string,
  weightType: PropTypes.string,
};

AccountWeightsContainer.defaultProps = {
  valuationDate: '',
  previousValuationDate: '',
  weightType: '',
};

export { AccountWeightsContainer, initialState };
