import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { currencyPropType, fxContractPropType } from '../commonPropTypes';
import { ExposureTradeTableRow } from './exposureTradesTableRow';

export const ExposureTradesTable = (props) => {
  const {
    hedgeRatioEvaluation, fxContracts, baseCurrency, localCurrency, showContent,
  } = props;
  const hedgeEvaluatesOnBaseSide = hedgeRatioEvaluation === 'account_base_currency';

  return (
    <table className={classNames('table', 'currency-contracts-table', { hidden: !showContent })}>
      <thead>
        <tr>
          <th />
          <th className="text-right" style={{ width: 120 }}>{ hedgeEvaluatesOnBaseSide ? baseCurrency.isoCode : localCurrency.isoCode }</th>
          <th />
          <th />
          <th className="text-right">{ hedgeEvaluatesOnBaseSide ? localCurrency.isoCode : baseCurrency.isoCode }</th>
          <th className="text-center">{ I18n.t('currency_balance_sheet.currency_contracts.dates') }</th>
          <th>{ I18n.t('currency_balance_sheet.currency_contracts.counterparty') }</th>
          <th>{ I18n.t('currency_balance_sheet.currency_contracts.trade_purpose') }</th>
          <th>{ I18n.t('currency_balance_sheet.currency_contracts.trade_status') }</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
        fxContracts.map(fxContract => (
          <ExposureTradeTableRow
            key={fxContract.id}
            fxContract={fxContract}
            hedgeRatioEvaluation={hedgeRatioEvaluation}
            baseCurrency={baseCurrency}
          />
        ))
      }
      </tbody>
    </table>
  );
};

ExposureTradesTable.propTypes = {
  baseCurrency: currencyPropType.isRequired,
  fxContracts: PropTypes.arrayOf(fxContractPropType).isRequired,
  hedgeRatioEvaluation: PropTypes.string.isRequired,
  localCurrency: currencyPropType.isRequired,
  showContent: PropTypes.bool.isRequired,
};
