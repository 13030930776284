import { useEffect, useState, useCallback } from 'react';
import Client from '../client';

export const useApi = (url, options = { method: 'GET', requestData: {}, fireOnMount: true }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { method, requestData, fireOnMount } = options;

  const callApi = useCallback(() => {
    if (!url) return;
    setLoading(true);
    let request;
    if (method === 'GET') {
      request = Client.getJson(url);
    } else if (method === 'POST') {
      request = Client.postJson(url, requestData);
    } else if (method === 'PUT') {
      request = Client.putJson(url, requestData);
    } else if (method === 'DELETE') {
      request = Client.deleteJson(url);
    } else {
      throw new Error(`Unknown method ${method}`);
    }
    request
      .then(res => res.json())
      .then((responseData) => {
        setLoading(false);
        setData(responseData);
      });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [url, setLoading, setData, method, JSON.stringify(requestData)]);

  useEffect(() => {
    if (fireOnMount) callApi();
  }, [url, setLoading, fireOnMount, callApi]);

  return {
    loading,
    data,
    callApi,
  };
};
