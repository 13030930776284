import React, { Fragment } from 'react';
import I18n from 'i18n-js';
import { Tab, NavItem, Nav } from 'react-bootstrap';
import { ExecutionTab } from './executionTab/executionTab';
import NewTradesTab from './newTradesTab/newTradesTab';

const TABS = {
  NEW_TRADES: 'NEW_TRADES',
  EXECUTION: 'EXECUTION',
};

const DashboardContainer = () => (
  <Fragment>
    <div className="ibox float-e-margins">
      <div className="ibox-content">
        <Tab.Container id="tabs-with-dropdown" defaultActiveKey={TABS.NEW_TRADES}>
          <Fragment>
            <Nav bsStyle="tabs">
              <NavItem eventKey={TABS.NEW_TRADES}>
                {I18n.t('oona.dashboard.index.tabs.new_trades')}
              </NavItem>
              <NavItem eventKey={TABS.EXECUTION}>
                {I18n.t('oona.dashboard.index.tabs.execution')}
              </NavItem>
            </Nav>
            <Tab.Content animation>
              <Tab.Pane eventKey={TABS.NEW_TRADES}>
                <NewTradesTab />
              </Tab.Pane>
              <Tab.Pane eventKey={TABS.EXECUTION}>
                <ExecutionTab />
              </Tab.Pane>
            </Tab.Content>
          </Fragment>
        </Tab.Container>
      </div>
    </div>
  </Fragment>
);

export default DashboardContainer;
