import connect from 'react-redux/es/connect/connect';
import {
  fetchCurrencyBalanceSheetBulkValidation, fetchCurrencyBalanceSheetBulkValidationAndSend,
  selectBalanceSheet, unselectBalanceSheet, createAccountFlag,
} from '../actions/currencyBalanceSheets';
import { fetchAccountFlagNotes } from '../actions/accountFlagsNotes';
import { CurrencyBalanceSheetRow } from '../components/currencyBalanceSheetRow';

const isValidating = (state, props) => {
  const { validating: { currencyBalanceSheets, processingGroups } } = state;
  return (!!currencyBalanceSheets[props.id] || !!processingGroups[props.processingGroupId]);
};

const mapStateToProps = (state, props) => ({
  currencyBalanceSheet: state.currencyBalanceSheets.currencyBalanceSheets[props.id],
  isValidating: isValidating(state, props),
  isRebalancing: !!state.rebalancing.currencyBalanceSheets[props.id],
  selected: !!state.selectedSheets.selected[props.id],
  currentFilters: state.processingGroups.filters,
  accountFlagsNotes: state.accountFlagsNotes,
});

const mapDispatchToProps = {
  onValidate: fetchCurrencyBalanceSheetBulkValidation,
  onValidateAndSend: fetchCurrencyBalanceSheetBulkValidationAndSend,
  onSelect: selectBalanceSheet,
  onUnselect: unselectBalanceSheet,
  onCreateAccountFlag: createAccountFlag,
  onFetchAccountFlagNotes: fetchAccountFlagNotes,
};

export const CurrencyBalanceSheetRowContainer = connect(mapStateToProps,
  mapDispatchToProps)(CurrencyBalanceSheetRow);
