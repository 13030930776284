/* eslint-disable max-len */
import { connect } from 'react-redux';
import { fetchProcessingGroupsMetadata } from '../actions/processingGroups';

import { Paginator } from '../components/paginator';

const mapStateToProps = state => ({
  filters: state.processingGroups.filters,
  order: state.processingGroups.order,
  page: state.processingGroups.page,
  loading: state.processingGroups.loading,
  totalPages: state.processingGroups.totalPages,
});

export const PaginatorContainer = connect(mapStateToProps, {
  onClick: fetchProcessingGroupsMetadata,
})(Paginator);
