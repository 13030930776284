import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import I18n from 'i18n-js';
import List from './List';
import Client from '../../../client';
import useIndex from '../../hooks/useIndex';

const apiCallback = _.curry(Client.getExecutionSubgroups);

export default () => {
  const { groupId } = useParams();
  const [group, setGroup] = useState({});

  const {
    currentPage,
    totalPages,
    items,
    setCurrentPage,
    setItems,
  } = useIndex({
    indexCallback: apiCallback(groupId),
    defaultOrder: {},
  });

  const removeItem = async (subgroupId) => {
    const res = await Client.removeExecutionSubgroup(subgroupId);
    if (res.ok) {
      setItems(items.filter(x => x.id !== subgroupId));
    } else {
      // TODO: alert error
    }
  };

  const fetchGroup = useCallback(async () => {
    const res = await Client.getExecutionGroup(groupId);
    const { executionGroup } = await res.json();
    setGroup(executionGroup);
  }, [groupId]);

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  return (
    <>
      <div className="view-container-actions">
        <Link to="/execution_settings" className="btn btn-success">Back</Link>
        <Link to={`/execution_settings/groups/${groupId}/subgroups/new`} className="btn btn-success">New Execution Subgroup</Link>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>Group Details</h1>
          <dl className="dl-horizontal wide-dl m-t-md small text-left">
            <dt>Name</dt>
            <dd>{group.name}</dd>
            <dt>Description</dt>
            <dd>{group.description}</dd>
            <dt>Netting Restrictions</dt>
            <dd>{group.nettingRestrictions}</dd>
            <dt>Client</dt>
            <dd>{group.client && group.client.displayName}</dd>
            <dt>Missing Trade Types</dt>
            <dd>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {
                  group.missingTradeTypes && group.missingTradeTypes.map(tradeType => (
                    <li key={tradeType}>
                      {I18n.t(`activerecord.attributes.fx_contract.trade_purposes.${tradeType}`)}
                    </li>
                  ))
                }
              </ul>
            </dd>
          </dl>
        </div>
        <div className="col-sm-12">
          <h1>Execution Subgroups</h1>
        </div>
        <div className="col-sm-12">
          <List
            groupId={parseInt(groupId, 10)}
            items={items}
            onRemove={removeItem}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};
