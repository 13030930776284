import {
  RECEIVE_CURRENCY_BALANCE_SHEETS,
  REQUEST_PROCESSING_GROUPS,
  RECEIVE_BULK_VALIDATE_BALANCE_SHEET,
  RECEIVE_ACCOUNT_FLAG,
  RECEIVE_BULK_ACCOUNT_FLAGS,
} from '../actions/currencyBalanceSheets';

import {
  RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS,
  RECEIVE_SEND_ORDER,
} from '../actions/processingGroups';

const initialState = {
  currencyBalanceSheets: {},
  sendOrderMessage: '',
};

export default function currencyBalanceSheets(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PROCESSING_GROUPS:
      return {
        ...initialState,
      };
    case RECEIVE_CURRENCY_BALANCE_SHEETS:
      return {
        ...state,
        currencyBalanceSheets: {
          ...action.payload.currencyBalanceSheets.reduce((accumulator, currencyBalanceSheet) => {
            accumulator[currencyBalanceSheet.id] = currencyBalanceSheet;
            return accumulator;
          }, {}),
          ...state.currencyBalanceSheets,
        },
      };
    case RECEIVE_BULK_VALIDATE_BALANCE_SHEET: {
      const newCurrencyBalanceSheets = {
        ...state.currencyBalanceSheets,
      };
      action.payload.currencyBalanceSheets.forEach((cbs) => {
        newCurrencyBalanceSheets[cbs.id] = {
          ...newCurrencyBalanceSheets[cbs.id],
          ...cbs,
        };
      });
      return {
        ...state,
        currencyBalanceSheets: newCurrencyBalanceSheets,
      };
    }
    case RECEIVE_SEND_ORDER: {
      return {
        ...state,
        sendOrderMessage: action.payload.message,
      };
    }
    case RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS: {
      const newCurrencyBalanceSheets = {
        ...state.currencyBalanceSheets,
      };
      action.payload.currencyBalanceSheets.forEach((balanceSheet) => {
        newCurrencyBalanceSheets[balanceSheet.id] = {
          ...newCurrencyBalanceSheets[balanceSheet.id],
          ...balanceSheet,
        };
      });
      return {
        ...state,
        currencyBalanceSheets: newCurrencyBalanceSheets,
      };
    }
    case RECEIVE_ACCOUNT_FLAG: {
      const { payload } = action;

      return {
        ...state,
        currencyBalanceSheets: {
          ...state.currencyBalanceSheets,
          [payload.currencyBalanceSheetId]: {
            ...state.currencyBalanceSheets[payload.currencyBalanceSheetId],
            accountFlags: [
              ...state
                .currencyBalanceSheets[payload.currencyBalanceSheetId]
                .accountFlags
                .filter(x => !payload.accountFlags.map(y => y.flagId).includes(x.flagId)),
              ...payload.accountFlags,
            ].sort((a, b) => a.priority - b.priority),
          },
        },
      };
    }
    case RECEIVE_BULK_ACCOUNT_FLAGS: {
      const { payload } = action;

      const currencyBalanceSheetForAccount = accountId => (
        Object
          .keys(state.currencyBalanceSheets)
          .map(key => state.currencyBalanceSheets[key])
          .find(currencyBalanceSheet => currencyBalanceSheet.account.id === accountId)
      );

      const updatedCurrencyBalanceSheets = payload
        .accountFlags
        .reduce((accumulator, accountFlag) => {
          const currencyBalanceSheet = currencyBalanceSheetForAccount(accountFlag.accountId);

          if (!currencyBalanceSheet) { return accumulator; }

          accumulator[currencyBalanceSheet.id] = {
            ...state.currencyBalanceSheets[currencyBalanceSheet.id],
            accountFlags: [
              ...state
                .currencyBalanceSheets[currencyBalanceSheet.id]
                .accountFlags
                .filter(x => !payload.accountFlags.map(y => y.flagId).includes(x.flagId)),
              ...payload.accountFlags.filter(x => x.accountId === accountFlag.accountId),
            ].sort((a, b) => a.priority - b.priority),
          };
          return accumulator;
        }, {});

      return {
        ...state,
        currencyBalanceSheets: {
          ...state.currencyBalanceSheets,
          ...updatedCurrencyBalanceSheets,
        },
      };
    }
    default:
      return state;
  }
}
