import { SafeAnchor } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import Routes from '../../../routes.js.erb';

export const RawDataFileLink = ({ id, clientId }) => (
  <SafeAnchor target="_blank" href={Routes.raw_data_file_path({ id, client: clientId })}>
    <i className="material-icons">
      launch
    </i>
  </SafeAnchor>
);

RawDataFileLink.propTypes = {
  id: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
};
