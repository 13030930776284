import {
  REQUEST_BULK_VALIDATE_BALANCE_SHEET,
  RECEIVE_BULK_VALIDATE_BALANCE_SHEET,
} from '../actions/currencyBalanceSheets';

import {
  REQUEST_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS,
  RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS,
} from '../actions/processingGroups';

// TODO: maybe we can simplify this state changing it from object to an array (Luigi)
// {currencyBalanceSheets: [], processingGroups: []}
const initialState = {
  currencyBalanceSheets: {},
  processingGroups: {},
};

export default function currencyBalanceSheets(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BULK_VALIDATE_BALANCE_SHEET:
      return {
        ...state,
        currencyBalanceSheets: {
          ...state.currencyBalanceSheets,
          ...action.payload.ids.reduce((acc, id) => {
            acc[id] = true;
            return acc;
          }, {}),
        },
      };
    case REQUEST_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS:
      return {
        ...state,
        processingGroups: {
          ...state.processingGroups,
          [action.payload.id]: true,
        },
      };
    case RECEIVE_VALIDATE_MOST_RECENT_BALANCE_SHEETS_IN_PROCESSING_GROUPS: {
      const newProcessingGroupsValidatingState = {
        ...state.processingGroups,
      };
      delete newProcessingGroupsValidatingState[action.payload.id];
      return {
        ...state,
        processingGroups: newProcessingGroupsValidatingState,
      };
    }
    case RECEIVE_BULK_VALIDATE_BALANCE_SHEET: {
      const newValidatingState = {
        ...state.currencyBalanceSheets,
      };
      action.payload.currencyBalanceSheets.forEach((cbs) => {
        delete newValidatingState[cbs.id];
      });
      return {
        ...state,
        currencyBalanceSheets: newValidatingState,
      };
    }
    default:
      return state;
  }
}
