import React from 'react';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import I18n from 'i18n-js';
import List from './List';
import Client from '../../../client';
import useIndex from '../../hooks/useIndex';

const apiCallback = _.curry(Client.getExecutionTradeTypes);

export default () => {
  const { groupId, subgroupId } = useParams();
  const subgroupsUrl = `/execution_settings/groups/${groupId}/subgroups`;

  const {
    currentPage,
    totalPages,
    items,
    setCurrentPage,
    setItems,
  } = useIndex({
    indexCallback: apiCallback(subgroupId),
    defaultOrder: {},
  });

  const removeItem = async (tradeTypeId) => {
    const res = await Client.removeExecutionTradeType(tradeTypeId);
    if (res.ok) {
      setItems(items.filter(x => x.id !== tradeTypeId));
    } else {
      const errorMessage = I18n.t('activerecord.errors.models.execution_subgroups.not_unique_in_group');
      alert(`Cannot delete Execution Trade Type: ${errorMessage}`);
    }
  };

  return (
    <>
      <div className="view-container-actions">
        <Link to={subgroupsUrl} className="btn btn-success">Back</Link>
        <Link to={`${subgroupsUrl}/${subgroupId}/trade_types/new`} className="btn btn-success">New Execution Trade Type</Link>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>Execution Trade Types</h1>
        </div>
        <div className="col-sm-12">
          <List
            items={items}
            onRemove={removeItem}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};
