/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Viewer } from './viewer';

const Popup = ({ positionLeft, positionTop, body }) => (
  <Popover
    id="notes-popover"
    placement="left"
    positionTop={positionTop}
    positionLeft={positionLeft}
  >
    <div style={{ margin: 2 }}>
      <Viewer value={body} />
    </div>
  </Popover>
);

Popup.propTypes = {
  positionTop: PropTypes.number.isRequired,
  positionLeft: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
};

const QuickView = ({ note }) => (
  <OverlayTrigger
    trigger={['hover', 'focus']}
    placement="left"
    overlay={
      (
        <Popup

          positionLeft={0}
          positionTop={0}
          body={note.text}
        />
      )
    }
  >
    <i className="material-icons" style={{ marginTop: '0.3em', cursor: 'pointer' }}>note</i>
  </OverlayTrigger>

);

QuickView.propTypes = {
  note: PropTypes.shape({ text: PropTypes.string.isRequired }).isRequired,
};

export default QuickView;
