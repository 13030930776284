import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  MenuItem,
} from 'react-bootstrap';
import I18n from 'i18n-js';
import { useAuthorizedResource } from '../../../shared/useAuthorizedResource';
import { CustomToggle } from './accountFlag';
import { RemovalModal } from './removalModal';
import { ValidateRemovalModal } from './validateRemovalModal';

import { flagSummary } from './commonPropTypes';

const FlagSummary = (props) => {
  const {
    summary, processingGroupId, accountFlagsNotes, onBulkCreateAccountFlag,
    onFetchAccountFlagNotes, currentFilters, clientId,
  } = props;

  const {
    name, count, accountIds, id, action: flagAction, category,
  } = summary;

  const {
    canDestroy,
  } = useAuthorizedResource([
    { modelClass: 'AccountFlag', modelAction: 'destroy' },
  ]);

  const [showRemovalModal, setShowRemovalModal] = useState(false);
  const [showValidateRemovalModal, setShowValidateRemovalModal] = useState(false);

  const handleAction = (e, action, note = null) => {
    e.preventDefault();
    onBulkCreateAccountFlag({
      flagId: id,
      accountIds,
      action,
      processingGroupId,
      filters: currentFilters,
      note,
    });

    setShowRemovalModal(false);
    setShowValidateRemovalModal(false);
  };

  const canValidateDestroy = actionerId => actionerId !== window.currentUser.id;

  const isRemoval = flagAction === 'removal_requested' || flagAction === 'removed';

  return (
    <Dropdown id={`flag-summary-${category}`}>
      <CustomToggle bsRole="toggle" flag={summary} top="0px">
        {`(${count})`}
        {' '}
        {name}
      </CustomToggle>

      <RemovalModal
        accountFlagsNotes={accountFlagsNotes}
        flag={{
          flagId: summary.id,
          displayName: summary.name,
          category: summary.category,
        }}
        clientId={clientId}
        onFetchAccountFlagNotes={onFetchAccountFlagNotes}
        onSubmit={(event, action, note) => handleAction(event, action, note)}
        setVisible={setShowRemovalModal}
        visible={showRemovalModal}
      />

      <ValidateRemovalModal
        flag={{
          displayName: summary.name,
          note: summary.note,
          actioner: summary.actioner,
        }}
        canValidate={canValidateDestroy(summary.actioner.id)}
        onSubmit={(event, action, note) => handleAction(event, action, note)}
        setVisible={setShowValidateRemovalModal}
        visible={showValidateRemovalModal}
      />

      <Dropdown.Menu>
        {
          flagAction === 'added' && (
            <MenuItem onClick={event => handleAction(event, 'reviewed')}>
              Mark As&nbsp;
              {I18n.t('activerecord.attributes.account_flag.actions.reviewed')}
            </MenuItem>
          )
        }

        {
          flagAction === 'reviewed' && (
            <MenuItem onClick={event => handleAction(event, 'added')}>Undo Review</MenuItem>
          )
        }


        {
          !isRemoval && canDestroy && (
            <MenuItem onClick={() => { setShowRemovalModal(true); }}>
              {summary.category === 'negative' ? 'Request flag removal' : 'Remove flag'}
            </MenuItem>
          )
        }

        {
          flagAction === 'removal_requested' && canDestroy && (
            <MenuItem onClick={() => { setShowValidateRemovalModal(true); }}>
              { canValidateDestroy(summary.actioner.id) ? 'Validate manual removal' : 'View removal request' }
            </MenuItem>
          )
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};


FlagSummary.propTypes = {
  summary: flagSummary.isRequired,
  processingGroupId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  onBulkCreateAccountFlag: PropTypes.func.isRequired,
  onFetchAccountFlagNotes: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  accountFlagsNotes: PropTypes.object.isRequired,
  currentFilters: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

export default FlagSummary;
