import {
  REQUEST_PD_PROCESSING_GROUPS_METADATA,
  RECEIVE_PD_PROCESSING_GROUPS_METADATA,
  ERROR_PD_PROCESSING_GROUPS_METADATA,
  RECEIVE_PD_PROCESSING_GROUP,
  ERROR_PD_PROCESSING_GROUP,
  requestProcessingGroupsData,
  fetchProcessingGroupsMetadata,
} from '../actions/processingGroups';

const initialState = {
  loading: false,
  data: {
    processingGroups: {},
    processingGroupIds: [],
  },
  filters: {
    clientId: null,
    accountId: null,
    processingGroupId: null,
    ledgerDate: null,
    perPage: null,
  },
  page: 1,
  totalPages: 0,
  order: {
    field: 'processing_group_name',
    direction: 'asc',
  },
  error: '',
  connected: true,
};

export default function processingGroupReducer(state = initialState, action) {
  /* eslint-disable no-case-declarations */
  switch (action.type) {
    case REQUEST_PD_PROCESSING_GROUPS_METADATA:
      const {
        payload: {
          filters, order, page, forceReload,
        },
      } = action;

      return {
        ...state,
        loading: true,
        data: {
          processingGroups:
            state.filters.ledgerDate === filters.ledgerDate && !forceReload
              ? state.data.processingGroups
              : {},
          processingGroupIds: [],
        },
        filters,
        order,
        page,
        error: '',
      };
    case RECEIVE_PD_PROCESSING_GROUPS_METADATA: {
      const {
        payload: {
          processingGroupsData, totalPages,
        },
      } = action;

      const incomingPgs = processingGroupsData?.reduce((accumulator, processingGroup) => {
        const pgData = state.data.processingGroups[processingGroup.id] || {};

        accumulator[processingGroup.id] = {
          ...pgData,
          ...processingGroup,
          loading: pgData.loading === undefined || pgData.loading,
        };
        return accumulator;
      }, {}) || {};

      const processingGroupIds = processingGroupsData?.map(pg => pg.id) || [];

      const loadingPGs = Object.keys(incomingPgs).reduce((accumulator, processingGroupId) => {
        if (incomingPgs[processingGroupId].loading) {
          accumulator.push(processingGroupId);
        }
        return accumulator;
      }, []);

      requestProcessingGroupsData(loadingPGs, state.filters);

      return {
        ...state,
        loading: false,
        error: '',
        totalPages,
        data: {
          processingGroups: {
            ...state.data.processingGroups,
            ...incomingPgs,
          },
          processingGroupIds,
        },
      };
    }
    case ERROR_PD_PROCESSING_GROUPS_METADATA: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case RECEIVE_PD_PROCESSING_GROUP: {
      const {
        payload: {
          processingGroup, ledgerDate,
        },
      } = action;

      if (state.filters.ledgerDate !== ledgerDate) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          processingGroups: {
            ...state.data.processingGroups,
            [processingGroup.id]: {
              ...processingGroup,
              loading: false,
            },
          },
        },
      };
    }
    case ERROR_PD_PROCESSING_GROUP: {
      const {
        payload: {
          processingGroup, ledgerDate, error,
        },
      } = action;

      if (state.filters.ledgerDate !== ledgerDate) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          processingGroups: {
            ...state.data.processingGroups,
            [processingGroup.id]: {
              ...state.data.processingGroups[processingGroup.id],
              error,
              loading: false,
            },
          },
        },
      };
    }
    case 'redux-cablecar/CONNECTED': {
      if (!state.connected) {
        fetchProcessingGroupsMetadata(state.filters, state.order, state.page);
      }
      return {
        ...state,
        connected: true,
      };
    }
    case 'redux-cablecar/DISCONNECTED': {
      return {
        ...state,
        connected: false,
      };
    }
    default:
      return state;
  /* eslint-enable no-case-declarations */
  }
}
