import { useState, useEffect, useCallback } from 'react';
import usePagination from './usePagination';

const useIndex = ({ indexCallback, defaultOrder }) => {
  const [items, setItems] = useState([]);
  const [order, setOrder] = useState(defaultOrder);

  const {
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
  } = usePagination();

  const fetchItems = useCallback(async () => {
    const res = await indexCallback({ perPage: 10, page: currentPage, order });
    const { data, metadata } = await res.json();
    setTotalPages(metadata.totalPages);
    setItems(data);
  }, [setTotalPages, currentPage, order, indexCallback]);

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, currentPage]);

  return {
    setCurrentPage,
    setTotalPages,
    setItems,
    setOrder,
    currentPage,
    totalPages,
    items,
    order,
  };
};

export default useIndex;
