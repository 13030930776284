import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { VALIDATION_STATUSES } from '../../show/commonPropTypes';
import { ValidatedSheetBadge } from '../../../shared/validatedSheetBadge';

const SheetValidationStatus = ({ validationStatus, validatedByEmail, isValidating }) => {
  if (isValidating) {
    return (
      <span className="badge badge-warning">
        {I18n.t('currency_balance_sheets.index.validating')}
      </span>
    );
  }
  if (validationStatus === 'validated') {
    return (
      <ValidatedSheetBadge validatedByEmail={validatedByEmail} />
    );
  } if (validationStatus === 'unvalidated') {
    return (
      <span className="badge badge-danger">
        {I18n.t('activerecord.attributes.currency_balance_sheet.validation_statuses.unvalidated')}
      </span>
    );
  } if (validationStatus === 'unchanged') {
    return (
      <span className="badge badge-info">
        {I18n.t('activerecord.attributes.currency_balance_sheet.validation_statuses.unchanged')}
      </span>
    );
  }
  return null;
};

SheetValidationStatus.propTypes = {
  validationStatus: PropTypes.oneOf(Object.values(VALIDATION_STATUSES)).isRequired,
  validatedByEmail: PropTypes.string,
  isValidating: PropTypes.bool.isRequired,
};

SheetValidationStatus.defaultProps = {
  validatedByEmail: '',
};

export { SheetValidationStatus };
