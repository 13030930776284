import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor as ToastEditor } from '@toast-ui/react-editor';

const Editor = (props) => {
  const editor = useRef(null);

  const { initialValue, onChange } = props;

  const handleEditorChange = () => {
    const value = editor.current.getInstance().getHtml();
    onChange(value);
  };

  return (
    <ToastEditor
      usageStatistics={false}
      initialEditType="wysiwyg"
      initialValue={initialValue}
      toolbarItems={[
        'heading',
        'bold',
        'italic',
        'strike',
        'divider',
        'hr',
        'quote',
        'divider',
        'ul',
        'ol',
        'task',
        'indent',
        'outdent',
        'divider',
        'table',
        'link',
      ]}
      hideModeSwitch
      onChange={handleEditorChange}
      ref={editor}
    />
  );
};

Editor.defaultProps = {
  initialValue: '',
};

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};


export { Editor };
