import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import PropType from 'prop-types';
import { NotePropType } from '../../commonPropTypes';
import { Viewer } from './viewer';
import './noteSectionRow.scss';

export const NoteSectionRow = ({ note, onEdit }) => {
  const { text, entity: { displayName } } = note;
  const [expanded, setExpanded] = useState(false);
  const toggleView = useCallback((event) => {
    event.preventDefault();
    setExpanded(prevExpanded => !prevExpanded);
  }, [setExpanded]);

  return (
    <tr className="note-row">
      <td className="note-row-entity-name">{displayName}</td>
      <td
        className="note-row-entity-text"
        onClick={toggleView}
      >
        { expanded && <Viewer value={text} /> }
      </td>
      <td className="note-row-actions">
        <Button
          bsStyle="default"
          onClick={toggleView}
        >
          {expanded ? I18n.t('hide') : I18n.t('show')}
        </Button>
        { expanded && (
          <Button
            bsStyle="primary"
            onClick={(event) => {
              event.preventDefault();
              onEdit(note);
            }}
          >
            {I18n.t('edit')}
          </Button>
        ) }
      </td>
    </tr>
  );
};

NoteSectionRow.propTypes = {
  note: NotePropType.isRequired,
  onEdit: PropType.func.isRequired,
};
