import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import _ from 'lodash';
import Client from '../../../client';
import { RollModal } from '../../shared/components/rollModal';
import { AccountNoRolleableModal } from '../../shared/components/accountNoRolleableModal';
import { AccountNotValidatedModal } from '../../shared/components/accountNotValidatedModal';
import { AccountDifferentCalendarSettingsModal } from '../../shared/components/accountDifferentCalendarSettingsModal';
import { AccountDifferentCalendarDatesModal } from '../../shared/components/accountDifferentCalendarDatesModal';

class RollProcessingGroupContainer extends React.Component {
  static propTypes = {
    processingGroupId: PropTypes.number.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleRoll = this.handleRoll.bind(this);

    this.state = {
      response: null,
      show: false,
    };
  }

  allAccountWithSameCalendarSettings = () => {
    const { response } = this.state;
    const { accounts = [] } = response;

    return accounts.every(account => (
      account.scheduledRollDateCalendar === accounts[0].scheduledRollDateCalendar
    ));
  }

  allAccountWithSameRollDates = () => {
    const { response } = this.state;
    const { accounts = [] } = response;

    const dateMaps = accounts.map((account) => {
      const scheduledRollDates = account.accountScheduledRollSettings.map(setting => (
        setting.scheduledRollDate
      ));

      const nextSettlementDates = account.accountScheduledRollSettings.map(setting => (
        setting.nextSettlementDate
      ));

      return [scheduledRollDates, nextSettlementDates];
    });

    const valid = dateMaps.every((dates) => {
      const comparationSettings = dateMaps[0];

      return _.isEqual(dates[0].sort(), comparationSettings[0].sort())
        && _.isEqual(dates[1].sort(), comparationSettings[1].sort());
    });

    return valid;
  }

  handleRoll(data, onError) {
    const { processingGroupId } = this.props;

    Client
      .createProcessingGroupRoll(processingGroupId, data)
      .then(response => response.json())
      .then(({ success, message, error }) => {
        if (success) {
          alert(message);
          this.handleClose();
        } else {
          alert(error);
          onError();
        }
      });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(e) {
    e.preventDefault();

    const { processingGroupId } = this.props;

    Client
      .getProcessingGroupAvailableRolls(processingGroupId)
      .then(response => response.json())
      .then((response) => {
        this.setState({ show: true, response });
      });
  }

  renderModal = () => {
    const { show, response } = this.state;
    const {
      processingGroupSetting, availableRolls = [], accounts = [], accountsNotValidated = [],
    } = response;
    const { processingGroupId } = this.props;

    if (!availableRolls.length) {
      return (
        <AccountNoRolleableModal show={show} onHide={this.handleClose} />
      );
    }

    if (accountsNotValidated.length) {
      return (
        <AccountNotValidatedModal
          show={show}
          onHide={this.handleClose}
          processingGroupId={processingGroupId}
          accountsNotValidated={accountsNotValidated}
        />
      );
    }

    if (!this.allAccountWithSameCalendarSettings()) {
      return (
        <AccountDifferentCalendarSettingsModal
          show={show}
          onHide={this.handleClose}
          accounts={accounts}
        />
      );
    }

    if (!this.allAccountWithSameRollDates()) {
      return (
        <AccountDifferentCalendarDatesModal
          show={show}
          onHide={this.handleClose}
          accounts={accounts}
        />
      );
    }

    return (
      <RollModal
        accounts={accounts}
        show={show}
        onClose={this.handleClose}
        onRoll={this.handleRoll}
        {...response}
        {...processingGroupSetting}
      />
    );
  }

  render() {
    const { response } = this.state;

    return (
      <React.Fragment>
        <a onClick={this.handleShow}>
          {I18n.t('currency_balance_sheets.index.bulk_roll')}
        </a>

        { response && this.renderModal() }

      </React.Fragment>
    );
  }
}

export { RollProcessingGroupContainer };
