import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { WrappedReactSelect } from '../wrappedReactSelect';

export const ClientFilter = ({
  clients, onChange, selectedClientId, loading, ...otherProps
}) => {
  const getClientOptions = useCallback(() => {
    const clientOptions = clients.map(client => ({
      value: client.id,
      label: `(${client.id}) ${client.shortCompanyName || client.companyName}`,
    }));
    clientOptions.unshift({
      value: undefined,
      label: I18n.t('sheets_filter_component.clients.all'),
    });
    return clientOptions;
  }, [clients]);

  const getSelectedClient = useCallback(
    () => getClientOptions().find(client => client.value === selectedClientId),
    [getClientOptions, selectedClientId],
  );

  return (
    <WrappedReactSelect
      className="filter-clients"
      placeholder={I18n.t('sheets_filter_component.clients.all')}
      options={getClientOptions()}
      value={getSelectedClient()}
      onChange={onChange}
      isLoading={loading}
      loadingMessage={I18n.t('loading')}
      {...otherProps}
    />
  );
};

ClientFilter.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      shortCompanyName: PropTypes.string,
      companyName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedClientId: PropTypes.number,
};

ClientFilter.getDefaultProps = {
  selectedClientId: null,
};
