import React from 'react';
import PropTypes from 'prop-types';

export const SafeLink = ({
  target, children, rel, ...otherProps
}) => (
  <a target={target} rel={target === '_blank' ? 'noopener noreferrer' : rel} {...otherProps}>
    {children}
  </a>
);

SafeLink.propTypes = {
  target: PropTypes.string,
  children: PropTypes.node,
  rel: PropTypes.string,
};

SafeLink.defaultProps = {
  children: null,
  rel: null,
  target: null,
};
