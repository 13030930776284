import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../../rawDataFiles/components/NewRawDataFilesTable.scss';
import moment from 'moment';
import Routes from '../../../routes.js.erb';

const unusualFilePropType = PropTypes.shape({
  accountAlias: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  medianArrivalTime: PropTypes.string.isRequired,
  arrivalDate: PropTypes.string.isRequired,
});

const UnusualFilesArrivalTableRow = ({ unusualFile }) => (
  <tr>
    <td>{unusualFile.accountAlias.name}</td>
    <td>{unusualFile.accountAlias.type}</td>
    <td>{unusualFile.client.name}</td>
    <td>{unusualFile.medianArrivalTime}</td>
    <td>{unusualFile.arrivalDate}</td>
    <td>
      <a
        href={/* eslint-disable-next-line no-undef */
          Routes.raw_data_files_path({
            client: unusualFile.client.id,
            type: unusualFile.accountAlias.rawDataFileType,
          })
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {I18n.t('view')}
      </a>
    </td>
  </tr>
);

UnusualFilesArrivalTableRow.propTypes = {
  unusualFile: unusualFilePropType.isRequired,
};

const isUnusualOfTheDay = unusualFile => moment(unusualFile.arrivalDate).isSame(moment(), 'day');

export const UnusualFilesArrivalTable = ({ unusualFiles }) => {
  if (unusualFiles.length === 0) {
    return (
      <h2 key="title" className="text-center">
        {I18n.t('rosy.dashboard.index.unusually_late_files_section.no_data')}
      </h2>
    );
  }

  return (
    [
      <h2 key="title" className="text-center">{I18n.t('rosy.dashboard.index.unusually_late_files_section.title')}</h2>,
      <div key="table" className="table-responsive with-dropdown">
        <table className="table">
          <thead>
            <tr>
              <th>{I18n.t('activerecord.attributes.rosy/expected_account_alias_file_arrival_usualness.account_alias')}</th>
              <th>{I18n.t('activerecord.attributes.account_alias.type')}</th>
              <th>{I18n.t('activerecord.attributes.rosy/record_usualness.client')}</th>
              <th>{I18n.t('activerecord.attributes.rosy/account_alias_median.median')}</th>
              <th>{I18n.t('activerecord.attributes.rosy/record_usualness.arrival_date')}</th>
            </tr>
          </thead>
          <tbody>
            <TransitionGroup component={null} appear exit={false}>
              {
                unusualFiles.map(unusualFile => (isUnusualOfTheDay(unusualFile)
                  ? (
                    <CSSTransition
                      key={unusualFile.id}
                      timeout={1000}
                      classNames="updated-files-table-row"
                    >
                      <UnusualFilesArrivalTableRow unusualFile={unusualFile} />
                    </CSSTransition>
                  ) : (
                    <UnusualFilesArrivalTableRow unusualFile={unusualFile} />
                  )))
              }
            </TransitionGroup>
          </tbody>
        </table>
      </div>,
    ]
  );
};

UnusualFilesArrivalTable.propTypes = {
  unusualFiles: PropTypes.arrayOf(unusualFilePropType).isRequired,
};
