import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import I18n from 'i18n-js';
import momentPropTypes from 'react-moment-proptypes';
import Routes from '../../../routes.js.erb';
import { Loading } from '../../../shared/loading';
import { useApi } from '../../../shared/useApi';

export const FileScopeButtonGroup = (props) => {
  const {
    scopes,
    selectedClientId,
    selectedAccountId,
    selectedProcessingGroupId,
    selectedStatus,
    selectedType,
    selectedScope,
    selectedRawAccountDataMapSetId,
    selectedPublishedDate,
  } = props;

  const urlParams = {
    client: selectedClientId,
    account: selectedAccountId,
    processing_group: selectedProcessingGroupId,
    raw_account_data_map_set: selectedRawAccountDataMapSetId,
    status: selectedStatus,
    type: selectedType,
    published_date: selectedPublishedDate ? selectedPublishedDate.format('YYYY-MM-DD') : null,
  };
  const { loading, data } = useApi(Routes.scope_count_api_v1_raw_data_files_path(urlParams));

  const fetchCount = (scope) => {
    if (!data) return 0;

    return data.count[scope];
  };

  if (loading) {
    return (
      <Loading loading />
    );
  }

  return (
    <div aria-label="Scopes" className="btn-group" role="group">
      {
        scopes.map(scope => (
          <a
            key={scope}
            className={classNames('btn', 'btn-success', { active: selectedScope === scope })}
            href={Routes.raw_data_files_path(({ ...urlParams, scope }))}
          >
            <span className="bold">
              {I18n.t(`users_portal.raw_data_files.index.scopes.${scope}`, { count: fetchCount(scope) })}
            </span>
          </a>
        ))
      }
    </div>
  );
};

FileScopeButtonGroup.defaultProps = {
  selectedAccountId: null,
  selectedClientId: null,
  selectedProcessingGroupId: null,
  selectedRawAccountDataMapSetId: null,
  selectedStatus: null,
  selectedType: null,
  selectedScope: null,
  selectedPublishedDate: null,
};

FileScopeButtonGroup.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedAccountId: PropTypes.number,
  selectedClientId: PropTypes.number,
  selectedProcessingGroupId: PropTypes.number,
  selectedRawAccountDataMapSetId: PropTypes.number,
  selectedScope: PropTypes.string,
  selectedStatus: PropTypes.string,
  selectedType: PropTypes.string,
  selectedPublishedDate: momentPropTypes.momentObj,
};
