import React, { useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ClientProcessingGroupAccountFilter } from '../../../../shared/filters/clientProcessingGroupAccountFilter';
import { FilterControl } from '../../../../shared/filters/FilterControl';

const initialState = {
  selectedClientId: null,
  selectedAccountId: null,
  selectedProcessingGroupId: null,
};

export const Filters = ({ onFilter, loading }) => {
  const [searchState, setSearchState] = useState(initialState);

  const handleFilterClick = useCallback((e) => {
    e.preventDefault();
    onFilter(searchState);
  }, [onFilter, searchState]);

  const handleClearFilter = useCallback((e) => {
    e.preventDefault();
    setSearchState(initialState);
    onFilter(initialState);
  }, [onFilter]);

  return (
    <Row>
      <ClientProcessingGroupAccountFilter
        layout={{ sm: 3 }}
        onChange={setSearchState}
        {...searchState}
      />
      <Col sm={3}>
        <FilterControl
          className="text-right"
          onSubmit={handleFilterClick}
          disabled={loading}
          onClear={handleClearFilter}
        />
      </Col>
    </Row>
  );
};

Filters.propTypes = {
  loading: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
};
