import React, { useState } from 'react';
import I18n from 'i18n-js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormGroup, Col } from 'react-bootstrap';
import { ClientProcessingGroupAccountFilter } from '../../../shared/filters/clientProcessingGroupAccountFilter';
import { TypesSelect } from './TypesSelect';
import { NewDatePicker } from '../../../shared/newLedgerDatePicker/NewLedgerDatePicker';
import { RawAccountDataMapSetsSelect } from './RawAccountDataMapSetsSelect';
import { FilterControl } from '../../../shared/filters/FilterControl';
import Routes from '../../../routes.js.erb';
import { FileNamesSelect } from './FileNamesSelect';
import { FileScopeButtonGroup } from './FileScopeButtonGroup';
import { SelectFilter as StatusesSelect } from '../../../shared/filters/SelectFilter';

export const Filters = (props) => {
  const {
    statuses, scopes, types,
    selectedAccountId: defaultAccountId,
    selectedClientId: defaultClientId,
    selectedProcessingGroupId: defaultProcessingGroupId,
    selectedStatus: defaultStatus,
    selectedType: defaultType,
    selectedFileName: defaultFileName,
    selectedRawAccountDataMapSetId: defaultRawAccountDataMapSetId,
    selectedScope,
  } = props;

  const [clientPgAccountState, setClientPgAccountState] = useState({
    selectedClientId: defaultClientId,
    selectedAccountId: defaultAccountId,
    selectedProcessingGroupId: defaultProcessingGroupId,
  });
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
  const [selectedType, setSelectedType] = useState(defaultType);
  const [selectedFileName, setSelectedFileName] = useState(defaultFileName);
  const [selectedRawAccountDataMapSetId,
    setSelectedRawAccountDataMapSetId] = useState(defaultRawAccountDataMapSetId);
  const [selectedPublishedDate, setPublishedDate] = useState(null);
  const { selectedAccountId, selectedClientId, selectedProcessingGroupId } = clientPgAccountState;

  const statusesOptions = statuses.map(opt => ({ label: _.startCase(opt), value: opt }));

  return (
    <form action={Routes.raw_data_files_path()}>
      <ClientProcessingGroupAccountFilter
        layout={{ sm: 3 }}
        onChange={setClientPgAccountState}
        accountInputProps={{ name: 'account' }}
        clientInputProps={{ name: 'selected_client' }}
        processingGroupInputProps={{ name: 'processing_group' }}
        {...clientPgAccountState}
      />
      <Col sm={3}>
        <FormGroup>
          <StatusesSelect
            id="select_status"
            name="status"
            nullOption={I18n.t('users_portal.raw_data_files.index.filter.all_statuses')}
            options={statusesOptions}
            selectedOption={selectedStatus}
            onChange={setSelectedStatus}
          />
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          <TypesSelect
            types={types}
            selectedType={selectedType}
            onChange={setSelectedType}
          />
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          <FileNamesSelect
            selectedAccountId={selectedAccountId}
            selectedClientId={selectedClientId}
            selectedProcessingGroupId={selectedProcessingGroupId}
            selectedStatus={selectedStatus}
            selectedType={selectedType}
            selectedScope={selectedScope}
            selectedFileName={selectedFileName}
            onChange={setSelectedFileName}
          />
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          <RawAccountDataMapSetsSelect
            selectedAccountId={selectedAccountId}
            selectedClientId={selectedClientId}
            selectedProcessingGroupId={selectedProcessingGroupId}
            selectedRawAccountDataMapSetId={selectedRawAccountDataMapSetId}
            onChange={setSelectedRawAccountDataMapSetId}
          />
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          <NewDatePicker
            selectedDate={selectedPublishedDate}
            onChange={setPublishedDate}
            inputProps={
              {
                placeholder: 'Published Date',
                id: 'select_published_date',
                name: 'published_date',
              }
            }
          />
        </FormGroup>
      </Col>
      <Col sm={12}>
        <FilterControl
          onSubmit={() => {}}
          onClear={() => {
            window.location.href = Routes.raw_data_files_path();
          }}
        />
      </Col>
      <Col sm={12}>
        <FileScopeButtonGroup
          selectedAccountId={selectedAccountId}
          selectedClientId={selectedClientId}
          selectedProcessingGroupId={selectedProcessingGroupId}
          selectedStatus={selectedStatus}
          selectedType={selectedType}
          selectedScope={selectedScope}
          selectedFileName={selectedFileName}
          selectedRawAccountDataMapSetId={selectedRawAccountDataMapSetId}
          selectedPublishedDate={selectedPublishedDate}
          scopes={scopes}
        />
      </Col>
    </form>
  );
};

Filters.defaultProps = {
  selectedAccountId: null,
  selectedClientId: null,
  selectedFileName: null,
  selectedProcessingGroupId: null,
  selectedStatus: null,
  selectedType: null,
  selectedScope: null,
  selectedRawAccountDataMapSetId: null,
};

Filters.propTypes = {
  selectedAccountId: PropTypes.number,
  selectedClientId: PropTypes.number,
  selectedFileName: PropTypes.string,
  selectedProcessingGroupId: PropTypes.number,
  selectedStatus: PropTypes.string,
  selectedScope: PropTypes.string,
  selectedType: PropTypes.string,
  selectedRawAccountDataMapSetId: PropTypes.number,
  scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};
