const initialState = {
  notifications: [],
  unread: 0,
};

const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        unread: action.payload.unread,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload.notification, ...state.notifications.slice(0, 2)],
        unread: state.unread + 1,
      };
    default:
      return state;
  }
}
