import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Modal,
} from 'react-bootstrap';

import { accountFlag } from './commonPropTypes';

const Footer = ({ canValidate, setVisible, onSubmit }) => (
  canValidate ? (
    <div style={{
      display: 'flex', width: '50%', float: 'right', justifyContent: 'space-between',
    }}
    >
      <Button onClick={() => { setVisible(false); }}>Cancel</Button>
      <div>
        <Button bsStyle="danger" onClick={e => onSubmit(e, false)}>
          Reject
        </Button>
        <Button bsStyle="primary" onClick={e => onSubmit(e, true)}>
          Validate
        </Button>
      </div>
    </div>
  ) : (
    <Button onClick={() => { setVisible(false); }}>Close</Button>
  )
);

export const ValidateRemovalModal = ({
  flag, visible, setVisible, onSubmit, canValidate,
}) => {
  const handleSubmit = async (event, removed) => {
    const note = removed ? flag.note : null;
    const action = removed ? 'removed' : 'added';

    onSubmit(event, action, note);
  };

  return (
    <Modal show={visible} onHide={() => { setVisible(false); }}>
      <Modal.Header>
        <Modal.Title>
          Removal request of
          {' '}
          { flag.displayName }
          {' '}
          - by
          {' '}
          { canValidate ? flag.actioner.email : 'you' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl
          className="m-t-sm"
          componentClass="textarea"
          value={flag.note}
          disabled
        />
      </Modal.Body>
      <Modal.Footer>
        <Footer canValidate={canValidate} setVisible={setVisible} onSubmit={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};

const commonProps = {
  setVisible: PropTypes.func.isRequired,
  canValidate: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Footer.propTypes = { ...commonProps };

ValidateRemovalModal.propTypes = {
  visible: PropTypes.bool,
  flag: accountFlag.isRequired,
  ...commonProps,
};


ValidateRemovalModal.defaultProps = {
  visible: false,
};
