import React from 'react';
import PropTypes from 'prop-types';
import { orderedClassname } from '../shared/orderedClassname';
import { currentDirection as currentDirectionPropTypes } from './commonPropTypes';

export class SortedValue extends React.PureComponent {
  render = () => {
    const {
      // eslint-disable-next-line react/prop-types
      currentDirection, children, 'data-test-id': dataTestId, 'data-test-class': dataTestClass, options,
    } = this.props;
    return (
      <td
        className={orderedClassname(currentDirection)}
        data-test-id={dataTestId}
        data-test-class={dataTestClass}
        colSpan={options.colSpan}
        width={options.width}
      >
        { children }
      </td>
    );
  }
}

SortedValue.propTypes = {
  currentDirection: currentDirectionPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
  options: PropTypes.shape({
    width: PropTypes.string,
    colSpan: PropTypes.number,
  }),
};

SortedValue.defaultProps = {
  currentDirection: null,
  options: {
    width: null,
    colSpan: null,
  },
};
