import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Hover from './hover';
import { processedData as processedDataPropType } from '../commonPropTypes';
import { SafeLink } from '../../../shared/safeLink';

/* eslint-disable react/no-array-index-key */
const DataBox = ({
  status, value, isCircle, postString, defaultValue,
}) => (
  <div className={classNames('cell', status, { 'full-width': !!value }, { circle: isCircle })}>
    { ['', null].includes(value) ? defaultValue : value + postString }
  </div>
);

const HoverCellArray = ({ data, links }) => (
  <div className="cell-container">
    { data?.map(({ reason, status }, index) => (
      <Hover reason={reason} key={index + status}>
        <div>
          <SafeLink target="_blank" href={links[index]}>
            <DataBox status={status} />
          </SafeLink>
        </div>
      </Hover>
    )) }
  </div>
);

const HoverCell = ({
  isCircle, link, reason, status,
}) => (
  <div className="cell-container">
    <Hover reason={reason}>
      <div>
        <SafeLink target="_blank" href={link}>
          <DataBox status={status} isCircle={isCircle} />
        </SafeLink>
      </div>
    </Hover>
  </div>
);

const ValueCell = ({
  status, value, postString, reason, defaultValue,
}) => {
  const DataBoxComponent = (
    <DataBox
      status={status}
      value={value}
      postString={postString}
      defaultValue={defaultValue}
    />
  );

  return (
    <div className="cell-container">
      {
        reason ? (
          <Hover reason={reason}>
            <div className="full-width">
              { DataBoxComponent }
            </div>
          </Hover>
        ) : DataBoxComponent
      }
    </div>
  );
};

const EmptyCell = ({ length, isCircle }) => (
  <div className="cell-container">
    { Array(length).fill(1).map((_, index) => (
      <div key={index + length} className={classNames('cell', 'white', { circle: isCircle })} />
    )) }
  </div>
);
/* eslint-enable react/no-array-index-key */

DataBox.propTypes = {
  status: PropTypes.string.isRequired,
  postString: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  isCircle: PropTypes.bool,
};

DataBox.defaultProps = {
  value: null,
  isCircle: false,
  postString: '',
  defaultValue: '',
};

EmptyCell.propTypes = {
  length: PropTypes.number,
  isCircle: PropTypes.bool,
};

HoverCellArray.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(processedDataPropType)).isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
};

HoverCell.propTypes = {
  status: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  link: PropTypes.string,
  isCircle: PropTypes.bool,
};

ValueCell.propTypes = {
  status: PropTypes.string.isRequired,
  value: PropTypes.string,
  postString: PropTypes.string,
  defaultValue: PropTypes.string,
  reason: PropTypes.string,
};

ValueCell.defaultProps = {
  value: '',
  postString: '',
  reason: null,
  defaultValue: '',
};

EmptyCell.defaultProps = {
  isCircle: false,
  length: 1,
};

HoverCell.defaultProps = {
  isCircle: false,
  link: '',
};

export {
  HoverCellArray, HoverCell, ValueCell, EmptyCell,
};
