import React from 'react';
import PropTypes from 'prop-types';

const RemoveSelectableFieldButton = React.memo(({ onClick }) => (
  <i className="material-icons remove-icon" onClick={onClick}>
    clear
  </i>
));

RemoveSelectableFieldButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { RemoveSelectableFieldButton };
