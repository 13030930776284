import PropTypes from 'prop-types';

const currencyLineItemMap = {
  id: PropTypes.number,
  action: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

const capstockException = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  dateProvided: PropTypes.bool,
  action: PropTypes.string,
  businessDays: PropTypes.number,
  uuid: PropTypes.string,
});

export {
  currencyLineItemMap,
  capstockException,
};
