import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/es/Row';
import Col from 'react-bootstrap/es/Col';

import I18n from 'i18n-js';
import moment from 'moment';

import { AccountFileBadge } from '../../../rawDataFiles/components/AccountFileBadge';
import { Loading } from '../../../shared/loading';
import { SelectFilter } from '../../../shared/filters/SelectFilter';
import { SafeLink } from '../../../shared/safeLink';
import Routes from '../../../routes.js.erb';

import { file as filePropType } from './commonPropTypes';

const AccountWeightsFileInfo = ({
  loading, date, files, size, changeSelectedFile, weightType,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (files?.length > 0) {
      if (weightType) {
        setSelectedFile(files.reverse().find(file => file.weightType === weightType));
      } else {
        setSelectedFile(files.at(-1));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    changeSelectedFile(selectedFile);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  if (loading) {
    return (
      <Col xs={size} className="text-align-center loading">
        <Loading loading={loading} />
      </Col>
    );
  }

  if (!date) {
    return (<h5 className="no-files">{ I18n.t('users_portal.account_weights.no_weights') }</h5>);
  }

  if (!files || files?.length === 0 || !selectedFile) {
    return (<h5 className="no-files">{ I18n.t('users_portal.account_weights.no_files_on_date', { date }) }</h5>);
  }

  return (
    <Col xs={size} className="account-weights-info-container">
      <Row>
        <h5 className="text-align-center">{moment(date).format('YYYY-MM-DD')}</h5>
        <SelectFilter
          id="select_file"
          name="files"
          options={files.map(file => ({ label: file.name, value: file }))}
          selectedOption={selectedFile}
          onChange={setSelectedFile}
        />
      </Row>
      <Col className="column data">
        <span>
          <b>Status:</b>
          {' '}
          <AccountFileBadge status={selectedFile.status} />
        </span>
        <span>
          <b>Source: </b>
          <SafeLink href={Routes.raw_data_file_path(selectedFile.rawDataFileId)}>
            {selectedFile.weightType}
          </SafeLink>
        </span>
        <span>
          <b>Published date:</b>
          {' '}
          {selectedFile.publishedDate}
        </span>
        <span>
          <b>File name:</b>
          {' '}
          {selectedFile.name}
        </span>
        { selectedFile.lastRunMessage && (
          <span>
            <b>Message:</b>
            {' '}
            {selectedFile.lastRunMessage}
          </span>
        )}
      </Col>
    </Col>
  );
};

AccountWeightsFileInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  changeSelectedFile: PropTypes.func.isRequired,
  date: PropTypes.string,
  files: PropTypes.arrayOf(filePropType),
  size: PropTypes.number,
  weightType: PropTypes.string,
};

AccountWeightsFileInfo.defaultProps = {
  files: null,
  size: 5,
  date: null,
  weightType: '',
};

export { AccountWeightsFileInfo };
