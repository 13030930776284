import connect from 'react-redux/es/connect/connect';
import { CurrencyLineItemMapsSection } from '../components/currencyLineItemMapsSection';
import { addLineItemMap, removeLineItemMap } from '../actions/currencyLineItemMaps';

const mapStateToProps = state => ({
  currencyLineItemMaps: state.currencyLineItemMaps,
  destroyed: state.destroyed,
});

const mapDispatchToProps = {
  onRemove: removeLineItemMap,
  onAdd: addLineItemMap,
};

export const CurrencyLineItemMapsSectionContainer = connect(mapStateToProps,
  mapDispatchToProps)(CurrencyLineItemMapsSection);
