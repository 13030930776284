/* rails-erb-loader-dependencies ../config/routes */
var routes = {};

(function() {
  /*
File generated by js-routes 1.4.9
Based on Rails 6.1.7.6 routes of Lumint::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_user_invitation => /invitation/accept(.:format)
  // function(options)
  accept_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account => /accounts/:id(.:format)
  // function(id, options)
  account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_currency_balance_sheet => /accounts/:account_id/currency_balance_sheets/:id(.:format)
  // function(account_id, id, options)
  account_currency_balance_sheet_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_profit_and_loss_local_conversions => /accounts/:account_id/profit_and_loss_local_conversions(.:format)
  // function(account_id, options)
  account_profit_and_loss_local_conversions_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"profit_and_loss_local_conversions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_raw_data_file_processes => /account_raw_data_files/:id/processes(.:format)
  // function(id, options)
  account_raw_data_file_processes_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"account_raw_data_files",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"processes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accounts => /accounts(.:format)
  // function(options)
  accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// activate_admin_user => /admin/users/:id/activate(.:format)
  // function(id, options)
  activate_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// activate_client_account_alias => /clients/:client_id/account_aliases/:id/activate(.:format)
  // function(client_id, id, options)
  activate_client_account_alias_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"account_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// activate_roll_scheduled_dates_account => /accounts/:id/activate_roll_scheduled_dates(.:format)
  // function(id, options)
  activate_roll_scheduled_dates_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate_roll_scheduled_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// add_comment_support => /supports/:id/add_comment(.:format)
  // function(id, options)
  add_comment_support_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"supports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"add_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_account => /admin/accounts/:id(.:format)
  // function(id, options)
  admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_account_benchmark => /admin/account_benchmarks/:id(.:format)
  // function(id, options)
  admin_account_benchmark_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_benchmarks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_account_benchmarks => /admin/account_benchmarks(.:format)
  // function(options)
  admin_account_benchmarks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_benchmarks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_account_check => /admin/account_checks/:id(.:format)
  // function(id, options)
  admin_account_check_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_checks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_account_checks => /admin/account_checks(.:format)
  // function(options)
  admin_account_checks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_account_flag => /admin/account_flags/:id(.:format)
  // function(id, options)
  admin_account_flag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_flags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_account_flags => /admin/account_flags(.:format)
  // function(options)
  admin_account_flags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_flags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_accounts => /admin/accounts(.:format)
  // function(options)
  admin_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_audit_record => /admin/audit_records/:id(.:format)
  // function(id, options)
  admin_audit_record_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"audit_records",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_audit_records => /admin/audit_records(.:format)
  // function(options)
  admin_audit_records_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"audit_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_calleo_branch_timezone_map => /admin/calleo_branch_timezone_maps/:id(.:format)
  // function(id, options)
  admin_calleo_branch_timezone_map_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calleo_branch_timezone_maps",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_calleo_branch_timezone_maps => /admin/calleo_branch_timezone_maps(.:format)
  // function(options)
  admin_calleo_branch_timezone_maps_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calleo_branch_timezone_maps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_comment => /admin/comments/:id(.:format)
  // function(id, options)
  admin_comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_comments => /admin/comments(.:format)
  // function(options)
  admin_comments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_counterparties => /admin/counterparties(.:format)
  // function(options)
  admin_counterparties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"counterparties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_counterparty => /admin/counterparties/:id(.:format)
  // function(id, options)
  admin_counterparty_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"counterparties",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_countries => /admin/countries(.:format)
  // function(options)
  admin_countries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"countries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_country => /admin/countries/:id(.:format)
  // function(id, options)
  admin_country_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"countries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_country_alias => /admin/country_aliases/:id(.:format)
  // function(id, options)
  admin_country_alias_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"country_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_country_aliases => /admin/country_aliases(.:format)
  // function(options)
  admin_country_aliases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"country_aliases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_currencies => /admin/currencies(.:format)
  // function(options)
  admin_currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_currency => /admin/currencies/:id(.:format)
  // function(id, options)
  admin_currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_currency_alias => /admin/currency_aliases/:id(.:format)
  // function(id, options)
  admin_currency_alias_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_currency_aliases => /admin/currency_aliases(.:format)
  // function(options)
  admin_currency_aliases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_aliases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_currency_balance_sheet => /admin/currency_balance_sheets/:id(.:format)
  // function(id, options)
  admin_currency_balance_sheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_currency_balance_sheets => /admin/currency_balance_sheets(.:format)
  // function(options)
  admin_currency_balance_sheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_currency_line_item => /admin/currency_line_items/:id(.:format)
  // function(id, options)
  admin_currency_line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_line_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_currency_line_items => /admin/currency_line_items(.:format)
  // function(options)
  admin_currency_line_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_line_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard => /admin/dashboard(.:format)
  // function(options)
  admin_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_email_event => /admin/email_events/:id(.:format)
  // function(id, options)
  admin_email_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_email_events => /admin/email_events(.:format)
  // function(options)
  admin_email_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_execution_group => /admin/execution_groups/:id(.:format)
  // function(id, options)
  admin_execution_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_execution_groups => /admin/execution_groups(.:format)
  // function(options)
  admin_execution_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_features => /admin/features(.:format)
  // function(options)
  admin_features_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"features",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_features_turn_off => /admin/features/turn_off(.:format)
  // function(options)
  admin_features_turn_off_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"features",false],[2,[7,"/",false],[2,[6,"turn_off",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_features_turn_on => /admin/features/turn_on(.:format)
  // function(options)
  admin_features_turn_on_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"features",false],[2,[7,"/",false],[2,[6,"turn_on",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_flag => /admin/flags/:id(.:format)
  // function(id, options)
  admin_flag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"flags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_flags => /admin/flags(.:format)
  // function(options)
  admin_flags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"flags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_fx_contract => /admin/fx_contracts/:id(.:format)
  // function(id, options)
  admin_fx_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_fx_contract_valuation => /admin/fx_contract_valuations/:id(.:format)
  // function(id, options)
  admin_fx_contract_valuation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contract_valuations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_fx_contract_valuations => /admin/fx_contract_valuations(.:format)
  // function(options)
  admin_fx_contract_valuations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contract_valuations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_fx_contracts => /admin/fx_contracts(.:format)
  // function(options)
  admin_fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_holiday => /admin/holidays/:id(.:format)
  // function(id, options)
  admin_holiday_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"holidays",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_holidays => /admin/holidays(.:format)
  // function(options)
  admin_holidays_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"holidays",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_issue => /admin/issues/:id(.:format)
  // function(id, options)
  admin_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_issues => /admin/issues(.:format)
  // function(options)
  admin_issues_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"issues",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_performance_look_through_performance => /admin/performance_look_through_performances/:id(.:format)
  // function(id, options)
  admin_performance_look_through_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_look_through_performances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_performance_look_through_performances => /admin/performance_look_through_performances(.:format)
  // function(options)
  admin_performance_look_through_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_look_through_performances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_performance_new_share_class_performance => /admin/performance_new_share_class_performances/:id(.:format)
  // function(id, options)
  admin_performance_new_share_class_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_new_share_class_performances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_performance_new_share_class_performances => /admin/performance_new_share_class_performances(.:format)
  // function(options)
  admin_performance_new_share_class_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_new_share_class_performances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_performance_portfolio_performance => /admin/performance_portfolio_performances/:id(.:format)
  // function(id, options)
  admin_performance_portfolio_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_portfolio_performances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_performance_portfolio_performances => /admin/performance_portfolio_performances(.:format)
  // function(options)
  admin_performance_portfolio_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_portfolio_performances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_preprocess_strategies => /admin/preprocess_strategies(.:format)
  // function(options)
  admin_preprocess_strategies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"preprocess_strategies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_preprocess_strategy => /admin/preprocess_strategies/:id(.:format)
  // function(id, options)
  admin_preprocess_strategy_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"preprocess_strategies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_processing_group => /admin/processing_groups/:id(.:format)
  // function(id, options)
  admin_processing_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_processing_groups => /admin/processing_groups(.:format)
  // function(options)
  admin_processing_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_quickfix_messages => /admin/quickfix_messages(.:format)
  // function(options)
  admin_quickfix_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quickfix_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_quicksight_nav_exposure_batch => /admin/quicksight_nav_exposure_batches/:id(.:format)
  // function(id, options)
  admin_quicksight_nav_exposure_batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quicksight_nav_exposure_batches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_quicksight_nav_exposure_batches => /admin/quicksight_nav_exposure_batches(.:format)
  // function(options)
  admin_quicksight_nav_exposure_batches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quicksight_nav_exposure_batches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_raw_account_data_map_set => /admin/raw_account_data_map_sets/:id(.:format)
  // function(id, options)
  admin_raw_account_data_map_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_raw_account_data_map_sets => /admin/raw_account_data_map_sets(.:format)
  // function(options)
  admin_raw_account_data_map_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_reconcile_message => /admin/reconcile_messages/:id(.:format)
  // function(id, options)
  admin_reconcile_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reconcile_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_reconcile_messages => /admin/reconcile_messages(.:format)
  // function(options)
  admin_reconcile_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reconcile_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_reports_selectable_field_data_type_format => /admin/reports_selectable_field_data_type_formats/:id(.:format)
  // function(id, options)
  admin_reports_selectable_field_data_type_format_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reports_selectable_field_data_type_formats",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_reports_selectable_field_data_type_formats => /admin/reports_selectable_field_data_type_formats(.:format)
  // function(options)
  admin_reports_selectable_field_data_type_formats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reports_selectable_field_data_type_formats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_root => /admin(.:format)
  // function(options)
  admin_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_share_class_performance => /admin/share_class_performances/:id(.:format)
  // function(id, options)
  admin_share_class_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_share_class_performances => /admin/share_class_performances(.:format)
  // function(options)
  admin_share_class_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_sns_event => /admin/sns_events/:id(.:format)
  // function(id, options)
  admin_sns_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sns_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_sns_events => /admin/sns_events(.:format)
  // function(options)
  admin_sns_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sns_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_user => /admin/users/:id(.:format)
  // function(id, options)
  admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_users => /admin/users(.:format)
  // function(options)
  admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_zendesk_field => /admin/zendesk_fields/:id(.:format)
  // function(id, options)
  admin_zendesk_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"zendesk_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_zendesk_fields => /admin/zendesk_fields(.:format)
  // function(options)
  admin_zendesk_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"zendesk_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// amazon_notifications => /amazon_notifications(.:format)
  // function(options)
  amazon_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"amazon_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// api_v1_account_flags => /api/v1/account_flags(.:format)
  // function(options)
  api_v1_account_flags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"account_flags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_account_hedge_settlement_date_pickers => /api/v1/accounts/:account_id/hedge_settlement_date_pickers(.:format)
  // function(account_id, options)
  api_v1_account_hedge_settlement_date_pickers_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"hedge_settlement_date_pickers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_account_ledger_dates => /api/v1/accounts/:account_id/ledger_dates(.:format)
  // function(account_id, options)
  api_v1_account_ledger_dates_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"ledger_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_account_rolls => /api/v1/accounts/:account_id/rolls(.:format)
  // function(account_id, options)
  api_v1_account_rolls_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"rolls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_accounts => /api/v1/accounts(.:format)
  // function(options)
  api_v1_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_authorizations => /api/v1/authorizations(.:format)
  // function(options)
  api_v1_authorizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_client_raw_account_data_map_set => /api/v1/clients/:client_id/raw_account_data_map_sets/:id(.:format)
  // function(client_id, id, options)
  api_v1_client_raw_account_data_map_set_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_client_raw_account_data_map_sets => /api/v1/clients/:client_id/raw_account_data_map_sets(.:format)
  // function(client_id, options)
  api_v1_client_raw_account_data_map_sets_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_clients => /api/v1/clients(.:format)
  // function(options)
  api_v1_clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_currencies => /api/v1/currencies(.:format)
  // function(options)
  api_v1_currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"currencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_currency_balance_sheet => /api/v1/currency_balance_sheets/:id(.:format)
  // function(id, options)
  api_v1_currency_balance_sheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_currency_balance_sheets => /api/v1/currency_balance_sheets(.:format)
  // function(options)
  api_v1_currency_balance_sheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_entity_note => /api/v1/:entities/:entity_id/note(.:format)
  // function(entities, entity_id, options)
  api_v1_entity_note_path: Utils.route([["entities",true],["entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[3,"entities",false],[2,[7,"/",false],[2,[3,"entity_id",false],[2,[7,"/",false],[2,[6,"note",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_execution_group => /api/v1/execution_groups/:id(.:format)
  // function(id, options)
  api_v1_execution_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_execution_group_execution_subgroups => /api/v1/execution_groups/:execution_group_id/execution_subgroups(.:format)
  // function(execution_group_id, options)
  api_v1_execution_group_execution_subgroups_path: Utils.route([["execution_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[2,[7,"/",false],[2,[3,"execution_group_id",false],[2,[7,"/",false],[2,[6,"execution_subgroups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_execution_groups => /api/v1/execution_groups(.:format)
  // function(options)
  api_v1_execution_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_execution_local_currencies => /api/v1/execution_local_currencies(.:format)
  // function(options)
  api_v1_execution_local_currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_local_currencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_execution_local_currency => /api/v1/execution_local_currencies/:id(.:format)
  // function(id, options)
  api_v1_execution_local_currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_local_currencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_execution_subgroup => /api/v1/execution_subgroups/:id(.:format)
  // function(id, options)
  api_v1_execution_subgroup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_subgroups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_execution_subgroup_execution_local_currencies => /api/v1/execution_subgroups/:execution_subgroup_id/execution_local_currencies(.:format)
  // function(execution_subgroup_id, options)
  api_v1_execution_subgroup_execution_local_currencies_path: Utils.route([["execution_subgroup_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_subgroups",false],[2,[7,"/",false],[2,[3,"execution_subgroup_id",false],[2,[7,"/",false],[2,[6,"execution_local_currencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_execution_subgroup_execution_trade_types => /api/v1/execution_subgroups/:execution_subgroup_id/execution_trade_types(.:format)
  // function(execution_subgroup_id, options)
  api_v1_execution_subgroup_execution_trade_types_path: Utils.route([["execution_subgroup_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_subgroups",false],[2,[7,"/",false],[2,[3,"execution_subgroup_id",false],[2,[7,"/",false],[2,[6,"execution_trade_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_execution_subgroups => /api/v1/execution_subgroups(.:format)
  // function(options)
  api_v1_execution_subgroups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_subgroups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_execution_trade_type => /api/v1/execution_trade_types/:id(.:format)
  // function(id, options)
  api_v1_execution_trade_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_trade_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_execution_trade_types => /api/v1/execution_trade_types(.:format)
  // function(options)
  api_v1_execution_trade_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"execution_trade_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_flags => /api/v1/flags(.:format)
  // function(options)
  api_v1_flags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"flags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_fx_contract => /api/v1/fx_contracts/:id(.:format)
  // function(id, options)
  api_v1_fx_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_fx_contracts => /api/v1/fx_contracts(.:format)
  // function(options)
  api_v1_fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_interpolated_rates => /api/v1/interpolated_rates(.:format)
  // function(options)
  api_v1_interpolated_rates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"interpolated_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_note => /api/v1/notes/:id(.:format)
  // function(id, options)
  api_v1_note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_notes => /api/v1/notes(.:format)
  // function(options)
  api_v1_notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_process => /api/v1/processes/:id(.:format)
  // function(id, options)
  api_v1_process_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_processes => /api/v1/processes(.:format)
  // function(options)
  api_v1_processes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_processing_group_available_rolls => /api/v1/processing_groups/:processing_group_id/available_rolls(.:format)
  // function(processing_group_id, options)
  api_v1_processing_group_available_rolls_path: Utils.route([["processing_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[3,"processing_group_id",false],[2,[7,"/",false],[2,[6,"available_rolls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_processing_group_rolls => /api/v1/processing_groups/:processing_group_id/rolls(.:format)
  // function(processing_group_id, options)
  api_v1_processing_group_rolls_path: Utils.route([["processing_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[3,"processing_group_id",false],[2,[7,"/",false],[2,[6,"rolls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_processing_groups => /api/v1/processing_groups(.:format)
  // function(options)
  api_v1_processing_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_quickfix_messages => /api/v1/quickfix_messages(.:format)
  // function(options)
  api_v1_quickfix_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"quickfix_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_raw_account_data_map_sets => /api/v1/raw_account_data_map_sets(.:format)
  // function(options)
  api_v1_raw_account_data_map_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_raw_data_files => /api/v1/raw_data_files(.:format)
  // function(options)
  api_v1_raw_data_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"raw_data_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_reports_config => /api/v1/reports/configs/:id(.:format)
  // function(id, options)
  api_v1_reports_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_reports_configs => /api/v1/reports/configs(.:format)
  // function(options)
  api_v1_reports_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_reports_dataset => /api/v1/reports/datasets/:id(.:format)
  // function(id, options)
  api_v1_reports_dataset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"datasets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// approve_account_raw_data_file_processes => /account_raw_data_file_processes/approve(.:format)
  // function(options)
  approve_account_raw_data_file_processes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_raw_data_file_processes",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// auth_sso_authorize => /auth/sso/authorize(.:format)
  // function(options)
  auth_sso_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sso",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// auth_sso_user => /auth/sso/user(.:format)
  // function(options)
  auth_sso_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sso",false],[2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// available_rolls_api_v1_accounts => /api/v1/accounts/available_rolls(.:format)
  // function(options)
  available_rolls_api_v1_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"available_rolls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// batch_action_admin_account_benchmarks => /admin/account_benchmarks/batch_action(.:format)
  // function(options)
  batch_action_admin_account_benchmarks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_benchmarks",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_account_checks => /admin/account_checks/batch_action(.:format)
  // function(options)
  batch_action_admin_account_checks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_checks",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_account_flags => /admin/account_flags/batch_action(.:format)
  // function(options)
  batch_action_admin_account_flags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_flags",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_accounts => /admin/accounts/batch_action(.:format)
  // function(options)
  batch_action_admin_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_audit_records => /admin/audit_records/batch_action(.:format)
  // function(options)
  batch_action_admin_audit_records_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"audit_records",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_calleo_branch_timezone_maps => /admin/calleo_branch_timezone_maps/batch_action(.:format)
  // function(options)
  batch_action_admin_calleo_branch_timezone_maps_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calleo_branch_timezone_maps",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_counterparties => /admin/counterparties/batch_action(.:format)
  // function(options)
  batch_action_admin_counterparties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"counterparties",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_countries => /admin/countries/batch_action(.:format)
  // function(options)
  batch_action_admin_countries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"countries",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_country_aliases => /admin/country_aliases/batch_action(.:format)
  // function(options)
  batch_action_admin_country_aliases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"country_aliases",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_currencies => /admin/currencies/batch_action(.:format)
  // function(options)
  batch_action_admin_currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_currency_aliases => /admin/currency_aliases/batch_action(.:format)
  // function(options)
  batch_action_admin_currency_aliases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_aliases",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_currency_balance_sheets => /admin/currency_balance_sheets/batch_action(.:format)
  // function(options)
  batch_action_admin_currency_balance_sheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_currency_line_items => /admin/currency_line_items/batch_action(.:format)
  // function(options)
  batch_action_admin_currency_line_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_line_items",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_email_events => /admin/email_events/batch_action(.:format)
  // function(options)
  batch_action_admin_email_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_events",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_execution_groups => /admin/execution_groups/batch_action(.:format)
  // function(options)
  batch_action_admin_execution_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_flags => /admin/flags/batch_action(.:format)
  // function(options)
  batch_action_admin_flags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"flags",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_fx_contract_valuations => /admin/fx_contract_valuations/batch_action(.:format)
  // function(options)
  batch_action_admin_fx_contract_valuations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contract_valuations",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_fx_contracts => /admin/fx_contracts/batch_action(.:format)
  // function(options)
  batch_action_admin_fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_holidays => /admin/holidays/batch_action(.:format)
  // function(options)
  batch_action_admin_holidays_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"holidays",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_issues => /admin/issues/batch_action(.:format)
  // function(options)
  batch_action_admin_issues_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_performance_look_through_performances => /admin/performance_look_through_performances/batch_action(.:format)
  // function(options)
  batch_action_admin_performance_look_through_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_look_through_performances",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_performance_new_share_class_performances => /admin/performance_new_share_class_performances/batch_action(.:format)
  // function(options)
  batch_action_admin_performance_new_share_class_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_new_share_class_performances",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_performance_portfolio_performances => /admin/performance_portfolio_performances/batch_action(.:format)
  // function(options)
  batch_action_admin_performance_portfolio_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_portfolio_performances",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_preprocess_strategies => /admin/preprocess_strategies/batch_action(.:format)
  // function(options)
  batch_action_admin_preprocess_strategies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"preprocess_strategies",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_processing_groups => /admin/processing_groups/batch_action(.:format)
  // function(options)
  batch_action_admin_processing_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_quickfix_messages => /admin/quickfix_messages/batch_action(.:format)
  // function(options)
  batch_action_admin_quickfix_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quickfix_messages",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_quicksight_nav_exposure_batches => /admin/quicksight_nav_exposure_batches/batch_action(.:format)
  // function(options)
  batch_action_admin_quicksight_nav_exposure_batches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quicksight_nav_exposure_batches",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_raw_account_data_map_sets => /admin/raw_account_data_map_sets/batch_action(.:format)
  // function(options)
  batch_action_admin_raw_account_data_map_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_reconcile_messages => /admin/reconcile_messages/batch_action(.:format)
  // function(options)
  batch_action_admin_reconcile_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reconcile_messages",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_reports_selectable_field_data_type_formats => /admin/reports_selectable_field_data_type_formats/batch_action(.:format)
  // function(options)
  batch_action_admin_reports_selectable_field_data_type_formats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reports_selectable_field_data_type_formats",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_share_class_performances => /admin/share_class_performances/batch_action(.:format)
  // function(options)
  batch_action_admin_share_class_performances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_sns_events => /admin/sns_events/batch_action(.:format)
  // function(options)
  batch_action_admin_sns_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sns_events",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_users => /admin/users/batch_action(.:format)
  // function(options)
  batch_action_admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_zendesk_fields => /admin/zendesk_fields/batch_action(.:format)
  // function(options)
  batch_action_admin_zendesk_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"zendesk_fields",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_rebalance_api_v1_accounts => /api/v1/accounts/bulk_rebalance(.:format)
  // function(options)
  bulk_rebalance_api_v1_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"bulk_rebalance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_roll_api_v1_accounts => /api/v1/accounts/bulk_roll(.:format)
  // function(options)
  bulk_roll_api_v1_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"bulk_roll",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_validate_api_v1_currency_balance_sheets => /api/v1/currency_balance_sheets/bulk_validate(.:format)
  // function(options)
  bulk_validate_api_v1_currency_balance_sheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[6,"bulk_validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulkable_profit_and_loss_api_v1_processing_group => /api/v1/processing_groups/:id/bulkable_profit_and_loss(.:format)
  // function(id, options)
  bulkable_profit_and_loss_api_v1_processing_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bulkable_profit_and_loss",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// calculable_dates_api_v1_account_share_class_performances => /api/v1/accounts/:account_id/share_class_performances/calculable_dates(.:format)
  // function(account_id, options)
  calculable_dates_api_v1_account_share_class_performances_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[2,[7,"/",false],[2,[6,"calculable_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// calculations_api_v1_account_share_class_performances => /api/v1/accounts/:account_id/share_class_performances/calculations(.:format)
  // function(account_id, options)
  calculations_api_v1_account_share_class_performances_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[2,[7,"/",false],[2,[6,"calculations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// checks_account => /accounts/:id/checks(.:format)
  // function(id, options)
  checks_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client => /clients/:id(.:format)
  // function(id, options)
  client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// client_account => /clients/:client_id/accounts/:id(.:format)
  // function(client_id, id, options)
  client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_account_alias => /clients/:client_id/account_aliases/:id(.:format)
  // function(client_id, id, options)
  client_account_alias_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"account_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// client_account_aliases => /clients/:client_id/account_aliases(.:format)
  // function(client_id, options)
  client_account_aliases_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"account_aliases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_accounts => /clients/:client_id/accounts(.:format)
  // function(client_id, options)
  client_accounts_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// client_data_emails_new_email => /client_data_emails/new_email(.:format)
  // function(options)
  client_data_emails_new_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"client_data_emails",false],[2,[7,"/",false],[2,[6,"new_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// clients => /clients(.:format)
  // function(options)
  clients_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// clone_accounts => /accounts/clone(.:format)
  // function(options)
  clone_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// close_admin_issue => /admin/issues/:id/close(.:format)
  // function(id, options)
  close_admin_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// close_ticket_support => /supports/:id/close_ticket(.:format)
  // function(id, options)
  close_ticket_support_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"supports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close_ticket",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// confirm_deactivate_client_account_alias => /clients/:client_id/account_aliases/:id/confirm_deactivate(.:format)
  // function(client_id, id, options)
  confirm_deactivate_client_account_alias_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"account_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// copy_admin_raw_account_data_map_set => /admin/raw_account_data_map_sets/:id/copy(.:format)
  // function(id, options)
  copy_admin_raw_account_data_map_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cumulative_attribution_client_account => /clients/:client_id/accounts/:id/cumulative_attribution(.:format)
  // function(client_id, id, options)
  cumulative_attribution_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cumulative_attribution",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// currency_balance_sheets => /currency_balance_sheets(.:format)
  // function(options)
  currency_balance_sheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// daily_attribution_client_account => /clients/:client_id/accounts/:id/daily_attribution(.:format)
  // function(client_id, id, options)
  daily_attribution_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"daily_attribution",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// deactivate_admin_user => /admin/users/:id/deactivate(.:format)
  // function(id, options)
  deactivate_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// deactivate_client_account_alias => /clients/:client_id/account_aliases/:id/deactivate(.:format)
  // function(client_id, id, options)
  deactivate_client_account_alias_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"account_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// deactivate_roll_scheduled_dates_account => /accounts/:id/deactivate_roll_scheduled_dates(.:format)
  // function(id, options)
  deactivate_roll_scheduled_dates_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deactivate_roll_scheduled_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_saml_user_session => /logout(.:format)
  // function(options)
  destroy_saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_user_session => /logout(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// download_file_admin_sns_event => /admin/sns_events/:id/download_file(.:format)
  // function(id, options)
  download_file_admin_sns_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sns_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_raw_data_file => /raw_data_files/:id/download(.:format)
  // function(id, options)
  download_raw_data_file_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account => /accounts/:id/edit(.:format)
  // function(id, options)
  edit_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_admin_account => /admin/accounts/:id/edit(.:format)
  // function(id, options)
  edit_admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_account_benchmark => /admin/account_benchmarks/:id/edit(.:format)
  // function(id, options)
  edit_admin_account_benchmark_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_benchmarks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_calleo_branch_timezone_map => /admin/calleo_branch_timezone_maps/:id/edit(.:format)
  // function(id, options)
  edit_admin_calleo_branch_timezone_map_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calleo_branch_timezone_maps",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_counterparty => /admin/counterparties/:id/edit(.:format)
  // function(id, options)
  edit_admin_counterparty_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"counterparties",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_country => /admin/countries/:id/edit(.:format)
  // function(id, options)
  edit_admin_country_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"countries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_country_alias => /admin/country_aliases/:id/edit(.:format)
  // function(id, options)
  edit_admin_country_alias_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"country_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_currency => /admin/currencies/:id/edit(.:format)
  // function(id, options)
  edit_admin_currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_currency_alias => /admin/currency_aliases/:id/edit(.:format)
  // function(id, options)
  edit_admin_currency_alias_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_currency_balance_sheet => /admin/currency_balance_sheets/:id/edit(.:format)
  // function(id, options)
  edit_admin_currency_balance_sheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_currency_line_item => /admin/currency_line_items/:id/edit(.:format)
  // function(id, options)
  edit_admin_currency_line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_line_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_execution_group => /admin/execution_groups/:id/edit(.:format)
  // function(id, options)
  edit_admin_execution_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_flag => /admin/flags/:id/edit(.:format)
  // function(id, options)
  edit_admin_flag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"flags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_fx_contract => /admin/fx_contracts/:id/edit(.:format)
  // function(id, options)
  edit_admin_fx_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_fx_contract_valuation => /admin/fx_contract_valuations/:id/edit(.:format)
  // function(id, options)
  edit_admin_fx_contract_valuation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contract_valuations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_holiday => /admin/holidays/:id/edit(.:format)
  // function(id, options)
  edit_admin_holiday_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"holidays",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_issue => /admin/issues/:id/edit(.:format)
  // function(id, options)
  edit_admin_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_performance_look_through_performance => /admin/performance_look_through_performances/:id/edit(.:format)
  // function(id, options)
  edit_admin_performance_look_through_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_look_through_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_performance_new_share_class_performance => /admin/performance_new_share_class_performances/:id/edit(.:format)
  // function(id, options)
  edit_admin_performance_new_share_class_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_new_share_class_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_performance_portfolio_performance => /admin/performance_portfolio_performances/:id/edit(.:format)
  // function(id, options)
  edit_admin_performance_portfolio_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_portfolio_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_preprocess_strategy => /admin/preprocess_strategies/:id/edit(.:format)
  // function(id, options)
  edit_admin_preprocess_strategy_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"preprocess_strategies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_processing_group => /admin/processing_groups/:id/edit(.:format)
  // function(id, options)
  edit_admin_processing_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_quicksight_nav_exposure_batch => /admin/quicksight_nav_exposure_batches/:id/edit(.:format)
  // function(id, options)
  edit_admin_quicksight_nav_exposure_batch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quicksight_nav_exposure_batches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_raw_account_data_map_set => /admin/raw_account_data_map_sets/:id/edit(.:format)
  // function(id, options)
  edit_admin_raw_account_data_map_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_reports_selectable_field_data_type_format => /admin/reports_selectable_field_data_type_formats/:id/edit(.:format)
  // function(id, options)
  edit_admin_reports_selectable_field_data_type_format_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reports_selectable_field_data_type_formats",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_share_class_performance => /admin/share_class_performances/:id/edit(.:format)
  // function(id, options)
  edit_admin_share_class_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_sns_event => /admin/sns_events/:id/edit(.:format)
  // function(id, options)
  edit_admin_sns_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sns_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_user => /admin/users/:id/edit(.:format)
  // function(id, options)
  edit_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_v1_client_raw_account_data_map_set => /api/v1/clients/:client_id/raw_account_data_map_sets/:id/edit(.:format)
  // function(client_id, id, options)
  edit_api_v1_client_raw_account_data_map_set_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_api_v1_fx_contract => /api/v1/fx_contracts/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_fx_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client => /clients/:id/edit(.:format)
  // function(id, options)
  edit_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_client_account => /clients/:client_id/accounts/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_client_account_alias => /clients/:client_id/account_aliases/:id/edit(.:format)
  // function(client_id, id, options)
  edit_client_account_alias_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"account_aliases",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_raw_data_file => /raw_data_files/:id/edit(.:format)
  // function(id, options)
  edit_raw_data_file_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_reports_account_group => /reports/account_groups/:id/edit(.:format)
  // function(id, options)
  edit_reports_account_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_reports_config => /reports/configs/:id/edit(.:format)
  // function(id, options)
  edit_reports_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// execution_settings => /execution_settings(.:format)
  // function(options)
  execution_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"execution_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// file_names_api_v1_raw_data_files => /api/v1/raw_data_files/file_names(.:format)
  // function(options)
  file_names_api_v1_raw_data_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[6,"file_names",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts => /fx_contracts(.:format)
  // function(options)
  fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// fx_contracts_trading_platforms_calleo_sftp_export => /fx_contracts/trading_platforms/calleo/sftp_export(.:format)
  // function(options)
  fx_contracts_trading_platforms_calleo_sftp_export_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"calleo",false],[2,[7,"/",false],[2,[6,"sftp_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts_trading_platforms_calleo_xmls_export => /fx_contracts/trading_platforms/calleo/xmls_export(.:format)
  // function(options)
  fx_contracts_trading_platforms_calleo_xmls_export_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"calleo",false],[2,[7,"/",false],[2,[6,"xmls_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts_trading_platforms_calleo_xmls_import => /fx_contracts/trading_platforms/calleo/xmls_import(.:format)
  // function(options)
  fx_contracts_trading_platforms_calleo_xmls_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"calleo",false],[2,[7,"/",false],[2,[6,"xmls_import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts_trading_platforms_common_client_export_to_csv => /fx_contracts/trading_platforms/common/client_export_to_csv(.:format)
  // function(options)
  fx_contracts_trading_platforms_common_client_export_to_csv_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"common",false],[2,[7,"/",false],[2,[6,"client_export_to_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts_trading_platforms_common_export_to_csv => /fx_contracts/trading_platforms/common/export_to_csv(.:format)
  // function(options)
  fx_contracts_trading_platforms_common_export_to_csv_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"common",false],[2,[7,"/",false],[2,[6,"export_to_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts_trading_platforms_common_export_to_trading_platform => /fx_contracts/trading_platforms/common/export_to_trading_platform(.:format)
  // function(options)
  fx_contracts_trading_platforms_common_export_to_trading_platform_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"common",false],[2,[7,"/",false],[2,[6,"export_to_trading_platform",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts_trading_platforms_common_export_to_xlsx => /fx_contracts/trading_platforms/common/export_to_xlsx(.:format)
  // function(options)
  fx_contracts_trading_platforms_common_export_to_xlsx_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"common",false],[2,[7,"/",false],[2,[6,"export_to_xlsx",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fx_contracts_trading_platforms_milltech_csv_import => /fx_contracts/trading_platforms/milltech/csv_import(.:format)
  // function(options)
  fx_contracts_trading_platforms_milltech_csv_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"trading_platforms",false],[2,[7,"/",false],[2,[6,"milltech",false],[2,[7,"/",false],[2,[6,"csv_import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// generated_data_to_csv_raw_data_file => /raw_data_files/:id/generated_data_to_csv(.:format)
  // function(id, options)
  generated_data_to_csv_raw_data_file_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"generated_data_to_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// hedge_ratio_client_account => /clients/:client_id/accounts/:id/hedge_ratio(.:format)
  // function(client_id, id, options)
  hedge_ratio_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hedge_ratio",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// idp_destroy_saml_user_session => /saml/idp_sign_out(.:format)
  // function(options)
  idp_destroy_saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"idp_sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ignore_account_raw_data_file_processes => /account_raw_data_file_processes/ignore(.:format)
  // function(options)
  ignore_account_raw_data_file_processes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_raw_data_file_processes",false],[2,[7,"/",false],[2,[6,"ignore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// import_csv_admin_currencies => /admin/currencies/import_csv(.:format)
  // function(options)
  import_csv_admin_currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[6,"import_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// import_fields_with_options_supports => /supports/import_fields_with_options(.:format)
  // function(options)
  import_fields_with_options_supports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"supports",false],[2,[7,"/",false],[2,[6,"import_fields_with_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// list_notes_api_v1_account_flags => /api/v1/account_flags/list_notes(.:format)
  // function(options)
  list_notes_api_v1_account_flags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"account_flags",false],[2,[7,"/",false],[2,[6,"list_notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lock_admin_user => /admin/users/:id/lock(.:format)
  // function(id, options)
  lock_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"lock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// metadata_user_session => /saml/metadata(.:format)
  // function(options)
  metadata_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account => /accounts/new(.:format)
  // function(options)
  new_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_account => /admin/accounts/new(.:format)
  // function(options)
  new_admin_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_account_benchmark => /admin/account_benchmarks/new(.:format)
  // function(options)
  new_admin_account_benchmark_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_benchmarks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_calleo_branch_timezone_map => /admin/calleo_branch_timezone_maps/new(.:format)
  // function(options)
  new_admin_calleo_branch_timezone_map_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calleo_branch_timezone_maps",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_counterparty => /admin/counterparties/new(.:format)
  // function(options)
  new_admin_counterparty_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"counterparties",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_country => /admin/countries/new(.:format)
  // function(options)
  new_admin_country_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"countries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_country_alias => /admin/country_aliases/new(.:format)
  // function(options)
  new_admin_country_alias_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"country_aliases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_currency => /admin/currencies/new(.:format)
  // function(options)
  new_admin_currency_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_currency_alias => /admin/currency_aliases/new(.:format)
  // function(options)
  new_admin_currency_alias_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_aliases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_currency_balance_sheet => /admin/currency_balance_sheets/new(.:format)
  // function(options)
  new_admin_currency_balance_sheet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_currency_line_item => /admin/currency_line_items/new(.:format)
  // function(options)
  new_admin_currency_line_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_line_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_execution_group => /admin/execution_groups/new(.:format)
  // function(options)
  new_admin_execution_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"execution_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_fx_contract => /admin/fx_contracts/new(.:format)
  // function(options)
  new_admin_fx_contract_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_fx_contract_valuation => /admin/fx_contract_valuations/new(.:format)
  // function(options)
  new_admin_fx_contract_valuation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contract_valuations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_holiday => /admin/holidays/new(.:format)
  // function(options)
  new_admin_holiday_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"holidays",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_issue => /admin/issues/new(.:format)
  // function(options)
  new_admin_issue_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_performance_look_through_performance => /admin/performance_look_through_performances/new(.:format)
  // function(options)
  new_admin_performance_look_through_performance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_look_through_performances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_performance_new_share_class_performance => /admin/performance_new_share_class_performances/new(.:format)
  // function(options)
  new_admin_performance_new_share_class_performance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_new_share_class_performances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_performance_portfolio_performance => /admin/performance_portfolio_performances/new(.:format)
  // function(options)
  new_admin_performance_portfolio_performance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_portfolio_performances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_preprocess_strategy => /admin/preprocess_strategies/new(.:format)
  // function(options)
  new_admin_preprocess_strategy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"preprocess_strategies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_processing_group => /admin/processing_groups/new(.:format)
  // function(options)
  new_admin_processing_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_quicksight_nav_exposure_batch => /admin/quicksight_nav_exposure_batches/new(.:format)
  // function(options)
  new_admin_quicksight_nav_exposure_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quicksight_nav_exposure_batches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_raw_account_data_map_set => /admin/raw_account_data_map_sets/new(.:format)
  // function(options)
  new_admin_raw_account_data_map_set_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_reports_selectable_field_data_type_format => /admin/reports_selectable_field_data_type_formats/new(.:format)
  // function(options)
  new_admin_reports_selectable_field_data_type_format_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reports_selectable_field_data_type_formats",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_share_class_performance => /admin/share_class_performances/new(.:format)
  // function(options)
  new_admin_share_class_performance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_sns_event => /admin/sns_events/new(.:format)
  // function(options)
  new_admin_sns_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sns_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_user => /admin/users/new(.:format)
  // function(options)
  new_admin_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_v1_client_raw_account_data_map_set => /api/v1/clients/:client_id/raw_account_data_map_sets/new(.:format)
  // function(client_id, options)
  new_api_v1_client_raw_account_data_map_set_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_api_v1_fx_contract => /api/v1/fx_contracts/new(.:format)
  // function(options)
  new_api_v1_fx_contract_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client => /clients/new(.:format)
  // function(options)
  new_client_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_client_account => /clients/:client_id/accounts/new(.:format)
  // function(client_id, options)
  new_client_account_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_client_account_alias => /clients/:client_id/account_aliases/new(.:format)
  // function(client_id, options)
  new_client_account_alias_path: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"account_aliases",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_fx_rates_market_data_notifications => /market_data_notifications/new_fx_rates(.:format)
  // function(options)
  new_fx_rates_market_data_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"market_data_notifications",false],[2,[7,"/",false],[2,[6,"new_fx_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_process => /processes/new(.:format)
  // function(options)
  new_process_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"processes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_raw_data_file => /raw_data_files/new(.:format)
  // function(options)
  new_raw_data_file_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_reports_account_group => /reports/account_groups/new(.:format)
  // function(options)
  new_reports_account_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_reports_account_group_report => /reports/account_groups/:account_group_id/reports/new(.:format)
  // function(account_group_id, options)
  new_reports_account_group_report_path: Utils.route([["account_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[3,"account_group_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_reports_config => /reports/configs/new(.:format)
  // function(options)
  new_reports_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_saml_user_session => /saml/sign_in(.:format)
  // function(options)
  new_saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_support => /supports/new(.:format)
  // function(options)
  new_support_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"supports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_invitation => /invitation/new(.:format)
  // function(options)
  new_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_password => /password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /login(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// notes => /notes(.:format)
  // function(options)
  notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// notifications => /notifications(.:format)
  // function(options)
  notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// oauth_token => /oauth/token(.:format)
  // function(options)
  oauth_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oona => /oona(.:format)
  // function(options)
  oona_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oona",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// oona_api_fx_contracts => /oona/api/fx_contracts(.:format)
  // function(options)
  oona_api_fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oona",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oona_api_net_by_pivot => /oona/api/net_by_pivot(.:format)
  // function(options)
  oona_api_net_by_pivot_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oona",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"net_by_pivot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oona_api_net_pairs => /oona/api/net_pairs(.:format)
  // function(options)
  oona_api_net_pairs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oona",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"net_pairs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// open_admin_issue => /admin/issues/:id/open(.:format)
  // function(id, options)
  open_admin_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"open",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// performance_dates_api_v1_account => /api/v1/accounts/:id/performance_dates(.:format)
  // function(id, options)
  performance_dates_api_v1_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"performance_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// performance_report_client_account => /clients/:client_id/accounts/:id/performance_report(.:format)
  // function(client_id, id, options)
  performance_report_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"performance_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// performance_reports => /performance_reports(.:format)
  // function(options)
  performance_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"performance_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// period_excess_return_client_account => /clients/:client_id/accounts/:id/period_excess_return(.:format)
  // function(client_id, id, options)
  period_excess_return_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"period_excess_return",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// period_performance_client_account => /clients/:client_id/accounts/:id/period_performance(.:format)
  // function(client_id, id, options)
  period_performance_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"period_performance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// period_tracking_error_client_account => /clients/:client_id/accounts/:id/period_tracking_error(.:format)
  // function(client_id, id, options)
  period_tracking_error_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"period_tracking_error",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// print_account_currency_balance_sheet => /accounts/:account_id/currency_balance_sheets/:id/print(.:format)
  // function(account_id, id, options)
  print_account_currency_balance_sheet_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// process_event_admin_email_event => /admin/email_events/:id/process_event(.:format)
  // function(id, options)
  process_event_admin_email_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"email_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"process_event",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// process_message_admin_reconcile_message => /admin/reconcile_messages/:id/process_message(.:format)
  // function(id, options)
  process_message_admin_reconcile_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reconcile_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"process_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// processes => /processes(.:format)
  // function(options)
  processes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"processes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// processing_dashboard => /processing_dashboard(.:format)
  // function(options)
  processing_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"processing_dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// processing_dashboard_data_api_v1_processing_groups => /api/v1/processing_groups/processing_dashboard_data(.:format)
  // function(options)
  processing_dashboard_data_api_v1_processing_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[6,"processing_dashboard_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// processing_dashboard_metadata_api_v1_processing_groups => /api/v1/processing_groups/processing_dashboard_metadata(.:format)
  // function(options)
  processing_dashboard_metadata_api_v1_processing_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[6,"processing_dashboard_metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// profit_and_loss_local_conversions_api_v1_processing_group => /api/v1/processing_groups/:id/profit_and_loss_local_conversions(.:format)
  // function(id, options)
  profit_and_loss_local_conversions_api_v1_processing_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"profit_and_loss_local_conversions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// quicksight => /quicksight(.:format)
  // function(options)
  quicksight_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"quicksight",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// raw_data_file => /raw_data_files/:id(.:format)
  // function(id, options)
  raw_data_file_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// raw_data_file_processes => /raw_data_files/:raw_data_file_id/processes(.:format)
  // function(raw_data_file_id, options)
  raw_data_file_processes_path: Utils.route([["raw_data_file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[3,"raw_data_file_id",false],[2,[7,"/",false],[2,[6,"processes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// raw_data_files => /raw_data_files(.:format)
  // function(options)
  raw_data_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"raw_data_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// readings => /notifications/readings(.:format)
  // function(options)
  readings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"readings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rebalance_account => /accounts/:id/rebalance(.:format)
  // function(id, options)
  rebalance_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rebalance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// regenerate_reports_report => /reports/reports/:id/regenerate(.:format)
  // function(id, options)
  regenerate_reports_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"regenerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// remove_user_invitation => /invitation/remove(.:format)
  // function(options)
  remove_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports => /reports(.:format)
  // function(options)
  reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// reports_account_group => /reports/account_groups/:id(.:format)
  // function(id, options)
  reports_account_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_account_group_reports => /reports/account_groups/:account_group_id/reports(.:format)
  // function(account_group_id, options)
  reports_account_group_reports_path: Utils.route([["account_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"account_groups",false],[2,[7,"/",false],[2,[3,"account_group_id",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reports_account_groups => /reports/account_groups(.:format)
  // function(options)
  reports_account_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"account_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_config => /reports/configs/:id(.:format)
  // function(id, options)
  reports_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_configs => /reports/configs(.:format)
  // function(options)
  reports_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_report => /reports/reports/:id(.:format)
  // function(id, options)
  reports_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_scheduled => /reports/scheduled(.:format)
  // function(options)
  reports_scheduled_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"scheduled",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reprocess_account_raw_data_file_processes => /account_raw_data_file_processes/reprocess(.:format)
  // function(options)
  reprocess_account_raw_data_file_processes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_raw_data_file_processes",false],[2,[7,"/",false],[2,[6,"reprocess",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// resend_invite_instructions_admin_user => /admin/users/:id/resend_invite_instructions(.:format)
  // function(id, options)
  resend_invite_instructions_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resend_invite_instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_account => /admin/accounts/:id/restore(.:format)
  // function(id, options)
  restore_admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_account_benchmark => /admin/account_benchmarks/:id/restore(.:format)
  // function(id, options)
  restore_admin_account_benchmark_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"account_benchmarks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_counterparty => /admin/counterparties/:id/restore(.:format)
  // function(id, options)
  restore_admin_counterparty_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"counterparties",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_currency => /admin/currencies/:id/restore(.:format)
  // function(id, options)
  restore_admin_currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_currency_balance_sheet => /admin/currency_balance_sheets/:id/restore(.:format)
  // function(id, options)
  restore_admin_currency_balance_sheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_currency_line_item => /admin/currency_line_items/:id/restore(.:format)
  // function(id, options)
  restore_admin_currency_line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currency_line_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_fx_contract => /admin/fx_contracts/:id/restore(.:format)
  // function(id, options)
  restore_admin_fx_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_fx_contract_valuation => /admin/fx_contract_valuations/:id/restore(.:format)
  // function(id, options)
  restore_admin_fx_contract_valuation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contract_valuations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_performance_look_through_performance => /admin/performance_look_through_performances/:id/restore(.:format)
  // function(id, options)
  restore_admin_performance_look_through_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_look_through_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_performance_new_share_class_performance => /admin/performance_new_share_class_performances/:id/restore(.:format)
  // function(id, options)
  restore_admin_performance_new_share_class_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_new_share_class_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_performance_portfolio_performance => /admin/performance_portfolio_performances/:id/restore(.:format)
  // function(id, options)
  restore_admin_performance_portfolio_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"performance_portfolio_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_preprocess_strategy => /admin/preprocess_strategies/:id/restore(.:format)
  // function(id, options)
  restore_admin_preprocess_strategy_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"preprocess_strategies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_quickfix_message => /admin/quickfix_messages/:id/restore(.:format)
  // function(id, options)
  restore_admin_quickfix_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"quickfix_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_raw_account_data_map_set => /admin/raw_account_data_map_sets/:id/restore(.:format)
  // function(id, options)
  restore_admin_raw_account_data_map_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"raw_account_data_map_sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_reconcile_message => /admin/reconcile_messages/:id/restore(.:format)
  // function(id, options)
  restore_admin_reconcile_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reconcile_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_share_class_performance => /admin/share_class_performances/:id/restore(.:format)
  // function(id, options)
  restore_admin_share_class_performance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"share_class_performances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_admin_user => /admin/users/:id/restore(.:format)
  // function(id, options)
  restore_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// revoke_two_factor_admin_user => /admin/users/:id/revoke_two_factor(.:format)
  // function(id, options)
  revoke_two_factor_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revoke_two_factor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// rosy_api_v1_unusual_files => /rosy/api/v1/unusual_files(.:format)
  // function(options)
  rosy_api_v1_unusual_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosy",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"unusual_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rosy_api_v1_unusual_records => /rosy/api/v1/unusual_records(.:format)
  // function(options)
  rosy_api_v1_unusual_records_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosy",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"unusual_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rosy_root => /rosy(.:format)
  // function(options)
  rosy_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// saml_user_session => /saml/auth(.:format)
  // function(options)
  saml_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// scope_count_api_v1_raw_data_files => /api/v1/raw_data_files/scope_count(.:format)
  // function(options)
  scope_count_api_v1_raw_data_files_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"raw_data_files",false],[2,[7,"/",false],[2,[6,"scope_count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// send_order_api_v1_fx_contracts => /api/v1/fx_contracts/send_order(.:format)
  // function(options)
  send_order_api_v1_fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"send_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// simulate_trade_execution_fx_contracts => /fx_contracts/simulate_trade_execution(.:format)
  // function(options)
  simulate_trade_execution_fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"simulate_trade_execution",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// simulate_trade_execution_oona_api_fx_contracts => /oona/api/fx_contracts/simulate_trade_execution(.:format)
  // function(options)
  simulate_trade_execution_oona_api_fx_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oona",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fx_contracts",false],[2,[7,"/",false],[2,[6,"simulate_trade_execution",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// solve_request_support => /supports/:id/solve_request(.:format)
  // function(id, options)
  solve_request_support_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"supports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"solve_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// support => /supports/:id(.:format)
  // function(id, options)
  support_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"supports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// supports => /supports(.:format)
  // function(options)
  supports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"supports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// theme_client => /clients/:id/theme(.:format)
  // function(id, options)
  theme_client_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"theme",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// trigger_calculations_admin_fx_contract_valuation => /admin/fx_contract_valuations/:id/trigger_calculations(.:format)
  // function(id, options)
  trigger_calculations_admin_fx_contract_valuation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"fx_contract_valuations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"trigger_calculations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// unlock_admin_user => /admin/users/:id/unlock(.:format)
  // function(id, options)
  unlock_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// unrealized_profit_and_loss_client_account => /clients/:client_id/accounts/:id/unrealized_profit_and_loss(.:format)
  // function(client_id, id, options)
  unrealized_profit_and_loss_client_account_path: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unrealized_profit_and_loss",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upload_csv_admin_currencies => /admin/currencies/upload_csv(.:format)
  // function(options)
  upload_csv_admin_currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[6,"upload_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_disable_authy => /disable_authy(.:format)
  // function(options)
  user_disable_authy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"disable_authy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_enable_authy => /enable-two-factor(.:format)
  // function(options)
  user_enable_authy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enable-two-factor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_invitation => /invitation(.:format)
  // function(options)
  user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_password => /password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_password_expired => /password_expired(.:format)
  // function(options)
  user_password_expired_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password_expired",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_request_phone_call => /request-phone-call(.:format)
  // function(options)
  user_request_phone_call_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"request-phone-call",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_request_sms => /request-sms(.:format)
  // function(options)
  user_request_sms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"request-sms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /login(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_terms_and_conditions => /users/:user_id/terms_and_conditions(.:format)
  // function(user_id, options)
  user_terms_and_conditions_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"terms_and_conditions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_verify_authy => /verify-token(.:format)
  // function(options)
  user_verify_authy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"verify-token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_verify_authy_installation => /verify-installation(.:format)
  // function(options)
  user_verify_authy_installation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"verify-installation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// validate_account_currency_balance_sheet => /accounts/:account_id/currency_balance_sheets/:id/validate(.:format)
  // function(account_id, id, options)
  validate_account_currency_balance_sheet_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"currency_balance_sheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// validate_most_recent_balance_sheets_api_v1_processing_group => /api/v1/processing_groups/:id/validate_most_recent_balance_sheets(.:format)
  // function(id, options)
  validate_most_recent_balance_sheets_api_v1_processing_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"processing_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"validate_most_recent_balance_sheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// weights_account => /accounts/:id/weights(.:format)
  // function(id, options)
  weights_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"weights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// weights_api_v1_account => /api/v1/accounts/:id/weights(.:format)
  // function(id, options)
  weights_api_v1_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"weights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Namespace" + ".defaults is removed. Use " + "Namespace" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Namespace", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

}.call(routes));

export default routes.Namespace;
