import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from './Form';
import Client from '../../../client';
import useForm from '../../hooks/useForm';

export default () => {
  const navigate = useNavigate();

  const {
    setFormData,
    setFormErrors,
    formData,
    formErrors,
  } = useForm({
    nettingRestrictions: '',
    name: '',
    description: '',
    client: '',
  });

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const res = await Client.createExecutionGroup({ ...formData, clientId: formData.client });
    if (res.ok) {
      navigate('/execution_settings');
      return;
    }
    const { errors } = await res.json();
    setFormErrors(errors);
  };

  return (
    <div>
      <div className="view-container-actions">
        <Link to="/execution_settings" className="btn btn-success">Back</Link>
      </div>
      <div className="view-container-content">
        <div className="col-sm-12">
          <h1>New Execution Group</h1>
        </div>
        <div className="col-sm-12">
          <Form
            onSubmit={handleSubmit}
            errors={formErrors}
            onChange={handleFormChange}
            formData={formData}
          />
        </div>
      </div>
    </div>
  );
};
