import { Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import { capstockException } from './commonPropTypes';
import { CapstockExceptionFormGroup } from './capstockExceptionFormGroup';
import { DestroyInput, IdInput } from './capstockExceptionInputs';

class CapstockExceptionsSection extends React.Component {
  handleAddClicked = () => {
    const { onAdd, uuid } = this.props;
    onAdd(uuid);
  };

  shouldDisableAddButton = (exceptions) => {
    if (exceptions.length === 0) return false;
    if (exceptions.length === 2) return true;
    return !exceptions[0].action || !exceptions[0].type || exceptions[0].action === 'inflow_outflow';
  };

  render() {
    const {
      onRemove, onChange, exceptions, mapIndex, uuid, destroyedCapstockExceptions,
    } = this.props;
    return (
      <React.Fragment>
        {
          exceptions.map((exception, index) => (
            <CapstockExceptionFormGroup
              key={exception.uuid}
              exception={exception}
              index={index}
              mapIndex={mapIndex}
              currencyLineItemMapUuid={uuid}
              onChange={onChange}
              onRemove={onRemove}
            />
          ))
        }
        <div className="links col-sm-12 text-right">
          <Button
            bsStyle="primary"
            onClick={this.handleAddClicked}
            disabled={this.shouldDisableAddButton(exceptions)}
          >
            {I18n.t('users_portal.accounts.form.capstock_exceptions.add_exception')}
          </Button>
        </div>
        {
          destroyedCapstockExceptions.map((exception, index) => {
            const startingIndex = exceptions.length + index;
            return (
              <React.Fragment key={exception.uuid}>
                <IdInput exception={exception} mapIndex={mapIndex} index={startingIndex} />
                <DestroyInput exception={exception} mapIndex={mapIndex} index={startingIndex} />
              </React.Fragment>
            );
          })
        }
      </React.Fragment>
    );
  }
}

CapstockExceptionsSection.propTypes = {
  exceptions: PropTypes.arrayOf(capstockException).isRequired,
  mapIndex: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  destroyedCapstockExceptions: PropTypes.arrayOf(PropTypes.shape(capstockException)).isRequired,
};

export { CapstockExceptionsSection };
