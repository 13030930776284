import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { availableRollPropTypes } from './commonPropTypes';
import { NewSettlementDatePicker } from './newSettlementDatePicker';

export class AvailableRollRow extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onPairSelected: PropTypes.func.isRequired,
    onSettlementDateChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      momentPropTypes.momentObj,
    ]),
    roll: availableRollPropTypes.isRequired,
  };

 static defaultProps = {
   value: '',
 };

  handlePairSelected = (event) => {
    const { target: { checked } } = event;
    const { index, onPairSelected } = this.props;
    onPairSelected(index, checked);
  };

  hasHolidayError = () => {
    const { roll, value, isSelected } = this.props;

    const { holidays } = roll;

    return isSelected && holidays && holidays.find(holiday => moment(holiday).isSame(value));
  }

  handleSettlementDateOpenChange = (date) => {
    const { index, onSettlementDateChange } = this.props;

    onSettlementDateChange(index, date);
  };

  render() {
    const { roll, isSelected, value } = this.props;
    const {
      currency1: { isoCode: isoCode1 },
      currency2: { isoCode: isoCode2 },
      settlementDateToClose,
      counterparty,
      holidays,
    } = roll;

    const key = `${isoCode1}${isoCode2}${settlementDateToClose}${counterparty.shortName}`;
    return (
      <tr key={key}>
        <td>
          <input
            data-test-id={`availableRollRowCheck${key}`}
            type="checkbox"
            checked={isSelected}
            onChange={this.handlePairSelected}
          />
        </td>
        <td>{isoCode1}</td>
        <td>{isoCode2}</td>
        <td>{settlementDateToClose}</td>
        <td>{counterparty.shortName}</td>
        <td data-test-id={`newSettlementDateToOpenCol${key}`}>
          <NewSettlementDatePicker
            onSelectedDate={this.handleSettlementDateOpenChange}
            required={isSelected}
            disabled={!isSelected}
            originalSettlementDate={settlementDateToClose}
            value={value}
            holidays={holidays}
          />
          { this.hasHolidayError() && (
            <div className="invalid-feedback">The date cannot be a holiday for the currency pair</div>
          )}
        </td>
      </tr>
    );
  }
}
