import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  DropdownButton,
  MenuItem,
  Label,
} from 'react-bootstrap';
import I18n from 'i18n-js';
import { DateTime } from '../../../shared/date';

const Item = (props) => {
  const {
    id,
    name,
    executionTradeTypes,
    executionLocalCurrencies,
    createdAt,
    updatedAt,
    onRemove,
    groupId,
  } = props;

  const navigate = useNavigate();

  const handleRemove = () => {
    onRemove(id);
  };

  const handleManageTradeTypes = () => {
    navigate(`/execution_settings/groups/${groupId}/subgroups/${id}/trade_types`);
  };

  const handleManageLocalCurrencies = () => {
    navigate(`/execution_settings/groups/${groupId}/subgroups/${id}/local_currencies`);
  };

  const handleEdit = () => {
    navigate(`/execution_settings/groups/${groupId}/subgroups/${id}/edit`);
  };

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>
        {
          executionTradeTypes.map(({ tradePurpose, tradeSubPurpose }) => {
            const [className, purpose] = tradePurpose
              ? ['', I18n.t(`activerecord.attributes.fx_contract.trade_purposes.${tradePurpose}`)]
              : ['light', tradeSubPurpose];

            return (
              <span>
                <Label className={className}>{purpose}</Label>
                {' '}
              </span>
            );
          })
        }
      </td>
      <td>
        {
          executionLocalCurrencies.map(({ currencyIsoCode }) => (
            <span>
              <Label className="light">{currencyIsoCode}</Label>
              {' '}
            </span>
          ))
        }
      </td>
      <td>
        <DateTime dateTime={createdAt} />
      </td>
      <td>
        <DateTime dateTime={updatedAt} />
      </td>
      <td className="pull-right">
        <DropdownButton
          bsStyle="primary"
          title="Actions"
        >
          <MenuItem eventKey="1" onClick={handleEdit}>
            Edit
          </MenuItem>
          <MenuItem eventKey="2" onClick={handleRemove}>
            Delete
          </MenuItem>
          <MenuItem divider />
          <MenuItem eventKey="3" onClick={handleManageTradeTypes}>
            Manage Trade Types
          </MenuItem>
          <MenuItem eventKey="4" onClick={handleManageLocalCurrencies}>
            Manage Local Currencies
          </MenuItem>
        </DropdownButton>
      </td>
    </tr>
  );
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  executionTradeTypes: PropTypes.arrayOf(PropTypes.shape({
    tradePurpose: PropTypes.string,
    tradeSubPurpose: PropTypes.string,
  })).isRequired,
  executionLocalCurrencies: PropTypes.arrayOf(PropTypes.shape({
    currencyId: PropTypes.number.isRequired,
    currencyIsoCode: PropTypes.string.isRequired,
  })).isRequired,
};

export default Item;
