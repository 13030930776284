import connect from 'react-redux/es/connect/connect';
import { addCapstockException, removeCapstockException, changeCapstockException } from '../actions/capstockExceptions';
import { CapstockExceptionsSection } from '../components/capstockExceptionsSections';

const mapStateToProps = (state, props) => {
  const allExceptions = state.capstockExceptions[props.uuid] || [];
  /* eslint-disable no-underscore-dangle */
  return {
    exceptions: allExceptions.filter(exc => !exc._destroy),
    destroyedCapstockExceptions: allExceptions.filter(exc => exc._destroy),
  };
  /* eslint-enable no-underscore-dangle */
};

const mapDispatchToProps = {
  onRemove: removeCapstockException,
  onAdd: addCapstockException,
  onChange: changeCapstockException,
};

export const CapstockExceptionsContainer = connect(mapStateToProps,
  mapDispatchToProps)(CapstockExceptionsSection);
