import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import moment from 'moment';

const AccountWeightsCalendar = ({
  accountId, latestWeightsDate, onChange, previousValuationDate,
}) => {
  const handleOnChange = (dates) => {
    const formattedDates = dates.filter(Boolean).map(date => moment(date).format('YYYY-MM-DD'));

    onChange(accountId, formattedDates);
  };

  const latestDate = moment(latestWeightsDate, 'YYYY-MM-DD');
  const previousDate = previousValuationDate ? moment(previousValuationDate, 'YYYY-MM-DD') : latestDate;

  useEffect(() => {
    if (latestDate) {
      const dates = previousValuationDate
        ? [previousValuationDate, latestWeightsDate] : [latestWeightsDate];
      onChange(accountId, dates);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!latestDate.isValid()) {
    return (
      <Calendar
        disabled
      />
    );
  }

  return (
    <Calendar
      allowPartialRange
      defaultValue={[previousDate.toDate(), latestDate.toDate()]}
      returnValue="range"
      onChange={values => handleOnChange(values)}
      selectRange
    />
  );
};

AccountWeightsCalendar.propTypes = {
  accountId: PropTypes.number.isRequired,
  latestWeightsDate: PropTypes.string,
  previousValuationDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AccountWeightsCalendar.defaultProps = {
  latestWeightsDate: null,
  previousValuationDate: null,
};

export { AccountWeightsCalendar };
