import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormGroup,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';

const Component = (props) => {
  const {
    errors,
    onChange,
    name,
    value,
    label,
  } = props;

  return (
    <FormGroup validationState={errors.length > 0 ? 'error' : null}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl type="number" name={name} onChange={onChange} value={value} />
      <HelpBlock>{errors && errors.join(', ')}</HelpBlock>
    </FormGroup>
  );
};

Component.defaultProps = {
  value: '',
  errors: [],
};

Component.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default Component;
