import {
  REQUEST_BULK_REBALANCE_ACCOUNT,
  RECEIVE_CURRENCY_BALANCE_SHEETS,
} from '../actions/currencyBalanceSheets';

const initialState = {
  currencyBalanceSheets: {},
};

export default function currencyBalanceSheets(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BULK_REBALANCE_ACCOUNT:
      return {
        ...state,
        currencyBalanceSheets: {
          ...state.currencyBalanceSheets,
          ...action.payload.ids.reduce((acc, id) => {
            acc[id] = true;
            return acc;
          }, {}),
        },
      };
    case RECEIVE_CURRENCY_BALANCE_SHEETS:
      return {
        ...state,
        currencyBalanceSheets: {
          ...state.currencyBalanceSheets,
          ...action.payload.currencyBalanceSheets.reduce((acc, currencyBalanceSheet) => {
            acc[currencyBalanceSheet.id] = currencyBalanceSheet.account.blocked;
            return acc;
          }, {}),
        },
      };

    default:
      return state;
  }
}
