import { useCallback, useState } from 'react';
import Client from '../../../client';

export const useNoteSubmit = ({ onSubmit, entityType, entityId }) => {
  const [text, setText] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const submitNote = useCallback(() => {
    setSubmitting(true);
    Client
      .createNote({ note: { text, entity_type: entityType, entity_id: entityId } })
      .then(response => response.json())
      .then(({ note }) => {
        setSubmitting(false);
        onSubmit(note);
      });
  }, [onSubmit, setSubmitting, entityType, entityId, text]);

  return {
    setText, text, submitNote, submitting,
  };
};
