import connect from 'react-redux/es/connect/connect';
import { fetchCurrencyBalanceSheets, bulkCreateAccountFlag } from '../actions/currencyBalanceSheets';
import { fetchAccountFlagNotes } from '../actions/accountFlagsNotes';
import { ProcessingGroupTable } from '../components/processingGroupTable';


const mapStateToProps = (state, props) => {
  const processingGroup = state.processingGroups.processingGroups[props.id];
  const { processingGroups, defaultOrder } = state.processingGroupSorting;
  const order = processingGroups[props.id] || defaultOrder;

  return {
    ...processingGroup,
    accountFlagsNotes: state.accountFlagsNotes,
    currentFilters: state.processingGroups.filters,
    selected: processingGroup.currencyBalanceSheetIds.length === 0 ? false
      : processingGroup.currencyBalanceSheetIds.every(id => state.selectedSheets.selected[id]),
    order,
  };
};

const mapDispatchToProps = {
  onFetchCurrencyBalanceSheet: fetchCurrencyBalanceSheets,
  onBulkCreateAccountFlag: bulkCreateAccountFlag,
  onFetchAccountFlagNotes: fetchAccountFlagNotes,
};

export const ProcessingGroupTableContainer = connect(mapStateToProps,
  mapDispatchToProps)(ProcessingGroupTable);
