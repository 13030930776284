const EXCEPTION_BUSINESS_DAYS = 'CapstockSettlementDateExceptions::SetBusinessDaysFromTradeDate';
const EXCEPTION_FAR_OPEN_HEDGE = 'CapstockSettlementDateExceptions::UseFarOpenHedge';

const getSelectedType = (type, dateProvided) => {
  if (!type) return '';
  let prefix; let
    suffix;
  if (type === EXCEPTION_BUSINESS_DAYS) {
    prefix = 'business_days';
  } else if (type === EXCEPTION_FAR_OPEN_HEDGE) {
    prefix = 'far_hedge_date';
  } else {
    throw new Error('Unknown Type');
  }
  if (dateProvided) {
    suffix = 'date_provided';
  } else {
    suffix = 'no_date_provided';
  }
  return `${prefix}_${suffix}`;
};

const TYPE_OPTIONS = {
  BUSINESS_DAYS_DATE_PROVIDED: getSelectedType(EXCEPTION_BUSINESS_DAYS, true),
  BUSINESS_DAYS_NO_DATE_PROVIDED: getSelectedType(EXCEPTION_BUSINESS_DAYS, false),
  FAR_OPEN_HEDGE_DATE_PROVIDED: getSelectedType(EXCEPTION_FAR_OPEN_HEDGE, true),
  FAR_OPEN_HEDGE_NO_DATE_PROVIDED: getSelectedType(EXCEPTION_FAR_OPEN_HEDGE, false),
};

export {
  EXCEPTION_BUSINESS_DAYS, EXCEPTION_FAR_OPEN_HEDGE, TYPE_OPTIONS, getSelectedType,
};
