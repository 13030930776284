import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const Component = (props) => {
  const {
    currentPage,
    totalPages,
    onChange,
  } = props;

  const handleChange = (page) => {
    onChange(page);
  };

  const pages = [...Array(totalPages).keys()].map(x => x + 1);

  return (
    <Pagination bsSize="large">
      <Pagination.First onClick={() => { handleChange(1); }} />
      <Pagination.Prev onClick={() => { handleChange(currentPage - 1); }} />
      {
        pages
          .map(n => (
            <Pagination.Item
              active={currentPage === n}
              onClick={() => { handleChange(n); }}
              key={n}
            >
              {n}
            </Pagination.Item>
          ))
      }
      <Pagination.Next onClick={() => { handleChange(currentPage + 1); }} />
      <Pagination.Last onClick={() => { handleChange(totalPages); }} />
    </Pagination>
  );
};

Component.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Component;
