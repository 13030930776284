import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { ProcessingGroupBalanceSheetsContainer } from '../containers/processingGroupBalanceSheetsContainer';
import { ProcessingGroupActionsContainer } from '../containers/processingGroupActionsContainer';
import { flagSummary } from './commonPropTypes';
import {
  SortedProcessingGroupNameValueContainer,
  SortedProcessingGroupStatusValueContainer,
  SortedProcessingGroupIdValueContainer,
} from '../containers/sortedProcessingGroupNameFieldContainer';
import { LinkAsButton } from '../../../shared/linkAsButton';
import { FlagSummaryList } from './flagSummaryList';
import QuickView from '../../../notes/listing/components/quickView';

const BALANCE_SHEETS_PER_PAGE_RETRIEVED = 5;

export class ProcessingGroupTable extends React.Component {
  state = {
    showCbs: false,
  };

  componentDidMount() {
    const { toggleSheets } = this.props;

    if (toggleSheets) {
      this.sheetsToggle();
    }
  }

  componentDidUpdate(prevProps) {
    const { currencyBalanceSheetsPaginationMetadata } = this.props;
    if (_.isEmpty(currencyBalanceSheetsPaginationMetadata)) return;
    const { nextPage } = currencyBalanceSheetsPaginationMetadata;
    if (nextPage === null) return;
    if (prevProps.currencyBalanceSheetsPaginationMetadata.nextPage === nextPage) return;
    this.fetchCurrencyBalanceSheets(nextPage);
  }

  handleSheetsToggle = (e) => {
    e.preventDefault();
    this.sheetsToggle();
  };

  sheetsToggle = () => {
    const { showCbs: stateShowCbs } = this.state;
    const showCbs = !stateShowCbs;

    this.setState({ showCbs });

    if (!showCbs) return;

    this.fetchCurrencyBalanceSheets();
  };

  fetchCurrencyBalanceSheets = (page) => {
    const {
      currentFilters, id, onFetchCurrencyBalanceSheet, order,
    } = this.props;

    const params = {
      ...currentFilters,
      processingGroupId: id,
    };

    onFetchCurrencyBalanceSheet(params, order, page, BALANCE_SHEETS_PER_PAGE_RETRIEVED);
  }

  render = () => {
    const {
      id,
      name,
      note,
      accountFlagsNotes,
      flagSummaries,
      currentFilters,
      isFetchingCurrencyBalanceSheets,
      error,
      client,
      onBulkCreateAccountFlag,
      onFetchAccountFlagNotes,
    } = this.props;

    const { showCbs } = this.state;

    const notes = [note, client && client.note].filter(n => n);

    return (
      <React.Fragment>
        <tr
          className={classNames('processing-group-header', 'pg-header', {
            'processing-group-selected': showCbs,
          })}
        >
          <SortedProcessingGroupIdValueContainer>
            {id
              ? (
                <LinkAsButton
                  disabled={isFetchingCurrencyBalanceSheets}
                  onClick={this.handleSheetsToggle}
                  data-test-class="processingGroupId"
                  data-test-id={`processingGroupId${id || ''}`}
                  className="toggle-pg"
                >
                  {id}
                </LinkAsButton>
              )
              : ''
            }
          </SortedProcessingGroupIdValueContainer>
          <SortedProcessingGroupNameValueContainer>
            <LinkAsButton
              disabled={isFetchingCurrencyBalanceSheets}
              onClick={this.handleSheetsToggle}
              data-test-class="processingGroupName"
              data-test-id={`processingGroupName${id || 0}`}
              className="toggle-pg"
            >
              {name}
            </LinkAsButton>
          </SortedProcessingGroupNameValueContainer>
          <SortedProcessingGroupStatusValueContainer>
            <FlagSummaryList
              accountFlagsNotes={accountFlagsNotes}
              flagSummaries={flagSummaries}
              processingGroupId={id}
              clientId={client && client.id}
              onBulkCreateAccountFlag={onBulkCreateAccountFlag}
              currentFilters={currentFilters}
              onFetchAccountFlagNotes={onFetchAccountFlagNotes}
            />
          </SortedProcessingGroupStatusValueContainer>
          <td className="text-right">
            {notes.map(n => (
              <QuickView key={n.id} note={n} />
            ))}
          </td>
          <td className="text-right">
            {id !== 0 && (
              <ProcessingGroupActionsContainer
                processingGroupId={id}
                currentFilters={currentFilters}
              />
            )}
          </td>
        </tr>
        {
          error && !isFetchingCurrencyBalanceSheets ? (
            <tr className="text-center">
              <td colSpan={9} className="currency-balance-sheet-error">
                <div>An error occurred while loading the currency balance sheets:</div>
                <div>{error}</div>
                <LinkAsButton onClick={this.handleSheetsToggle}>Retry</LinkAsButton>
              </td>
            </tr>
          ) : (
            <ProcessingGroupBalanceSheetsContainer expanded={showCbs} processingGroupId={id} />
          )
        }

      </React.Fragment>
    );
  };
}

const noteType = PropTypes.shape({ text: PropTypes.string.isRequired });

ProcessingGroupTable.propTypes = {
  flagSummaries: PropTypes.arrayOf(flagSummary).isRequired,
  /* eslint-disable react/forbid-prop-types */
  accountFlagsNotes: PropTypes.object.isRequired,
  currentFilters: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  onFetchCurrencyBalanceSheet: PropTypes.func.isRequired,
  currencyBalanceSheetsPaginationMetadata: PropTypes.shape({
    nextPage: PropTypes.number,
    previousPage: PropTypes.number,
    totalPages: PropTypes.number,
    page: PropTypes.number,
  }),
  isFetchingCurrencyBalanceSheets: PropTypes.bool.isRequired,
  error: PropTypes.string,
  note: noteType,
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyName: PropTypes.string,
    shortCompanyName: PropTypes.string,
    note: noteType,
  }),
  toggleSheets: PropTypes.bool,
  onBulkCreateAccountFlag: PropTypes.func.isRequired,
  onFetchAccountFlagNotes: PropTypes.func.isRequired,
};

ProcessingGroupTable.defaultProps = {
  id: null,
  currencyBalanceSheetsPaginationMetadata: {},
  client: null,
  error: null,
  note: null,
  toggleSheets: false,
};
